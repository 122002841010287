import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { BlockTitle, ProfileDataItems, ProfileDataForm } from '../../components';
import './profile-data.scss';

const ProfileData = (
        {
            title,
            description,
            phoneReplacement,
            openReplacementPhoneModalHandler,
            openConfirmReplacementPhoneModalHandler,
            openConfirmCancelReplacementPhoneModalHandler,
            openReplacementEmailModalHandler,
            openConfirmReplacementEmailModalHandler,
            items,
            onSubmit,
            errors,
            back,
            kid
        }
    ) => {

    const [isEditable, setIsEditable] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (errors) {
            setIsEditable(true);
        } else {
            setIsEditable(false);
        }
    }, [errors, items]);

    const handleEditableClick = () => {
        setIsEditable(state => !state);
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <section className="profile-data" ref={ref}>
            <div className="profile-data__header">
                <BlockTitle
                    title={title}
                    description={description}
                    back={back}
                />
            </div>
            <div className="profile-data__body">
                {!isEditable ?
                    <ProfileDataItems
                        items={items}
                        phoneReplacement={phoneReplacement}
                        openReplacementPhoneModalHandler={openReplacementPhoneModalHandler}
                        openConfirmReplacementPhoneModalHandler={openConfirmReplacementPhoneModalHandler}
                        openConfirmCancelReplacementPhoneModalHandler={openConfirmCancelReplacementPhoneModalHandler}
                        openReplacementEmailModalHandler={openReplacementEmailModalHandler}
                        openConfirmReplacementEmailModalHandler={openConfirmReplacementEmailModalHandler}
                        kid={kid}
                    />
                :
                    <ProfileDataForm items={items} kid={kid} onSubmit={onSubmit} errors={errors} />
                }
                <button
                    type="button"
                    className={classNames({
                        'profile-data__edit-button': true,
                        'active': isEditable
                    })}
                    onClick={handleEditableClick}
                >
                    {isEditable ? 'Отменить изменение' : 'Изменить данные'}
                </button>
            </div>
        </section>
    );
};

ProfileData.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string,
            value: PropTypes.string.isRequired | PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired | PropTypes.number.isRequired,
                    label: PropTypes.string.isRequired
                })
            ),
            editable: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired
        })
    )
};

ProfileData.defaultProps = {
    phoneReplacement: {},
    openReplacementPhoneModalHandler: () => {},
    openConfirmReplacementPhoneModalHandler: () => {},
    openConfirmCancelReplacementPhoneModalHandler: () => {},
    openReplacementEmailModalHandler: () => {},
    openConfirmReplacementEmailModalHandler: () => {}
}

export default ProfileData;