import ordersTypes from '../types/orders';

export const setOrders = payload => ({
    type: ordersTypes.SET_ORDERS,
    payload,
});

export const setActiveOrders = payload => ({
    type: ordersTypes.SET_ACTIVE_ORDERS,
    payload,
});

export const setDetailOrder = payload => ({
    type: ordersTypes.SET_DETAIL_ORDER,
    payload,
});

export const cancelOrder = payload => ({
    type: ordersTypes.CANCEL_ORDER,
    payload,
});

export const setFiltersStatus = payload => ({
    type: ordersTypes.SET_FILTERS_STATUS,
    payload,
});

export const setCurrentFiltersStatus = payload => ({
    type: ordersTypes.SET_CURRENT_FILTERS_STATUS,
    payload,
});

export const setFiltersYear = payload => ({
    type: ordersTypes.SET_FILTERS_YEAR,
    payload,
});

export const setCurrentFiltersYear = payload => ({
    type: ordersTypes.SET_CURRENT_FILTERS_YEAR,
    payload,
});

export const resetFilters = () => ({
    type: ordersTypes.RESET_FILTERS,
});

export const setLoadedOrders = payload => ({
    type: ordersTypes.SET_LOADED_ORDERS,
    payload,
});

export const setLoadedActiveOrders = payload => ({
    type: ordersTypes.SET_LOADED_ACTIVE_ORDERS,
    payload,
});

export const setErrors = payload => ({
    type: ordersTypes.SET_ERRORS,
    payload,
});