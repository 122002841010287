import { useEffect } from 'react';
import { getBrowserToken, onMessageListener } from '../service/firebase';
import { getNotificationsSettings, sendBrowserToken } from '../store/actions/notifications';
import { useDispatch, useSelector } from 'react-redux';
import parseSettingsForPushNotifications from '../utils/parseSettingsForPushNotifications';

export const useNotification = isAuth => {
    const { settings, isLoaded } = useSelector(state => state.notifications);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuth) return;

        if (!isLoaded && Object.keys(settings).length === 0) {
            dispatch(getNotificationsSettings());
            return;
        }

        const pushSettings = parseSettingsForPushNotifications(settings);
        const isPushSettingsOn = !!pushSettings.find(item => item.value === true);

        if (isPushSettingsOn) {
            getBrowserToken()
                .then(token => {
                    dispatch(sendBrowserToken(token))
                })
                .catch(e => console.error(e));
        }

    }, [isAuth, settings]);

    useEffect(() => {
        onMessageListener();
    }, [isAuth]);
}