import { CustomLink } from '../index';
import { Checkbox } from 'pretty-checkbox-react';
import classNames from 'classnames/bind';
import './custom-checkbox-register-form.scss';

const CustomCheckboxRegisterForm = ({ label, link, state, required, error, ...props }) => {
    return (
        <div className="custom-checkbox-wrapper">
            <Checkbox className={classNames({
                'custom-checkbox': true,
                'checked': state
            })}
                icon={<img className="custom-checkbox__icon" src="/images/checkmark.svg"/>}
                state={state}
                required={required}
                {...props}
            >
            </Checkbox>
            <div className="custom-checkbox__privacy-policy-block">
                <CustomLink
                    to={link}
                    target="_blank"
                    className="custom-checkbox__link"
                    rel="noopener noreferrer"
                >
                    {label}
                </CustomLink>
                {required && <span className="required-mark">*</span>}
            </div>
            {error && <div className="error-text">{error}</div>}
        </div>
    );
};

export default CustomCheckboxRegisterForm;