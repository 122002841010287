import loadingTypes from './../types/loading';

const initialState = {
    isLoading: false,
};

export const loadingReducer = (state = initialState, action) => {
    switch(action.type) {
        case loadingTypes.SET_LOADING:
            return {...state, isLoading: action.payload}

        default:
            return state
    }
}