import { useDispatch, useSelector } from 'react-redux';
import { replaceEmail, replaceEmailCall } from '../../store/actions/replacement-email';
import { IoCloseOutline } from 'react-icons/io5';
import './modal-replacement-email.scss';
import { Button, Input } from "../UI";
import { useInput } from "../../hooks/useInput";
import {useEffect} from "react";

const ModalReplacementEmail = ({closeHandler, openConfirmHandler}) => {
    const dispatch = useDispatch();
    const { state: newEmail, handleChange: changeNewEmail, clearInput } = useInput('');
    const { errors, isNeedCall } = useSelector(state => state.replacementEmail);

    const onFormClick = (event) => event.stopPropagation();

    const submitModalForm = (event) => {
        event.preventDefault();
        dispatch(replaceEmail(newEmail))
            .then(() => {
                closeHandler();
                openConfirmHandler();
            })
            .catch(() => clearInput());
    }

    useEffect(() => {
        if (isNeedCall) {
            dispatch(replaceEmailCall(newEmail))
                .then(() => {
                    closeHandler();
                    openConfirmHandler();
                })
                .catch(() => {})
        }
    }, [isNeedCall])

    return (
        <form className="modal-replacement-email" onSubmit={submitModalForm} onClick={onFormClick}>
            <div className="modal-replacement-email__inner-block">
                <div className="modal-replacement-email__close-icon" onClick={closeHandler}>
                    <IoCloseOutline size="40"/>
                </div>
                <div className="modal-replacement-email__title">Введите новый адрес электронной почты</div>
                <div className="input-wrapp">
                    <Input
                        type="email"
                        label="Адрес электронной почты"
                        name="email"
                        placeholder="Email"
                        value={newEmail}
                        handleChange={changeNewEmail}
                        error={(errors && errors['email']) ? errors['email'] : errors}
                        required
                    />
                </div>
                <div className="modal-replacement-email__submit-btn">
                    <Button className="submit-btn" type="submit">Далее</Button>
                </div>
            </div>
        </form>
    );
}

export default ModalReplacementEmail;