const favoritesTypes = {
    SET_ITEMS: 'FAVORITES/SET_ITEMS',
    SET_PAGE: 'FAVORITES/SET_PAGE',
    SET_TOTAL_PAGES: 'FAVORITES/SET_TOTAL_PAGES',
    SET_CATEGORIES: 'FAVORITES/SET_CATEGORIES',
    SET_SORT: 'FAVORITES/SET_SORT',
    SET_FILTERS_CATEGORIES: 'FAVORITES/SET_FILTERS_CATEGORIES',
    SET_FILTERS_AVAILABILITY: 'FAVORITES/SET_FILTERS_AVAILABILITY',
    RESET_FILTERS: 'FAVORITES/RESET_FILTERS',
    SET_IS_LOADING: 'FAVORITES/SET_IS_LOADING',
    SET_ERROR: 'FAVORITES/SET_ERROR',
};

export default favoritesTypes;