import { useSelector } from 'react-redux';
import { InviteBanner, CardAndCode } from '../../components';

const CardOrBanner = () => {
    const isQuestionnaireCompleted = useSelector(state => state.user.questionnaireCompleted);
    const isParticipation = useSelector(state => state.user.participation);

    return (
        <>
            {(isQuestionnaireCompleted || isParticipation) ?
                <CardAndCode />
                :
                <InviteBanner
                    title="Дисконтная карта покупателя"
                    description="Дорогие друзья! Мы просим вас заполнить анкету для получения дисконтной карты и реализации нашей общей цели: помочь подрастающему поколению сформироваться физически и духовно здоровыми людьми через вовлечение в занятия различными видами спорта. Мы уверены, что нам по пути!"
                    link="/anketa"
                    linkText="Заполнить анкету покупателя"
                />
            }
        </>
    );
};

export default CardOrBanner;