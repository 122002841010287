import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { confirmEmail } from '../../store/actions/email';
import {PageTitle} from "../../components";
import { Button } from "../../components/UI";
import getConfirmEmailData from '../../utils/getConfirmEmailData';
import getConfirmEmailUrlQueryParams from '../../utils/getConfirmEmailUrlQueryParams';
import './confirm-email.scss';


const ConfirmEmail = ({ title }) => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const { isEmailConfirmed, loaded } = useSelector(state => state.email);
    const { isLoading } = useSelector(state => state.loading);
    const { userId, token }  = getConfirmEmailUrlQueryParams(search);

    useEffect(() => {
        if(!loaded) {
            dispatch(confirmEmail(userId, token));
        }
    }, []);

    let error = false;
    if(!userId || !token)
        error = true;

    const { img, mainTitle, subTitle } = getConfirmEmailData(isEmailConfirmed);

    const repeatConfirmEmail = (userId, token) => () => dispatch(confirmEmail(userId, token))
    const repeatBtn = !isEmailConfirmed
        ? <Button className="repeat-button button__red" handleClick={repeatConfirmEmail(userId, token)}>Отправить повторно</Button>
        : '';

    const content = (
        <>
            <div className="confirm-email">
                <div className="confirm-email__img">
                    {img}
                </div>
                <div className="confirm-email__text-block">
                    <p className="confirm-email__title">{mainTitle}</p>
                    { !error ? <p className="confirm-email__subtitle">{subTitle}</p> : '' }
                </div>
            </div>
            <div className="confirm-email__btn-repeat">
                { !error ? repeatBtn : '' }
            </div>
        </>
    );

    return (
        <>
            <PageTitle title={title} />
            <section className="page">
                { !isLoading && isEmailConfirmed !== null ? content : '' }
            </section>
        </>
    )
}

export default ConfirmEmail;