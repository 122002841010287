import directoriesTypes from '../types/directories';

const initialState = {
    userDirectories: null,
    childDirectories: null,
};

export const directoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case directoriesTypes.SET_USER_DIRECTORIES:
            return {...state, userDirectories: action.payload}
        case directoriesTypes.SET_CHILD_DIRECTORIES:
            return {...state, childDirectories: action.payload}

        default:
            return state
    }
}