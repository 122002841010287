import trainerTypes from '../types/trainer';

export const setRemainder = payload => ({
    type: trainerTypes.SET_REMAINDER,
    payload,
});

export const setRefCode = payload => ({
    type: trainerTypes.SET_REF_CODE,
    payload,
});

export const setMonths = payload => ({
    type: trainerTypes.SET_MONTHS,
    payload,
});

export const setFilterMonth = payload => ({
    type: trainerTypes.SET_FILTER_MONTH,
    payload,
});

export const setTransactions = payload => ({
    type: trainerTypes.SET_TRANSACTIONS,
    payload,
});

export const setIsLoadedRemainder = payload => ({
    type: trainerTypes.SET_IS_LOADED_REMAINDER,
    payload,
});

export const setIsLoadedMonths = payload => ({
    type: trainerTypes.SET_IS_LOADED_MONTHS,
    payload,
});

export const setIsLoadedTransactions = payload => ({
    type: trainerTypes.SET_IS_LOADED_TRANSACTIONS,
    payload,
});

export const setErrorRemainder = payload => ({
    type: trainerTypes.SET_ERROR_REMAINDER,
    payload,
});

export const setErrorMonths = payload => ({
    type: trainerTypes.SET_ERROR_MONTHS,
    payload,
});

export const setErrorTransactions = payload => ({
    type: trainerTypes.SET_ERROR_TRANSACTIONS,
    payload,
});

export const setTotalPages = payload => ({
    type: trainerTypes.SET_TOTAL_PAGES,
    payload,
});

export const setPage = payload => ({
    type: trainerTypes.SET_PAGE,
    payload,
});