import notificationsTypes from '../types/notifications';

const initialState = {
    settings: {},
    isLoaded: false,
    errors: null
};

export const notificationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case notificationsTypes.SET_SETTINGS:
            return {...state, settings: action.payload}
        case notificationsTypes.SET_LOADED:
            return {...state, isLoaded: action.payload}
        default:
            return state
    }
}