import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormRegister, CustomLink } from '../../components/'
import {checkRedirect, login} from '../../store/actions/auth';
import './register.scss';

const Register = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();

    if (!state || !state.phoneNumber) {
        return <Redirect to={process.env.REACT_APP_REDIRECT_NON_AUTH} />
    }

    const signIn = () => {
        dispatch(checkRedirect())
            .then(() => {})
            .catch(() => dispatch(login()));
    }

    return (
        <div className="form-auth">
            <div className="form-auth__header auth-header">
                <div className="form-auth__title auth-header__item">Регистрация</div>
                <div className="already-registered auth-header__item">
                    <span className="already-registered__text">Уже зарегистрированы?</span>
                    <CustomLink to={'/login'}>Войти</CustomLink>
                </div>
            </div>

            <div className="form-auth__body">
                <FormRegister onSubmit={signIn} />
            </div>

        </div>
    );
};

export default Register;