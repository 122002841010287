import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAddress, fetchAddress, fetchAddresses } from '../../store/actions/addresses';
import { setError } from "../../store/action-creators/addresses";
import { PageTitle, BlockTitle, Backdrop, ModalAddNewAddress, ModalEditAddress, CardAddress, Pagination } from '../../components';
import { usePagination } from '../../hooks/usePagination';
import { PAGE_ELEMENT_COUNT } from '../../const';
import { IoLocationOutline } from 'react-icons/io5';
import './addresses.scss';

const Addresses = ({ title }) => {
    const dispatch = useDispatch();
    const { addresses, loaded, totalPages } = useSelector(state => state.address);
    const [isShowingAddModal, setIsShowingAddModal] = useState(false);
    const [isShowingEditModal, setIsShowingEditModal] = useState(false);
    const [currentPage, changePage] = usePagination(
        () => dispatch(fetchAddresses({ pageNumber: currentPage, pageElementCount: PAGE_ELEMENT_COUNT }))
    );


    useEffect(() => {
        if(!loaded) {
            dispatch(fetchAddresses());
        }
    }, []);

    const openAddModalHandler = () => setIsShowingAddModal(true);

    const closeAddModalHandler = () => {
        dispatch(setError(null));
        setIsShowingAddModal(false);
    };

    const openEditModalHandler = (id) => (event) => {
        event.stopPropagation();
        dispatch(fetchAddress(id));
        setIsShowingEditModal(true);
    };

    const closeEditModalHandler = () => setIsShowingEditModal(false);


    const deleteAddressHandler = (addressId, currentPage) => (event) => {
        event.stopPropagation();

        if(window.confirm('Вы уверены, что хотите удалить адрес доставки?'))
            dispatch(deleteAddress(addressId, currentPage)).then((newCurrentPage) => changePage(newCurrentPage));

    }

    let renderModalForm = '';

    if(isShowingAddModal) {
       renderModalForm = (<Backdrop closeHandler={closeAddModalHandler}>
            <ModalAddNewAddress closeHandler={closeAddModalHandler}/>
        </Backdrop>);
    }

    if(isShowingEditModal) {
        renderModalForm = (<Backdrop closeHandler={closeEditModalHandler}>
            <ModalEditAddress closeHandler={closeEditModalHandler}/>
        </Backdrop>);
    }

    return (
        <>
            <PageTitle title={title} />
            <section className="page addresses-page">
                {renderModalForm}
                <div className="addresses">
                    <div className="addresses__header">
                        <BlockTitle title="Адреса доставки" description="Ваши адреса для доставки"/>
                    </div>
                    <div className="addresses__body">
                        <div className="addresses__list">
                            {
                                addresses.map((item, index) => (
                                    <CardAddress
                                        key={item.id + index}
                                        item={item}
                                        openEditModalHandler={openEditModalHandler(item.id)}
                                        deleteAddressHandler={deleteAddressHandler(item.id, currentPage)}
                                    />
                                ))
                            }
                            {
                                totalPages === currentPage && <button className="addresses__button add" onClick={openAddModalHandler}>
                                                                <IoLocationOutline size="40"/>
                                                                <span className="addresses__button-text add-text">+ Добавить адрес</span>
                                                            </button>
                            }
                        </div>
                    </div>
                    {   addresses && addresses.length > 0 &&
                        <div className="pagination-block">
                            <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={changePage} />
                        </div>
                    }
                </div>
            </section>
        </>
    );
};

export default Addresses;