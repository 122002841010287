import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, CustomCheckbox, CustomCheckboxRegisterForm } from '../../components';
import { register } from '../../store/actions/auth';
import { setError } from '../../store/action-creators/auth';
import { useInput } from '../../hooks/useInput';
import './form-register.scss';

import { sendRegisterFormToMetrika } from "../../service/sendRegisterFormToMetrika";
import { sendClientIdFromMetrika } from "../../service/sendClientIdFromMetrika";

const FormRegister = ({ onSubmit }) => {
    const { state: params } = useLocation();
    const error = useSelector(state => state.auth.error);
    const dispatch = useDispatch();
    const { state: form, handleChange } = useInput({
        name: '',
        surname: '',
        patronymic: '',
        email: '',
        password: '',
    });
    const [isConsent, setIsConsent] = useState(false)

    useEffect(() => {
        dispatch(setError(null));
    }, [])

    const confirmData = e => {
        e.preventDefault();
        const data = {...form, phone: params.phoneNumber, consent: isConsent};
        dispatch(register(data))
            .then(() => sendRegisterFormToMetrika())
            .then(() => onSubmit())
            .catch(() => {});
    }

    return (
        <form className="form-register" onSubmit={confirmData}>
            {error && typeof error !== 'object' && <span className="error-text">{error}</span>}
            <Input
                autoFocus
                type="text"
                label="Ваше имя"
                name="name"
                placeholder="Имя"
                value={form.name}
                handleChange={handleChange}
                required
                error={(error && error['name']) ? error['name'] : ''}
            />
            <Input
                type="text"
                label="Ваша фамилия"
                name="surname"
                placeholder="Фамилия"
                value={form.surname}
                handleChange={handleChange}
                required
                error={(error && error['surname']) ? error['surname'] : ''}
            />
            <Input
                type="text"
                label="Ваше отчество"
                name="patronymic"
                placeholder="Отчество"
                value={form.patronymic}
                handleChange={handleChange}
                error={(error && error['patronymic']) ? error['patronymic'] : ''}
            />
            <Input
                type="email"
                label="Ваш e-mail"
                name="email"
                placeholder="E-mail"
                value={form.email}
                handleChange={handleChange}
                required
                error={(error && error['email']) ? error['email'] : ''}
            />
            <Input
                type="password"
                label="Ваш пароль"
                name="password"
                placeholder="Пароль"
                value={form.password}
                handleChange={handleChange}
                minLength={6}
                required
                error={(error && error['password']) ? error['password'] : ''}
            />
            <CustomCheckboxRegisterForm
                name="consent"
                label="Согласен на обработку персональных данных"
                link="/privacy-policy"
                state={isConsent}
                setState={setIsConsent}
                onChange={() => setIsConsent(state => !state)}
                required
                error={(error && error['consent']) ? error['consent'] : ''}
            />
            <div className="required-text"><span className="required-mark">*</span> - обязательные для заполнения поля</div>

            <Button type="submit" className="button__black register__button">Продолжить</Button>

        </form>
    );
};

export default FormRegister;