import favoritesTypes from '../types/favorites';
import { sortItems, availabilityFilters } from '../../const';

const initialState = {
    items: [],
    categories: [],
    page: 1,
    totalPages: null,
    sort: {
        name: sortItems.favorites[0].name,
        title: sortItems.favorites[0].title,
        by: sortItems.favorites[0].value.by,
        order: sortItems.favorites[0].value.order
    },
    filters: {
        availability: availabilityFilters[0],
        categories: []
    },
    isLoading: false,
    error: null,
};

export const favoritesReducer = (state = initialState, action) => {
    switch(action.type) {
        case favoritesTypes.SET_ITEMS:
            return { ...state, items: action.payload }
        case favoritesTypes.SET_TOTAL_PAGES:
            return { ...state, totalPages: action.payload }
        case favoritesTypes.SET_PAGE:
            return { ...state, page: action.payload }
        case favoritesTypes.SET_CATEGORIES:
            return { ...state, categories: action.payload }
        case favoritesTypes.SET_SORT:
            return { ...state, sort: action.payload }
        case favoritesTypes.SET_FILTERS_CATEGORIES:
            return { ...state, filters: { ...state.filters, categories: action.payload } }
        case favoritesTypes.SET_FILTERS_AVAILABILITY:
            return { ...state, filters: { ...state.filters, availability: action.payload } }
        case favoritesTypes.RESET_FILTERS:
            return { ...state, filters: initialState.filters }
        case favoritesTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload }
        case favoritesTypes.SET_ERROR:
            return { ...state, error: action.payload }
        default:
            return state;
    }
}