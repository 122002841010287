import { setLoading } from '../action-creators/loading';
import {
    setNewPhone,
    setSenderPhone,
    setSmsSentToChangePhone,
    setSmsSentToCancelChangePhone,
    setTypeDispatch,
    setExistTask,
    setDaysLeft,
    setTimer,
    setError
} from '../action-creators/replacement-phone';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';

export const replacePhone = (newPhone) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('phone', newPhone);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/send-sms-code-to-change-phone',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Одноразовый код успешно отправлен!');
            dispatch(setSmsSentToChangePhone(true));
            dispatch(setSenderPhone(data.phone));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setError(null));
            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setTimer(data.time));
                return Promise.reject();
            }

            if(data.call) {
                dispatch(setError(data.errors));
                dispatch(setTypeDispatch("CALL"));
                return Promise.reject();
            }

            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при отправке смс. Попробуйте еще');
        console.error(e);
    }
}

export const confirmReplacePhone = (confirmCode) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('confirm_code', confirmCode);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/confirm-to-change-phone',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Операция по замена номера успешно подтверждена!');
            dispatch(setExistTask(true));
            dispatch(setNewPhone(data.phone));
            dispatch(setDaysLeft(data.days_left));
            dispatch(setSmsSentToChangePhone(false));
            dispatch(setSenderPhone(""));
            dispatch(setError(null));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            return Promise.resolve();
        } else {
            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при подтверждении операции. Попробуйте еще');
        console.error(e);
    }
}

export const cancelReplacePhone = () => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/send-sms-code-to-cancel-change-phone',
            data: new FormData(),
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Одноразовый код успешно отправлен!');
            dispatch(setSmsSentToCancelChangePhone(true));
            dispatch(setSenderPhone(data.phone));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setError(null));
            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setTimer(data.time));
                return Promise.reject();
            }

            if(data.call) {
                dispatch(setTypeDispatch("CALL"));
                dispatch(setError(data.errors));
                return Promise.reject();
            }

            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при отправке смс. Попробуйте еще');
        console.error(e);
    }
}

export const confirmCancelReplacePhone = (confirmCode) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('confirm_code', confirmCode);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/confirm-to-cancel-change-phone',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Операция по замена номера успешно отменена!');
            dispatch(setExistTask(false));
            dispatch(setNewPhone(""));
            dispatch(setDaysLeft(""));
            dispatch(setSmsSentToCancelChangePhone(false));
            dispatch(setSenderPhone(""));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setError(null));
            return Promise.resolve();
        } else {
            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при подтверждении. Попробуйте еще');
        console.error(e);
    }
}

export const replacePhoneCall = (newPhone) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('confirm_code', newPhone);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/call-number-code-to-change-phone',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            dispatch(setSmsSentToChangePhone(true));
            dispatch(setSenderPhone(data.phone));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setError(null));
            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setTimer(data.time));
                return Promise.reject();
            }

            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при звонке. Попробуйте еще');
        console.error(e);
    }
}

export const cancelReplacePhoneCall = (newPhone) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('confirm_code', newPhone);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-number/call-number-code-to-cancel-change-phone',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            dispatch(setSmsSentToCancelChangePhone(true));
            dispatch(setSenderPhone(data.phone));
            dispatch(setTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setError(null));

            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setTimer(data.time));
                return Promise.reject();
            }

            dispatch(setError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        toast.error('Произошла ошибка при звонке. Попробуйте еще');
        console.error(e);
    }
}