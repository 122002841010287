import { BlockTitle, PageTitle, TrainerRegisterForm } from '../../../components';
import './trainer-register.scss';

const TrainerRegister = ({ title }) => {
    return (
        <>
            <PageTitle title={title} />
            <section className="page trainer-register-page">
                <div className="trainer-register-page__wrap">
                    <div className="trainer-register__header">
                        <BlockTitle
                            title={`Анкета тренера "Нужный спорт"`}
                            description="Уважаемый тренер! Мы просим Вас заполнить все поля анкеты для получения личной карты тренера, корректного и полного начисления вознаграждений за покупки воспитанников в личном кабинете. Благодарим, что Вы с нами."
                        />
                    </div>
                    <div className="trainer-register__body">
                        <TrainerRegisterForm />
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrainerRegister;