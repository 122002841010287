import { PageTitle } from "../../components";

const AgreementOffer = ({ title }) => {
    return (
        <div className="container privacy-policy-container">
            <PageTitle title={title} />
            <section className="page ">
                <div className="privacy-policy">
                    <div className="privacy-policy__header">
                        <h2 className="privacy-policy__title">Договор-оферта интернет-магазина
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                        </h2>
                        <p className="block-description">
                            ИП Зыков М.А., именуемое в дальнейшем "Продавец" с одной стороны и лицо, к
                            которому направлена данная официальная публичная оферта, именуемое в дальнейшем
                            «Покупатель» с другой стороны, заключили настоящий Договор о нижеследующем:
                        </p>
                    </div>
                    <div className="privacy-policy__body">
                        <h3 className="privacy-policy__body-title">1. ВВЕДЕНИЕ</h3>
                        <p>1.1. В настоящей оферте, если контекст не требует иного, нижеприведенные термины
                            имеют следующие значения и являются ее составной неотъемлемой частью:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                «Интернет-магазин» – официальный сайт Продавца ИП Зыкова М.А., расположенный по
                                интернет адресу:
                                <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                                {'\u00A0'}и предназначенный для заключения Договоров
                                розничной и оптовой купли-продажи на основании ознакомления Покупателя с
                                предложенным Продавцом описанием Товара и/или представленным на фотоснимках,
                                посредством сети интернет, исключающей возможность непосредственного
                                ознакомления Покупателя с Товаром - дистанционный способ продажи Товара
                            </li>
                            <li className="secure-data__item">
                                «Продавец» - ИП Зыков М.А. Юридический адрес: 625034 Тюменская обл., г. Тюмень, ул.
                                Судостроителей, д. 38, кв. 825. Почтовый адрес:614990 г. Пермь ул. Куйбышева, 115 Б ИНН
                                590414001017 Зарегистрирован Администрацией Свердловского р-на г. Перми 13.05.2003
                                г. ГРНИП 304590411100151 Св-во о внесении в ЕГРИП серия 59 № 002997800 Выдано
                                20.04.2004 г. Инспекцией МНС по Свердловскому району г. Перми ОКПО
                                0121423948 ОКАТО 71401000000 ОКВЭД 47 ОКОПФ 91 Р/сч. 40802810918420000005 В
                                Филиале «Центральный» Банка ВТБ (ПАО) в г. Москве БИК 044525411 Кор.сч.
                                30101810145250000411
                            </li>
                            <li className="secure-data__item">
                                «Покупатель» – любое физическое или юридическое лицо, имеющее намерение
                                заказать или приобрести Товары, разместившее свой заказ в Интернет-магазине, либо
                                лицо, указанное в заказе в качестве Покупателя, либо лицо акцептировавшее публичную
                                оферту на условиях настоящей оферты.
                            </li>
                            <li className="secure-data__item">
                                «Товар» - объект купли-продажи (соглашение сторон), не изъятый и не ограниченный в
                                гражданском обороте и представленный к продаже в Интернет-магазине
                                <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>,
                                посредством размещения в соответствующем разделе Интернет-магазина.
                            </li>
                            <li className="secure-data__item">
                                «Заказ» – должным образом оформленный запрос от Покупателя на покупку и доставку
                                Товаров, выбранных в Интернет-магазине.
                            </li>
                            <li className="secure-data__item">
                                «Транспортная компания» – организации, привлекаемые на основании Договора с
                                Продавцом для доставки Заказов Покупателям.
                            </li>
                        </ul>
                        <h3 className="privacy-policy__body-title">2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                        <p>
                            2.1. Настоящая публичная Оферта (в дальнейшем - Договор) согласно ст. 437
                            Гражданского Кодекса РФ является официальным предложением Продавца в адрес
                            любого Покупателя и содержит все существенные условия организации купли-продажи
                            дистанционным способом (т. е. через интернет-магазин).
                        </p>
                        <p>
                            Настоящий Договор и информация, размещенная в Интернет-магазине, содержат
                            предложение по покупке Товара согласно указанным условиям, и представляют собой
                            публичную оферту в соответствии со ст. 435 и ч. 2 ст. 437 Гражданского кодекса РФ.
                            Отсутствие подписанного между сторонами экземпляра Договора на бумажном носителе
                            не является основанием считать Договор купли-продажи незаключенным.
                        </p>
                        <p>
                            Оплата сделанного Заказа считается акцептом Покупателем оферты согласно п. 3 ст.
                            438 Гражданского кодекса РФ.
                        </p>
                        <p>
                            2.4. Отношения в области защиты прав любой из Сторон настоящего Договора
                            регулируются Гражданским кодексом РФ, Законом «О защите прав потребителей»,
                            Постановлением Правительства РФ от 31.12.2020 N 2463 "Об утверждении Правил
                            продажи товаров по договору розничной купли-продажи, перечня товаров длительного
                            пользования, на которые не распространяется требование потребителя о безвозмездном
                            предоставлении ему товара, обладающего этими же основными потребительскими
                            свойствами, на период ремонта или замены такого товара, и перечня
                            непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о
                            внесении изменений в некоторые акты Правительства Российской Федерации"
                        </p>
                        <p>
                            2.5. Все информационные материалы, представленные в интернет-магазине, носят
                            справочный характер и не могут в полной мере передавать полную информацию о
                            свойствах и характеристиках Товара, включая цвет, размеры и формы. В случае
                            возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара,
                            перед оформлением Заказа, Покупатель может обратиться к Продавцу.
                        </p>
                        <p>
                            2.6. Оформление Покупателем Заказа и дальнейшая передача его к исполнению, означает
                            достаточное и полное ознакомление Покупателя с Товаром, с информацией о наличии
                            (отсутствии) скидок, а также о влиянии условий Заказа на формирование конечной цены
                            Товара.
                        </p>
                        <p>
                            2.7. В случае отсутствия Заказанных Товаров на складе Продавца, в том числе по
                            причинам, не зависящим от Продавца, Продавец вправе аннулировать указанный Товар
                            из Заказа Покупателя, и уведомить об этом Покупателя путем направления электронного
                            сообщения по электронному адресу и/или по телефону, указанным при формировании
                            Покупателем Заказа.
                        </p>
                        <p>
                            2.8. Заказ считается исполненным, а право собственности на Товар и связанные с этим
                            риски переходят от Продавца к Покупателю в момент 100% оплаты и приемки
                            Покупателем Товара, подтверждением чего является подпись в накладной (или ином
                            документе, подтверждающем передачу Товара Покупателю). С этого момента Заказ
                            считается исполненным, а обязательства Продавца перед Покупателем считаются
                            выполненными в полном объеме.
                        </p>
                        <p>
                            2.9. В случае подписания Покупателем накладной (или ином документе,
                            подтверждающем передачу Товара Покупателю) на заказ без вскрытия Заказа, проверки и
                            без составления акта, Покупатель не может предъявить претензии Продавцу по данному
                            Заказу.
                        </p>
                        <p>
                            2.10. Подтверждением того, что все условия настоящего Договора понятны Покупателю и
                            он согласен с этими условиями в целом и без оговорок, является:
                        </p>
                        <p>
                            2.10.1. Проставление отметки Покупателем на сайте:
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                            {'\u00A0'}в графе «С условиями
                            Публичной Оферты согласен».
                        </p>
                        <p>
                            2.10.2. Факт 100% оплаты Заказа Покупателем. Данный факт является безоговорочным
                            фактом принятия Покупателем условий данного Договора, в том числе согласием
                            Покупателя на рассылку ему Продавцом sms-сообщений о статусе его Заказа и опросов
                            для улучшения качества обслуживания Покупателей.
                        </p>
                        <p>
                            2.11. Договор, заключаемый на основании акцептирования Покупателем настоящей
                            оферты является договором присоединения, к которому Покупатель присоединяется без
                            каких-либо исключений и/или оговорок.
                        </p>
                        <h3 className="privacy-policy__body-title">3. ПРЕДМЕТ ДОГОВОРА</h3>
                        <p>
                            3.1. Продавец обязан:
                        </p>
                        <p>
                            3.1.1. Поставить и передать Товар Покупателю в соответствии с условиями Заказа.
                        </p>
                        <p>
                            3.1.2. Обеспечить гарантийные обязательства в соответствии с условиями настоящего
                            Договора.
                        </p>
                        <p>
                            3.2. Продавец имеет право:
                        </p>
                        <p>
                            3.2.1. Изменять настоящий Договор, Цены на Товар и Тарифы на сопутствующие услуги,
                            способы и сроки оплаты и доставки Товара в одностороннем порядке, помещая их на
                            страницах интернет-магазина, расположенного по интернет-адресу:
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                            {'\u00A0'}Все изменения вступают в силу немедленно после публикации, и считаются доведенными до
                            сведения Покупателя с момента такой публикации.
                        </p>
                        <p>
                            3.2.2. Без согласования с Покупателем, передавать свои права и обязанности по
                            исполнению Договора третьим лицам.
                        </p>
                        <p>
                            3.2.3. Перед поставкой Заказанного Покупателем Товара Продавец имеет право
                            потребовать от Покупателя 100% предоплаты Заказанного Товара. Продавец имеет право
                            отказать Покупателю в доставке Товара при отсутствии такой оплаты.
                        </p>
                        <p>
                            3.2.4. Продавец вправе направлять Покупателю сообщения рекламно-информационного
                            характера посредством e-mail, sms-рассылок, push-уведомлений и рассылок в
                            мессенджеры с информацией о персональных предложениях, скидках, акциях, новых
                            поступлениях и т.п. Частота рассылок определяется Продавцом самостоятельно, в
                            одностороннем порядке
                        </p>
                        <p>
                            3.2.5. Продавец вправе изменять условия рекламных акций, размещённых на сайте
                            интернет-магазина
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru,</a>
                            {'\u00A0'}в одностороннем порядке без согласия Покупателя,
                            путём размещения таких изменений на сайте интернет-магазина
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                        </p>
                        <p>
                            3.3. Покупатель обязан:
                        </p>
                        <p>
                            3.3.1. До момента заключения Договора ознакомиться с содержанием и условиями
                            Договора, ценами на Товар, предлагаемыми Продавцом в интернет-магазине.
                        </p>
                        <p>
                            3.3.2. Предоставлять при оформлении Заказа точное наименование требуемого Товара,
                            его артикул, или все данные необходимые для точного определения требуемого Товара
                            (цвет, вариант исполнения). В случае, самостоятельного Заказа нести ответственность за
                            подбор данного Товара.
                        </p>
                        <p>
                            3.3.3. Оплачивать Товар, согласно Заказам, по указанной в них цене.
                        </p>
                        <p>
                            3.3.3.1. При приемке Заказа провести осмотр на предмет внешнего повреждения
                            упаковки, вскрыть в присутствии представителя Транспортной компании внешнюю
                            упаковку с целью проверки сохранности вложенного, осмотреть Товар на предмет
                            наличия механических повреждений, сверить артикул получаемого Товара с артикулом,
                            проставленным в сопроводительном документе, проверить комплектацию и цвет
                            Товара. Осмотреть и проверить его на соответствие заявленному количеству,
                            ассортименту и комплектности Товара.
                        </p>
                        <p>
                            3.3.3.2. При получении Заказа в отделении почтовой связи Получатель после оплаты
                            доставленного Товара обязан осмотреть доставленный Товар и произвести его вскрытие в
                            присутствии работников Почты России для проверки Товара на соответствие заявленному
                        </p>
                        <p>
                            3.3.4. В случае наличия претензий к доставленному Товару, составить претензию с
                            указанием недостатков и направить ее на адрес электронной почты:
                            <a className="privacy-policy-link" href="https://www.avtsport.ru/" target="_blank"> internet-magazin@avtsport.ru.</a>
                        </p>
                        <p>
                            3.3.5. В случае передачи Продавцом Товара с нарушением условий Заказа о количестве,
                            ассортименте, комплектности, таре и (или) упаковке Товара, - не позднее 10-ти,
                            следующих за днем покупки, календарных дней известить Продавца об этих нарушениях.
                        </p>
                        <p>
                            3.3.6. Покупатель понимает и соглашается с тем, что: осуществление доставки —
                            отдельная платная услуга, не являющаяся неотъемлемой частью приобретаемого
                            Покупателем Товара, выполнение которой заканчивается в момент получения
                            Получателем Товара и осуществления платежа за него.
                        </p>
                        <p>
                            3.3.7. Приобретение Товара с доставкой не дает Покупателю право требования
                            возможности осуществлять гарантийное обслуживание посредством выезда к
                            Покупателю специалистов и не подразумевает возможность возврата стоимости доставки
                            Товара в случаях, в соответствии с Законом РФ «О защите прав потребителей».
                        </p>
                        <p>
                            3.4. Покупатель имеет право:
                        </p>
                        <p>
                            3.4. Вносить изменения в размещенный Заказ до момента передачи Товара Продавцом в
                            Транспортную компанию, при этом Покупатель принимает на себя все риски, связанные с
                            увеличением стоимости за доставку, с задержкой исполнения его Заказа по причине
                            изменения наименования и (или) количества Заказанного Товара.
                        </p>
                        <p>
                            3.4.1. Потребовать возврата оплаты в случае невозможности исполнения Продавцом
                            условий Заказа.
                        </p>
                        <p>
                            3.4.2. До передачи Товара отказаться от исполнения Договора при условии возмещения
                            Продавцу транспортных расходов, понесенных Продавцом в связи с совершением
                            действий по выполнению настоящего Договора.
                        </p>
                        <p>
                            3.4.3. Предъявить претензии относительно потери работоспособности Товара в ходе его
                            эксплуатации в случаях, на которые распространяются гарантийные обязательства
                            производителя Товара, в установленном производителем порядке на основании
                            заключения официального представителя производителя в данном регионе. В случае
                            обнаружения, что недостатки в качестве Товара возникли не по вине Продавца, расходы
                            по проведению экспертизы ложатся на Покупателя.
                        </p>
                        <h3 className="privacy-policy__body-title">4. ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА</h3>
                        <p>
                            4.1. Покупатель самостоятельно оформляет заказ в Интернет-магазине
                        </p>
                        <p>
                            4.2. Продавец подтверждает Покупателю получение Заказа посредством телефонной или
                            электронной связи.
                        </p>
                        <h3 className="privacy-policy__body-title">5. СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ ЗАКАЗА</h3>
                        <p>
                            5.1. Цена Товара указывается на сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                            {'\u00A0'}в рублях Российской Федерации.
                        </p>
                        <p>
                            5.2. В случае неверного указания цены на Заказанный Покупателем Товар, Продавец
                            информирует об этом Покупателя посредством электронной и телефонной связи. При
                            невозможности связаться с Покупателем данный Заказ считается аннулированным. Если
                            аннулированный Заказ был оплачен, Продавец возвращает Покупателю оплаченную за
                            Заказ сумму.
                        </p>
                        <p>
                            5.3. Цена Товара на Сайте может быть изменена Продавцом в одностороннем порядке.
                        </p>
                        <p>
                            5.4. Особенности оплаты Товара с помощью банковских карт:
                        </p>
                        <p>
                            5.4.1. В соответствии с положением ЦБ РФ «Об эмиссии банковских карт и об операциях,
                            совершаемых с использованием платежных карт» от 24.12.2004 № 266-П операции по
                            банковским картам совершаются держателем карты либо уполномоченным им лицом.
                        </p>
                        <p>
                            5.4.2. Авторизация операций по банковским картам осуществляется банком. Если у банка
                            есть основания полагать, что операция носит мошеннический характер, то банк вправе
                            отказать в осуществлении данной операции. Мошеннические операции с банковскими
                            картами попадают под действие статьи 159 УК РФ.
                        </p>
                        <p>
                            5.4.3. Во избежание случаев различного рода неправомерного использования банковских
                            карт при оплате все Заказы, оформленные на Сайте и предоплаченные банковской
                            картой, проверяются Продавцом. В целях проверки личности владельца и его
                            правомочности на использование карты Продавец вправе потребовать от Покупателя,
                            оформившего такой заказ, предъявления документа, удостоверяющего личность.
                        </p>
                        <p>
                            5.5. При доставке Курьерскими службами или Почтой России стоимость Заказа подлежит
                            увеличению на стоимость доставки. Стоимость доставки каждого Заказа рассчитывается
                            индивидуально, исходя из сведений о Продавце Заказанного Товара, веса Товара,
                            региона и способа доставки, а также (в случае необходимости) формы оплаты, и
                            указывается на Сайте на этапе оформления Заказа. Однако, данная стоимость доставки не
                            является окончательной. Окончательную стоимость доставки Заказа Продавец сообщает
                            Покупателю путем направления электронного сообщения по адресу, указанному при
                            формировании Покупателем Заказа, или по телефону только после обработки Заказа.
                        </p>
                        <p>
                            5.6. Покупатель оплачивает заказ любым способом, выбранным в Интернет-магазине в
                            размере стоимости Товара и стоимости доставки при условии ее наличия.
                        </p>
                        <p>
                            5.7. Моментом исполнения обязательства Покупателя по оплате Товара считается момент
                            зачисления денежных средств на счёт Продавца либо их передачу Курьерской службе.
                        </p>
                        <p>
                            5.8. Стоимость перевода при безналичном расчете оплачивает отправитель, кроме оплаты
                            с помощью банковских карт.
                        </p>
                        <h3 className="privacy-policy__body-title">6. ДОСТАВКА ЗАКАЗА</h3>
                        <p>
                            6.1. Территория доставки Товаров ограничена пределами Российской Федерации.
                        </p>
                        <p>
                            6.2. Во время заполнения формы Заказа в Интернет-магазине Покупатель самостоятельно
                            указывает каким способом доставки воспользуется.
                        </p>
                        <p>
                            6.3. При выборе способа доставки, Товар доставляется за счёт Покупателя.
                        </p>
                        <p>
                            6.4. Продавец самостоятельно определяет перевозчика и передает ему Товар для
                            доставки Покупателю. Товар доставляется Покупателю по адресу, указанному им в Заказе.
                            Продавец не несет ответственности в случае, если указанный Покупателем адрес,
                            окажется недостоверным или не соответствующим действительности.
                        </p>
                        <p>
                            6.5. Общий срок доставки Товара до Покупателя, состоит из срока обработки, сбора Заказа
                            и срока доставки Транспортной компанией или Почтой России. О ходе выполнения своего
                            Заказа Покупатель может узнать, позвонив в рабочее время по телефону Интернетмагазина или сделав запрос по электронной почте.
                        </p>
                        <p>
                            6.6. Продавец не может гарантировать доступность Товара на складе Продавца в
                            количестве, указанном в момент оформления Заказа, как следствие, могут увеличиться
                            сроки обработки Заказа.
                        </p>
                        <p>
                            6.7. Сроки доставки, указанные на сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru,</a>
                            {'\u00A0'}носят справочный
                            (информационный) характер и не порождают никаких юридических последствий как для
                            Продавца, так и для Покупателя. Окончательные сроки доставки Заказа Продавец
                            сообщает Покупателю путем направления электронного сообщения по адресу,
                            указанному при формировании Покупателем Заказа, или по телефону только после
                            обработки Заказа.
                        </p>
                        <p>
                            6.8. Задержки в доставке возможны ввиду непредвиденных обстоятельств,
                            произошедших не по вине Продавца.
                        </p>
                        <p>
                            6.9. Продавец приложит все усилия для скорейшей доставки Товара Покупателю, тем не
                            менее, задержки в доставке возможны ввиду непредвиденных обстоятельств,
                            произошедших не по вине Продавца или Транспортной компании или Почтой России.
                        </p>
                        <p>
                            6.10. В момент передачи Товара Покупателю и подписания Покупателем документов,
                            подтверждающих доставку Товара, риск случайной утери или случайного повреждения
                            Товара переходит к Покупателю.
                        </p>
                        <p>
                            6.11. Одновременно с передачей Товара Покупателю передается информация о Товаре,
                            инструкция, памятка о порядке и сроках возврата Товара.
                        </p>
                        <p>
                            6.12. Во избежание случаев мошенничества, а также для надлежащего выполнения
                            Продавцом взятых на себя обязательств, при вручении предоплаченного Заказа
                            Покупателю, - лицо осуществляющее доставку Заказа (Транспортная компания), вправе
                            затребовать документ удостоверяющий личность Покупателя.
                        </p>
                        <h3 className="privacy-policy__body-title">7. ПОРЯДОК ВОЗВРАТА ТОВАРА</h3>
                        <p>
                            7.1. Возврат Товара надлежащего качества
                        </p>
                        <p>
                            7.1.1. При дистанционной торговле (интернет-продажи) Покупатель вправе отказаться от
                            Товара в любое время до его передачи, а после передачи Товара - в течение семи дней
                            (Федеральный Закон РФ «О защите прав потребителей» ст.26.1 п.4, ст.25), в том случае,
                            если Товар не был в употреблении, сохранены упаковка, Товарный вид, Покупательские
                            свойства, пломбы, фабричные ярлыки, а также Товарный или кассовый чек, а также
                            документы, подтверждающие факт и условия покупки указанного Товара.
                        </p>
                        <p>
                            7.1.2. При отсутствии у Покупателя указанных документов Покупатель может сослаться на
                            другие объективные доказательства приобретения Товара у данного Продавца.
                            Если к Товару был приложен подарок, он должен быть передан вместе с Товаром, при
                            этом подарок также не должен иметь следов эксплуатации, должен быть сохранен его
                            Товарный вид, Покупательские свойства, пломбы и оригинальная упаковка.
                        </p>
                        <p>
                            7.1.3. Для возврата Товара Покупателю необходимо произвести все следующие действия:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                Сообщить Продавцу о возврате Товара посредством электронной или телефонной связи;
                            </li>
                            <li className="secure-data__item">
                                Аккуратно упаковать Товар (со всеми комплектующими и полным комплектом
                                аксессуаров, указанным в документации на Товар) в фирменную упаковку Продавца.
                                Возврат Товара производиться в порядке, указанном в пункте 7.1.4 настоящего договора.
                            </li>
                            <li className="secure-data__item">
                                Заполнить и приложить в 2-х экземплярах заявление о возврате Товара надлежащего
                                качества. В заявление на возврат указываются банковские реквизиты Покупателя (ФИО;
                                Название банка получателя; БИК банка получателя; Корреспондентский счет; Расчетный
                                счет) для возврата денежных средств;
                            </li>
                            <li className="secure-data__item">
                                Приложить оригинал или копию кассового чека или квитанции о подтверждение
                                оплаты.
                            </li>
                            <li className="secure-data__item">
                                Приложить копию документа, удостоверяющего личность (согласно положению ЦБ РФ
                                от 12 октября 2011 г. № 373-П «ПОЛОЖЕНИЕ О ПОРЯДКЕ ВЕДЕНИЯ КАССОВЫХ ОПЕРАЦИЙ
                                С БАНКНОТАМИ И МОНЕТОЙ БАНКА РОССИИ НА ТЕРРИТОРИИ РОССИЙСКОЙ
                                ФЕДЕРАЦИИ»)
                                Для граждан РФ:
                                паспорт гражданина РФ; военный билет, временное удостоверение, выдаваемое взамен
                                военного билета, или удостоверение личности (для лиц, которые проходят военную
                                службу); временное удостоверение личности гражданина РФ, выдаваемое на период
                                оформления паспорта; справка установленной формы, выдаваемая гражданам РФ,
                                находящимся в местах содержания под стражей подозреваемых и обвиняемых / справка
                                об освобождении из мест лишения свободы; документ, удостоверяющий личность
                                гражданина РФ, по которому гражданин РФ осуществляет въезд в РФ (для лиц, постоянно
                                проживающих за пределами территории РФ); паспорт моряка.
                                Для иностранных граждан: национальный заграничный паспорт иностранного
                                гражданина; дипломатический паспорт иностранного гражданина; разрешение на
                                временное проживание для лица без гражданства; вид на жительство для лица без
                                гражданства;
                                документ, указанный в миграционной карте, на основании которого лицо въехало на
                                территорию РФ; удостоверение беженца или Свидетельство о рассмотрении ходатайства
                                о признании беженцем на территории РФ; паспорт моряка.
                            </li>
                            <li className="secure-data__item">
                                Сделать скан всех вложений и описи и отправить по электронной почте – internetmagazin@avtsport.ru, а оригиналы и сам товар отправить по адресу Продавца: 614000, г.
                                Пермь, ул. Куйбышева,115Б.
                                7.1.4. Возврат товара осуществляется через транспортную компанию, которой была
                                доставлена покупка. Чтобы узнать ближайшее место приема посылки для отправки,
                                необходимо уточнить пункт приема посылок в вашем городе, пожалуйста, свяжитесь с
                                менеджерами этой компании
                            </li>
                            <li className="secure-data__item">
                                Пункты приема CDEK Вы можете найти на сайте{'\u00A0'}
                                <a className="privacy-policy-link" href="http://www.edostavka.ru" target="_blank">
                                     www.edostavka.ru
                                </a>
                                {'\u00A0'} по этому адресу:{'\u00A0'}
                                <a className="privacy-policy-link" href="http://www.edostavka.ru/contacts/gperm_ulmira_d9.html" target="_blank">
                                     http://www.edostavka.ru/contacts/gperm_ulmira_d9.html
                                </a>
                                {'\u00A0'}или по телефону 8 800 250-04-05
                            </li>
                            <li className="secure-data__item">
                                Пункты приема Boxberry Вы можете найти на сайте{'\u00A0'}
                                <a className="privacy-policy-link" href="https://boxberry.ru/" target="_blank">
                                     www.boxberry.ru
                                </a>
                                {'\u00A0'}компании по этому адресу:
                                <a className="privacy-policy-link" href="http://boxberry.ru/find_an_office/" target="_blank">
                                     http://boxberry.ru/find_an_office/
                                </a>
                                {'\u00A0'}или по телефону 8-800-700-54-30.
                            </li>
                        </ul>
                        <p>
                            7.1.5. Отправка возвращаемого Товара наложенным платежом не допускается.
                            Покупатель самостоятельно несет все риски, связанные с несоблюдением порядка
                            возврата Товара.
                        </p>
                        <p>
                            7.1.6. Порядок обращения для возврата Товара надлежащего качества:
                        </p>
                        <p>
                            7.1.6.1. Продавец принимает заявление к рассмотрению в случае его обоснованности, а
                            также, если выдержаны сроки подачи заявлений.
                        </p>
                        <p>
                            7.1.6.2. Проверка по каждому случаю проводится в течение 10 рабочих дней, с момента
                            поступления Товара.
                        </p>
                        <p>
                            7.1.6.3. Проверив полученную информацию, Продавец сообщает Покупателю результат
                            проверки по его заявлению, предложив оптимальный способ решения вопроса.
                        </p>
                        <p>
                            7.1.7. Возврат Товара осуществляется исключительно за счет Покупателя.
                        </p>
                        <p>
                            7.1.8. В случае повреждения упаковки, Товарного вида, Покупательских свойств, пломбы,
                            фабричных ярлыков в следствие транспортировки, Продавец не имеет право обменять
                            или вернуть стоимость за возвращенный Товар. В данном случае, претензии Покупатель
                            направляет Курьерской компании, которая повредила Товар в процессе доставки.
                        </p>
                        <p>
                            7.1.9. Продавец имеет право не принимать претензий по возвращаемому Товару
                            надлежащего качества, имеющему индивидуально-определенные свойства, если
                            указанный Товар может быть использован исключительно приобретающим его
                            Покупателем. Так же бывших в употреблении и/или с механическими повреждениями
                            (трещины, царапины, сколы, вмятины и пр.)
                        </p>
                        <p>
                            7.1.10. Продавец имеет право не принимать к возврату и обмену Товары, приобретенные
                            у других компаний и на других сайтах (интернет – магазинах).
                        </p>
                        <p>
                            7.1.11. Продавец имеет право принимать к возврату и обмену Товар только полным
                            комплектом.
                        </p>
                        <p>7.2. Обмен Товара надлежащего качества</p>
                        <p>
                            7.2.1. Согласно ст. 25 Закона о «Защите прав потребителей» Покупатель вправе обменять
                            непродовольственный Товар надлежащего качества на аналогичный Товар у Продавца, у
                            которого этот Товар был приобретен, если указанный Товар не подошел по форме,
                            габаритам, фасону, расцветке, размеру или комплектации.
                        </p>
                        <p>
                            7.2.2. Покупатель имеет право на обмен непродовольственного Товара надлежащего
                            качества в течение четырнадцати дней, не считая дня его покупки, в порядке,
                            предусмотренном памяткой покупателя
                        </p>
                        <p>
                            7.2.3. Обмен непродовольственного Товара надлежащего качества проводится, если
                            указанный Товар не был в употреблении, сохранены его Товарный вид, Покупательские
                            свойства, пломбы, фабричные ярлыки, а также имеется Товарный чек или кассовый чек
                            либо иной подтверждающий оплату указанного Товара документ. Отсутствие у
                            потребителя Товарного чека или кассового чека либо иного подтверждающего оплату
                            Товара документа не лишает его возможности ссылаться на свидетельские показания.
                        </p>
                        <p>
                            7.2.4. Перечень Товаров, не подлежащих обмену:
                        </p>
                        <ol>
                            <li>Товары для профилактики и лечения заболеваний в домашних условиях</li>
                            <li>Предметы личной гигиены</li>
                            <li>Парфюмерно-косметические Товары</li>
                            <li>Текстильные Товары</li>
                            <li>Швейные и трикотажные изделия</li>
                            <li>
                                Изделия и материалы, полностью или частично изготовленные из полимерных
                                материалов и контактирующие с пищевыми продуктами (посуда и принадлежности
                                столовые и кухонные, емкости и упаковочные материалы для хранения и
                                транспортирования пищевых продуктов, в том числе для разового использования)
                            </li>
                            <li>
                                Товары бытовой химии, пестициды и агрохимикаты
                            </li>
                            <li>
                                Мебель бытовая (мебельные гарнитуры и комплекты)
                            </li>
                            <li>
                                Ювелирные и другие изделия из драгоценных металлов и (или) драгоценных камней,
                                ограненные драгоценные камни
                            </li>
                            <li>
                                Автомобили и мотовело Товары, прицепы и номерные агрегаты к ним; мобильные
                                средства малой механизации сельскохозяйственных работ; прогулочные суда и иные
                                плав-средства бытового назначения
                            </li>
                            <li>
                                Технически сложные Товары бытового назначения, на которые установлены
                                гарантийные сроки
                            </li>
                            <li>
                                Гражданское оружие, основные части гражданского и служебного огнестрельного
                                оружия, патроны к нему
                            </li>
                            <li>
                                Животные и растения
                            </li>
                            <li>
                                Непериодические издания (книги, брошюры, альбомы, картографические и нотные
                                издания, листовые изоиздания, календари, буклеты, издания, воспроизведенные на
                                технических носителях информации)
                            </li>
                        </ol>
                        <p>
                            В случае, если аналогичный Товар отсутствует в продаже на день обращения Покупателя к
                            Продавцу, Покупатель вправе отказаться от исполнения договора купли-продажи и
                            потребовать возврата уплаченной за указанный Товар денежной суммы. Требование
                            Покупателя о возврате уплаченной за указанный Товар денежной суммы подлежит
                            удовлетворению в течение трех дней со дня возврата указанного Товара.
                        </p>
                        <p>
                            7.2.5. По соглашению Покупателя с Продавцом обмен Товара может быть предусмотрен
                            при поступлении аналогичного Товара в продажу. Продавец обязан незамедлительно
                        </p>
                        <p>7.3. Возврат Товара ненадлежащего качества</p>
                        <p>7.3.1. Под Товаром ненадлежащего качества подразумевается Товар, который неисправен
                            и не может обеспечить исполнение своих функциональных качеств. В случае, если
                            выявленные недостатки (дефекты) Покупателем, были изначально оговорены
                            Покупателю, Товар считается надлежащего качества.
                        </p>
                        <p>
                            7.3.2. Клиент может возвратить Товар ненадлежащего качества Продавцу и потребовать
                            возврата уплаченной за Товар денежной суммы в течение гарантийного срока, срока
                            годности либо, если такой срок не установлен, в разумный срок, не превышающий два
                            года. Клиент также может потребовать замены Товара ненадлежащего качества на
                            аналогичный Товар.
                        </p>
                        <p>
                            7.3.3. Возврат Товаров ненадлежащего качества возможен только после уведомления об
                            этом Продавца по его контактному телефону и/или по электронной почте
                            <a className="privacy-policy-link" href="https://www.avtsport.ru/" target="_blank"> internetmagazin@avtsport.ru.</a>
                        </p>
                        <p>
                            7.3.4. В случае отказа Покупателя от Публичной оферты (Договора купли-продажи) и
                            предъявления требования о возврате уплаченной за Товар денежной суммы, стоимость
                            Товара подлежит возврату Клиенту в течение 10 дней с момента получения Продавцом
                            письменного заявления Клиента и получения Товара ненадлежащего качества. Заявление
                            признается поданным в надлежащей форме, если оно составлено в письменном виде с
                            собственноручной подписью Клиента. Заявление Клиента на возврат передается
                            одновременно с Товаром, от которого Клиент отказывается. Товар должен быть передан
                            вместе с оригиналами гарантийного талона и заключения сервисного центра (при
                            наличии).
                        </p>
                        <p>
                            7.3.5. Обратная пересылка Товара первоначально производится за счет Покупателя. После
                            получения Товара и подтверждения наличия заявленных дефектов, возникших не по вине
                            Покупателя, Продавец обязуется возместить эти расходы не позднее чем через 10 дней с
                            даты поступления возвращаемого Товара на склад Продавца.
                        </p>
                        <p>
                            7.3.6. Продавец, обязан принять Товар ненадлежащего качества у Потребителя и в случае
                            необходимости провести проверку качества Товара. Покупатель вправе участвовать в
                            проверке качества Товара.
                        </p>
                        <p>
                            7.3.7. В случае спора о причинах возникновения недостатков Товара Продавец обязан
                            провести экспертизу Товара за свой счет. Покупатель вправе присутствовать при
                            проведении экспертизы Товара и в случае несогласия с ее результатами оспорить
                            заключение такой экспертизы в судебном порядке.
                        </p>
                        <p>
                            7.3.8. Если в результате экспертизы Товара установлено, что его недостатки возникли
                            вследствие обстоятельств, за которые не отвечает Продавец, Покупатель обязан
                            возместить Продавцу расходы на проведение экспертизы, а также связанные с ее
                            проведением расходы на хранение и транспортировку Товара.
                        </p>
                        <p>
                            7.3.9. Продавец отвечает за недостатки Товара, на который не установлен гарантийный
                            срок, если Покупатель докажет, что они возникли до передачи Товара потребителю или
                            по причинам, возникшим до этого момента.
                        </p>
                        <p>
                            7.3.10. При получении Товара ненадлежащего качества, Покупатель вправе потребовать
                            замену некачественного Товара на качественный, если ненадлежащее качество Товара
                            обнаружено в течение гарантийного срока на Товар. В отношении Товара, на который
                            гарантийный срок не установлен, Покупатель вправе предъявить вышеуказанное
                            требование, если ненадлежащее качество Товара обнаружено в пределах двух лет с даты
                            передачи Товара Покупателю. Расходы по возврату согласовываются Продавцом и
                            Покупателем дополнительно. Продавец удовлетворяет требование Покупателя в течение
                            20 (двадцати) дней с даты получения возвращенного Товара на свой склад. В случаях,
                            когда Товар для замены отсутствует на складе Продавца, срок удовлетворения
                            требования Покупателя может быть продлен, но не более чем до 30 (тридцати) дней.
                        </p>
                        <p>
                            7.3.11. Замена Товара ненадлежащего качества на Товар надлежащего качества
                            осуществляется путем возврата Товара Продавцу, аннуляции Заказа или Товара, затем
                            оформления нового Заказа. При этом аннуляция является техническим действием и не
                            подразумевает отказ Продавца от исполнения договора. Не оформление нового Заказа
                            на Товар в течение 30 календарных дней с момента аннуляции Заказа, является
                            действием Клиента, свидетельствующем об отказе клиента от намерения осуществить
                            замену Товара.
                        </p>
                        <p>
                            7.3.12. В случае осуществления доставки и приемке Товара по адресу Покупателя
                            выявлены расхождения по количеству и/или качеству Товара Покупатель в обоих
                            экземплярах Товарной накладной делает исправление о фактически принятом Товаре за
                            подписью Покупателя. На Товар не соответствующий количеству, комплектации, торговой
                            марке или качеству (нарушена герметичность упаковки) составляется претензия в
                            произвольной форме в адрес Продавца с указанием наименования Товара, номера и
                            даты Товарной накладной, даты и места приемки Товара, паспортных данных Покупателя
                            или представителя Покупателя с его же подписью и сути претензии. При получении
                            данной претензии Продавец обязуется произвести поставку Товара взамен
                            несоответствующего количеству, комплектации, торговой марке или качеству Товара в
                            кротчайшие сроки по согласованию с Покупателем. В случаях, когда Товар для замены
                            отсутствует на складе Продавца срок удовлетворения претензии Покупателя может быть
                            продлен, но не более чем до 30 (тридцати) дней с даты получения претензии.
                        </p>
                        <h3 className="privacy-policy__body-title">8. КОНФИДЕНЦИАЛЬНОСТЬ И ЗАЩИТА ИНФОРМАЦИИ</h3>
                        <p>
                            8.1. Персональные данные Пользователя/Покупателя обрабатывается в соответствии с ФЗ
                            «О персональных данных» № 152-ФЗ.
                        </p>
                        <p>
                            8.2. При регистрации на Сайте Покупатель предоставляет следующую информацию: Имя,
                            Фамилия, Отчество, дата рождения, e-mail, точный адрес доставки Товара. А также
                            дополнительную информацию для оформления заказа по программе лояльности
                            «Нужный спорт»: адрес проживания Покупателя, приоритетный способ получения
                            заказов, место жительства, логин и пароль, дата рождения, пол, виды спорта, которыми
                            занимается Покупатель, фото покупателя, отзывы покупателя о товарах, информация о
                            детях Покупателя: фамилия, имя, отчество, пол, дата рождения, типы и названия
                            спортивных секций, которые они посещают, тренировочный уровень ребёнка в
                            спортивной секции, отметка об участии в спортивных соревнованиях, фамилия тренера
                            спортивной секции у детей Покупателя, адрес спортивной секции.
                        </p>
                        <p>
                            8.3. Предоставляя свои персональные данные Продавцу, Посетитель
                            Сайта/Пользователь/Покупатель соглашается на их обработку Продавцом, в том числе в
                            целях выполнения Продавцом обязательств перед Посетителем
                            Сайта/Пользователем/Покупателем в рамках настоящей Публичной оферты ,
                            продвижения Продавцом Товаров и услуг, проведения электронных и sms-опросов,
                            контроля результатов маркетинговых акций, клиентской поддержки, организации
                            доставки Товара Покупателям, проведение розыгрышей призов среди Посетителей
                            Сайта/Пользователей/ Покупателей, контроля удовлетворенности Посетителя
                            Сайта/Пользователя/Покупателя, а также качества услуг, оказываемых Продавцом.
                        </p>
                        <p>
                            8.4. Под обработкой персональных данных понимается любое действие (операция) или
                            совокупность действий (операций), совершаемых с использованием средств
                            автоматизации или без использования таких средств с персональными данными, включая
                            сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                            изменение) извлечение, использование, передачу (в том числе передачу третьим лицам,
                            не исключая трансграничную передачу, если необходимость в ней возникла в ходе
                            исполнения обязательств), обезличивание, блокирование, удаление, уничтожение
                            персональных данных.
                        </p>
                        <p>
                            8.5. Продавец имеет право отправлять информационные, в том числе рекламные
                            сообщения, на электронную почту и мобильный телефон Пользователя/Покупателя с его
                            согласия, выраженного посредством совершения им действий, однозначно
                            идентифицирующих этого абонента и позволяющих достоверно установить его
                            волеизъявление на получение сообщения. Покупатель/Покупатель вправе отказаться от
                            получения рекламной и другой информации без объяснения причин отказа
                        </p>
                        <p>
                            8.6. Продавец не несет ответственности за сведения, предоставленные
                            Пользователем/Покупателем на Сайте в общедоступной форме.
                        </p>
                        <p>
                            8.7. Продавец вправе осуществлять записи телефонных разговоров с
                            Пользователем/Покупателем. При этом Продавец обязуется: предотвращать попытки
                            несанкционированного доступа к информации, полученной в ходе телефонных
                            переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного
                            отношения к исполнению Заказов, в соответствии с п. 4 ст. 16 Федерального закона «Об
                            информации, информационных технологиях и о защите информации».
                        </p>
                        <h3 className="privacy-policy__body-title">9. СРОК ДЕЙСТВИЯ ПУБЛИЧНОЙ ОФЕРТЫ</h3>
                        <p>
                            9.1. Настоящая Публичная оферта вступает в силу с момента ее акцепта Покупателем, и
                            действует до момента отзыва акцепта Публичной оферты.
                        </p>
                        <h3 className="privacy-policy__body-title">10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
                        <p>
                            10.1. Продавец вправе переуступать либо каким-либо иным способом передавать свои
                            права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам
                        </p>
                        <p>
                            10.2. Интернет-магазин и предоставляемые сервисы могут временно частично или
                            полностью недоступны по причине проведения профилактических или иных работ или по
                            любым другим причинам технического характера. Техническая служба имеет право
                            периодически проводить необходимые профилактические или иные работы с
                            предварительным уведомлением Покупателей или без такового.
                        </p>
                        <p>
                            10.3. К отношениям между Покупателем и Продавцом применяются положения
                            Российского законодательства.
                        </p>
                        <p>
                            10.4. В случае возникновения вопросов и претензий со стороны Пользователя/Покупателя
                            он должен обратиться к Продавцу по телефону или иным доступным способом. Все
                            возникающее споры стороны будут стараться решить путем переговоров, при
                            недостижении соглашения спор будет передан на рассмотрение в судебный орган в
                            соответствии с действующим законодательством РФ.
                        </p>
                        <p>
                            10.5. Признание судом недействительности какого-либо положения настоящего
                            Соглашения не влечет за собой недействительность остальных положений.
                        </p>
                        <h3 className="privacy-policy__body-title">11. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ</h3>
                        <p>
                            11.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие
                            ненадлежащего использования Товаров, приобретённых в Интернет-магазине.
                        </p>
                        <p>
                            11.2. Продавец не отвечает за убытки Покупателя возникшие в результате
                        </p>
                        <p>
                            а) неправильного заполнения бланка-Заказа на сайте Продавца, в том числе
                            неправильного указания персональных данных.
                        </p>
                        <p>
                            б) неправомерных действий третьих лиц.
                        </p>
                        <p>
                            11.3. Покупатель обязуется не использовать Заказанный Товар в предпринимательских
                            целях.
                        </p>
                        <p>
                            11.4. Покупатель несёт полную ответственность за достоверность сведений, указанных им
                            при регистрации в Интернет-магазине.
                        </p>
                        <p>
                            11.5. В случае форс-мажорных обстоятельств, стороны освобождаются от исполнения
                            настоящего Договора. Стороны освобождаются от ответственности за полное или
                            частичное неисполнение своих обязательств, если такое неисполнение явилось
                            следствием действия обстоятельств непреодолимой силы, возникших после вступления в
                            силу настоящего Договора, в результате событий чрезвычайного характера, которые
                            Стороны не могли предвидеть и предотвратить разумными действиями.
                        </p>
                        <p>
                            11.6. В иных случаях, не предусмотренных п. 11.5 настоящего Договора за неисполнение
                            или ненадлежащее исполнение своих обязательств Стороны несут ответственность в
                            соответствии с действующим законодательством Российской Федерации.
                        </p>
                        <p>
                            11.7. Все разногласия, возникающие во время исполнения условий настоящего Договора,
                            стороны будут стараться решить путем переговоров. При не достижении соглашения все
                            споры подлежат рассмотрению в соответствии с процедурой, предусмотренной
                            законодательством РФ.
                        </p>
                        <h3 className="privacy-policy__body-title">12. ПРОЧИЕ УСЛОВИЯ</h3>
                        <p>
                            12.1. Покупатель гарантирует, что все условия настоящего Договора ему понятны и он
                            принимает их безусловно и в полном объёме. Оформление Заказа Покупателем в
                            Интернет-магазине подтверждает его согласие с условиями настоящего Договора
                        </p>
                        <p>
                            12.2. Признание судом недействительности какого-либо пункта настоящего договора не
                            влечет за собой недействительность остальных пунктов.
                        </p>
                        <p>
                            12.3. Настоящий договор, размещенный на используемом Продавцом в Интернетемагазине по адресу
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                            {'\u00A0'}является публичной офертой. Отсутствие подписанного
                            между сторонами экземпляра Договора на бумажном носителе, с проставлением
                            подписей сторон, в случае проведения по нему фактической оплаты Покупателем, не
                            является основанием считать настоящий Договор не заключенным. Проведение оплаты в
                            соответствии со сделанным Заказом считается акцептом Покупателя.
                        </p>
                        <h3 className="privacy-policy__body-title">13. СРОК ДЕЙСТВИЯ НАСТОЯЩЕГО ДОГОВОРА</h3>
                        <p>
                            13.1. Настоящий договор вступает в силу с даты его публикации на сайте:
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                            {'\u00A0'}Продавец: ИП Зыков М.А. Юридический адрес: 625034 Тюменская обл., г. Тюмень, ул.
                            Судостроителей, д. 38, кв. 825. Почтовый адрес:614990 г. Пермь ул. Куйбышева, 115 Б ИНН
                            590414001017 Зарегистрирован Администрацией Свердловского р-на г. Перми 13.05.2003
                            г. ОГРНИП 304590411100151 Св-во о внесении в ЕГРИП серия 59 № 002997800 Выдано
                            20.04.2004 г. Инспекцией МНС по Свердловскому району г. Перми ОКПО 0121423948
                            ОКАТО 71401000000 ОКВЭД 47 ОКОПФ 91 Р/сч. 40802810918420000005 В Филиале
                            «Центральный» Банка ВТБ (ПАО) в г. Москве БИК 044525411 Кор.сч.
                            30101810145250000411
                        </p>
                        <p>Руководитель Зыков Михаил Альбертович</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AgreementOffer;