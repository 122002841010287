import Select from 'react-select';
import './custom-select.scss';

const CustomSelect = ({ label, required, error, ...props }) => {
    return (
        <div className="custom-select-wrapper">
            {label &&
                <label className="custom-select-label">
                    {label}
                    {required && <span className="required-mark">*</span>}
                </label>
            }
            <Select {...props} />
            {error && <div className="error-text">{error}</div>}
        </div>
    );
};

export default CustomSelect;