import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Sidebar, PageTitle, LineButton } from '../../components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { logout } from '../../store/actions/auth';
import './menu.scss';

const Menu = ({ title }) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const signOut = (e) => {
        e.preventDefault();
        dispatch(logout());
    }

    if (width > 991) {
        return <Redirect to={process.env.REACT_APP_REDIRECT_AUTH} />
    }

    return (
        <>
            <PageTitle title={title} />
            <Sidebar />
            <div className="menu__logout-btn">
                <LineButton handleClick={signOut}>Выйти</LineButton>
            </div>
        </>
    );
};

export default Menu;