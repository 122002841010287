const reviewsTypes = {
    SET_REVIEWS: 'REVIEWS/SET_REVIEWS',
    SET_IS_LOADING: 'REVIEWS/SET_IS_LOADING',
    SET_IS_LOADED: 'REVIEWS/SET_IS_LOADED',
    SET_TOTAL_PAGES: 'REVIEWS/SET_TOTAL_PAGES',
    SET_SORT: 'REVIEWS/SET_SORT',
    SET_FILTERS: 'REVIEWS/SET_FILTERS',
    SET_PAGE: 'REVIEWS/SET_PAGE',
    TOGGLE_RELOAD: 'REVIEWS/TOGGLE_RELOAD',
    SET_ERRORS: 'REVIEWS/SET_ERRORS',
    SET_CREATE_ERRORS: 'REVIEWS/SET_CREATE_ERRORS'
};

export default reviewsTypes;