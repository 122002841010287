import cardTypes from './../types/card';

const initialState = {
    card: null
};

export const cardReducer = (state = initialState, action) => {
    switch(action.type) {
        case cardTypes.SET_CARD:
            return {...state, card: action.payload};

        default:
            return state;
    }
}