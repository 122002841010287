import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CustomLink, Button } from '../UI';
import { confirmEmail } from "../../store/actions/email";
import getConfirmEmailData from '../../utils/getConfirmEmailData';
import getConfirmEmailUrlQueryParams from '../../utils/getConfirmEmailUrlQueryParams';
import './confirm-email-status.scss';


const ConfirmEmailStatus = () => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const { isEmailConfirmed, loaded } = useSelector(state => state.email);
    const { isLoading } = useSelector(state => state.loading);
    const { userId, token }  = getConfirmEmailUrlQueryParams(search);

    useEffect(() => {
        if(!loaded) {
            dispatch(confirmEmail(userId, token));
        }
    }, []);

    let error = false;
    if(!userId || !token)
        error = true;

    const { img, mainTitle, subTitle } = getConfirmEmailData(isEmailConfirmed);

    const repeatConfirmEmail = (userId, token) => () => dispatch(confirmEmail(userId, token))
    const repeatBtn = !isEmailConfirmed
        ? <Button className="repeat-button button__red" handleClick={repeatConfirmEmail(userId, token)}>Отправить повторно</Button>
        : '';

    const content = (
        <div className="confirm-email-status">
            <div className="confirm-email-status__info">
                <div className="confirm-email-status__img">
                    {img}
                </div>
                <div className="confirm-email-status__text-block">
                    <p className="confirm-email-status__title">{mainTitle}</p>
                    { !error ? <p className="confirm-email-status__subtitle">{subTitle}</p> : '' }
                </div>
            </div>
            <div className="confirm-email-status__btn-repeat">
                { !error ? repeatBtn : '' }
            </div>
            <div className="confirm-email-status__btn-wrapper">
                <CustomLink to={ process.env.REACT_APP_REDIRECT_AUTH }>
                    Авторизация/Регистрация
                </CustomLink>
            </div>
        </div>
    );

    return !isLoading && isEmailConfirmed !== null ? content : '';
}

export default ConfirmEmailStatus;