import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { IoCloseCircle } from 'react-icons/io5';
import {cancelReplacePhone, cancelReplacePhoneCall} from "../../store/actions/replacement-phone";
import { toast } from 'react-toastify';
import './replacement-phone.scss';

const ReplacementPhone = ({
          phoneReplacement,
          openReplacementPhoneModalHandler,
          openConfirmReplacementPhoneModalHandler,
          openConfirmCancelReplacementPhoneModalHandler,
      }) => {

    const dispatch = useDispatch();
    const { typeDispatch } = useSelector(state => state.replacementPhone);

    const { isExistTask, newPhone, daysLeft, isSmsSentToChangePhone, isSmsSentToCancelChangePhone } = phoneReplacement;

    let buttonTitle = isExistTask ? "Отменить смену номера" : "Изменить номер телефона";
    let onClickHandler = openReplacementPhoneModalHandler;

    if(isSmsSentToChangePhone)
        onClickHandler = openConfirmReplacementPhoneModalHandler;

    if(isExistTask)
        onClickHandler = () => {
            if(window.confirm('Вы уверены, что хотите отменить замену номера?')) {
                if(typeDispatch === "SMS") {
                    dispatch(cancelReplacePhone())
                        .then(() => {
                            openConfirmCancelReplacementPhoneModalHandler();
                        })
                        .catch(() => {
                            toast.error('Произошла ошибка при отправке смс. Попробуйте еще');
                        });
                }

                dispatch(cancelReplacePhoneCall())
                    .then(() => {
                        openConfirmCancelReplacementPhoneModalHandler();
                    })
                    .catch(() => {
                        toast.error('Произошла ошибка при совершении звонка с 4-х значным кодом. Попробуйте еще');
                    });
            }
        };

    if(isExistTask && isSmsSentToCancelChangePhone)
        onClickHandler = openConfirmCancelReplacementPhoneModalHandler;

    const renderButtonReplacementPhone = <button className="replacement-phone_btn" onClick={onClickHandler}>
        {buttonTitle}
    </button>;

    const renderMessage = (newPhone, daysLeft) => {
        if(daysLeft)
            return `Номер телефона будет заменен на +${newPhone} через ${daysLeft} дней!`;

        return `Номер телефона будет заменен на +${newPhone} сеогдня в течении дня`;
    }

    return (
        <div>
            <div>{renderButtonReplacementPhone}</div>
            {
                isExistTask && (
                    <div className="replacement-data__item-value--icon">
                        <i data-tip="" data-for="replace-phone">
                            <IoCloseCircle size="24px" fill="#D32A29" />
                        </i>
                        <ReactTooltip id="replace-phone" place="right" type="dark" effect="solid">
                            {renderMessage(newPhone, daysLeft)}
                        </ReactTooltip>
                    </div>
                )
            }
        </div>
    )
}

export default ReplacementPhone;