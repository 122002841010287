import apiClient from "./axios";

export const sendClientIdFromMetrika = () => {
    ym(38516975, 'getClientID', async function(clientID) { // eslint-disable-line
        await apiClient({
            method: 'post',
            url: '/user/add-client-id',
            data: JSON.stringify({
                clientID
            }),
        });
    })
}