import replacementEmailTypes from "../types/replacement-email";

export const setEmailError = payload => ({
    type: replacementEmailTypes.SET_ERRORS,
    payload
});

export const setNewEmail = payload => ({
    type: replacementEmailTypes.SET_NEW_EMAIL,
    payload
});

export const setNeedCall = payload => ({
    type: replacementEmailTypes.SET_NEED_CALL,
    payload
});

export const setSenderPhone = payload => ({
    type: replacementEmailTypes.SET_SENDER_PHONE,
    payload
});

export const setTypeDispatch = payload => ({
    type: replacementEmailTypes.SET_TYPE_DISPATCH,
    payload
});

export const setEmailTimer = payload => ({
    type: replacementEmailTypes.SET_TIMER,
    payload
});