import { Link } from 'react-router-dom';

const CustomLink = ({ className, children, to, ...props }) => {
    return (
        <Link to={to} className={`custom-link ${className}`} {...props}>
            {children}
        </Link>
    );
};

export default CustomLink;