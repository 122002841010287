const parseUserDataForStore = (data) => {
    let result = {};
    for (let i = 0; i < data.length; i++) {
        if (typeof data[i].value === 'string' || typeof data[i].value === 'number' || typeof data[i].value === 'boolean') {
            result[data[i].name] = data[i].value;
        }
        if (typeof data[i].value === 'object') {
            if (data[i].value.length === undefined) {
                result[data[i].name] = data[i].value.value;
            } else {
                result[data[i].name] = data[i].value.map(v => v.label);
            }
        }

        result['fields'] = data;
    }

    return result;
}

export default parseUserDataForStore;