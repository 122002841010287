import { memo } from 'react';
import getTrainerPeriodDate from '../../../utils/getTrainerPeriodDate';
import './trainer-history-table.scss';

const TrainerHistoryTable = ({ items }) => {
    const renderItems = () => {
        if (items.length > 0) {
            return (
                items.map((item, index) => (
                    <div key={index} className={`trainer-history-table__row ${index % 2 === 0 ? '' : 'trainer-history-table__row--dark'}`}>
                        <div className="trainer-history-table__col">{getTrainerPeriodDate(item.period)}</div>
                        <div className="trainer-history-table__col trainer-history-table__col--name">{item.name}</div>
                        {/*<div className="trainer-history-table__col">1 506 руб.</div>*/}
                        <div className="trainer-history-table__col trainer-history-table__col--reverse">
                            <span className={`trainer-history-table__${item.nachisleno > 0 ? 'came' : 'gone'}`}>{item.nachisleno > 0 ? '+' : ''}{item.nachisleno}&nbsp;руб.</span>
                        </div>
                    </div>
                ))
            );
        } else {
            return <div className="trainer-history-table__empty">Нет данных</div>;
        }
    };

    return (
        <div className="trainer-history-table">
            <div className="trainer-history-table__header">
                <div className="trainer-history-table__header-col">Дата и время</div>
                <div className="trainer-history-table__header-col">Операция</div>
                {/*<div className="trainer-history-table__header-col">Сумма</div>*/}
                <div className="trainer-history-table__header-col trainer-history-table__header-col--reverse">Списание/Начисление</div>
            </div>
            <div className="trainer-history-table__body">
                {renderItems()}
            </div>
        </div>
    );
};

export default memo(TrainerHistoryTable);