import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import CountUp from 'react-countup';
import { getRemainderInfo } from '../../../store/actions/trainer';
import './trainer-info-card.scss';

const TIME_FOR_COPY = 8; //seconds

const TrainerInfoCard = () => {
    const dispatch = useDispatch();
    const { remainder, refCode } = useSelector(({trainer}) => trainer);
    const isLoaded = useSelector(({trainer}) => trainer.isLoaded.remainder);
    const [state, setState] = useState({
        value: '',
        copied: false
    });

    useEffect(() => {
        if (!isLoaded) {
            dispatch(getRemainderInfo());
        }
    }, [isLoaded]);

    useEffect(() => {
        if (refCode) {
            setState({ ...state, value: refCode })
        }
    }, [refCode]);

    const copyToClipboard = (text, result) => {
        if (!state.copied && text && result) {
            setState({ ...state, copied: true });

            setTimeout(() => {
                setState({ ...state, copied: false });
            }, TIME_FOR_COPY * 1000);
        }
    };

    return (
        <div className="trainer-info-card">
            <div className="trainer-info-card__income">
                Вы заработали:&nbsp;
                <CountUp
                    start={0}
                    end={remainder}
                    duration={1}
                    separator=" "
                    suffix=" руб."
                    className="trainer-info-card__income-sum"
                />
            </div>
            <div className="trainer-info-card__ref-code">
                Ваш реферальный код:&nbsp;
                <CopyToClipboard
                    text={state.value ? state.value.replace(/\s+/g, '') : ''}
                    onCopy={(text, result) => copyToClipboard(text, result)}
                >
                    <span className="trainer-info-card__code" data-for="copy" data-tip="">{state.value}</span>
                </CopyToClipboard>
                <ReactTooltip id="copy" place="top" type="dark" effect="solid">{state.copied ? 'Скопирован ✓' : 'Скопировать'}</ReactTooltip>
            </div>
        </div>
    );
};

export default TrainerInfoCard;