import notificationsTypes from '../types/notifications';

export const setSettings = payload => ({
    type: notificationsTypes.SET_SETTINGS,
    payload,
});

export const setLoaded = payload => ({
    type: notificationsTypes.SET_LOADED,
    payload,
});