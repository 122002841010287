import authTypes from './../types/auth';

const initialState = {
    error: null,
    isAuth: false
};

export const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case authTypes.SET_ERROR:
            return {...state, error: action.payload}

        case authTypes.SET_AUTH:
            return {...state, isAuth: action.payload}

        default:
            return state
    }
}