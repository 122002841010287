export const getUniqueImages = (images = [], key = 'data_url') => {
    if (images.length === 0) return [];

    const urls = [];
    const indexes = [];
    images.forEach((obj, index) => {
        if (urls.includes(obj[key])){
            indexes.push(index)
        } else {
            urls.push(obj[key]);
        }
    });

    if (indexes.length > 0) {
        const result = [...images];
        indexes.forEach((id, ind) => {
            result.splice(id - ind, 1);
        });
        return result;
    } else {
        return images;
    }
};