import { useEffect, useState } from 'react';
import { useForceUpdate } from './useForceUpdate';

export const usePagination = (fetchData, ref = null) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [update, forceUpdate] = useForceUpdate();

    const changePage = newPage => {
        setCurrentPage(newPage);
        forceUpdate();
    }

    useEffect(() => {
        if (ref !== null && ref.current !== null) {
            ref.current.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        fetchData();
    }, [update]);

    return [
        currentPage,
        changePage
    ]
}