import './checkbox-button.scss';

const CheckboxButton = ({ children, checked, onChange, ...props }) => {
    return (
        <div className={`checkbox-button${checked ? " checkbox-button__active" : ""}`}>
            <label className="checkbox-button__label">{children}
                <input
                    type="checkbox"
                    className="checkbox-button__input"
                    checked={checked}
                    onChange={e => onChange(e)}
                    {...props}
                />
            </label>
        </div>
    );
};

export default CheckboxButton;