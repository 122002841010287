import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { privateRoutes } from '../routes/routes';

export const usePrivateRoutes = () => {
    const isParticipation = useSelector(({ user }) => user.participation);
    const isQuestionnaireCompleted = useSelector(({ user }) => user.questionnaireCompleted);
    const isTrainerParticipation = useSelector(({ user }) => user.trainerParticipation);
    const isTrainerQuestionnaireCompleted = useSelector(({ user }) => user.trainerQuestionnaireCompleted);

    const privateRoutesFilterMemo = useMemo(() => {
        let privateRoutesFilter = [];
        const routes = privateRoutes.filter(route => !route.trainer && route.participation);

        if (isParticipation) {
            privateRoutesFilter = routes.filter(route => route.participation.showIfYes && !route.questionnaireCompleted);
        } else if (isQuestionnaireCompleted) {
            privateRoutesFilter = routes.filter(route => route.participation.showIfNot && !route.questionnaireCompleted);
        } else {
            privateRoutesFilter = routes.filter(route => route.participation.showIfNot);
        }

        // Adding trainer routes
        if (!isTrainerQuestionnaireCompleted) {
            privateRoutesFilter = [...privateRoutesFilter, ...privateRoutes.filter(route => route.trainer && route.trainer.questionnaire.showIfNot)]
        }
        if (isTrainerParticipation) {
            privateRoutesFilter = [...privateRoutesFilter, ...privateRoutes.filter(route => route.trainer && route.trainer.participation.showIfYes)]
        }

        return privateRoutesFilter;
    }, [isParticipation, isQuestionnaireCompleted, isTrainerParticipation, isTrainerQuestionnaireCompleted])

    return privateRoutesFilterMemo;
}