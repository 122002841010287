export const getFormattedAddress = (addressData) => {
    const { address, entrance, floor, apartment } = addressData;

    let addressLine = address.address_user + ' ';

    if(entrance)
        addressLine += ', подъезд ' + entrance;

    if(floor)
        addressLine += ', этаж ' + floor;

    if(apartment)
        addressLine += ', кв./офис ' + apartment;

    return addressLine;
}

const toLowerCaseAddressesKey = (profiles) => {
    return Object.values(profiles)
        .map((profile) => Object.entries(profile)
            .reduce((a, [key, value]) => {
                a[key.toLowerCase()] = value;
                return a;
            }, {}));
}

export const toConvertAddressesDataForRedux = (profiles) => {
    const addresses = toLowerCaseAddressesKey(profiles);
    return addresses.map(item => {
        const address = {}
        for (const [key, value] of Object.entries(item)) {
            if(key === 'address_user' || key === 'dadata_fias'
                || key === 'dadata_kladr' || key === 'geo_lat'
                || key === 'geo_lon' || key === 'zip')
                address[key] = value;
        }
        item['address'] = address;
        delete item.address_user;
        delete item.dadata_fias;
        delete item.dadata_kladr;
        delete item.geo_lat;
        delete item.geo_lon;
        delete item.zip;
        return item;
    });
}