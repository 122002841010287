import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { removeProduct } from '../../store/actions/products-for-review';
import { LineButton } from '../../components';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import StarsSVG from '../../assets/svg/stars.svg';
import './product-for-review.scss';

const ProductForReview = ({ id, link, image, name, site, onFeedbackClick }) => {
    const dispatch = useDispatch();

    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    const removeItem = (id) => {
        if (window.confirm('Подтверждаете действие?') && +id > 0) {
            dispatch(removeProduct(id));
        }
    };

    return (
        <div className="product-for-review">
            <div className="product-for-review__info">
                <a href={`https://${site}${link}`} className="product-for-review__image" target="_blank">
                    <img src={`https://${site}${image}`} alt={name} onError={setDefaultImage} />
                </a>
                <div className="product-for-review__title">
                    <div className="product-for-review__site">{site}</div>
                    <a href={`https://${site}${link}`} className="product-for-review__name" target="_blank">{name}</a>
                </div>
            </div>

            <div className="product-for-review__buttons">
                <LineButton
                    className="custom-link__blue product-for-review__reject"
                    style={{fontSize: '14px'}}
                    onClick={() => removeItem(id)}
                >
                    Не оценивать
                </LineButton>
                <button
                    type="button"
                    className="product-for-review__feedback"
                    data-for="tool"
                    data-tip=""
                    onClick={() => onFeedbackClick(id, name)}
                >
                    <ReactSVG src={StarsSVG}/>
                </button>
                <ReactTooltip id="tool" place="left" type="dark" effect="solid">Оценить</ReactTooltip>
            </div>
        </div>
    );
};

export default memo(ProductForReview);