import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../store/actions/sites';

const SitesMenu = () => {
    const dispatch = useDispatch();
    const { sites, isLoaded } = useSelector(state => state.sites);

    useEffect(() => {
        if (!isLoaded) {
            dispatch(getSites())
        }
    }, [isLoaded])

    if (sites.length === 0) {
        return '';
    }

    return (
        <section className="sidebar__menu">
            <div className="menu-groups">
            {sites.length > 0 && isLoaded && (
                <div className="menu-group">
                    <div className="menu-group__title">Сайты</div>
                    <div className="menu-group__item">
                        {sites.map((site, index) => (
                            <a href={`https://${site.url}`} target="_blank" className="sidebar__menu-item" key={`${site.url}-${index}`}>
                                <span className="sidebar__menu-item-text">{site.title}</span>
                            </a>
                        ))}
                    </div>
                </div>
            )}
            </div>
        </section>
    );
};

export default SitesMenu;