import { useEffect, useState } from 'react';
import {
    BlockTitle,
    LineButton,
    PageTitle,
    Backdrop,
    ModalCancelOrder,
    OrderBasket,
    OrderInfo,
    OrderTotal
} from '../../components';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailOrder } from '../../store/actions/orders';
import { setDetailOrder } from '../../store/action-creators/orders';
import getDateFromTimestamp from '../../utils/getDateFromTimestamp';
import { toast } from 'react-toastify';
import './order.scss';

const Order = ({ title }) => {
    const { id: orderId } = useParams();
    const { detailOrder: order } = useSelector(state => state.orders);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDetailOrder(orderId))
            .then(() => {})
            .catch(() => setRedirect(true));

        return () => {
            dispatch(setDetailOrder(null));
        }
    }, []);

    useEffect(() => {
        if (order && localStorage.getItem('successCancelOrder')) {
            toast.success(localStorage.getItem('successCancelOrder'));
            localStorage.removeItem('successCancelOrder');
        }
    }, [order]);

    const closeModalCancel = () => setIsOpenModal(false);
    const openModalCancel = () => setIsOpenModal(true);

    if (redirect) {
        return <Redirect to="/orders" />
    }

    return (
        <>
            <PageTitle title={`${title} №${orderId}`} />
            <section className="page order-page">

                {isOpenModal &&
                    <Backdrop closeHandler={closeModalCancel}>
                        <ModalCancelOrder orderId={orderId} closeHandler={closeModalCancel} />
                    </Backdrop>
                }

                {order &&
                    <>
                        <div className="order-page__header">
                            <BlockTitle
                                title={`${title} №${orderId}`}
                                description={`От ${getDateFromTimestamp(order.createdAt)}`}
                                back
                            />
                        </div>

                        <div className="order-page__body">
                            <OrderInfo order={order} />
                        </div>

                        <OrderBasket basket={order.basket} />

                        <OrderTotal price={order.price} isPaid={order.paid} />

                        {order.canCancel &&
                            <div className="order-cancel">
                                <LineButton className="custom-link__blue order-cancel__button" handleClick={openModalCancel}>Отменить заказ</LineButton>
                            </div>
                        }

                        {/*{order.paid && <Button className="button__black order-info__button">Повторить заказ</Button>}*/}
                    </>
                }
            </section>
        </>
    );
};

export default Order;