import { ReactSVG } from 'react-svg';
import EmailConfirmed from '../assets/svg/email-confirmed.svg';
import EmailNotConfirmed from '../assets/svg/email-not-confirmed.svg';

const getConfirmEmailData = (isEmailConfirmed) => {
    let img = <i><ReactSVG src={ EmailConfirmed } /></i>;
    let mainTitle = 'Ваш e-mail успешно подтвержден!';
    let subTitle = 'Спасибо, что подтвердили свой e-mail.';

    if(!isEmailConfirmed) {
        img = <i><ReactSVG src={ EmailNotConfirmed } /></i>;
        mainTitle = 'Не удалось подтвердить Ваш e-mail';
        subTitle = 'Попробуйте ещё раз!';
    }

    return { img, mainTitle, subTitle }
}

export default getConfirmEmailData;