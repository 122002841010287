import { setLoading } from '../action-creators/loading';
import { setUserDirectories, setChildDirectories } from '../action-creators/directories';
import apiClient from '../../service/axios';

export const getUserDirectories = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiClient.get('/user/directories');
        const responseJson = response.data;
        dispatch(setUserDirectories(responseJson));
        dispatch(setLoading(false));
        return Promise.resolve();
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const getChildDirectories = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiClient.get('/child/directories');
        const responseJson = response.data;
        dispatch(setChildDirectories(responseJson));
        dispatch(setLoading(false));
        return Promise.resolve();
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}