import { BlockTitle, CustomLink, OrderCard, OrderLoadingCard } from '../../../components';
import { useSelector } from 'react-redux';
import './active-orders.scss';

const ActiveOrders = () => {
    const { activeOrders, isLoadedActiveOrders } = useSelector(state => state.orders);

    return (
        <section className="active-orders">
            <div className="active-orders__header">
                <BlockTitle
                    title="Активные заказы"
                    description={isLoadedActiveOrders ? activeOrders.length > 0 ? "Ваши активные заказы" : "У Вас нет активных заказов" : ''}
                />
            </div>
            <div className="active-orders__body">

                {isLoadedActiveOrders
                    ?  activeOrders.map(order => {
                            return <OrderCard orderInfo={order} key={order.id} />
                        })
                    :  Array(2)
                       .fill(0).map((_, index) => (
                            <OrderLoadingCard key={index} />
                       ))
                }

            </div>
            <CustomLink to="/orders" className="custom-link__blue all-orders-link">Все заказы</CustomLink>
        </section>
    );
};

export default ActiveOrders;