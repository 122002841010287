import recoveryTypes from '../types/recovery-password';

export const setTokenSent = (payload) => ({
    type: recoveryTypes.SET_TOKEN_SENT,
    payload,
});

export const setErrorsStepEmail = (payload) => ({
    type: recoveryTypes.SET_ERRORS_STEP_EMAIL,
    payload,
});

export const setErrorsStepPassword = (payload) => ({
    type: recoveryTypes.SET_ERRORS_STEP_PASSWORD,
    payload,
});

export const setRedirectToStepEmail = (payload) => ({
    type: recoveryTypes.SET_REDIRECT_TO_EMAIL_STEP,
    payload,
});

export const setSuccess = (payload) => ({
    type: recoveryTypes.SET_SUCCESS,
    payload,
});