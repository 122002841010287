import { memo } from 'react';
import { CustomSelect, CustomCheckbox, LineButton } from '../../../components';
import { availabilityFilters } from '../../../const';
import './products-filters.scss';

const ProductsFilters = ({ totalPages, categories, filters, isLoading, handleResetFilters, handleCategoryChange, handleAvailabilityFilter }) => {
    const renderFilters = () => {
        if (totalPages !== null) {
            return (
                <>
                    {availabilityFilters.length > 0 && (
                        <div className="favorites-filters__item">
                            <div className="favorites-filters__item-title">Наличие товара</div>
                            <div className="favorites-filters__item-body">
                                <div className="favorites-filters__item-select">
                                    <CustomSelect
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        placeholder="Выберите"
                                        noOptionsMessage={() => 'Выбраны все варианты'}
                                        options={availabilityFilters}
                                        value={filters.availability}
                                        onChange={(value) => handleAvailabilityFilter(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {categories && categories.length > 1 && (
                        <div className="favorites-filters__item">
                            <div className="favorites-filters__item-title">Категории</div>
                            <div className="favorites-filters__item-body">
                                <div className="favorites-filters__item-checkboxes">
                                    {categories.map(section => (
                                        <CustomCheckbox
                                            key={`category-${section.ID}`}
                                            name={`category-${section.ID}`}
                                            label={section.NAME}
                                            state={filters.categories.includes(section.ID)}
                                            checked={filters.categories.includes(section.ID)}
                                            onChange={(e) => handleCategoryChange(e, section.ID)}
                                            disabled={isLoading}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="favorites-filters__reset-button-wrap">
                        <LineButton
                            className="custom-link__blue"
                            style={{fontSize: '16px'}}
                            onClick={handleResetFilters}
                        >
                            Очистить фильтры
                        </LineButton>
                    </div>
                </>
            );
        } else {
            return <div className="favorites-filters__item-title favorites-filters__item-title--mod">{isLoading ? 'Загрузка' : 'Фильтры недоступны'}</div>;
        }
    };

    return (
        <div className="favorites-filters">
            {renderFilters()}
        </div>
    );
};

export default memo(ProductsFilters);