import InputMask from 'react-input-mask';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import getCurrentDate from '../../../utils/getCurrentDate';
import { Input } from '../../../components';
import { IoCalendarClearOutline } from 'react-icons/io5';
import classNames from 'classnames/bind';
import './custom-date-input.scss';

const CustomDateInput = ({ label, name, value, handleChange, required = false, error = '', helpText = '' }) => {
    const { width } = useWindowDimensions();

    const renderInputs = () => {
        if (width <= 767) {
            const [year, month, day] = value.split('-');

            return (
                <div className="custom-date-input">
                    <label className="custom-date-input__label">
                        {label}
                        {label && required && <span className="required-mark">*</span>}
                    </label>
                    <div className={classNames({
                        'date-input': true,
                        'error': !!error
                    })}>
                        <InputMask
                            className="date-input__text"
                            mask="99-99-9999"
                            maskplaceholder="_"
                            value={`${day}-${month}-${year}`}
                            placeholder="дд-мм-гггг"
                            onChange={(e) => {
                                e.target.value = e.target.value.replaceAll('_', '0').split('-').reverse().join('-');
                                e.target.name = name;
                                handleChange(e, true);
                            }}
                        />
                        <input
                            type="date"
                            name={name}
                            className="date-input__date"
                            value={`${year}-${month}-${day}`}
                            min="1920-01-01"
                            max={getCurrentDate()}
                            onChange={handleChange}
                        />
                        <div className="date-input__icon">
                            <IoCalendarClearOutline size="24px" />
                        </div>
                    </div>

                    {error && <div className="error-text">{error}</div>}
                    {helpText && <div className="help-text">{helpText}</div>}
                </div>
            );
        } else {
            return (
                <Input
                    type="date"
                    label={label}
                    name={name}
                    min="1920-01-01"
                    max={getCurrentDate()}
                    value={value}
                    handleChange={handleChange}
                    required={required}
                    error={error}
                    helpText={helpText}
                />
            );
        }
    };

    return (
        <>
            {renderInputs()}
        </>
    );
};

export default CustomDateInput;