import { useHistory } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import './back-arrow.scss';

const BackArrow = () => {
    const history = useHistory();

    return (
        <button className="back-arrow" onClick={() => history.goBack()}>
            <IoArrowBack size="24px" />
        </button>
    );
};

export default BackArrow;