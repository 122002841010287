import { setLoading } from '../action-creators/loading';
import {
    setEmailError,
    setEmailTimer,
    setSenderPhone,
    setTypeDispatch,
    setNeedCall
} from '../action-creators/replacement-email';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';

export const replaceEmail = (newEmail) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('email', newEmail);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-email/send-sms-code-to-change-email',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Одноразовый код успешно отправлен!');
            dispatch(setTypeDispatch("SMS"));
            dispatch(setSenderPhone(data.phone));
            dispatch(setEmailTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setEmailError(null));
            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setEmailTimer(data.time));
                return Promise.reject();
            }

            if(data.call) {
                dispatch(setNeedCall(true))
                return Promise.reject();
            }

            dispatch(setEmailError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        dispatch(setLoading(false));
        toast.error('Произошла ошибка при отправке смс. Попробуйте еще');
        console.error(e);
    }
}

export const confirmReplaceEmail = (confirmCode) => async(dispatch) => {
    dispatch(setLoading(true));

    try {
        const formData = new FormData();
        formData.append('confirm_code', confirmCode);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-email/confirm-to-change-email',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            localStorage.setItem('IsEmailChangedSuccess', 'Операция по замене email успешно подтверждена!')
            window.location.reload()
        } else {
            dispatch(setEmailError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        dispatch(setLoading(false));
        toast.error('Произошла ошибка при подтверждении операции. Попробуйте еще');
        console.error(e);
    }
}

export const replaceEmailCall = (newEmail) => async(dispatch) => {
    dispatch(setLoading(true));
    dispatch(setNeedCall(false))
    try {
        const formData = new FormData();
        formData.append('email', newEmail);

        const { data } = await apiClient({
            method: 'post',
            url: '/change-email/call-number-code-to-change-email',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));

        if (data.type === 'OK') {
            toast.success('Одноразовый код успешно отправлен!');
            dispatch(setTypeDispatch("CALL"));
            dispatch(setSenderPhone(data.phone));
            dispatch(setEmailTimer(+process.env.REACT_APP_SMS_TIMEOUT));
            dispatch(setEmailError(null));
            return Promise.resolve();
        } else {
            if(data.time) {
                dispatch(setEmailTimer(data.time));
                return Promise.reject();
            }

            dispatch(setEmailError(data.errors));
            return Promise.reject();
        }

    } catch(e) {
        dispatch(setLoading(false));
        toast.error('Произошла ошибка при звонке. Попробуйте еще');
        console.error(e);
    }
}