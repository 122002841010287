import types from '../types/trainer-register';

const initialState = {
    errors: null
};

export const trainerRegisterReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state;
    }
};