import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    withCredentials: true,
});

apiClient.interceptors.response.use(response => {
    const { data } = response;
    if(data.spreadUrl) {
        window.location.replace(data.spreadUrl);
        return false;
    }
    return response;
}, error => {

    if (error.response.status === 401) {
        window.location = '/login';
        return Promise.reject();
    }

    if (error.response.status >= 500) {
        window.alert('Произошла ошибка загрузки данных. Пожалуйста, обновите страницу или попробуйте позже!');
        return Promise.reject();
    }

    return Promise.reject(error);
})

export default apiClient