import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BlockTitle,
    PageTitle,
    ProductsFilters,
    ProductsSortPanel,
    ProductsList,
    Button,
    Pagination,
    EmptyItems
} from '../../components';
import { usePagination } from '../../hooks/usePagination';
import { getFavorites, getCategories, removeFromFavorites } from '../../store/actions/favorites';
import { setFiltersCategories, resetFilters, setFiltersAvailability, setSort } from '../../store/action-creators/favorites';
import { IoCloseOutline, IoHeartDislikeOutline } from 'react-icons/io5';
import { sortItems } from '../../const';
import { toast } from 'react-toastify';
import './favorites.scss';

const Favorites = ({ title }) => {
    const [modalIsActive, setModalIsActive] = useState(false);
    const { items, totalPages, sort, filters, categories, isLoading, page } = useSelector(({ favorites }) => favorites);
    const dispatch = useDispatch();

    const [currentPage, changePage] = usePagination(() => dispatch(getFavorites(currentPage)));

    useEffect(() => {
        if (totalPages !== null) {
            changePage(1);
        }
    }, [filters, sort]);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const handleFavoriteChange = (id, remove) => {
        if (remove && window.confirm('Удалить из списка избранных товаров?')) {
            dispatch(removeFromFavorites(id))
                .then(() => {
                    dispatch(getFavorites(page));
                    toast.success('Товар успешно удалён из списка избранного');
                })
                .catch(() => toast.error('Не удалось удалить товар'));
        }
    };

    const handleCategoryChange = (e, id) => {
        if (e.target.checked) {
            dispatch(setFiltersCategories([ ...filters.categories, id ]));
        } else {
            dispatch(setFiltersCategories(filters.categories.filter(c => c !== id)));
        }
    };

    const renderPagination = () => {
        if (items && items.length > 0) {
            return <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={changePage} />;
        } else {
            return '';
        }
    };

    return (
        <>
            <PageTitle title={title} />
            <section className="page favorites-page">
                <div className="favorites">
                    <div className="favorites__header">
                        <BlockTitle
                            title="Избранные товары"
                            description="Ваши избранные товары."
                            back
                        />
                    </div>
                    <div className="favorites__body">
                        <ProductsSortPanel
                            items={items}
                            sort={sort}
                            sortItems={sortItems.favorites}
                            handleSortChange={(value) => dispatch(setSort(value))}
                            handleFilterModalClick={() => setModalIsActive(true)}
                        />
                        <ProductsList
                            items={items}
                            isLoading={isLoading}
                            keys={{
                                id: 'UF_PRODUCT_ID',
                                name: 'NAME',
                                picture: 'DETAIL_PICTURE',
                                link: 'DETAIL_PAGE_URL',
                                price: 'OPTIMAL_PRICE_VALUE',
                                oldPrice: 'OLD_PRICE_VALUE',
                                site: 'DOMAIN',
                                available: 'AVAILABLE',
                                isFavorite: 'isFavorite'
                            }}
                            emptyComponent={(
                                <EmptyItems
                                    icon={(props) => <IoHeartDislikeOutline {...props} />}
                                    text="Список избранных товаров пуст!"
                                />
                            )}
                            handleFavoriteChange={(id, remove) => handleFavoriteChange(id, remove)}
                        />

                        {renderPagination()}
                    </div>
                </div>
            </section>

            <section className={`favorites-filters-modal ${modalIsActive ? 'active' : ''}`} onClick={() => setModalIsActive(false)}>
                <button className="favorites-filters-modal__close">
                    <IoCloseOutline size="24px" stroke="#fff" />
                </button>
                <div className="favorites-filters-modal__wrap" onClick={e => e.stopPropagation()}>
                    <ProductsFilters
                        totalPages={totalPages}
                        categories={categories}
                        filters={filters}
                        isLoading={isLoading}
                        handleResetFilters={() => dispatch(resetFilters())}
                        handleCategoryChange={(e, id) => handleCategoryChange(e, id)}
                        handleAvailabilityFilter={(value) => dispatch(setFiltersAvailability(value))}
                    />
                    <Button style={{marginTop: '1rem'}} handleClick={() => setModalIsActive(false)}>Применить и закрыть</Button>
                </div>
            </section>
        </>
    );
};

export default Favorites;