import ImageUploading from 'react-images-uploading';
import { ReactSVG } from 'react-svg';
import classNames from "classnames/bind";
import CameraIcon from '../../../assets/svg/camera-add.svg';
import { IoCloseOutline } from 'react-icons/io5';
import './image-uploader.scss';

const ImageUploader = ({ label, error, helpText, required, currentImages = [], onRemoveCurrentImage = null, site = '', ...props }) => {
    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    return (
        <div className="image-uploader">
            <label className="image-uploader__label">
                {label}
                {required && <span className="required-mark">*</span>}
            </label>
            <ImageUploading
                {...props}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                }) => (
                    <>
                        <div className="image-uploader__wrap">
                            {currentImages.length > 0 && currentImages.map((image) => {
                                if (image.ID && image.ID !== null) {
                                    return (
                                        <div key={image.ID} className="image-uploader__image">
                                            <button
                                                className="image-uploader__image-remove"
                                                type="button"
                                                onClick={() => onRemoveCurrentImage(image.ID)}
                                            >
                                                <IoCloseOutline size="14px" stroke="#2B2A29" />
                                            </button>
                                            <div className="image-uploader__image-wrap">
                                                <img
                                                    src={`https://${site}/${image.IMG_RESIZE}` || `https://${site}/${image.IMG}`}
                                                    onError={setDefaultImage}
                                                    alt={`Current №${image.ID}`}
                                                />
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return '';
                                }
                            })}

                            {imageList.map((image, index) => (
                                <div key={index} className="image-uploader__image">
                                    <button
                                        className="image-uploader__image-remove"
                                        type="button"
                                        onClick={() => onImageRemove(index)}
                                    >
                                        <IoCloseOutline size="14px" stroke="#2B2A29" />
                                    </button>
                                    <div className="image-uploader__image-wrap">
                                        <img
                                            src={image[props.dataURLKey]}
                                            alt={`Upload №${index}`}
                                        />
                                    </div>
                                </div>
                            ))}

                            <button
                                className={classNames({
                                    'image-uploader__add': true,
                                    'image-uploader__add--dragging': isDragging
                                })}
                                onClick={onImageUpload}
                                type="button"
                                {...dragProps}
                            >
                                <ReactSVG src={CameraIcon} className="image-uploader__add-icon" />
                            </button>
                        </div>
                        {errors && <div>
                            {errors.maxNumber && <div className="error-text">Нельзя загрузить больше {props.maxNumber} фото</div>}
                            {errors.acceptType && <div className="error-text">Выбранный вами тип файла не разрешен</div>}
                        </div>}
                    </>
                )}
            </ImageUploading>
            {error && <div className="error-text">{error}</div>}
            {helpText && <div className="help-text">{helpText}</div>}
        </div>
    );
};

export default ImageUploader;