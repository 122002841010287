import { useState } from 'react';
import { Button, CustomSelect } from '../../../components';
import { useDispatch } from 'react-redux';
import { changePaymentType } from '../../../store/actions/orders';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { IoCloseOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import './modal-change-payment.scss';

const ModalChangePayment = ({ paymentTypes, currentPayment, closeHandler }) => {
    const [paymentType, setPaymentType] = useState(paymentTypes.filter(type => type.label === currentPayment.name)[0]);
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const onFormClick = e => e.stopPropagation();

    const changePayment = paymentType => setPaymentType(paymentType);

    const onSubmit = e => {
        e.preventDefault();
        dispatch(changePaymentType(paymentType.value, currentPayment.paymentId))
            .then(() => {
                closeHandler();
                toast.success('Способ оплаты успешно изменен!');
            })
            .catch(() => {
                toast.error('Произошла ошибка смены типа оплаты!');
            })
    }

    return (
        <form className="modal-change-payment" onSubmit={onSubmit} onClick={onFormClick}>
            <div className="modal-change-payment__close-icon" onClick={closeHandler}>
                <IoCloseOutline size="40" />
            </div>
            <div className="modal-change-payment__title">Выбор способа оплаты</div>
            <CustomSelect
                defaultValue={paymentType}
                options={paymentTypes}
                menuPlacement={width < 576 ? 'top': 'bottom'}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => changePayment(value)}
                placeholder="Выберите"
                label="Способ оплаты"
                required
            />
            <div className="modal-change-payment__button-wrapper">
                <Button type="submit" className="button__black modal-change-payment__button" disabled={!paymentType}>Сохранить</Button>
            </div>
        </form>
    );
}

export default ModalChangePayment;