import { Delimiter, EmptyItems } from '../../../components';
import { IoBagRemoveOutline } from 'react-icons/io5';
import './order-basket.scss';

const OrderBasket = ({ basket }) => {

    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    return (
        <div className="order-basket">
            <h2 className="order-basket__title">Товары</h2>
            <Delimiter />
            <div className="order-products">
                {basket.map((product, index) => (
                    <div className="order-product" key={index}>
                        <div className="order-product__main">
                            <div className="order-product__image">
                                {product.link
                                    ? <a href={product.link} target="_blank">
                                        <img src={product.image} alt={product.name} onError={setDefaultImage} />
                                      </a>
                                    : <img src={product.image} alt={product.name} onError={setDefaultImage} />
                                }
                            </div>
                            <div className="order-product__info">
                                <div className="order-product__name">{product.name}</div>
                                <div className="order-product__price"><span>{product.price.toLocaleString()}</span> руб. x {product.quantity} шт.</div>
                            </div>
                        </div>
                        {/*<LineButton className="order-product__review">Оставить отзыв</LineButton>*/}
                    </div>
                ))}
                {(!basket || basket.length === 0) &&
                    <EmptyItems icon={(props) => <IoBagRemoveOutline {...props} />} text="Не удалось получить информацию о товарах!" />
                }
            </div>
        </div>
    );
};

export default OrderBasket;