import userTypes from '../types/user';

const initialState = {
    registerData: [],
    registerDataErrors: null,
    customerData: [],
    customerDataErrors: null,
    participation: false,
    questionnaireCompleted: false,
    trainerParticipation: false,
    trainerQuestionnaireCompleted: false,
    errors: null
};

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case userTypes.SET_REGISTER_DATA:
            return {...state, registerData: action.payload}
        case userTypes.SET_REGISTER_DATA_ERRORS:
            return {...state, registerDataErrors: action.payload}
        case userTypes.SET_CUSTOMER_DATA:
            return {...state, customerData: action.payload}
        case userTypes.SET_CUSTOMER_DATA_ERRORS:
            return {...state, customerDataErrors: action.payload}
        case userTypes.SET_PARTICIPATION:
            return {...state, participation: action.payload}
        case userTypes.SET_QUESTIONNAIRE_COMPLETED:
            return {...state, questionnaireCompleted: action.payload}
        case userTypes.SET_TRAINER_PARTICIPATION:
            return {...state, trainerParticipation: action.payload}
        case userTypes.SET_TRAINER_QUESTIONNAIRE_COMPLETED:
            return {...state, trainerQuestionnaireCompleted: action.payload}
        case userTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}