const replacementPhoneTypes = {
    SET_SMS_SENT_TO_CHANGE_PHONE: 'REPLACEMENT_PHONE/SET_SMS_SENT_TO_CHANGE_PHONE',
    SET_SMS_SENT_TO_CANCEL_CHANGE_PHONE: 'REPLACEMENT_PHONE/SET_SMS_SENT_TO_CANCEL_CHANGE_PHONE',
    SET_TYPE_DISPATCH: 'REPLACEMENT_PHONE/SET_TYPE_DISPATCH',
    SET_SENDER_PHONE: 'REPLACEMENT_PHONE/SET_SENDER_PHONE',
    SET_NEW_PHONE: 'REPLACEMENT_PHONE/SET_NEW_PHONE',
    SET_EXIST_TASK: 'REPLACEMENT_PHONE/SET_EXIST_TASK',
    SET_DAYS_LEFT: 'REPLACEMENT_PHONE/SET_DAYS_LEFT',
    SET_TIMER: 'REPLACEMENT_PHONE/SET_TIMER',
    SET_ERRORS: 'REPLACEMENT_PHONE/SET_ERRORS',
}

export default replacementPhoneTypes;