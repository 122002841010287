import { setError, setAuth } from '../action-creators/auth';
import { setLoading } from '../action-creators/loading';
import apiClient from '../../service/axios';
import axios from 'axios';
import StorageCache from '../../service/storageCache';

export const sendSmsCode = ({phone, captchaToken}) => async dispatch => {
    if (!phone) {
        dispatch(setError('Некорректный номер телефона'));
        return Promise.reject();
    }
    dispatch(setLoading(true));
    const data = new FormData();
    data.append('phone', phone);
    data.append('captchaToken', captchaToken);
    try {
        const response = await apiClient.post(
            '/login/send-four-digit-code',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        const responseJson = response.data;
        if (responseJson.type === 'OK') {
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve();
        } else {
            dispatch(setError(responseJson.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const sendPhoneCall = ({phone, captchaToken}) => async dispatch => {
    if (!phone) {
        dispatch(setError('Некорректный номер телефона'));
        return Promise.reject();
    }
    dispatch(setError(null));
    dispatch(setLoading(true));
    const data = new FormData();
    data.append('phone', phone);
    data.append('captchaToken', captchaToken);
    try {
        const response = await apiClient.post(
            '/login/call-number-code',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        const responseJson = response.data;
        if (responseJson.type === 'OK') {
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve();
        } else {
            dispatch(setError(responseJson.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const confirmCode = (phone, code) => async dispatch => {
    dispatch(setLoading(true));
    const data = new FormData();
    data.append('phone', phone);
    data.append('code', code);
    try {
        const response = await apiClient.post(
            '/login/by-phone',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        const responseJson = response.data;
        if (responseJson.type === 'OK' && !responseJson.registerAllow) {
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve(responseJson);
        }
        if (responseJson.registerAllow) {
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve(responseJson);
        } else {
            dispatch(setError(responseJson.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const sendEmailAndPassword = ({ email, password }) => async dispatch => {
    dispatch(setLoading(true));
    const data = new FormData();
    data.append('login', email);
    data.append('password', password);
    try {
        const response = await apiClient.post(
            '/login/by-email',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        const responseJson = response.data;
        if (responseJson.type === 'OK') {
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve();
        } else {
            dispatch(setError(responseJson.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const register = data => async dispatch => {
    if (data.password.length < 6) {
        dispatch(setError({password: 'Пароль должен содержать минимум 6 символов'}));
        return Promise.reject();
    }
    dispatch(setLoading(true));
    try {
        const response = await apiClient.post('/register', JSON.stringify(data));
        const responseJson = response.data;
        if (responseJson.type === 'OK') {
            localStorage.setItem('emailConfirm', 'true');
            dispatch(setError(null));
            dispatch(setLoading(false));
            return Promise.resolve();
        } else if (responseJson.errors) {
            dispatch(setError(responseJson.errors));
        } else {
            dispatch(setError(responseJson.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const login = () => dispatch => {
    dispatch(setAuth(true));
}

export const logout = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        await apiClient.post('/logout');
    } catch (e) {
        console.log(e);
    }
    dispatch(setLoading(false));
}

export const checkRedirect = () => async dispatch => {
    dispatch(setLoading(true));
    const cache = new StorageCache();

    const referrer = cache.get('referrer');
    if(referrer === 'order') {
        return Promise.reject();
    }

    const redirectUrl = cache.get('redirect_url');
    cache.unset('redirect_url');
    if (!redirectUrl) {
        return Promise.reject();
    }
    const data = new FormData();
    data.append('uri', redirectUrl);
    try {
        const response = await apiClient.post(
            '/is-redirect-allowed',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        const responseJson = response.data;
        if (responseJson.type === 'OK') {
            let url = new URL(redirectUrl);
            setTimeout(() => {
                window.location = url.href;
            }, 1000)
            return Promise.resolve();
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const checkAuth = () => async dispatch => {
    try {
        const response = await axios.get('/user/check-auth', {
            baseURL: process.env.REACT_APP_BASE_API_URL,
            withCredentials: true
        });
        if (response) {
            dispatch(login());
            return Promise.resolve();
        }
    } catch (e) {
        console.error(e);
    }
    return Promise.reject();
}