import { setLoading } from "../action-creators/loading";
import {
    setFiltersStatus,
    setFiltersYear,
    setOrders,
    setActiveOrders,
    setDetailOrder,
    setPaymentTypes,
    setLoadedOrders,
    setLoadedActiveOrders,
    setErrors,
} from '../action-creators/orders';
import apiClient from '../../service/axios';
import parseFiltersStatusForOrders from '../../utils/parseFiltersStatusForOrders';
import parseFiltersYearForOrders from '../../utils/parseFiltersYearForOrders';
import parseOrderPaymentTypes from '../../utils/parseOrderPaymentTypes';

export const getOrdersFilters = () => async dispatch => {
    dispatch(setLoading(true));
    dispatch(setLoadedOrders(false));
    try {
        const {data} = await apiClient.get('/orders/available-filters');
        if (data.type !== 'ERROR') {
            dispatch(setFiltersStatus(parseFiltersStatusForOrders(data.status)));
            dispatch(setFiltersYear(parseFiltersYearForOrders(data.years)));
            return Promise.resolve();
        }
    } catch (e) {
        console.error(e);
    }
    return Promise.reject();
}

export const changePaymentType = (newPaymentId, oldPaymentId) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { detailOrder } = getState().orders;
    const dataToSend = {
        'order_id': String(detailOrder.id),
        'payment_id': String(oldPaymentId),
        'payment_system_id': String(newPaymentId)
    };
    try {
        const {data} = await apiClient.post('/orders/change-payment', JSON.stringify(dataToSend));
        if (data.type !== 'ERROR') {
            dispatch(setDetailOrder({...data.order, availablePaySystemsList: parseOrderPaymentTypes(data.order.availablePaySystemsList)}));
            dispatch(setLoading(false));
            return Promise.resolve();
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const getOrders = page => async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setLoadedOrders(false));
    const { filtersStatus: statuses, filtersYear: years } = getState().orders;
    const dataToSend = {
        page,
        status: (statuses.current.value !== 'ALL' && statuses.current.value !== 'onlyActive') ? statuses.current.value : '',
        years: years.filter(year => year.value === true).map(year => year.id),
        onlyActive: statuses.current.value === 'onlyActive'
    };
    try {
        const {data} = await apiClient.post('/orders/', JSON.stringify(dataToSend));
        if (data.type !== 'ERROR') {
            dispatch(setOrders(data));
            dispatch(setLoading(false));
            dispatch(setLoadedOrders(true));
            return Promise.resolve();
        } else {
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    dispatch(setLoadedOrders(true));
    return Promise.reject();
}

export const getActiveOrders = () => async dispatch => {
    dispatch(setLoadedActiveOrders(false));
    const dataToSend = {onlyActive: true, years:[], itemsPerPage: 3};
    try {
        const {data} = await apiClient.post('/orders/', JSON.stringify(dataToSend));
        if (data.type !== 'ERROR') {
            dispatch(setActiveOrders(data));
            dispatch(setLoadedActiveOrders(true));
            return Promise.resolve();
        } else {
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoadedActiveOrders(true));
    return Promise.reject();
}

export const getDetailOrder = orderId => async dispatch => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.get(`/orders/${orderId}`);
        dispatch(setDetailOrder({...data.order, availablePaySystemsList: parseOrderPaymentTypes(data.order.availablePaySystemsList)}));
        dispatch(setLoading(false));
        return Promise.resolve();
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const cancelOrder = (orderId, message) => async dispatch => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.post(`/orders/${orderId}/cancel`, JSON.stringify({reason: message}));
        if (data.type === 'OK') {
            dispatch(setLoading(false));
            window.location.reload();
            return Promise.resolve();
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}