import { setLoading } from '../action-creators/loading';
import {
    setRemainder,
    setRefCode,
    setMonths,
    setFilterMonth,
    setTransactions,
    setIsLoadedMonths,
    setIsLoadedTransactions,
    setIsLoadedRemainder,
    setErrorMonths,
    setErrorRemainder,
    setErrorTransactions,
    setTotalPages
} from '../action-creators/trainer';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';

const messages = {
    transactionError: 'Ошибка загрузки истории',
    remainderError: 'Ошибка загрузки информации о счёте'
};
const TRANSACTIONS_COUNT = 20;

export const getRemainderInfo = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/trainer/transaction/get-remainder');

        if (data.type === 'OK') {
            const remainder = data.remainder === null ? 0 : data.remainder;
            const redCode = data.card_code;

            dispatch(setRemainder(remainder));
            dispatch(setRefCode(redCode));
            dispatch(setIsLoadedRemainder(true));
            dispatch(setErrorRemainder(null));
        } else {
            dispatch(setErrorRemainder(data.errors));
            toast.error(messages.remainderError);
        }
    } catch (e) {
        dispatch(setErrorRemainder(e.message));
        toast.error(messages.remainderError);
    }
    dispatch(setLoading(false));
};

export const getMonths = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.get('/trainer/transaction/get-months');

        if (data.type === 'OK') {
            if (data.months && data.months.length > 0) {
                const months = data.months.sort((a, b) => b.number - a.number);
                dispatch(setMonths(months));
                dispatch(setFilterMonth(months[0].number));
            }
            dispatch(setIsLoadedMonths(true));
            dispatch(setErrorMonths(null));
        } else {
            dispatch(setErrorMonths(data.errors));
        }
    } catch (e) {
        dispatch(setErrorMonths(e.message));
    }
    dispatch(setLoading(false));
};

export const getTransactions = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { filterMonth, page } = getState().trainer;
        const { data } = await apiClient.post('/trainer/transaction/get-transactions', JSON.stringify({
            filter_month: filterMonth,
            page: page,
            limit: TRANSACTIONS_COUNT
        }));

        if (data.type === 'OK') {
            dispatch(setTransactions(data.data));
            dispatch(setIsLoadedTransactions(true));
            dispatch(setErrorTransactions(null));
            dispatch(setTotalPages(Math.ceil(Number(data.count) / TRANSACTIONS_COUNT)));
        } else {
            dispatch(setErrorTransactions(data.errors));
            toast.error(messages.transactionError);
        }
    } catch (e) {
        dispatch(setErrorTransactions(e.message));
        toast.error(messages.transactionError);
    }
    dispatch(setLoading(false));
};