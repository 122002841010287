import { useEffect } from 'react';
import {
    OrderCard,
    BlockTitle,
    CheckboxButton,
    CustomSelect,
    PageTitle,
    Pagination,
    Delimiter,
    OrderLoadingCard,
    EmptyItems,
    OrderLoadingFilters
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, getOrdersFilters } from '../../store/actions/orders';
import {
    resetFilters,
    setCurrentFiltersStatus,
    setCurrentFiltersYear,
    setOrders
} from '../../store/action-creators/orders';
import { usePagination } from '../../hooks/usePagination';
import { IoBagRemoveOutline } from 'react-icons/io5';
import './orders.scss';

const Orders = ({ title }) => {
    const [currentPage, changePage] = usePagination(() => dispatch(getOrders(currentPage)));
    const { filtersStatus, filtersYear, orders, totalPages, isLoadedOrders } = useSelector(state => state.orders);
    const dispatch = useDispatch();

    const changeFilterYear = e => {
        dispatch(setCurrentFiltersYear([e.target.dataset.key]));
        changePage(1);
    }

    const changeFiltersStatus = value => {
        if (filtersStatus.current.value !== value.value) {
            dispatch(setCurrentFiltersStatus(value));
            changePage(1);
        }
    };

    useEffect(() => {
        dispatch(getOrdersFilters());

        return () => {
            dispatch(resetFilters());
            dispatch(setOrders({orders: [], pagesTotal: 0}));
        }
    }, []);

    return (
        <>
            <PageTitle title={title} />
            <section className="page orders-page">
                <div className="orders">
                    <div className="orders__header">
                        <BlockTitle
                            title={title}
                            description="Список ваших заказов."
                        />

                        {filtersStatus.list.length > 0 &&
                            <>
                                <div className="orders-filters">
                                    {filtersStatus.list.length > 2 &&
                                        <div className="orders-filters__statuses">
                                            <CustomSelect
                                                defaultValue={filtersStatus.default}
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                name="orders_statuses"
                                                closeMenuOnSelect={true}
                                                options={filtersStatus.list}
                                                onChange={value => changeFiltersStatus(value)}
                                                placeholder="Выберите"
                                                noOptionsMessage={() => "Выбраны все варианты"}
                                            />
                                        </div>
                                    }
                                    {filtersYear.length > 1 &&
                                        <div className="orders-filters__years">
                                            {filtersYear.map(filter => (
                                                <div className="orders-filters__year" key={filter.id}>
                                                    <CheckboxButton checked={filter.value} onChange={changeFilterYear} data-key={filter.id}>{filter.id}</CheckboxButton>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {filtersStatus.list.length === 0 &&
                            <OrderLoadingFilters />
                        }
                    </div>

                    <Delimiter />

                    <div className="orders__body">
                        <div className="orders-list">
                            {isLoadedOrders && orders.length > 0 &&
                                orders.map(order => (
                                    <OrderCard orderInfo={order} key={order.id} />
                                ))
                            }
                            {!isLoadedOrders &&
                                Array(20)
                                    .fill(0).map((_, index) => (
                                    <OrderLoadingCard key={index} />
                                ))
                            }
                            {isLoadedOrders && orders.length === 0 && filtersStatus.list.length === 2 && filtersYear.length <= 1 &&
                                <EmptyItems icon={(props) => <IoBagRemoveOutline {...props} />} text="Список Ваших заказов пуст!" />
                            }
                            {isLoadedOrders && orders.length === 0 && (filtersStatus.list.length > 2 || filtersYear.length > 1) &&
                                <EmptyItems icon={(props) => <IoBagRemoveOutline {...props} />} text="Заказы с выбранными фильтрами отсутствуют!" />
                            }
                        </div>
                    </div>

                    {orders && orders.length > 0 &&
                        <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={changePage} />
                    }

                </div>
            </section>
        </>
    );
};

export default Orders;