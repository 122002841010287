
const getNotificationsTitles = type => {
    let title, description;
    switch (type) {
        case 'for_order':
            title = 'Статус заказа';
            description = 'Отслеживайте заказы в реальном времени';
            break;
        case 'for_favorites':
            title = 'Избранные товары';
            description = 'Узнавайте о снижении цены и появлении в продаже любимых товаров';
            break;
        case 'for_discounts':
            title = 'Скидки и акции';
            description = 'Экономьте на акциях и распродажах';
            break
        default:
            title = '';
            description = '';
    }

    return { title, description };
};

export default getNotificationsTitles;