import { BlockTitle, PageTitle, TrainerInfoCard, TrainerHistory } from '../../../components';
import './trainer-main.scss';

const TrainerMain = ({ title }) => {
    return (
        <>
            <PageTitle title={title} />
            <section className="page trainer-main-page">
                <div className="trainer-main-page__wrap">
                    <div className="trainer-main__header">
                        <BlockTitle
                            title="Кабинет тренера. Статистика"
                        />
                    </div>
                    <div className="trainer-main__body">
                        <div className="trainer-main__card">
                            <TrainerInfoCard />
                        </div>
                        <div className="trainer-main__history">
                            <TrainerHistory />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TrainerMain;