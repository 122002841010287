const getNormalPhone = phone => {
    const maxPhoneLength = 11;
    if (!phone || phone.length < maxPhoneLength || (phone[0] !== '7' && phone[0] !== '8')) {
        return null;
    }
    let normalPhone = phone;
    if (phone[0] === '8') {
        normalPhone = normalPhone.replace('8', '7');
    }
    return normalPhone;
}

export default getNormalPhone;