import sitesTypes from '../types/sites';

const initialState = {
    sites: [],
    isLoaded: false,
    errors: null
};

export const sitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case sitesTypes.SET_SITES:
            return {...state, sites: action.payload}
        case sitesTypes.SET_IS_LOADED:
            return {...state, isLoaded: action.payload}
        case sitesTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
};