import addressesTypes from '../types/addresses';

export const setError = payload => ({
    type: addressesTypes.SET_ERRORS,
    payload,
});

export const setLoaded = payload => ({
    type: addressesTypes.SET_LOADED,
    payload,
});

export const setAddresses = payload => ({
    type: addressesTypes.SET_ADDRESSES,
    payload
});

export const setAddress = payload => ({
    type: addressesTypes.SET_ADDRESS,
    payload
});

export const setTotalPages = payload => ({
  type: addressesTypes.SET_TOTAL_PAGES,
  payload
});

export const setPageElementCount = payload => ({
    type: addressesTypes.SET_PAGE_ELEMENT_COUNT,
    payload
});

export const setAddressesTotalCount = payload => ({
    type: addressesTypes.SET_ADDRESSES_TOTAL_COUNT,
    payload
});