import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fancybox } from '../../../components';
import ReactTooltip from 'react-tooltip';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { setReviewRatings } from '../../../store/actions/reviews';
import getDateFromTimestamp from '../../../utils/getDateFromTimestamp';
import parseHtmlSpecialChars from '../../../utils/parseHtmlSpecialChars';
import DislikeDisabled from '../../../assets/svg/dislike-disabled.svg';
import DislikeActive from '../../../assets/svg/dislike-active.svg';
import LikeActive from '../../../assets/svg/like-active.svg';
import LikeDisabled from '../../../assets/svg/like-disabled.svg';
import './reviews-item.scss';

const ReviewsItem = ({ review, onRemoveClick, onEditClick }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const user = useSelector(state => state.user);
    const { ID, COMMENT, CONS, DATE_CREATE, IMAGES, PRODUCT, PROS, RATING, ANSWER, LIKES, DISLIKES, USER_REACTION } = review;
    const randNumber = Math.floor(Math.random() * 100);

    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    const handleRemoveClick = (e, id) => {
        e.preventDefault();

        if (window.confirm('Подтверждаете удаление отзыва?')) {
            onRemoveClick(id);
        }
    };

    const handleEditClick = (e, id) => {
        e.preventDefault();
        onEditClick(id);
    };

    const renderPhotoList = () => {
        const imagesList = IMAGES.filter((img) => img.ID !== null);
        if (imagesList.length > 0) {
            return (
                <div className="reviews-item__comment">
                    <div className="reviews-item__comment-title">ФОТО</div>
                    <div className="reviews-item__photos">
                        <Fancybox
                            options={{
                                infinite: false,
                                groupAttr: 'data-lightbox'
                            }}
                        >
                            {imagesList.map(({ IMG, IMG_RESIZE }) => IMG !== null && (
                                <a
                                    href={`https://${PRODUCT.SITE}${IMG}`}
                                    className="reviews-item__photo"
                                    data-fancybox="review-image"
                                    data-lightbox={`${randNumber}`}
                                    key={IMG}
                                >
                                    <img src={`https://${PRODUCT.SITE}${IMG_RESIZE}`} alt="User photo." />
                                </a>
                            ))}
                        </Fancybox>
                    </div>
                </div>
            );
        } else {
            return '';
        }
    };

    const renderReviewRatings = () => {
        const likeIcon = USER_REACTION === 'like' ? LikeActive : LikeDisabled;
        const dislikeIcon = USER_REACTION === 'dislike' ? DislikeActive : DislikeDisabled;

        const clickHandler = (reviewId, action) => (event) => {
            event.stopPropagation();
            setDisable(true);
            dispatch(setReviewRatings(reviewId, action)).finally(() => setDisable(false));
        }

        return (
            <div className="reviews-item__review-ratings">
                <div className="review-ratings">
                    <button className="review-ratings__img" disabled={disable} onClick={clickHandler(ID, 'like')}>
                        <img src={likeIcon} alt="" />
                    </button>
                    <span className="review-ratings__count">{LIKES}</span>
                </div>
                <div className="review-ratings">
                    <button className="review-ratings__img" disabled={disable} onClick={clickHandler(ID, 'dislike')}>
                        <img src={dislikeIcon} alt="" />
                    </button>
                    <span className="review-ratings__count">{DISLIKES}</span>
                </div>
            </div>
        )
    };

    const renderAdminFeedback = () => {

        if(!ANSWER)
            return null;

        const reviewOwner = `${user.registerData.second_name} ${user.registerData.first_name[0]}.`;

        return (
            <div className="reviews-item__admin-feedback">
                <div className="admin-feedback__header">
                    <div className="admin-feedback__header-logo">
                        <img src="/images/logo-horizontal.png" alt="" />
                    </div>
                    <div className="admin-feedback__header-content">
                        <div className="admin-feedback__header-title">
                            Представитель магазина
                        </div>
                        <div className="admin-feedback__header-subtitle">
                            В ответ {reviewOwner}
                        </div>
                    </div>
                </div>
                <div className="admin-feedback__body">
                    <p className="admin-feedback__body-text">
                        {parseHtmlSpecialChars(ANSWER)}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className="reviews-item">
            <div className="reviews-item-wrapper">
                <div className="reviews-item__left">
                    <a href={PRODUCT.LINK} className="reviews-item__product-image" target="_blank">
                        <img src={PRODUCT.PICTURE || '/images/no-image.png'} alt={PRODUCT.NAME} onError={setDefaultImage} />
                    </a>
                    <div className="reviews-item__controls">
                        <button
                            type="button"
                            className="reviews-item__control"
                            onClick={(e) => handleEditClick(e, ID)}
                        >
                            <IoPencil size="16px" fill="#2B2A29" />
                        </button>
                        <button
                            type="button"
                            className="reviews-item__control"
                            onClick={(e) => handleRemoveClick(e, ID)}
                        >
                            <IoTrash size="16px" fill="#2B2A29" />
                        </button>
                    </div>
                </div>
                <div className="reviews-item__content">
                    <div className="reviews-item__header">
                        <div className="reviews-item__product-info">
                            <a href={`https://${PRODUCT.SITE}`} className="reviews-item__product-site" target="_blank">{PRODUCT.SITE}</a>
                            <a href={PRODUCT.LINK} className="reviews-item__product-name" target="_blank">{PRODUCT.NAME}</a>
                        </div>
                        <div className="reviews-item__rating-info">
                            <div className="reviews-item__rating">
                                <img src={`/images/rating/rating-${RATING}.svg`} alt="Rating." data-for={`rating-${ID}`} data-tip="" />
                                <ReactTooltip id={`rating-${ID}`} place="left" type="dark" effect="solid">Ваша оценка: {RATING}&#11088;</ReactTooltip>
                            </div>
                            <div className="reviews-item__date">{getDateFromTimestamp(DATE_CREATE)}</div>
                        </div>
                    </div>
                    <div className="reviews-item__body">
                        <div className="reviews-item__comment">
                            <div className="reviews-item__comment-title">ДОСТОИНСТВА</div>
                            <div className="reviews-item__comment-value">{parseHtmlSpecialChars(PROS) || '-'}</div>
                        </div>
                        <div className="reviews-item__comment">
                            <div className="reviews-item__comment-title">НЕДОСТАТКИ</div>
                            <div className="reviews-item__comment-value">{parseHtmlSpecialChars(CONS) || '-'}</div>
                        </div>
                        <div className="reviews-item__comment">
                            <div className="reviews-item__comment-title">КОММЕНТАРИЙ</div>
                            <div className="reviews-item__comment-value">{parseHtmlSpecialChars(COMMENT) || '-'}</div>
                        </div>
                        {renderPhotoList()}
                    </div>
                </div>
            </div>
            {renderReviewRatings()}
            {renderAdminFeedback()}
        </div>
    );
};

export default ReviewsItem;