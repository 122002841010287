import replacementEmailTypes from '../types/replacement-email';

const initialState = {
    newEmail: '',
    senderPhone: '',
    typeDispatch: 'SMS',
    isNeedCall: false,
    timer: 0,
    errors: null
}

export const replacementEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case replacementEmailTypes.SET_NEW_EMAIL:
            return {...state, newEmail: action.payload}
        case replacementEmailTypes.SET_SENDER_PHONE:
            return {...state, senderPhone: action.payload}
        case replacementEmailTypes.SET_TYPE_DISPATCH:
            return {...state, typeDispatch: action.payload}
        case replacementEmailTypes.SET_NEED_CALL:
            return {...state, isNeedCall: action.payload}
        case replacementEmailTypes.SET_TIMER:
            return {...state, timer: action.payload}
        case replacementEmailTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state;
    }
}