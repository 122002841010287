import StorageCache from "../service/storageCache";

const setReferrerParam = searchParams => {
    const referrer = searchParams.get('REFERRER');

    if (referrer) {
        const cache = new StorageCache();
        cache.set('referrer', referrer, 1000 * 300);
    }
}

export default setReferrerParam;