import { useEffect } from 'react';
import './page-title.scss';

const PageTitle = ({ title }) => {
    useEffect(() => {
        document.title = `${title} - ${process.env.REACT_APP_NAME}`;

        return () => {
            document.title = process.env.REACT_APP_NAME;
        };
    }, [title]);

    return (
        <header className="mobile-title d-block d-sm-none">
            <h1>{title}</h1>
        </header>
    );
};

export default PageTitle;