import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editReview } from '../../../store/actions/reviews';
import { Backdrop, Textarea, Rating, ImageUploader, Switcher, Button } from '../../../components';
import { CSSTransition } from 'react-transition-group';
import { IoCloseOutline } from 'react-icons/io5';
import { getUniqueImages } from '../../../utils/images';
import { toast } from 'react-toastify';
import parseHtmlSpecialChars from '../../../utils/parseHtmlSpecialChars';
import './modal-edit-review.scss';

const MAX_PHOTOS = 10;
const ACCEPT_TYPES = ['jpg', 'jpeg', 'png'];

const ModalEditReview = ({ closeHandler, id: reviewId }) => {
    const dispatch = useDispatch();
    const { reviews, createErrors: errors } = useSelector(({ reviews }) => reviews);
    const [form, setForm] = useState({
        productId: '',
        productName: '',
        productSite: '',
        reviewId: '',
        rating: 5,
        dignity: '',
        limitations: '',
        comment: '',
        photos: [],
        anonymous: false,
        currentPhotos: [],
        deleteImages: []
    });
    const [isReady, setIsReady] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const review = reviews.find((item) => item.ID === reviewId);

        if (!review) {
            closeHandler();
        } else {
            setForm({
                ...form,
                productId: review.PRODUCT.ID,
                productName: review.PRODUCT.NAME,
                productSite: review.PRODUCT.SITE,
                reviewId: reviewId,
                rating: +review.RATING,
                dignity: review.PROS,
                limitations: review.CONS,
                comment: review.COMMENT,
                anonymous: +review.ANONYMOUS === 1,
                currentPhotos: review.IMAGES,
            });
            setIsReady(true);
        }
    }, []);

    const handleFormClick = (e) => e.stopPropagation();
    const handleFormChange = (e) => setForm({...form, [e.target.name]: e.target.value});
    const handleImagesChange = (images) => {
        const filteredImages = getUniqueImages(images, 'data_url');
        handleFormChange({
            target: {
                name: 'photos',
                value: filteredImages
            }
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('review_id', form.reviewId);
        formData.append('rating', form.rating);
        formData.append('dignity', form.dignity);
        formData.append('limitations', form.limitations);
        formData.append('comment', form.comment);
        formData.append('anonymous', form.anonymous ? '1' : '0');
        form.deleteImages.forEach((img) => {
            formData.append('images_delete[]', img);
        });
        form.photos.forEach((photo) => {
            formData.append('photos[]', photo.file);
        });

        setDisabled(true);
        dispatch(editReview(formData))
            .then(() => {
                setDisabled(false);
                toast.success('Отзыв успешно обновлён');
                closeHandler();
            })
            .catch((e) => {
                setDisabled(false);
                toast.error('Не удалось обновить отзыв');
            });
    };

    let imageUploadHelpText;
    const maxPhotos = MAX_PHOTOS - +form.currentPhotos.length + +form.deleteImages.length;
    if (form.photos.length === 0) {
        if (maxPhotos === MAX_PHOTOS) {
            imageUploadHelpText = `Можно загрузить до ${MAX_PHOTOS} фотографий!`;
        } else {
            imageUploadHelpText = `Можно загрузить eще ${maxPhotos} фото`;
        }
    } else if (form.photos.length < MAX_PHOTOS) {
        imageUploadHelpText = `Можно загрузить еще ${maxPhotos - form.photos.length} фото`;
    } else {
        imageUploadHelpText = 'Загружено максимальное количество фото';
    }

    return (
        <CSSTransition
            in={isReady}
            timeout={300}
            classNames="modal-edit-review-animate"
            unmountOnExit
        >
            <Backdrop closeHandler={closeHandler}>
                <div className="modal-edit-review">
                    <div className="modal-edit-review__close" onClick={closeHandler}>
                        <div className="modal-edit-review__close-icon">
                            <IoCloseOutline size="40px" stroke="#FEFEFE" />
                        </div>
                    </div>
                    <form className="modal-edit-review__form" onSubmit={handleFormSubmit} onClick={handleFormClick}>
                        <div className="modal-edit-review__title">
                            Добавление отзыва к товару - <br/>
                            {form.productName}
                        </div>

                        <div className="modal-edit-review__fields">
                            <Rating
                                label="Оценка"
                                size={35}
                                count={5}
                                isHalf={false}
                                name="rating"
                                value={form.rating}
                                onChange={(value) => handleFormChange({
                                    target: {
                                        name: 'rating',
                                        value: value
                                    }
                                })}
                                error={errors && errors['rating']}
                            />

                            <Textarea
                                rows="4"
                                label="Достоинства"
                                name="dignity"
                                placeholder="Достоинства"
                                value={parseHtmlSpecialChars(form.dignity) || ''}
                                handleChange={handleFormChange}
                            />
                            <Textarea
                                rows="4"
                                label="Недостатки"
                                name="limitations"
                                placeholder="Недостатки"
                                value={parseHtmlSpecialChars(form.limitations) || ''}
                                handleChange={handleFormChange}
                            />
                            <Textarea
                                rows="4"
                                label="Комментарий"
                                name="comment"
                                placeholder="Комментарий"
                                value={parseHtmlSpecialChars(form.comment) || ''}
                                handleChange={handleFormChange}
                            />

                            <ImageUploader
                                multiple
                                label="Фото"
                                value={form.photos}
                                name="photos"
                                onChange={(images) => handleImagesChange(images)}
                                maxNumber={maxPhotos}
                                acceptType={ACCEPT_TYPES}
                                dataURLKey="data_url"
                                helpText={imageUploadHelpText}
                                currentImages={form.currentPhotos.filter((img) => !form.deleteImages.includes(img.ID))}
                                onRemoveCurrentImage={(id) => setForm({...form, deleteImages: [ ...form.deleteImages, id ]})}
                                site={form.productSite}
                            />

                            <Switcher
                                checked={form.anonymous}
                                name="anonymous"
                                onChange={(event) => handleFormChange({
                                    target: {
                                        name: event.target.name,
                                        value: event.target.checked
                                    }
                                })}
                                text="Скрыть мои данные в отзыве"
                                labelPosition="top"
                            />

                            <div className="modal-edit-review__rules-text">
                                Перед отправкой отзыва, пожалуйста, ознакомтесь с <a href="/">правилами публикации</a>.
                            </div>

                            <div className="modal-edit-review__submit-wrap">
                                <Button className="modal-edit-review__submit" type="submit" disabled={disabled}>Отправить отзыв</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Backdrop>
        </CSSTransition>
    );
};

export default ModalEditReview;