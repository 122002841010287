import classNames from 'classnames/bind';
import { Button } from '../../../components';
import getDateFromTimestamp from '../../../utils/getDateFromTimestamp';
import createLinkFromDomain from '../../../utils/createLinkFromDomain';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import './order-card.scss';

const OrderCard = ({ orderInfo }) => {
    const { id, status, basket, payment, price, site, paid, canceled, createdAt } = orderInfo;

    const getDate = () => {
        const date = getDateFromTimestamp(createdAt);
        return `От ${date}`;
    }

    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    return (
        <div className={classNames({
            'order-card': true,
            'order-card_canceled': canceled
        })}>
            <div className="order-card__left-block">
                <div className="order-card__info">
                    <div className="order-card__number">
                        {`№ ${id}`}
                        {canceled
                            ? <span className="order-card__site">{site}</span>
                            : <a className="order-card__site custom-link" href={createLinkFromDomain(site)} target="_blank">{site}</a>
                        }
                    </div>
                    <div className="order-card__date">{getDate()}</div>
                </div>

                {basket.length === 0
                    ? <div className="order-card__empty-basket">Не удалось получить данные о товарах</div>
                    : <div className="order-card__images">
                        {basket.map((product, index) => {
                            if (index > 3) {
                                return '';
                            }
                            if (index === 3) {
                                return <div className="order-card__more">+{basket.length - 3}</div>
                            }
                            return (
                                <>
                                    <div className={`order-card__image${canceled ? " order-card__image_dim" : ""}`} data-for={`${id}${product.name}`} data-tip="" key={index}>
                                        {product.link
                                            ? <a href={product.link} target="_blank">
                                                <img src={product.image} alt="" onError={setDefaultImage} />
                                              </a>
                                            : <img src={product.image} alt="" onError={setDefaultImage} />
                                        }
                                    </div>
                                    <ReactTooltip id={`${id}${product.name}`} place="right" type="dark" effect="solid">{product.name}</ReactTooltip>
                                </>
                            )
                        })}
                    </div>
                }

            </div>
            <div className="order-card__right-block">
                <div className="order-card__info">
                    <div className="order-card__price">{price.total} руб.</div>
                    <div className="order-card__status">
                        {canceled ?
                            'Заказ отменен'
                            : status.name
                        }
                    </div>
                </div>


                <div className="order-card__buttons">
                    {!paid && !canceled && payment.length > 0 && payment[0].link && !payment[0].isPaid &&
                        <a className="order-card__payment-link" href={payment[0].link} target="_blank">
                            <Button className="button__red order-card__button">Оплатить</Button>
                        </a>
                    }
                    <Link to={`/order/${id}`} className="order-card__detail-link">
                        <Button className="button__black order-card__button">Подробнее</Button>
                    </Link>
                </div>

            </div>
            {canceled && <div className="order-card-background" />}
        </div>
    )
};

export default OrderCard;