import apiClient from '../../service/axios';
import { setLoading } from '../action-creators/loading';
import { setConfirmedEmail, setLoaded, setError } from '../action-creators/email';

export const confirmEmail = (userId, token) => async dispatch => {
    if(!userId || !token) {
        dispatch(setLoading(false));
        dispatch(setConfirmedEmail(false));
        return Promise.resolve();
    }

    dispatch(setLoading(true));
    try {
        const url = `/register/confirm-email/?user_id=${userId}&token=${token}`;
        const {data} = await apiClient.get(url);
        console.log('data', data);
        if (data.type === 'OK') {
            dispatch(setConfirmedEmail(true));
            dispatch(setLoaded(true));
            dispatch(setError(null));
        } else {
            dispatch(setConfirmedEmail(false));
            dispatch(setError(data.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}