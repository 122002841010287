import { useValidateInput } from '../../../hooks/useValidateInput';
import classNames from 'classnames/bind';
import './input.scss'

const Input = ({ label, required, handleChange, error, helpText, ...props }) => {
    const { validate } = useValidateInput(props.type);

    const onChange = e => {
        handleChange(e);
    }

    const onKeyDown = e => validate(e);

    return (
        <div className={classNames({
            'input-wrapper': true,
            'error': !!error
        })}>
            <label className="custom-label">
                {label}
                {label && required && <span className="required-mark">*</span>}
            </label>

            <input className="custom-input" onKeyDown={onKeyDown} onChange={onChange} required={required} {...props} />
            {error && <div className="error-text">{error}</div>}
            {helpText && <div className="help-text">{helpText}</div>}
        </div>
    );
};

export default Input;