import { BlockTitle, PageTitle } from "../../components";
import './privacy-policy.scss';

const PrivacyPolicy = ({ title }) => {
    return (
        <div className="container privacy-policy-container">
            <PageTitle title={title} />
            <section className="page ">
                <div className="privacy-policy">
                    <div className="privacy-policy__header">
                        <BlockTitle
                            title="Политика конфиденциальности Нужный спорт"
                            classNames="privacy-policy__title"
                            styles={{justifyContent: 'center'}}
                        />
                        <p className="block-description">Политика конфиденциальности интернет-магазина
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                        </p>
                    </div>
                    <div className="privacy-policy__body">
                        <h3 className="privacy-policy__body-title">1. ВВЕДЕНИЕ</h3>

                        <p>Благодарим вас за то, что вы выбираете «Нужный спорт»! Мы ценим оказанное нам доверие и
                        стремимся защищать конфиденциальность и безопасность переданной нам персональной
                        информации. Предоставляемая информация помогает нам оказывать качественные услуги.</p>

                        <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика) действует
                        в отношении всей информации, которую Интернет-магазин
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>,
                        расположенный на доменном имени
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> http://www.nuznyisport.ru/</a>,
                        может получить о Пользователе во время использования сайта
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> http://www.nuznyisport.ru/</a>,
                        программ и продуктов Интернет-магазина. «Нужный спорт» собирает, использует, обрабатывает ваши персональные данные и
                        информацию (далее – «персональные данные» или «персональная информация») только с
                        вашего согласия, и/или согласия вашего законного представителя, путем совершения
                        конклюдентных действий при использовании любых веб-сайтов, принадлежащих «Нужный
                        спорт», в том числе при совершении любых операций и/или использования, платформ,
                        программ, приложений, продуктов, при оплате товаров и услуг, в наших магазинах на кассах и
                        в аппаратах самообслуживания, в процессе общения с операторами нашей горячей линии,
                        оформления заказов, регистрации в личном кабинете и подписки на рассылку (далее –
                        Сервисы).</p>

                        <p>Пожалуйста, обратите внимание, что использование любого Сервиса может регулироваться
                        дополнительными условиями, положениями применимого законодательства и «Нужный
                        спорт» могут вносить в настоящую Политику изменения и/или дополнения в любое время.
                        Как мы используем вашу информацию?</p>

                        <p>Мы используем вашу информацию для предоставления вам услуг в Сервисах «Нужный спорт».
                        Ваша информация используется для настройки учетной записи, упрощения обмена
                        сообщениями, обеспечения поддержки, предоставления доступа к функциям приложений и
                        иных платформ, при получении и использовании карт лояльности, а также усовершенствования
                        наших сервисов. «Нужный спорт» использует предоставленные вами каналы связи, такие как
                        номер мобильного телефона или адрес электронной почты, для проверки и защиты вашей
                        учетной записи, а также для администрирования ресурса и рассылки вам рекламных
                        материалов.</p>

                        <p>Кто может получать доступ к вашей информации?</p>

                        <p>Поскольку мы используем дополнительные услуги третьих лиц, такие как услуги передачи SMS сообщений для проверки учетных записей, для осуществления экваринга операций,
                        мапирования данных и обслуживания точек интереса, а также услуг по переводу и поддержке.
                        Эти услуги третьих лиц используются исключительно для обработки или хранения вашей
                        информации в целях, описанных в настоящих правилах. Помимо вышеприведенных целей,
                        «Нужный спорт» не передает ваши данные третьим лицам без вашего предварительного
                        согласия. Любое третье лицо (выбранное нами), с кем мы обмениваемся вашими данными,
                        будет предоставлять идентичную или аналогичную защиту этих данных.</p>

                        <h3 className="privacy-policy__body-title">2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>

                        <p>Если определение термина не указано в настоящей Политике (например, для термина
                        «аффилированные лица»), то подобные определения используются в значении, указанном в
                        применимом законодательстве.</p>

                        <p>2.1. Термины «Нужный спорт», «мы», «нас» или «наш» обозначают компанию ИП Зыков М.А.
                        (ОГРНИП 304590411100151), юридическое лицо, созданное по законодательству Российской
                        Федерации и зарегистрированное по адресу: 625034 Тюменская обл., г. Тюмень, ул.
                        Судостроителей, д. 38, кв. 825, которая несет ответственность за данные пользователей
                        согласно настоящей Политике конфиденциальности.</p>

                        <p>2.2. Термины «Пользователь», «вы», «ваш», или «вам» обозначает физическое лицо
                        подтверждающее свою дееспособность и дающее согласие на обработку своих персональных
                        данных.</p>

                        <p>2.3. «Администрация сайта Интернет-магазина (далее – Администрация сайта)» –
                        уполномоченные сотрудники, действующие от имени ИП Зыков М.А., которые организуют и
                        (или) осуществляет обработку персональных данных, а также определяет цели обработки
                        персональных данных, состав персональных данных, подлежащих обработке, действия
                        (операции), совершаемые с персональными данными.</p>

                        <p>2.4. «Персональные данные» - любая информация, относящаяся к прямо или косвенно
                        определенному или определяемому физическому лицу (субъекту персональных данных).</p>

                        <p>2.5. «Обработка персональных данных» - любое действие (операция) или совокупность
                        действий (операций), совершаемых с использованием средств автоматизации или без
                        использования таких средств с персональными данными, включая сбор, запись,
                        систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                        использование, обезличивание, блокирование, удаление, уничтожение персональных данных.</p>

                        <p>2.6. «Конфиденциальность персональных данных» - обязательное для соблюдения
                        Оператором или иным получившим доступ к персональным данным лицом требование не
                        допускать их распространения без согласия субъекта персональных данных или наличия иного
                        законного основания.</p>

                        <p>2.7. «Пользователь сайта Интернет-магазина (далее Пользователь)» – лицо, имеющее доступ к
                        Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.</p>

                        <p>2.8. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на
                        компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>

                        <p>2.9. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по
                        протоколу IP.</p>

                        <h3 className="privacy-policy__body-title">3. ОБЩИЕ ПОЛОЖЕНИЯ</h3>

                        <p>3.1. Использование Пользователем сайта Интернет-магазина означает согласие с настоящей
                        Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>

                        <p>3.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен
                        прекратить использование сайта Интернет-магазина.</p>

                        <p>3.3.Настоящая Политика конфиденциальности применяется только к сайту Интернетмагазина
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru </a>
                        и личному кабинету сайта
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru </a>
                        не контролирует и не несет
                        ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам,
                        доступным на сайте Интернет-магазина.</p>

                        <p>3.4. Администрация сайта не проверяет достоверность персональных данных,
                        предоставляемых Пользователем сайта Интернет-магазина.</p>

                        <h3 className="privacy-policy__body-title">4. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>

                        <p>4.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации
                        сайта интернет-магазина по неразглашению и обеспечению режима защиты
                        конфиденциальности персональных данных, которые Пользователь предоставляет по запросу
                        Администрации сайта при регистрации на сайте интернет-магазина или при оформлении
                        заказа для приобретения Товара или иных действиях, требующих персональных данных.</p>

                        <p>4.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики
                        конфиденциальности, предоставляются Пользователем и включают в себя следующую
                        информацию:</p>

                        <p>4.2.1. информация о Пользователе: фамилию, имя, отчество, контактный телефон, адрес
                        электронной почты (e-mail), адрес проживания Пользователя, адрес доставки Товара,
                        приоритетный способ получения заказов, место жительства, логин и пароль, дата рождения,
                        пол, виды спорта, которыми занимается Пользователь, фото пользователя, отзывы
                        пользователя о товарах;</p>

                        <p>4.2.2. информация о детях Пользователя: фамилия, имя, отчество, пол, дата рождения, типы и
                        названия спортивных секций, которые они посещают, тренировочный уровень ребёнка в
                        спортивной секции, отметка об участии в спортивных соревнованиях;</p>

                        <p>4.2.3. дополнительная информация: фамилия тренера спортивной секции у детей
                        Пользователя, адрес спортивной секции.</p>

                        <p>4.3. Интернет-магазин защищает Данные, которые автоматически передаются при посещении
                        страниц Интернет-магазина:</p>

                        <ul className="secure-data__list">
                            <li className="secure-data__item">IP адрес;</li>
                            <li className="secure-data__item">информация из cookies;</li>
                            <li className="secure-data__item">информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
                            <li className="secure-data__item">время доступа;</li>
                            <li className="secure-data__item">адрес страницы, на которой расположен рекламный блок;</li>
                            <li className="secure-data__item">Дата, время и количество посещений;</li>
                            <li className="secure-data__item">Адрес сайта, с которого пользователь осуществил переход на сайт Компании;</li>
                            <li className="secure-data__item">Сведения о местоположении;</li>
                            <li className="secure-data__item">реферер (адрес предыдущей страницы).</li>
                        </ul>

                        <p>4.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта Интернетмагазина, требующим авторизации.</p>

                        <p>4.3.2. Интернет-магазин осуществляет сбор статистики об IP-адресах своих посетителей. Данная
                        информация используется с целью выявления и решения технических проблем, для контроля
                        законности проводимых финансовых платежей.</p>

                        <p>4.4. Любая иная персональная информация неоговоренная выше (история покупок,
                        используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и
                        нераспространению, за исключением случаев, предусмотренных в п.п. 6.2. и 6.3. настоящей
                        Политики конфиденциальности.</p>

                        <h3 className="privacy-policy__body-title">5. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h3>

                        <p>5.1. Персональные данные Пользователя Администрация сайта интернет-магазина может
                        использовать в целях:</p>

                        <p>5.1.1. Идентификации Пользователя, зарегистрированного на сайте Интернет-магазина, для
                        оформления заказа и (или) заключения Договора купли-продажи товара дистанционным
                        способом с Название интернет-магазина.</p>

                        <p>5.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта
                        интернет-магазина.</p>

                        <p>5.1.3. Установления с Пользователем обратной связи, включая направление уведомлений,
                        запросов, касающихся использования Сайта интернет-магазина, оказания услуг, обработка
                        запросов и заявок от Пользователя.</p>

                        <p>5.1.4. Определения места нахождения Пользователя для обеспечения безопасности,
                        предотвращения мошенничества.</p>

                        <p>5.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных
                        Пользователем.</p>

                        <p>5.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на
                        создание учетной записи.</p>

                        <p>5.1.7. Уведомления Пользователя Сайта интернет-магазина о состоянии Заказа.</p>

                        <p>5.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот,
                        оспаривания платежа, определения права на получение кредитной линии Пользователем.</p>

                        <p>5.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при
                        возникновении проблем, связанных с использованием Сайта интернет-магазина.</p>

                        <p>5.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных
                        предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернетмагазина или от имени партнеров Интернет-магазина.</p>

                        <p>5.1.11. Осуществления рекламной деятельности с согласия Пользователя.</p>

                        <p>5.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров Интернет магазина с целью получения продуктов, обновлений и услуг.</p>

                        <h3 className="privacy-policy__body-title">6. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>

                        <p>6.1. Обработка персональных данных Пользователя осуществляется без ограничения срока,
                        любым законным способом, в том числе в информационных системах персональных данных с
                        использованием средств автоматизации или без использования таких средств.</p>

                        <p>6.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать
                        персональные данные третьим лицам, в частности, курьерским службам, организациями
                        почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа
                        Пользователя, оформленного на Сайте интернет-магазина
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>,
                        включая доставку
                        Товара.</p>

                        <p>6.3. Персональные данные Пользователя могут быть переданы уполномоченным органам
                        государственной власти Российской Федерации только по основаниям и в порядке,
                        установленным законодательством Российской Федерации.</p>

                        <p>6.4. При утрате или разглашении персональных данных Администрация сайта информирует
                        Пользователя об утрате или разглашении персональных данных.</p>

                        <p>6.5. Администрация сайта принимает необходимые организационные и технические меры для
                        защиты персональной информации Пользователя от неправомерного или случайного доступа,
                        уничтожения, изменения, блокирования, копирования, распространения, а также от иных
                        неправомерных действий третьих лиц.</p>

                        <p>6.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по
                        предотвращению убытков или иных отрицательных последствий, вызванных утратой или
                        разглашением персональных данных Пользователя.</p>

                        <p>6.7. При совершении платежей Пользователя с использованием платежных банковских карт,
                        безопасность обработки платежей, в том числе и Интернет-платежей, гарантируется
                        сервисом: "3-D Secure". Ресурсы сервиса соответствуют международным стандартам
                        безопасности, имеют все необходимые разрешения и сертификаты.</p>

                        <p>При проведении платежей предоставляемая Пользователя персональная информация (имя,
                        адрес, телефон, e-mail, номер банковской карты и пр.) является конфиденциальной и не
                        подлежит разглашению ни при каких условиях.</p>

                        <p>Все платежи в системе Visa, MasterCard, «МИР» и JCB безотзывны. Это значит, что Пользователь
                        не рискуете столкнуться с мошенником, который, получив товар, захочет вернуть деньги.</p>

                        <h3 className="privacy-policy__body-title">7. ОБЯЗАТЕЛЬСТВА СТОРОН</h3>

                        <p>7.1. Пользователь обязан:</p>

                        <p>7.1.1. Предоставить информацию о персональных данных, необходимую для пользования
                        Сайтом интернет-магазина.</p>

                        <p>7.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае
                        изменения данной информации.</p>

                        <p>7.2. Администрация сайта обязана:</p>

                        <p>7.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4
                        настоящей Политики конфиденциальности.</p>

                        <p>7.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
                        предварительного письменного разрешения Пользователя, а также не осуществлять продажу,
                        обмен, опубликование, либо разглашение иными возможными способами переданных
                        персональных данных Пользователя, за исключением п.п. 6.2. и 6.3. настоящей Политики
                        Конфиденциальности.</p>

                        <p>7.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных
                        данных Пользователя согласно порядку, обычно используемого для защиты такого рода
                        информации в существующем деловом обороте.</p>

                        <p>7.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
                        Пользователю, с момента обращения или запроса Пользователя или его законного
                        представителя либо уполномоченного органа по защите прав субъектов персональных данных
                        на период проверки, в случае выявления недостоверных персональных данных или
                        неправомерных действий.</p>

                        <h3 className="privacy-policy__body-title">8. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</h3>

                        <p>8.1. Наименование (фамилия, имя, отчество) оператора: ИП Зыков М.А.</p>

                        <p>8.2. Цель обработки персональных данных: розничная торговля</p>

                        <p>8.3. Перечень персональных данных, на обработку которых дается согласие субъекта
                        персональных данных: фамилия, имя, отчество, контактный телефон, адрес электронной почты
                        (e-mail), адрес проживания Пользователя, адрес доставки Товара, приоритетный способ
                        получения заказов, место жительства, логин и пароль, дата рождения, пол, виды спорта,
                        которыми занимается Пользователь, фото пользователя, отзывы пользователя о товарах,
                        информация о детях Пользователя: фамилия, имя, отчество, пол, дата рождения, типы и
                        названия спортивных секций, которые они посещают, тренировочный уровень ребёнка в
                        спортивной секции, отметка об участии в спортивных соревнованиях, дополнительная
                        информация: фамилия тренера спортивной секции у детей Пользователя, адрес спортивной
                        секции.</p>

                        <p>8.4. Наименование или фамилия, имя, отчество и адрес лица, осуществляющего обработку
                        персональных данных по поручению оператора, если обработка будет поручена такому
                        лицу: отсутствует.</p>

                        <p>8.5. Перечень действий с персональными данными, на совершение которых дается согласие,
                        общее описание используемых оператором способов обработки персональных данных:
                        запись, хранение, использование, удаление. Обработка вышеуказанных персональных данных
                        будет осуществляться путем: автоматизированная. Осуществление трансграничной передачи
                        персональных данных не осуществляется.</p>

                        <p>8.6. Срок, в течение которого действует согласие субъекта персональных данных, а также
                        способ его отзыва, если иное не установлено федеральным законом: бессрочно.</p>

                        <p>8.7. Физическое лицо может отозвать свое согласие на обработку персональных данных: по
                        заявлению.</p>

                        <h3 className="privacy-policy__body-title">9. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>

                        <p>9.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за
                        убытки, понесённые Пользователем в связи с неправомерным использованием персональных
                        данных, в соответствии с законодательством Российской Федерации, за исключением случаев,
                        предусмотренных п.п. 6.2., 6.3. и 9.2. настоящей Политики Конфиденциальности.</p>

                        <p>9.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта
                        не несёт ответственность, если данная конфиденциальная информация:</p>

                        <p>9.2.1. Стала публичным достоянием до её утраты или разглашения.</p>

                        <p>9.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>

                        <p>9.2.3. Была разглашена с согласия Пользователя.</p>

                        <h3 className="privacy-policy__body-title">10. РАЗРЕШЕНИЕ СПОРОВ</h3>

                        <p>10.1. До обращения в суд с иском по спорам, возникающим из отношений между
                        Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является
                        предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>

                        <p>10.2. Получатель претензии в течение 30 календарных дней со дня получения претензии,
                        письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>

                        <p>10.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в
                        соответствии с действующим законодательством Российской Федерации.</p>

                        <p>10.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и
                        Администрацией сайта применяется действующее законодательство Российской Федерации.</p>

                        <h3 className="privacy-policy__body-title">11. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>

                        <p>11.1. Администрация сайта вправе вносить изменения в настоящую Политику
                        конфиденциальности без согласия Пользователя.</p>

                        <p>11.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте
                        интернет-магазина, если иное не предусмотрено новой редакцией Политики
                        конфиденциальности.</p>

                        <p>11.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует
                        сообщать на электронную почту internet-magazin@avtsport.ru.</p>

                        <p>11.4. Действующая Политика конфиденциальности размещена на странице по
                        адресу
                        <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> http://www.nuznyisport.ru/</a>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy;