"use strict";

var StorageCachePrototype = {
    _namespace: 'cached',
    _storage: {},

    /**
     * Позволяет кэшировать любые данные, в том числе на определенный срок
     * @param {string} [namespace = cached] Задает область видимости записей
     * @constructor
     */
    constructor: function (namespace) {
        if (window.Storage && window.localStorage && localStorage instanceof Storage) {
            this._storage = localStorage;
        } else if (window.console) {
            console.warn('StorageCache: localStorage not found in this browser, data will not be saved');
        }

        if (namespace === undefined) {
            return;
        }
        if (typeof namespace != 'string') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof namespace + ' given');
        }
        this._namespace = namespace;
    },

    /**
     * Сохраняет данные в кэш
     * @param {string} key Идентификатор записи
     * @param value Значение записи, может быть любым объектом кроме функции
     * @param {number} [expires] Время хранения записи в миллисекундах. Если не передать, запись будет храниться постоянно
     * @returns {boolean} Возвращает true если данные будут кэшированы, false в обратном случае
     */
    set: function (key, value, expires) {
        if (typeof key != 'string' && typeof key != 'number') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof key + ' given');
        }
        if (typeof value == 'function') {
            throw new TypeError('Parameter 2 cannot be a function');
        }
        if (expires === undefined) {
            expires = 0;
        }
        if (typeof expires != 'number') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof expires + ' given');
        }

        if (expires != 0) {
            expires = (new Date()).getTime() + expires
        }
        var record = {
            expires: expires,
            data: value
        };
        
        try {
            this._storage[this._getRealKey(key)] = JSON.stringify(record);
        } catch (e) {
            return false;
        }
        
        return this.isCacheable();
    },

    /**
     * Получает запись из кеша
     * @param {string} key Идентификатор записи
     * @returns {*}
     */
    get: function (key) {
        if (typeof key != 'string' && typeof key != 'number') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof key + ' given');
        }

        return this._getRecord(key);
    },

    /**
     * Проверяет, еслть ли запись с таким идентификатором
     * @param {string} key Идентификатор записи
     * @returns {boolean}
     */
    has: function (key) {
        if (typeof key != 'string' && typeof key != 'number') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof key + ' given');
        }

        return this._getRecord(key) !== undefined;
    },

    /**
     * Удаляет запись
     * @param {string} key Идентификатор записи
     * @returns {boolean}
     */
    unset: function (key) {
        if (typeof key != 'string' && typeof key != 'number') {
            throw new TypeError('Parameter 1 must be a string, ' + typeof key + ' given');
        }
        return delete this._storage[this._getRealKey(key)];
    },

    /**
     * Удаляет все записи данного пространства имен
     */
    clear: function () {
        for (var key in this._storage) {
            if (!this._storage.hasOwnProperty(key)) continue;
            if (this._namespace == key.slice(0, this._namespace.length)) {
                delete this._storage[key];
            }
        }
    },

    /**
     * Проверяет, будут ли кэшированы сохраняемые данные
     * @returns {boolean}
     */
    isCacheable: function () {
        if (window.Storage && this._storage instanceof Storage) {
            return true;
        } else {
            return false;
        }
    },
    
    /**
     * Возвращает реальный ключ, под которым сохранена запись
     * @param key
     * @returns {string}
     * @private
     */
    _getRealKey: function (key) {
        return this._namespace + '-' + key;
    },

    /**
     * Возвращает запись, если она корректна и её время жизни еще не истекло
     * @param key Идентификатор записи
     * @returns {*}
     * @private
     */
    _getRecord: function (key) {
        var record;
        var realkey = this._getRealKey(key);

        if (this._storage[realkey] === undefined) {
            return undefined;
        }

        try {
            record = JSON.parse(this._storage[realkey]);
        } catch (e) {
            this.unset(key);
            return undefined;
        }

        if (record.expires != 0 && (new Date).getTime() > record.expires) {
            this.unset(key);
            return undefined;
        }

        return record.data;
    }
};

var StorageCache = StorageCachePrototype.constructor;
StorageCache.prototype = StorageCachePrototype;

export default StorageCache;