import Skeleton from 'react-loading-skeleton';
import './order-loading-card.scss';

const OrderLoadingCard = () => {
    return (
        <div className="order-loading-card">
            <Skeleton width="100%" height={200} />
        </div>
    );
};

export default OrderLoadingCard;