import { Radio } from 'pretty-checkbox-react';
import './radio-group.scss';

const RadioGroup = ({ defaultValue, items, name, label, required, onChange, error, ...props }) => {
    return (
        <div className="custom-radio-wrapper">
            <label className="custom-radio-label">
                {label}
                {required && <span className="required-mark">*</span>}
            </label>
            <div className="custom-radio-items">
                {items && items.map(el =>
                    <Radio
                        key={el.value}
                        name={name}
                        value={el.value}
                        className="custom-radio"
                        checked={defaultValue === el.value ? true : false}
                        onChange={() => onChange({target: {name: name, value: {value: el.value, label: el.label}}})}
                        required={required}
                        {...props}
                    >
                        {el.label}
                    </Radio>
                )}
            </div>
            {error && <div className="error-text">{error}</div>}
        </div>
    );
};

export default RadioGroup;