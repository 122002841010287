import { useEffect, useState } from 'react';
import { BlockTitle } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getCard } from '../../store/actions/card';
import { IoTimeOutline } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './card-and-code.scss';

const CardAndCode = () => {
    const dispatch = useDispatch();

    const card = useSelector(state => state.card.card);
    const isParticipation = useSelector(state => state.user.participation);
    const { personal_mobil } = useSelector(state => state.user.registerData);
    const [modalIsActive, setModalIsActive] = useState(false);
    const {
        trainerParticipation: isTrainerParticipation,
        trainerQuestionnaireCompleted: isTrainerQuestionnaireCompleted
    } = useSelector(({ user }) => user);


    useEffect(() => {
        if (!card && isParticipation) {
            dispatch(getCard());
        }
    }, []);

    return (
        <>
            <section className="card-code">
                <div className="card-code__header">
                    <BlockTitle
                        title="Дисконтная карта"
                        description="Ваша дисконтная карта."
                    />
                </div>
                <div className="card-code__body">
                    <div className="row">
                        {isTrainerQuestionnaireCompleted && !isTrainerParticipation && (
                            <div className="col-12">
                                <div className="card-code__trainer">
                                    <div className="card-code__trainer-icon">
                                        <IoTimeOutline size="50px" fill="#D32A29" stroke="#D32A29" />
                                    </div>
                                    <div className="card-code__trainer-content">
                                        <div className="card-code__trainer-title">Активация личной карты тренера</div>
                                        <div className="card-code__trainer-description">
                                            Мы получили Вашу заявку на активацию личного кабинета и выпуск персональной карты «Нужный спорт» для тренера. Выпуск карты может занять до нескольких дней, но обычно всё готово в течение рабочего дня. Менеджер обязательно свяжется с Вами после проверки анкеты. Благодарим за ожидание.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-md-6 column column--info">
                            <div className="discount-card card-code__item">
                                <img className="discount-card__logo" src="/images/logo-vertical-white.png" alt="Logo"/>
                                {isParticipation
                                    ?
                                    <>
                                        <div className="discount-card__title">
                                            Дисконтная карта<br />
                                            "Нужный спорт"
                                        </div>
                                        <div className="discount-card__binding">
                                            <div className="discount-card__binding-title">Карта привязана к номеру</div>
                                            <div className="discount-card__binding-phone">{personal_mobil || ''}</div>
                                        </div>
                                    </>

                                    :
                                    <div className="discount-card__title">
                                        Ваша карта<br />
                                        будет доступна<br />
                                        через 2 минуты
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-6 column column--barcode">
                            <div className="barcode card-code__item">
                                <div className="barcode__element" onClick={card ? () => setModalIsActive(true): () => {}}>
                                    {card && card.barcode_image && card.barcode
                                        ? (<img src={card.barcode_image} alt={card.barcode} />)
                                        : (<Skeleton width={260} height={93} />)
                                    }
                                    <div className="barcode__number">
                                        {(card && card.barcode) || <Skeleton width={130} height={25} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-12">*/}
                    {/*        <div className="card-code__text">*/}
                    {/*            Оформите заказов ещё на <b>13 500р</b>, чтобы получить скидку на всё <b>3%</b>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>

            <section className={`barcode-modal ${modalIsActive ? 'active' : ''}`} onClick={() => setModalIsActive(false)}>
                <div className="barcode-modal__element" onClick={e => e.stopPropagation()}>
                    {card && card.barcode_image && card.barcode
                        ? (<img src={card.barcode_image} alt={card.barcode} />)
                        : (<Skeleton width={260} height={93} />)
                    }
                    <div className="barcode-modal__number">
                        {(card && card.barcode) || <Skeleton width={130} height={25} />}
                    </div>
                </div>
            </section>
        </>
    );
};

export default CardAndCode;