import {useCallback, useEffect} from 'react';
import { setError } from '../../store/action-creators/auth';
import { Button, Input } from '../UI';
import { useSelector, useDispatch } from 'react-redux';
import { sendSmsCode, sendPhoneCall } from '../../store/actions/auth';
import { useInput } from '../../hooks/useInput';
import { authType } from '../../const';
import getNormalPhone from '../../utils/auth/getNormalPhone';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const FormPhone = ({ handleClick }) => {
    const { state: phoneNumber, handleChange: changePhone } = useInput('');
    const error = useSelector(state => state.auth.error);
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        return await executeRecaptcha('auth');
    }, [executeRecaptcha]);

    useEffect(() => {
        dispatch(setError(null));
    }, [])

    const sendSms = async (e) => {
        e.preventDefault();
        const captchaToken = await handleReCaptchaVerify();
        const normalPhone = getNormalPhone(phoneNumber);
        dispatch(sendSmsCode({phone: normalPhone, captchaToken}))
            .then(() => handleClick(authType.SMS, normalPhone))
            .catch(() => callThePhone(normalPhone));
    }

    const callThePhone = async (phone) => {
        const captchaToken = await handleReCaptchaVerify();
        dispatch(sendPhoneCall({phone, captchaToken}))
            .then(() => handleClick(authType.CALL, phone))
            .catch(() => {})
    }

    return (
        <form className="form-phone" onSubmit={sendSms}>
            <Input
                autoFocus
                type="tel"
                label="Номер телефона"
                name="phone"
                placeholder="Телефон"
                error={error}
                value={phoneNumber}
                handleChange={changePhone}
                maxLength={11}
                required
            />
            <Button type="submit" className="button__black" disabled={phoneNumber.length === 0}>Получить код</Button>
        </form>
    );
};

export default FormPhone;