import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews, removeReview } from '../../../store/actions/reviews';
import {EmptyItems, ModalEditReview, Pagination, ReviewsItem, ReviewsSortPanel} from '../../../components';
import { IoChatbubblesOutline } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import { usePagination } from '../../../hooks/usePagination';
import { toast } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css';
import './reviews-list.scss';

const ReviewsList = () => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { reviews, isLoading, isLoaded, totalPages, sort, filters, reload, errors } = useSelector(({ reviews }) => reviews);
    const [currentPage, changePage] = usePagination(() => dispatch(getReviews(currentPage)), ref);
    const [showModal, setShowModal] = useState(false);
    const [reviewId, setReviewId] = useState(0);

    useEffect(() => {
        changePage(1);
    }, [sort, filters, reload]);

    const handleRemoveClick = (id) => {
        dispatch(removeReview(id))
            .then(() => {
                changePage(1);
                toast.success('Отзыв удалён!');
            })
            .catch(() => toast.error('Не удалось удалить отзыв. Попробуйте позже!'));
    };

    const handleEditClick = (id) => {
        setShowModal(true);
        setReviewId(id);
    };

    const onCloseModal = () => {
        setShowModal(false);
        setReviewId(0);
    };

    const renderItems = () => {
        if (isLoading) {
            return (
                <div className="reviews-list__wrap">
                {
                    Array(5)
                        .fill(0)
                        .map((_, index) => <Skeleton key={index} width="100%" height={300} style={{marginBottom: '2rem'}} />)
                }
                </div>
            );
        }

        if (isLoaded && reviews.length > 0 && !errors && totalPages !== null) {
            return (
                <div className="reviews-list__wrap">
                    {reviews.map((review) => (
                        <ReviewsItem key={review.ID} review={review} onRemoveClick={handleRemoveClick} onEditClick={handleEditClick} />
                    ))}
                </div>
            );
        } else {
            return (
                <EmptyItems
                    icon={(props) => <IoChatbubblesOutline {...props} />}
                    text="Список отзывов пуст"
                    hideBorder={filters.onlyPhoto}
                />
            );
        }
    };

    const renderPagination = () => {
        if (reviews && reviews.length > 0) {
            return <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={changePage} />;
        } else {
            return '';
        }
    };

    const renderEditModal = () => {
        if (showModal && reviewId > 0) {
            return <ModalEditReview closeHandler={onCloseModal} id={reviewId} />;
        } else {
            return '';
        }
    };

    return (
        <div className="reviews-list" ref={ref}>
            <ReviewsSortPanel />
            {renderItems()}
            {renderPagination()}
            {renderEditModal()}
        </div>
    );
};

export default ReviewsList;