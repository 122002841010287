import { BlockTitle, PageTitle } from "../../components";

const LoyaltyProgram = ({ title }) => {
    return (
        <div className="container privacy-policy-container">
            <PageTitle title={title} />
            <section className="page ">
                <div className="privacy-policy">
                    <div className="privacy-policy__header">
                        <BlockTitle
                            title="Правила программы лояльности «Нужный спорт»"
                            classNames="privacy-policy__title"
                            styles={{justifyContent: 'center'}}
                        />
                    </div>
                    <div className="privacy-policy__body">
                        <p>
                            Настоящие Правила определяют условия и порядок участия в программе лояльности
                            «Нужный спорт» (далее Программа). С момента регистрации в Программе «Нужный
                            Спорт» Участник вступает во взаимоотношения с компанией ИП Зыков М.А.,
                            коммерческое наименование «А.В.Т. Спорт» (далее — «Компания»), полностью и
                            безоговорочно принимает настоящие Правила, обязуется их выполнять и имеет право
                            на получение Привилегий в соответствии с настоящими Правилами.
                        </p>
                        <p>
                            Программа «Нужный Спорт» действует на территории Российской Федерации в
                            интернет-магазине
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                            и в розничных магазинах семейных спортивных
                            супермаркетов «А.В.Т. Спорт» в г. Пермь. Полный перечень адресов магазинов «А.В.Т.
                            Спорт» в г. Пермь размещен на Сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                        </p>
                        <p>
                            Программа «Нужный Спорт» действует с момента ее запуска и до полной ее отмены
                            по решению Компании. Программа может быть изменена или отменена в любое время
                            по решению Компании с момента размещения на сайте Программы соответствующего
                            изменения или сообщения о полной приостановке действия Программы.
                        </p>
                        <p>
                            Принимая настоящие правила и становясь Участником программы «Нужный Спорт»,
                            Покупатель соглашается с Политикой конфиденциальности персональных данных,
                            изложенной на сайте:
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                        </p>
                        <h3 className="privacy-policy__body-title">1. Термины и определения</h3>
                        <p>
                            1.1 Программа лояльности «Нужный Спорт» («Программа») означает
                            взаимоотношения, в которых Участник, приобретающий товары и/или услуги у
                            Компании с помощью Карты Участника, приобретает право на получение Привилегий
                            в соответствии с настоящими Правилами.
                        </p>
                        <p>
                            1.2 Привилегия — возможность приобретения товаров и/или услуг Компании с
                            финансовой выгодой. Привилегии предоставляются методом дифференцированной
                            скидки — предоставления права Участнику приобретать товар и/или услуги Компании
                            по сниженным ценам, обозначенным на ценнике отдельной строкой, а также в виде
                            целевого кэшбека (купона).
                        </p>
                        <p>
                            1.3 Участник — физическое лицо, достигшее возраста 18 лет, допущенное Компанией
                            к участию в Программе в соответствии с настоящими Правилами и являющееся
                            держателем Карты.
                        </p>
                        <p>
                            1.4 Компания — индивидуальный предприниматель Зыков М.А., ИНН 590414001017,
                            ОГРНИП 304590411100151, юридический адрес: 625034, Тюменская обл., г. Тюмень, ул.
                            Судостроителей, д. 38, кв. 825. Почтовый адрес: 614990, Пермский край, город Пермь,
                            улица Куйбышева, 115Б, оф. 152, обладающее исключительными правами по
                            управлению и развитию Программы, являющееся стороной всех сделок по
                            предоставлению Привилегий в Программе.
                        </p>
                        <p>
                            1.5 Личный кабинет (ЛК) — персональная страница Участника, доступная на
                            сайте
                            <a className="privacy-policy-link" href="https://lk.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru,</a>
                            на которой содержится информация об Участнике, заказах и
                            транзакциях, совершенных Участником с использованием Карты, отзывах Участника о
                            товаре, а также о целевом кэшбеке (купонах), персональных акциях и скидках
                        </p>
                        <p>
                            1.6 Анкета — информация о лице (покупателе), желающем стать Участником
                            Программы «Нужный Спорт», вносимая Покупателем либо сообщаемая Покупателем
                            при регистрации в Программе в порядке, предусмотренном Правилами.
                        </p>
                        <p>
                            1.7 Карта Участника (Карта) — персональная карта Участника Программы, выпущенная
                            Компанией, содержащая информацию о номере Карты, предназначенная для
                            идентификации Участника в Программе. Карта позволяет Участнику приобретать
                            Привилегии (товары и/или услуги Компании по сниженным ценам) в соответствии с
                            настоящими Правилами. Карта Участника выпускается только в виртуальном виде.
                            Карта имеет свой индивидуальный номер.
                        </p>
                        <p>
                            1.8 Проверочный код — код, отправляемый Участнику на номер мобильного телефона,
                            указанный Участником при регистрации в Программе, использование которого
                            необходимо для подтверждения совершения Участником определенных действий, а
                            именно: регистрации в Программе и подтверждении принятия настоящих Правил,
                            изменения данных Участника, а также в других случаях, требующих подтверждения
                            соответствующего Участника.
                        </p>
                        <p>
                            1.9 Интернет-магазин (Сайт) — информационный ресурс в сети Интернет,
                            принадлежащий Компании, расположенный по адресу:
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                        </p>
                        <p>
                            1.10 Уведомления — информационные сообщения, в том числе содержащие сведения
                            рекламного характера, направляемые Компанией Участнику Программы “Нужный
                            спорт” посредством электронной почты, СМС, push-уведомлений, в виде печатной
                            продукции и другими способами с целью информирования Участника о доступных ему
                            Привилегиях, проводимых акциях, статуса заказа в Интернет-магазине, персональных
                            предложениях, а также доведения до Участника иной другой информации, которая
                            потенциально может быть полезна Участнику.
                        </p>
                        <h3 className="privacy-policy__body-title">2. Участие в Программе «Нужный спорт»</h3>
                        <p>
                            2.1 Участие в Программе «Нужный спорт» является добровольным. Участником может
                            стать любое лицо, которому на момент регистрации в Программе лояльности
                            исполнилось 18 (восемнадцать) лет и которое использует национальный телефонный
                            номер сети подвижной связи российской системы нумерации (начинающийся на + 7).
                            На одного Участника, а также на один номер телефона, может быть оформлена только
                            одна персональная Карта Участника Программы «Нужный спорт».
                        </p>
                        <p>
                            2.2 Для участия в программе необходимо оформить карту «Нужный спорт» одним из
                            следующих способов:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                выпустить виртуальную карту «Нужный спорт» на кассе любого магазина «А.В.Т.
                                Спорт» в г. Пермь;
                            </li>
                            <li className="secure-data__item">
                                выпустить виртуальную карту в личном кабинете на сайте
                                <a className="privacy-policy-link" href="https://lk.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru.</a>
                            </li>
                        </ul>
                        <p>
                            Получение карты является абсолютно бесплатным
                        </p>
                        <p>
                            2.3 Для возможности совершении покупок товаров и/или услуг в магазинах «А.В.Т.
                            Спорт» и/или на сайте
                            <a className="privacy-policy-link" href="https://lk.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru,</a>
                            а также получения иных Привилегий у Компании,
                            Клиенту, являющемуся держателем Карты, необходимо стать Участником Программы
                            и активировать Карту одним из следующих способов:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                стать Участником Программы и активировать карту на сайте
                                <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                                Для этого необходимо зарегистрироваться в Личном кабинете
                                <a className="privacy-policy-link" href="https://lk.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru:</a>
                                ввести в форме авторизации номер мобильного телефона, к которому будет
                                привязана карта. При авторизации с помощью номера мобильного телефона
                                после выполнения указанных действий на номер мобильного телефона придет
                                SMS-сообщение с кодом подтверждения. Его необходимо ввести в
                                соответствующее поле и нажать кнопку «Продолжить». Для завершения
                                регистрации в Личном кабинете необходимо заполнить регистрационную форму
                                (Имя, Фамилия, Отчество, Дата рождения, e-mail, согласие на обработку
                                персональных данных) и нажать кнопку «Продолжить», что будет являться
                                подтверждением явного, полного и безоговорочного согласия соответствующего
                                Клиента с принятием Правил Программы, а также согласия на получение
                                рекламных и информационных рассылок от Компании. Далее в Личном кабинете
                                необходимо заполнить анкету Участника Программы. После выполнения всех
                                действий, предусмотренных настоящим абзацем, Клиент считается
                                зарегистрированным Участником Программы «Нужный спорт», и за ним
                                закрепляется номер Карты Участника;
                            </li>
                            <li className="secure-data__item">
                                стать Участником Программы и активировать карту на кассе в магазине «А.В.Т.
                                Спорт», г. Пермь. Для этого достаточно назвать продавцу имя и номер
                                телефона, к которому будет привязана карта. После выполнения указанных
                                действий на номер мобильного телефона, названный клиентом, придет SMSсообщение с кодом подтверждения.
                                Его необходимо сообщить продавцу. Для завершения регистрации в Личном кабинете необходимо заполнить
                                регистрационную форму (Имя, Фамилия, Отчество, Дата рождения, e-mail,
                                согласие на обработку персональных данных) и нажать кнопку «Продолжить»,
                                что будет являться подтверждением явного, полного и безоговорочного
                                согласия соответствующего Клиента с принятием Правил Программы, а также
                                согласия на получение рекламных и информационных рассылок от Компании.
                                Далее в Личном кабинете необходимо с помощью продавца заполнить анкету
                                Участника Программы. После выполнения всех действий, предусмотренных
                                настоящим абзацем, Клиент считается зарегистрированным Участником
                                Программы «Нужный спорт», и за ним закрепляется номер Карты Участника;
                            </li>
                        </ul>
                        <p>
                            2.4 На одно физическое лицо (на один номер мобильного телефона) может быть
                            оформлена только одна Карта Участника.
                        </p>
                        <p>
                            2.5 Согласившись с правилами Программы, Участник соглашается на получение от
                            Компании Уведомлений, предусмотренных настоящими Правилами, но сохраняет
                            за собой право отказаться от данных Уведомлений следующим способом:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                в Личном кабинете на сайте
                                <a className="privacy-policy-link" href="https://lk.nuznyisport.ru" target="_blank"> lk.nuznyisport.ru,</a>
                                путем проставления соответствующей галочки.
                            </li>
                        </ul>
                        <p>
                            2.6 При регистрации в Программе одним из способов, в том числе при оформлении
                            Карты Участника, предусмотренных настоящими Правилами, Участник также дает
                            согласие Компании, а также лицам, входящим с ним в одну группу лиц по смыслу ст. 9
                            Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных":
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                осуществлять с использованием средств автоматизации и/или без таковых
                                обработку всех персональных данных, указанных Участником при регистрации в
                                Программе, в т.ч. в Анкете, включая сбор, запись, систематизацию, накопление,
                                хранение, уточнение (обновление, изменение), извлечение, использование,
                                обезличивание, блокирование, удаление, уничтожение информации о
                                произведенных Участником покупках, их сумме, способах и средствах их оплаты,
                                в целях, связанных с возможностью предоставления Участнику информации
                                (рекламы), в т.ч. о товарах и/или услугах, о проводимых рекламных акциях, о
                                персональных предложениях, которые потенциально могут представлять для
                                Участника интерес, а также в целях сбора, возможности обеспечения
                                предоставления Участникам Привилегий, предусмотренных Программой, а
                                также обработки статистической информации и проведения маркетинговых
                                исследований, в том числе с возможностью коммерческого использования
                                результатов данных исследований;
                            </li>
                            <li className="secure-data__item">
                                поручать обработку всех персональных данных, указанных Участником при
                                регистрации в Программе, в т.ч. в Анкете, другим лицам любым способом в
                                вышеуказанных целях.
                            </li>
                        </ul>
                        <h3 className="privacy-policy__body-title">
                            3. Предложения для участников программы лояльности «Нужный спорт»
                        </h3>
                        <p>
                            3.1 Активация карты позволяет Участнику пользоваться услугами и привилегиями
                            программы лояльности в соответствии с настоящими Правилами.
                        </p>
                        <p>
                            3.2 Привилегии программы «Нужный спорт» не распространяется на сотрудников
                            компании ООО «Трек».
                        </p>
                        <p>
                            3.3 Специальные акции и привилегии для участников программы лояльности «Нужный
                            спорт»:
                        </p>
                        <p>
                            3.3.1 Специальные цены для участников программы «Нужный спорт»
                        </p>
                        <p>
                            3.3.1.1 Участник получает специальные, сниженные до 50% от рекомендуемой
                            розничной цены, цены на товары компании, продающиеся в интернет-магазине
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru,</a>
                            а также в розничных магазинах «А.В.Т. Спорт» в г. Пермь. Специальные
                            цены обозначены на сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru</a>
                            отметкой «для участников программы
                            «Нужный спорт», в розничных магазинах на ценнике отдельной строкой.
                        </p>
                        <p>
                            3.3.2 Целевой кешбэк (Купон)
                        </p>
                        <p>
                            3.3.2.1 Целевой кешбэк (Купон) - персональная Привилегия, определяемая Компанией
                            и предоставляемая Участнику в виде повышенного размера скидки сверх размера,
                            предусмотренного Правилами или в виде скидки, выраженной либо в денежном
                            эквиваленте, либо в проценте, предоставляемая на сумму чека/на конкретный
                            товар/категорию товаров, или на иных условиях, определяемых Компанией. Размер
                            кешбэка определяется индивидуальными условиями акций, в рамках которых
                            используется Привилегия. Целевой кэшбек не имеет наличного выражения и денежной
                            стоимости.
                        </p>
                        <p>
                            3.4 Любые привилегии, указанные в настоящем разделе, не распространяются на
                            подарочные карты и товары, цена на которые при предоставлении привилегии будет
                            меньше нормативно установленной розничной цены.
                        </p>
                        <p>
                            3.5 Правила и условия получения привилегий, указанных в настоящем разделе, могут
                            на постоянной или временной основе меняться, по решению Компании в
                            одностороннем порядке. Обо всех изменениях Компания обязана сообщать на Сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                        </p>
                        <h3 className="privacy-policy__body-title">
                            4. Возврат товаров, приобретенных с использованием карты
                        </h3>
                        <p>
                            4.1 Обмен и возврат товаров, приобретенных с использованием Карты,
                            осуществляется в соответствии с действующим законодательством РФ.
                        </p>
                        <h3 className="privacy-policy__body-title">5. Прочие условия</h3>
                        <p>
                            5.1 Участник несет ответственность за корректность и достоверность персональных
                            данных, указанных им при регистрации в Программе. При изменении персональных
                            данных, указанных при регистрации в Программе, Участник обязан незамедлительно
                            уведомить Компанию посредством изменения данных в Личном кабинете на Сайте
                            <a className="privacy-policy-link" href="http://www.nuznyisport.ru" target="_blank"> nuznyisport.ru.</a>
                            Неблагоприятные последствия, связанные с неуведомлением Компании
                            об изменении персональных данных Участника, указанных в Анкете, полностью лежат
                            на Участнике. Компания не будет нести ответственности за невыполнение
                            обязательств, предусмотренных Правилами, возникших по вине Участника, в т.ч. в
                            случае неуведомления Компании об изменении персональных данных Участника,
                            указанных в Анкете.
                        </p>
                        <p>
                            5.2 Правила Программы «Нужный спорт» могут быть изменены Компанией в любое
                            время в одностороннем порядке с обязательной публикацией изменений на Сайте.
                            Компания вправе также дополнительно проинформировать Участников об изменениях
                            Правил посредством телефонного звонка и/или направления электронного сообщения,
                            электронного письма или другим способом, предусмотренным Правилами программы.
                        </p>
                        <p>
                            5.3 Компания вправе в любое время в одностороннем порядке прекратить участие в
                            Программе «Нужный спорт» любого Участника без предупреждения по любой причине,
                            включая, но не ограничиваясь случаем если Участник:
                        </p>
                        <ul className="secure-data__list">
                            <li className="secure-data__item">
                                не соблюдает настоящие Правила;
                            </li>
                            <li className="secure-data__item">
                                злоупотребляет какими-либо Привилегиями, предоставляемыми Участнику в
                                рамках Программы «Нужный спорт»;
                            </li>
                            <li className="secure-data__item">
                                предоставляет недостоверные, недостаточные/некорректные (не позволяющие
                                идентифицировать его) сведения и/или информацию, а также совершил иные
                                действия, вводящие в заблуждение Компании;
                            </li>
                            <li className="secure-data__item">
                                совершил или намеревается совершить действия, имеющие значимые признаки
                                обмана или прочие манипуляции, которые повлекли или могут повлечь за собой
                                материальный ущерб, моральные и прочие негативные последствия;
                            </li>
                            <li className="secure-data__item">
                                в случае, если факты свидетельствуют о том, что покупки Участника
                                совершаются в предпринимательских целях, то есть для их последующей
                                реализации/перепродажи или от имени/на средства юридического лица для
                                осуществления деятельности юридического лица, или от имени/на средства
                                физического лица, полученные от группы физических лиц, для приобретения
                                товаров в целях общего пользования и/или дарения.
                            </li>
                        </ul>
                        <p>
                            5.4 Участник вправе прекратить участие в Программе «Нужный спорт» в любое время
                            путем направления Компании письменного уведомления о прекращении участия.
                            Участие соответствующего Участника в Программе будет считаться прекращенным с
                            момента получения Компанией уведомления Участника.
                        </p>
                        <p>
                            5.5 В случаях прекращения участия соответствующего Участника в Программе
                            «Нужный спорт» по основаниям, предусмотренным п.5.3 и п.5.4 настоящих Правил,
                            Компания удаляет данные Участника из информационной системы Программы
                            «Нужный спорт», при этом ранее начисленный Целевой кэшбек (купоны) аннулируется.
                            С момента прекращения участия Участника в Программе действие Карты
                            прекращается (Карта блокируется). При этом Участник не вправе требовать от
                            Компании какого-либо возмещения, в т.ч. в денежной форме, списанного
                            (аннулированного) Целевого кэшбека.
                        </p>
                        <h3 className="privacy-policy__body-title">6. Порядок разрешения споров</h3>
                        <p>
                            6.1 Все споры между Компанией и Участником в рамках участия в Программе «Нужный
                            спорт» разрешаются путем проведения переговоров.
                        </p>
                        <p>
                            6.2 В случае, если спор, возникший между Компанией и Участником, не может быть
                            урегулирован в процессе переговоров, он разрешается в порядке, предусмотренном
                            действующим законодательством РФ.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default LoyaltyProgram;