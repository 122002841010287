import { useEffect, useState } from 'react';
import { CustomSelect, DadataInput, Input, RadioGroup, CustomDateInput } from '../../components';
import getDataToSend from '../../utils/getDataToSend';
import { getDay, getMonth, getYear } from '../../utils/getNormalDateElement';
import { days, months } from '../../const';

const ProfileDataForm = ({ items, onSubmit, errors, kid }) => {
    const [form, setForm] = useState({});
    const [isReady, setIsReady] = useState(false);

    let kidIsOver14;
    if (kid) {
        kidIsOver14 = items.find(i => i.name === 'over_14').value;
    }

    useEffect(() => {
        let obj = {};
        for (let i = 0; i < items.length; i++) {
            obj[items[i].name] = items[i].value;
        }
        setForm(obj);
        setIsReady(true);
    }, [items]);

    const handleSubmit = e => {
        e.preventDefault();
        const dataToSend = getDataToSend(form);
        onSubmit(dataToSend);
    }

    const handleChange = e => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleBirthdayChange = (e, custom = false) => {
        let date = {
           day: '',
           month: '',
           year: '',
       };

        if (custom) {
            const dateArr = e.target.value.split('-');
            date = {
                day: dateArr[2],
                month: dateArr[1],
                year: dateArr[0],
            };
        } else {
            date = {
                day: getDay(e.target.value),
                month: getMonth(e.target.value),
                year: getYear(e.target.value),
            };
        }

        setForm(state => {
            return {
                ...state,
                personal_birthday_day: date.day,
                personal_birthday_month: date.month,
                personal_birthday_year: date.year
            }
        });
    }

    const renderFormElement = (item, key) => {
        if (item.type === 'id') return;
        if (!item.editable) return;

        let value;

        if (kid) {
            if (kidIsOver14) {
                switch (item.type) {
                    case 'date_year':
                        return (
                            <div className="profile-data__group" key={`${item.name}-${key}`}>
                                <div className="profile-data__group-inputs">
                                    <CustomSelect
                                        defaultValue={days.find(day => day.value === form.personal_birthday_day)}
                                        isSearchable={false}
                                        name="personal_birthday_day"
                                        options={days}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        onChange={value => handleChange({target: {name: 'personal_birthday_day', value}})}
                                        placeholder="День"
                                        noOptionsMessage={() => 'Выбраны все варианты'}
                                        label="День рождения"
                                        error={(errors && errors['personal_birthday_day']) ? errors['personal_birthday_day'] : ''}
                                        required={false}
                                    />
                                    <CustomSelect
                                        defaultValue={months.find(month => month.value === form.personal_birthday_month)}
                                        isSearchable={false}
                                        name="personal_birthday_month"
                                        options={months}
                                        className="custom-select"
                                        classNamePrefix="custom-select"
                                        onChange={value => handleChange({target: {name: 'personal_birthday_month', value}})}
                                        placeholder="Месяц"
                                        noOptionsMessage={() => 'Выбраны все варианты'}
                                        label="Месяц рождения"
                                        error={(errors && errors['personal_birthday_month']) ? errors['personal_birthday_month'] : ''}
                                        required={false}
                                    />
                                    <Input
                                        type="number"
                                        label="Год рождения"
                                        name="personal_birthday_year"
                                        placeholder="Год рождения"
                                        value={Number(form.personal_birthday_year) > 0 && form.personal_birthday_year}
                                        handleChange={handleChange}
                                        required
                                        error={(errors && errors['personal_birthday_year']) ? errors['personal_birthday_year'] : ''}
                                    />
                                </div>
                                <div className="help-text" style={{marginTop: '0'}}>Если ваш ребёнок старше 14 лет, то мы сохраним только год его рождения!</div>
                            </div>
                        );
                }
            } else {
                if (form.personal_birthday_year && form.personal_birthday_month && form.personal_birthday_day) {
                    switch (item.type) {
                        case 'date_year':
                            return (
                                <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                                    <CustomDateInput
                                        label="Дата рождения"
                                        name="personal_birthday"
                                        value={`${form.personal_birthday_year}-${form.personal_birthday_month}-${form.personal_birthday_day}`}
                                        handleChange={handleBirthdayChange}
                                        required
                                        error={(errors && (errors['personal_birthday_day'] || errors['personal_birthday_month'] || errors['personal_birthday_year'])) ? 'Некорректная дата' : ''}
                                        helpText="Если ваш ребёнок старше 14 лет, то мы сохраним только год его рождения!"
                                    />
                                </div>
                            );
                    }
                }
            }
        }

        switch (item.type) {
            case 'text':
            case 'number':
            case 'tel':
                value = form[item.name] ? form[item.name] : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <Input
                            type={item.type}
                            label={item.title}
                            name={item.name}
                            value={value}
                            handleChange={handleChange}
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'date':
                value = form[item.name] ? form[item.name] : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <CustomDateInput
                            label={item.title}
                            name={item.name}
                            value={value}
                            handleChange={handleChange}
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'email':
                value = (form[item.name] && form[item.name].value) ? form[item.name].value : '';
                const isConfirmed = (form[item.name] && form[item.name].is_confirmed) ? form[item.name].is_confirmed : false;

                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <Input
                            type={item.type}
                            label={item.title}
                            name={item.name}
                            value={value}
                            onChange={e => handleChange({target: {name: item.name, value: {value: e.target.value, is_confirmed: isConfirmed}}})}
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'multi-select':
                value = form[item.name] ? form[item.name] : [];
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <CustomSelect
                            defaultValue={value}
                            value={value}
                            closeMenuOnSelect={false}
                            blurInputOnSelect={false}
                            isMulti
                            name={item.name}
                            options={item.directory}
                            className="custom-select"
                            classNamePrefix="custom-select"
                            onChange={value => handleChange({target: {name: item.name, value}})}
                            placeholder="Выберите"
                            noOptionsMessage={() => 'Выбраны все варианты'}
                            label={item.title}
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'radio':
                value = (form[item.name] && form[item.name].value) ? form[item.name].value : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <RadioGroup
                            defaultValue={value}
                            items={item.directory}
                            name={item.name}
                            label={item.title}
                            onChange={(e) => handleChange(e)}
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'select':
                value = form[item.name] ? form[item.name] : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <CustomSelect
                            defaultValue={value}
                            value={value}
                            name={item.name}
                            options={item.directory}
                            className="custom-select"
                            classNamePrefix="custom-select"
                            onChange={value => handleChange({target: {name: item.name, value}})}
                            placeholder="Выберите"
                            noOptionsMessage={() => 'Выбраны все варианты'}
                            label={item.title}
                            required={item.required}
                            isClearable={!item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'city_address':
                value = form[item.name] ? form[item.name].value : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <DadataInput
                            label={item.title}
                            defaultQuery={value}
                            value={value}
                            name={item.name}
                            handleChange={payload => handleChange({
                                target: {
                                    name: payload.target.name,
                                    value: {
                                        value: payload.target.value.value,
                                        address_code_fias: payload.target.value.data.fias_code,
                                        address_code_kladr: payload.target.value.data.kladr_id
                                    }
                                }
                            })}
                            filterToBound="city"
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            case 'full_address':
                value = form[item.name] ? form[item.name].value : '';
                return (
                    <div className="profile-data__form-item" key={`${item.name}-${key}`}>
                        <DadataInput
                            label={item.title}
                            defaultQuery={value}
                            value={value}
                            name={item.name}
                            handleChange={payload => handleChange({
                                target: {
                                    name: payload.target.name,
                                    value: {
                                        value: payload.target.value.value,
                                        address_code_fias: payload.target.value.data.fias_code,
                                        address_code_kladr: payload.target.value.data.kladr_id
                                    }
                                }
                            })}
                            filterToBound="houses"
                            required={item.required}
                            error={(errors && errors[item.name]) ? errors[item.name] : ''}
                        />
                    </div>
                );
            default:
                return;
        }
    }

    if (!isReady) {
        return '';
    }

    return (
        <form method="POST" className="profile-data__form" onSubmit={handleSubmit}>
            {items && items.map((item, index) =>
                renderFormElement(item, index)
            )}
            <div className="required-text"><span className="required-mark">*</span> - обязательные для заполнения поля</div>
            <button type="submit" className="button">Сохранить</button>
        </form>
    );
};

export default ProfileDataForm;