import Skeleton from 'react-loading-skeleton';
import './order-loading-filters.scss';

const OrderLoadingFilters = () => {
    return (
        <div className="order-loading-filters">
            <Skeleton className="order-loading-filters-status" width="100%" height={55} />
            <div className="orders-filters-years-skeleton">
                <Skeleton width={70} height={38} style={{marginRight: '.9rem', borderRadius: '16px'}} />
                <Skeleton width={70} height={38} style={{marginRight: '.9rem', borderRadius: '16px'}} />
                <Skeleton width={70} height={38} style={{borderRadius: '16px'}} />
            </div>
        </div>
    );
};

export default OrderLoadingFilters;