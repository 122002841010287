import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomCheckbox, CustomSelect, DadataInput, CustomLink, Input, Button } from '../../../components';
import { getUserDirectories } from '../../../store/actions/directories';
import { sendTrainerRegisterData } from '../../../store/actions/trainer-register';
import { trainerDataValidate } from '../../../validators/trainer/trainerQuestionnaireValidator';
import { IoTrashSharp } from 'react-icons/io5';
import './trainer-register-form.scss';

const emptySection = {
    name: '',
    address: {
        value: '',
        fias_code: '',
        kladr_code: '',
        geo_lat: '',
        geo_lon: '',
    }
};
const SECTION_COUNT = 10;

const TrainerRegisterForm = () => {
    const directories = useSelector(state => state.directories.userDirectories);
    const { user_id: userId, fields: userFields } = useSelector(({user}) => user.customerData);
    const storeErrors = useSelector(({trainerRegister}) => trainerRegister.errors);
    const dispatch = useDispatch();

    const [isReady, setIsReady] = useState(false);
    const [form, setForm] = useState({
        city: {
            value: '',
            fias_code: '',
            kladr_code: '',
            geo_lat: '',
            geo_lon: '',
        },
        sports: [],
        sections: [
            {...emptySection}
        ]
    });
    const [isConsent, setIsConsent] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!directories) {
            dispatch(getUserDirectories());
        }
    }, []);

    useEffect(() => {
        if (storeErrors !== null) {
            setErrors(storeErrors);
        }
    }, [storeErrors]);

    useEffect(() => {
        if (userId) {
            const city = userFields.find(item => item.name === 'personal_city').value;
            const sports = userFields.find(item => item.name === 'kinds_of_sports').value;

            if (city) {
                setForm((state) => {
                    return {
                        ...state,
                        city: {
                            value: city.value,
                            fias_code: city.address_code_fias,
                            kladr_code: city.address_code_kladr,
                            geo_lat: city.hasOwnProperty('geo_lat') ? city.geo_lat : '',
                            geo_lon: city.hasOwnProperty('geo_lon') ? city.geo_lon : ''
                        }
                    }
                });
            }

            if (sports) {
                setForm((state) => {
                    return {
                        ...state,
                        sports: sports
                    }
                });
            }
        }

        setIsReady(true);
    }, []);

    /**
     * Стандартное изменение стейта формы
     * @param e
     */
    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    /**
     * Добавление пустой секции
     */
    const addSection = () => {
        setForm((state) => {
            return {
                ...state,
                sections: [
                    ...state.sections,
                    {...emptySection}
                ]
            }
        });
    };

    /**
     * Изменение стейта секций в форме
     * @param e
     */
    const handleSectionChange = (e) => {
        const i = +e.target.dataset.index;
        const name = e.target.dataset.name;

        const newArray = [...form.sections];
        newArray.forEach((item, index, thisArr) => {
            if (i === index) {
                thisArr[index][name] = e.target.value;
            }
        });

        setForm((state) => {
            return {
                ...state,
                sections: [...newArray]
            }
        });
    };

    /**
     * Удаление секции из стейта по индексу
     * @param i
     */
    const removeSection = (i) => {
        const newArray = [...form.sections];
        newArray.forEach((item, index, thisArr) => {
            if (i === index) {
                thisArr.splice(index, 1);
            }
        });

        setForm((state) => {
            return {
                ...state,
                sections: [...newArray]
            }
        });
    };

    /**
     * Обработка отправки формы
     * @param e
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataForSend = {
            ...form,
            sports: [...form.sports.map((sport) => sport.value)],
            sections: [...form.sections],
            consent: isConsent
        };

        const result = trainerDataValidate(dataForSend);
        if (result.status) {
            setErrors({});
            dispatch(sendTrainerRegisterData(dataForSend));
        } else {
            setErrors(result.errors);
        }
    };

    if (!isReady || !directories) {
        return <div>Загрузка формы...</div>;
    }

    return (
        <form className="trainer-register__form" onSubmit={(e) => handleSubmit(e)}>
            <DadataInput
                label="Ваш город проживания"
                name="city"
                placeholder="Город"
                defaultQuery={form.city.value}
                value={form.city}
                handleChange={(payload) => handleChange({
                    target: {
                        name: payload.target.name,
                        value: {
                            value: payload.target.value.value,
                            fias_code: payload.target.value.data.fias_code,
                            kladr_code: payload.target.value.data.kladr_id,
                            geo_lat: payload.target.value.data.geo_lat,
                            geo_lon: payload.target.value.data.geo_lon,
                        }
                    }
                })}
                filterFromBound="city"
                filterToBound="city"
                required
                error={(errors && errors['city']) ? errors['city'] : ''}
                helpText="Начните вводить и выберите значение из выпадающего списка"
                autoComplete="nope"
            />
            <CustomSelect
                defaultValue={form.sports}
                value={form.sports}
                name="sports"
                closeMenuOnSelect={false}
                isMulti
                options={directories.sport}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={(value) => handleChange({target: {name: 'sports', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Виды спорта"
                required
                error={(errors && errors['sports']) ? errors['sports'] : ''}
            />

            <div className="trainer-register__sections">
                <div className="trainer-register__sections-title">
                    Укажите секции
                    <span className="required-mark">*</span>
                </div>

                {form.sections && form.sections.length > 0 && form.sections.map((section, index) => (
                    <div key={index}>
                        <div className={`trainer-register__section ${form.sections.length > 1 ? 'trainer-register__section--active' : ''}`}>
                            {form.sections.length > 1 && (
                                <div className="trainer-register__section-delete">
                                    <button type="button" onClick={() => removeSection(index)}>
                                        <IoTrashSharp size="16"/>
                                    </button>
                                </div>
                            )}
                            <DadataInput
                                label=""
                                name={`section-city-${index}`}
                                placeholder="Адрес (Город, улица, дом)"
                                defaultQuery={form.sections[index].address.value}
                                value={form.sections[index].address}
                                handleChange={(payload) => handleSectionChange({
                                    target: {
                                        dataset: {
                                            index: index,
                                            name: 'address'
                                        },
                                        name: payload.target.name,
                                        value: {
                                            value: payload.target.value.value,
                                            fias_code: payload.target.value.data.fias_code,
                                            kladr_code: payload.target.value.data.kladr_id,
                                            geo_lat: payload.target.value.data.geo_lat,
                                            geo_lon: payload.target.value.data.geo_lon,
                                        }
                                    }
                                })}
                                filterFromBound="city"
                                filterToBound="house"
                                required={index === 0}
                                helpText="Начните вводить и выберите значение из выпадающего списка"
                                error={!!(errors['addresses'] && errors['addresses'].find(item => item.id === index))}
                                autoComplete="nope"
                            />
                            <Input
                                type="text"
                                label=""
                                data-index={index}
                                data-name="name"
                                name={`section-name-${index}`}
                                placeholder="Наименование"
                                value={form.sections[index].name}
                                handleChange={e => handleSectionChange(e)}
                                error={!!(errors['addresses'] && errors['addresses'].find(item => item.id === index))}
                                required
                            />
                        </div>
                        {errors['addresses'] && errors['addresses'].find(item => item.id === index) && <div className="error-text">Необходимо выбрать адрес из <strong>выпадающего</strong> списка и заполнить наимнование</div>}
                    </div>
                ))}

                {errors && errors['sections'] && <div className="error-text">{errors['sections']}</div>}

                {form.sections.length < SECTION_COUNT &&
                    <button
                        type="button"
                        className="trainer-register__sections-button"
                        onClick={addSection}
                    >
                        + Добавить секцию
                    </button>
                }
            </div>

            <CustomCheckbox
                name="consent"
                label="Даю своё согласие на законную обработку персональных данных"
                state={isConsent}
                setState={setIsConsent}
                onChange={() => setIsConsent(state => !state)}
                required
                error={(errors && errors['consent']) ? errors['consent'] : ''}
            />

            <CustomLink
                to="/loyalty-program"
                target="_blank"
                rel="noopener noreferrer"
                className="trainer-register__link"
            >
                Правила обработки, Оферта программы лояльности
            </CustomLink>

            <Button
                type="submit"
                className="button__red trainer-register__button"
                disabled={!isConsent}
            >
                Отправить
            </Button>
        </form>
    );
};

export default TrainerRegisterForm;