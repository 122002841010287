import { useState } from 'react';
import { Button, Textarea } from '../../../components';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../../../store/actions/orders';
import { IoCloseOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import './modal-cancel-order.scss';

const ModalCancelOrder = ({ orderId, closeHandler }) => {
    const [reason, setReason] = useState();
    const dispatch = useDispatch();

    const onFormClick = e => e.stopPropagation();

    const changeReasonText = e => setReason(e.target.value);

    const onSubmit = e => {
        e.preventDefault();
        if (window.confirm('Вы действительно хотите отменить заказ?')) {
            dispatch(cancelOrder(orderId, reason))
                .then(() => {
                    localStorage.setItem('successCancelOrder', 'Заказ успешно отменен!');
                })
                .catch(() => {
                    toast.error('Произошла ошибка отмены заказа!');
                })
        }
    }

    return (
        <form className="modal-cancel-order" onSubmit={onSubmit} onClick={onFormClick}>
            <div className="modal-cancel-order__close-icon" onClick={closeHandler}>
                <IoCloseOutline size="40" />
            </div>
            <div className="modal-cancel-order__title">Отмена заказа</div>
            <Textarea
                label="Причина отмены заказа"
                rows="3"
                placeholder="Причина отмены заказа"
                required
                autoFocus
                value={reason}
                handleChange={changeReasonText}
            />
            <div className="modal-cancel-order__button-wrapper">
                <Button type="submit" className="button__black modal-cancel-order__button" disabled={!reason}>ОК</Button>
            </div>
        </form>
    );
};

export default ModalCancelOrder;