import productsForReviewTypes from '../types/products-for-review';

export const setProducts = payload => ({
    type: productsForReviewTypes.SET_PRODUCTS,
    payload,
});

export const setIsLoading = payload => ({
    type: productsForReviewTypes.SET_IS_LOADING,
    payload,
});

export const setIsLoaded = payload => ({
    type: productsForReviewTypes.SET_IS_LOADED,
    payload,
});

export const setErrors = payload => ({
    type: productsForReviewTypes.SET_ERRORS,
    payload,
});