import { useSelector } from 'react-redux';
import './replacement-email.scss';

const ReplacementEmail = ({
      openReplacementEmailModalHandler,
      openConfirmReplacementEmailModalHandler,
    }) => {

    const { isSmsSent } = useSelector(state => state.replacementEmail);

    let onClickHandler = openReplacementEmailModalHandler;

    if(isSmsSent) {
        onClickHandler = openConfirmReplacementEmailModalHandler;
    }

    return (
        <div>
            <button className="replacement-email_btn" onClick={onClickHandler}>
                Изменить email
            </button>
        </div>
    );
}

export default ReplacementEmail;