import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDirectories } from '../../store/actions/directories';
import { CustomSelect, RadioGroup, DadataInput, CustomCheckboxRegisterForm, CustomDateInput } from '../../components';
import { editCustomerData } from '../../store/actions/user';
import { setCustomerDataErrors } from '../../store/action-creators/user';
import getDataToSend from '../../utils/getDataToSend';
import './form-personal.scss'

const FormPersonal = ({ children: button, onSubmit }) => {
    const directories = useSelector(state => state.directories.userDirectories);
    const errors = useSelector(state => state.user.customerDataErrors);
    const customerData = useSelector(state => state.user.customerData);
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);
    const [form, setForm] = useState({
        user_id: '',
        personal_city: '',
        personal_gender: {},
        personal_birthday: '',
        kinds_of_sports: '',
        delivery_service: '',
    });
    const [isConsent, setIsConsent] = useState(true);

    useEffect(() => {
        dispatch(setCustomerDataErrors(null));
        if (!directories) {
            dispatch(getUserDirectories());
        }
    }, [])

    useEffect(() => {
        if (customerData.user_id) {
            setForm({
                user_id: customerData.user_id,
                personal_city: customerData.fields.find(item => item.name === 'personal_city').value,
                personal_gender: customerData.fields.find(item => item.name === 'personal_gender').value,
                personal_birthday: customerData.personal_birthday,
                kinds_of_sports: customerData.fields.find(item => item.name === 'kinds_of_sports').value,
                delivery_service: customerData.fields.find(item => item.name === 'delivery_service').value,
            })
            setIsReady(true);
        }
    }, [customerData.user_id])

    const handleChange = e => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const confirmData = e => {
        e.preventDefault()
        const data = {...form, consent: isConsent};
        const dataToSend = getDataToSend(data);
        dispatch(editCustomerData(dataToSend))
            .then(() => onSubmit())
            .catch(() => {});
    }

    if (!isReady || !directories) {
        return <></>;
    }

    return (
        <form className="form-personal" onSubmit={confirmData}>
            <DadataInput
                label="Ваш город проживания"
                name="personal_city"
                placeholder="Город"
                defaultQuery={form.personal_city.value}
                value={form.personal_city}
                handleChange={payload => handleChange({
                    target: {
                        name: payload.target.name,
                        value: {
                            value: payload.target.value.value,
                            address_code_fias: payload.target.value.data.fias_code,
                            address_code_kladr: payload.target.value.data.kladr_id
                        }
                    }
                })}
                filterFromBound="city"
                filterToBound="city"
                required
                error={(errors && errors['personal_city']) ? errors['personal_city'] : ''}
            />
            <RadioGroup
                defaultValue={form.personal_gender.value}
                items={directories.sex}
                name="personal_gender"
                label="Пол"
                onChange={handleChange}
                required
                error={(errors && errors['personal_gender']) ? errors['personal_gender'] : ''}
            />
            <CustomDateInput
                label="Дата рождения"
                name="personal_birthday"
                value={form.personal_birthday}
                handleChange={handleChange}
                required
                error={(errors && errors['personal_birthday']) ? errors['personal_birthday'] : ''}
            />
            <CustomSelect
                defaultValue={form.kinds_of_sports}
                name="kinds_of_sports"
                closeMenuOnSelect={false}
                isMulti
                options={directories.sport}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => handleChange({target: {name: 'kinds_of_sports', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Спортивные секции"
                error={(errors && errors['kinds_of_sports']) ? errors['kinds_of_sports'] : ''}
            />
            <CustomSelect
                defaultValue={form.delivery_service}
                name="delivery_service"
                options={directories.delivery}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => handleChange({target: {name: 'delivery_service', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Приоритетный способ получения заказов"
                error={(errors && errors['delivery_service']) ? errors['delivery_service'] : ''}
            />
            <CustomCheckboxRegisterForm
                name="consent"
                label="Согласен на обработку персональных данных"
                link="/privacy-policy"
                state={isConsent}
                setState={setIsConsent}
                onChange={() => setIsConsent(state => !state)}
                required
                error={(errors && errors['consent']) ? errors['consent'] : ''}
            />
            <div className="required-text"><span className="required-mark">*</span> - обязательные для заполнения поля</div>

            {button}

        </form>
    );
};

export default FormPersonal;