import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateLayout, PublicLayout } from '../layouts';
import { publicRoutes } from './routes';
import { usePrivateRoutes } from '../hooks/usePrivateRoutes';

export const useRoutes = (isAuth) => {
    const privateRoutesFilter = usePrivateRoutes();

    if (isAuth) {
        return (
            <PrivateLayout>
                <Switch>
                    {privateRoutesFilter.length && privateRoutesFilter.map((route, index) =>
                        <Route key={`${route.path}-${index}`} path={route.path} exact={route.exact}>
                            {route.component({
                                title: route.title
                            })}
                        </Route>
                    )}

                    <Redirect to={process.env.REACT_APP_REDIRECT_AUTH} />
                </Switch>
            </PrivateLayout>
        );
    }

    return (
        <PublicLayout>
            <Switch>
                {publicRoutes && publicRoutes.map((route, index) =>
                    <Route key={`${route.path}-${index}`} path={route.path} exact={route.exact}>
                        {route.component({
                            title: route.title
                        })}
                    </Route>
                )}

                <Redirect to={process.env.REACT_APP_REDIRECT_NON_AUTH} />
            </Switch>
        </PublicLayout>
    );
}