import RatingStars from 'react-rating-stars-component';
import { IoStar } from 'react-icons/io5';
import './rating.scss';

const Rating = ({ label, error, helpText, required, ...props }) => {
    return (
        <label className="ui-rating">
            <span className="ui-rating__label">
                {label}
                {required && <span className="required-mark">*</span>}
            </span>
            <RatingStars
                emptyIcon={<IoStar fill="#D7D7D7" />}
                filledIcon={<IoStar fill="#F6D93E" />}
                {...props}
            />
            {error && <div className="error-text">{error}</div>}
            {helpText && <div className="help-text">{helpText}</div>}
        </label>
    );
};

export default Rating;