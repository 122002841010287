import directoriesTypes from '../types/directories';

export const setUserDirectories = payload => ({
    type: directoriesTypes.SET_USER_DIRECTORIES,
    payload,
});

export const setChildDirectories = payload => ({
    type: directoriesTypes.SET_CHILD_DIRECTORIES,
    payload,
});