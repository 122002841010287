import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getKids } from '../../store/actions/kids';
import { ReactSVG } from 'react-svg';
import { PageTitle, KidCard, BlockTitle } from '../../components';
import BabyIcon from '../../assets/svg/baby.svg';
import './kids.scss';

const Kids = ({ title }) => {
    const { items, max_amount_children, loaded } = useSelector(state => state.kids);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded) {
            dispatch(getKids());
        }
    }, []);

    return (
        <>
            <PageTitle title={title} />
            <section className="page kids-page">
                <div className="kids">
                    <div className="kids__header">
                        <BlockTitle
                            title="Мои дети"
                            description="Информация, которую Вы указали, используется для построения алгоритмов рекомендаций, автозаполнения полей заказа и заявок! И не передаётся третьим лицам."
                        />
                    </div>
                    <div className="kids__body">
                        <div className="kids__list">
                            {items && items.map(kid => <KidCard key={kid.id} item={kid} />)}

                            {items.length < max_amount_children &&
                                <Link to="/kids/add" className="kids__button">
                                    <i className="kids__button-icon">
                                        <ReactSVG src={BabyIcon} />
                                    </i>

                                    <span className="kids__button-text">+ Добавить ребёнка</span>

                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Kids;