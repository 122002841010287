import { Switcher } from '../index';
import { useDispatch, useSelector } from 'react-redux';
import { changeNotificationsSettings } from '../../store/actions/notifications';
import './notifications-section.scss';

const NotificationsSection = ({ type, title, description, settings }) => {
    const isEmailConfirmed = useSelector(state => state.user.registerData.fields.find(field => field.type === 'email').value.is_confirmed);
    const dispatch = useDispatch();

    const handleChange = e => {
        dispatch(changeNotificationsSettings(type, e.target.name));
    }

    return (
        <div className="notifications-section">
            <h2 className="notifications-section__title">{title}</h2>
            <div className="notifications-section__description">{description}</div>

            {settings && settings.map(setting => {
                if (setting.name === 'E-mail' && !isEmailConfirmed) {
                    return <div key={setting}></div>
                }
                return (
                    <Switcher
                        name={setting.id}
                        checked={setting.value}
                        text={setting.name}
                        onChange={handleChange}
                        key={setting.id}
                        helpText={
                            setting.name === 'Push-уведомления' &&
                            'Для корректной работы Push-уведомлений, убедитесь, что у Вас разрешены уведомления для этого сайта в браузере'
                        }
                    />
                )
            })}
        </div>
    );
};

export default NotificationsSection;