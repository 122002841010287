import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReview } from '../../../store/actions/reviews';
import { Backdrop, Textarea, Rating, ImageUploader, Switcher, Button } from '../../../components';
import { CSSTransition } from 'react-transition-group';
import { IoCloseOutline } from 'react-icons/io5';
import { getUniqueImages } from '../../../utils/images';
import { toast } from 'react-toastify';
import './modal-create-review.scss';

const MAX_PHOTOS = 10;
const ACCEPT_TYPES = ['jpg', 'jpeg', 'png'];

const ModalCreateReview = ({ closeHandler, id: productId, name: productName }) => {
    const dispatch = useDispatch();
    const errors = useSelector(({ reviews }) => reviews.createErrors);
    const [form, setForm] = useState({
        productId: '',
        rating: 5,
        dignity: '',
        limitations: '',
        comment: '',
        photos: [],
        anonymous: false,
    });
    const [isReady, setIsReady] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setForm({...form, productId: productId});
        setIsReady(true);
    }, []);

    const handleFormClick = (e) => e.stopPropagation();
    const handleFormChange = (e) => setForm({...form, [e.target.name]: e.target.value});
    const handleImagesChange = (images) => {
        const filteredImages = getUniqueImages(images, 'data_url');
        handleFormChange({
            target: {
                name: 'photos',
                value: filteredImages
            }
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('productId', form.productId);
        formData.append('rating', form.rating);
        formData.append('dignity', form.dignity);
        formData.append('limitations', form.limitations);
        formData.append('comment', form.comment);
        formData.append('anonymous', form.anonymous ? '1' : '0');
        form.photos.forEach((photo) => {
            formData.append('photos[]', photo.file);
        });

        setDisabled(true);
        dispatch(addReview(formData))
            .then(() => {
                setDisabled(false);
                toast.success('Отзыв успешно отправлен');
                closeHandler();
            })
            .catch((e) => {
                setDisabled(false);
                toast.error('Не удалось сохранить отзыв');
            });
    };

    let imageUploadHelpText;
    if (form.photos.length === 0) {
        imageUploadHelpText = `Можно загрузить до ${MAX_PHOTOS} фотографий!`;
    } else if (form.photos.length < MAX_PHOTOS) {
        imageUploadHelpText = `Можно загрузить еще ${MAX_PHOTOS - form.photos.length} фото`;
    } else {
        imageUploadHelpText = 'Загружено максимальное количество фото';
    }

    return (
        <CSSTransition
            in={isReady}
            timeout={300}
            classNames="modal-create-review-animate"
            unmountOnExit
        >
            <Backdrop closeHandler={closeHandler}>
                <div className="modal-create-review">
                    <div className="modal-create-review__close" onClick={closeHandler}>
                        <div className="modal-create-review__close-icon">
                            <IoCloseOutline size="40px" stroke="#FEFEFE" />
                        </div>
                    </div>
                    <form className="modal-create-review__form" onSubmit={handleFormSubmit} onClick={handleFormClick}>
                        <div className="modal-create-review__title">
                            Добавление отзыва к товару - <br/>
                            {productName}
                        </div>

                        <div className="modal-create-review__fields">
                            <Rating
                                label="Оценка"
                                size={35}
                                count={5}
                                isHalf={false}
                                name="rating"
                                value={form.rating}
                                onChange={(value) => handleFormChange({
                                    target: {
                                        name: 'rating',
                                        value: value
                                    }
                                })}
                                error={errors && errors['rating']}
                            />

                            <Textarea
                                rows="4"
                                label="Достоинства"
                                name="dignity"
                                placeholder="Достоинства"
                                value={form.dignity}
                                handleChange={handleFormChange}
                            />
                            <Textarea
                                rows="4"
                                label="Недостатки"
                                name="limitations"
                                placeholder="Недостатки"
                                value={form.limitations}
                                handleChange={handleFormChange}
                            />
                            <Textarea
                                rows="4"
                                label="Комментарий"
                                name="comment"
                                placeholder="Комментарий"
                                value={form.comment}
                                handleChange={handleFormChange}
                            />

                            <ImageUploader
                                multiple
                                label="Фото"
                                value={form.photos}
                                name="photos"
                                onChange={(images) => handleImagesChange(images)}
                                maxNumber={MAX_PHOTOS}
                                acceptType={ACCEPT_TYPES}
                                dataURLKey="data_url"
                                helpText={imageUploadHelpText}
                            />

                            <Switcher
                                checked={form.anonymous}
                                name="anonymous"
                                onChange={(event) => handleFormChange({
                                    target: {
                                        name: event.target.name,
                                        value: event.target.checked
                                    }
                                })}
                                text="Скрыть мои данные в отзыве"
                                labelPosition="top"
                            />

                            <div className="modal-create-review__rules-text">
                                Перед отправкой отзыва, пожалуйста, ознакомтесь с <a href="/">правилами публикации</a>.
                            </div>

                            <div className="modal-create-review__submit-wrap">
                                <Button className="modal-create-review__submit" type="submit" disabled={disabled}>Отправить отзыв</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Backdrop>
        </CSSTransition>
    );
};

export default ModalCreateReview;