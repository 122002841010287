import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRoutes } from './routes/useRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './store/actions/auth';
import { getUserData } from './store/actions/user';
import { Loader } from './components';
import { getActiveOrders } from './store/actions/orders';
import { useNotification } from './hooks/useNotification';
import showToastBasedOnLocalStorage from './utils/showToastBasedOnLocalStorage';
import { sendClientIdFromMetrika } from "./service/sendClientIdFromMetrika";

const App = () => {
    const { isAuth } = useSelector(state => state.auth);
    const [isShowLayout, setIsShowLayout] = useState(false);
    const dispatch = useDispatch();
    const routes = useRoutes(isAuth);
    useNotification(isAuth);

    useEffect(() => {
        dispatch(checkAuth())
            .catch(() => setIsShowLayout(true));
    }, []);

    useEffect(() => {
        if (isAuth) {
            setIsShowLayout(false);
            dispatch(getUserData())
                .finally(() => {
                    dispatch(getActiveOrders());
                    setInterval(() => {
                        dispatch(getActiveOrders());
                    }, 3 * 60 * 1000);
                    setIsShowLayout(true);
                    showToastBasedOnLocalStorage('anketaSuccess', 'Ваша анкета успешно сохранена');
                    showToastBasedOnLocalStorage('emailConfirm', 'На вашу почту отправлено подтверждение email');
                    showToastBasedOnLocalStorage('redirectToAnketaPage', 'Для завершения оформления заказа необходимо заполнить анкету!');
                });
        }
    }, [isAuth]);

    useEffect(() => {
        if (isAuth) {
            sendClientIdFromMetrika();
        }
    }, [isAuth]);

    if (!isShowLayout) {
        return <Loader className="loader--white" />;
    }

    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    );
};

export default App;