import replacementPhoneTypes from '../types/replacement-phone';

const initialState = {
    isExistTask: false,
    newPhone: '',
    daysLeft: '',
    isSmsSent: false,
    isSmsSentToChangePhone: false,
    isSmsSentToCancelChangePhone: false,
    senderPhone: '',
    typeDispatch: 'SMS',
    timer: 0,
    errors: null
};

export const replacementPhoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case replacementPhoneTypes.SET_NEW_PHONE:
            return {...state, newPhone: action.payload}
        case replacementPhoneTypes.SET_SENDER_PHONE:
            return {...state, senderPhone: action.payload}
        case replacementPhoneTypes.SET_EXIST_TASK:
            return {...state, isExistTask: action.payload}
        case replacementPhoneTypes.SET_DAYS_LEFT:
            return {...state, daysLeft: action.payload}
        case replacementPhoneTypes.SET_SMS_SENT_TO_CHANGE_PHONE:
            return {...state, isSmsSentToChangePhone: action.payload}
        case replacementPhoneTypes.SET_SMS_SENT_TO_CANCEL_CHANGE_PHONE:
            return {...state, isSmsSentToCancelChangePhone: action.payload}
        case replacementPhoneTypes.SET_TYPE_DISPATCH:
            return {...state, typeDispatch: action.payload}
        case replacementPhoneTypes.SET_TIMER:
            return {...state, timer: action.payload}
        case replacementPhoneTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state;
    }
}