import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { editRegisterData, editCustomerData } from '../../store/actions/user';
import {
    PageTitle,
    ProfileData,
    Delimiter,
    InviteBanner,
    Backdrop,
    ModalReplacementPhone,
    ModalConfirmReplacementPhone,
    ModalConfirmCancelReplacementPhone,
    ModalReplacementEmail,
    ModalConfirmReplacementEmail
} from '../../components';
import { setTimer, setError } from "../../store/action-creators/replacement-phone";
import { setEmailTimer, setEmailError } from "../../store/action-creators/replacement-email";
import { toast } from 'react-toastify';

const Profile = ({ title }) => {
    const dispatch = useDispatch();

    const [isShowingReplacementPhoneModal, setShowingReplacementPhoneModal] = useState(false);
    const [isShowingConfirmReplacementPhoneModal, setShowingConfirmReplacementPhoneModal] = useState(false);
    const [isShowingConfirmCancelReplacementPhoneModal, setShowingConfirmCancelReplacementPhoneModal] = useState(false);

    const [isShowingReplacementEmailModal, setShowingReplacementEmailModal] = useState(false);
    const [isShowingConfirmReplacementEmailModal, setShowingConfirmReplacementEmailModal] = useState(false);

    const { fields: registerDataFields } = useSelector(state => state.user.registerData);
    const { fields: customerDataFields } = useSelector(state => state.user.customerData);
    const { registerDataErrors, customerDataErrors } = useSelector(state => state.user);
    const isParticipation = useSelector(state => state.user.participation);
    const {
        trainerQuestionnaireCompleted: isTrainerQuestionnaireCompleted
    } = useSelector(({ user }) => user);
    const phoneReplacementData = useSelector(state => state.replacementPhone);

    const callEditRegisterData = (data) => {
        if (!data) {
            window.alert('Пожалуйста, проверьте заполненность данных');
            return;
        }
        dispatch(editRegisterData(data));
    };

    const callEditCustomerData = (data) => {
        if (!data) {
            window.alert('Пожалуйста, проверьте заполненность данных');
            return;
        }
        dispatch(editCustomerData(data));
    };

    const openReplacementPhoneModalHandler = () => setShowingReplacementPhoneModal(true);
    const closeReplacementPhoneModalHandler = () => {
        setShowingReplacementPhoneModal(false);
        dispatch(setError(null));
    };

    const openConfirmReplacementPhoneModalHandler = () => setShowingConfirmReplacementPhoneModal(true);
    const closeConfirmReplacementPhoneModalHandler = () => {
        setShowingConfirmReplacementPhoneModal(false);
        dispatch(setTimer(0));
        dispatch(setError(null));
    };

    const openConfirmCancelReplacementPhoneModalHandler = () => setShowingConfirmCancelReplacementPhoneModal(true);
    const closeConfirmCancelReplacementPhoneModalHandler = () => {
        setShowingConfirmCancelReplacementPhoneModal(false);
        dispatch(setTimer(0));
        dispatch(setError(null));
    };

    const openReplacementEmailModalHandler = () => setShowingReplacementEmailModal(true);
    const closeReplacementEmailModalHandler = () => {
        setShowingReplacementEmailModal(false);
        dispatch(setEmailError(null));
    };

    const openConfirmReplacementEmailModalHandler = () => setShowingConfirmReplacementEmailModal(true);
    const closeConfirmReplacementEmailModalHandler = () => {
        setShowingConfirmReplacementEmailModal(false);
        dispatch(setEmailTimer(0));
        dispatch(setEmailError(null));
    };

    let renderModalForm = '';

    if(isShowingReplacementPhoneModal) {
        renderModalForm = (<Backdrop closeHandler={closeReplacementPhoneModalHandler}>
            <ModalReplacementPhone
                closeHandler={closeReplacementPhoneModalHandler}
                openConfirmHandler={openConfirmReplacementPhoneModalHandler}
            />
        </Backdrop>);
    }

    if(isShowingConfirmReplacementPhoneModal && phoneReplacementData.isSmsSentToChangePhone) {
        renderModalForm = (<Backdrop closeHandler={closeConfirmReplacementPhoneModalHandler}>
            <ModalConfirmReplacementPhone closeHandler={closeConfirmReplacementPhoneModalHandler}/>
        </Backdrop>);
    }

    if(isShowingConfirmCancelReplacementPhoneModal && phoneReplacementData.isSmsSentToCancelChangePhone) {
        renderModalForm = (<Backdrop closeHandler={closeConfirmCancelReplacementPhoneModalHandler}>
            <ModalConfirmCancelReplacementPhone closeHandler={closeConfirmCancelReplacementPhoneModalHandler}/>
        </Backdrop>);
    }

    if(isShowingReplacementEmailModal) {
        renderModalForm = (<Backdrop closeHandler={closeReplacementEmailModalHandler}>
            <ModalReplacementEmail
                closeHandler={closeReplacementEmailModalHandler}
                openConfirmHandler={openConfirmReplacementEmailModalHandler}
            />
        </Backdrop>);
    }

    if(isShowingConfirmReplacementEmailModal) {
        renderModalForm = (<Backdrop closeHandler={closeConfirmReplacementEmailModalHandler}>
            <ModalConfirmReplacementEmail
                closeHandler={closeConfirmReplacementEmailModalHandler}
            />
        </Backdrop>);
    }

    useEffect(() => {
        if(localStorage.getItem('IsEmailChangedSuccess')) {
            toast.success(localStorage.getItem('IsEmailChangedSuccess'));
            localStorage.removeItem('IsEmailChangedSuccess')
        }
    }, [])

    return (
        <>
            <PageTitle title={title} />
            <section className="page profile-page">
                {renderModalForm}
                {registerDataFields && (
                    <ProfileData
                        title="Учётные данные"
                        description="Вы можете менять свои основные учётные данные, подтверждать почту, управлять аккаунтом и настройками безопасности."
                        items={registerDataFields}
                        phoneReplacement={phoneReplacementData}
                        openReplacementPhoneModalHandler={openReplacementPhoneModalHandler}
                        openConfirmReplacementPhoneModalHandler={openConfirmReplacementPhoneModalHandler}
                        openConfirmCancelReplacementPhoneModalHandler={openConfirmCancelReplacementPhoneModalHandler}
                        openReplacementEmailModalHandler={openReplacementEmailModalHandler}
                        openConfirmReplacementEmailModalHandler={openConfirmReplacementEmailModalHandler}
                        onSubmit={callEditRegisterData}
                        errors={registerDataErrors}
                    />
                )}
                {customerDataFields && isParticipation && (
                    <>
                        <Delimiter />
                        <ProfileData
                            title="Данные покупателя"
                            description="Информация, которую Вы указали используется для построения алгоритмов рекомендаций, автозаполнения полей заказа и заявок, и не передаётся третьим лицам."
                            items={customerDataFields}
                            onSubmit={callEditCustomerData}
                            errors={customerDataErrors}
                        />
                    </>
                )}

                {!isTrainerQuestionnaireCompleted && (
                    <>
                        <Delimiter />
                        <InviteBanner
                            title="Доступ к кабинету тренера"
                            description="Уважаемый тренер! Мы просим Вас заполнить анкету для получения доступа к Вашему личному кабинету и реализации нашей общей цели: помочь подрастающему поколению сформироваться физически и духовно здоровыми людьми через вовлечение в занятия различными видами спорта, помочь детям стать чемпионами, а стране – мировым лидером, недосягаемым для других государств. Мы уверены, что нам по пути!"
                            link="/trainer-registration"
                            linkText="Заполнить анкету тренера"
                            theme="dark"
                            styles={{
                                marginTop: '1.8rem'
                            }}
                        />
                    </>
                )}
            </section>
        </>
    );
};

export default Profile;