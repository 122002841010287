import ordersTypes from './../types/orders';

const initialState = {
    orders: [],
    activeOrders: [],
    detailOrder: null,
    filtersStatus: {
        list: [],
        current: {value: 'ALL', label: 'Все'},
        default: {value: 'ALL', label: 'Все'},
        active: {value: 'onlyActive', label: 'Активные заказы'}
    },
    filtersYear: [],
    totalPages: 0,
    totalActiveOrders: 0,
    isLoadedOrders: false,
    isLoadedActiveOrders: false,
    errors: null,
};

export const ordersReducer = (state = initialState, action) => {
    switch(action.type) {
        case ordersTypes.SET_FILTERS_STATUS:
            return {...state, filtersStatus: {...state.filtersStatus, list: [state.filtersStatus.default, state.filtersStatus.active, ...action.payload]}}
        case ordersTypes.SET_CURRENT_FILTERS_STATUS:
            return {...state, filtersStatus: {...state.filtersStatus, current: action.payload}}
        case ordersTypes.SET_FILTERS_YEAR:
            return {...state, filtersYear: action.payload}
        case ordersTypes.SET_CURRENT_FILTERS_YEAR:
            return {...state, filtersYear: [...state.filtersYear.map(year => year.id == action.payload ? {...year, value: !year.value} : year)]}
        case ordersTypes.RESET_FILTERS:
            return {...state, filtersStatus: {...state.filtersStatus, current: state.filtersStatus.default, list: []}, filtersYear: []}
        case ordersTypes.SET_ORDERS:
            return {...state, orders: action.payload.orders, totalPages: action.payload.pagesTotal}
        case ordersTypes.SET_ACTIVE_ORDERS:
            return {...state, activeOrders: action.payload.orders, totalActiveOrders: action.payload.ordersTotal}
        case ordersTypes.SET_DETAIL_ORDER:
            return {...state, detailOrder: action.payload}
        case ordersTypes.SET_LOADED_ORDERS:
            return {...state, isLoadedOrders: action.payload}
        case ordersTypes.SET_LOADED_ACTIVE_ORDERS:
            return {...state, isLoadedActiveOrders: action.payload}
        case ordersTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}