import { Delimiter } from '../../../components';
import './order-total.scss';

const OrderTotal = ({ price, isPaid }) => {
    return (
        <div className="order-total order-info__overall">
            <div className="order-total__item">
                <div className="order-total__title">Товары на сумму</div>
                <div className="order-total__sum">{price.basket.toLocaleString()} руб.</div>
            </div>
            <div className="order-total__item">
                <div className="order-total__title">Доставка</div>
                <div className="order-total__sum">{price.delivery.toLocaleString()} руб.</div>
            </div>

            <Delimiter />

            <div className="order-total__item">
                <div className="order-total__title">Сумма заказа</div>
                <div className="order-total__sum">{price.total.toLocaleString()} руб.</div>
            </div>
            <div className="order-total__item">
                <div className="order-total__title">Оплачено</div>
                <div className="order-total__sum">{price.alreadyPaid.toLocaleString()} руб.</div>
            </div>

            {!isPaid &&
                <div className="order-total__item">
                    <div className="order-total__title">К оплате</div>
                    <div className="order-total__sum">{(price.total - price.alreadyPaid).toLocaleString()} руб.</div>
                </div>
            }

        </div>
    );
};

export default OrderTotal;