import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { Header, Sidebar, BottomMenu, Loader, UserAgreements } from '../../components';
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './private-layout.scss';
import {checkRedirect} from "../../store/actions/auth";
import StorageCache from "../../service/storageCache";

TopBarProgress.config({
    barColors: {
        '0': '#D32A29',
        '1.0': '#A80B0A'
    },
    shadowBlur: 5
});

const PrivateLayout = ({ children }) => {
    const { isLoading } = useSelector(state => state.loading);
    const [isProgress, setIsProgress] = useState(false);
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(search);
    const cacheStorage = new StorageCache();

    useEffect(() => {
        if (queryParams.get('action') === 'checkRedirect' && cacheStorage.get('referrer') !== 'order' && !!cacheStorage.get('redirect_url') !== false) {
            dispatch(checkRedirect());
        }
    }, []);


    useEffect(() => {
        setIsProgress(true);
        setTimeout(() => {
            setIsProgress(false);
        }, 300);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname, search]);

    return (
        <>
            {isLoading && <Loader />}
            {isProgress && <TopBarProgress />}
            <Header />
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="d-none d-lg-block col-3">
                            <Sidebar />
                        </div>
                        <div className="col-12 col-lg-9 wrapper">
                            <div className="content">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </main>
            <UserAgreements />
            <BottomMenu />
        </>
    );
};

export default PrivateLayout;