import { memo } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import './products-card.scss';

const ProductsCard = ({ id, name, picture, link, price, oldPrice, site, available, isFavorite, handleFavoriteChange }) => {
    const handleFavoriteClick = (e, productId, remove) => {
        e.preventDefault();
        handleFavoriteChange(productId, remove);
    };

    const setDefaultImage = e => e.target.src = '/images/no-image.png';

    const isFavoriteStatus = isFavorite === 'Y';

    const imageSrc = (typeof picture === 'string' && picture.startsWith('http')) ? picture : `https://${site}${link}`;

    return (
        <div className="favorites-card">
            <div className="favorites-card__top">
                <a href={`https://${site}${link}`} target="_blank" className="favorites-card__image" rel="noreferrer">
                    <img src={imageSrc} alt={name} onError={setDefaultImage} />
                </a>
                <a href={`https://${site}`} target="_blank" className="favorites-card__site" rel="noreferrer">{site}</a>
                <a href={`https://${site}${link}`} target="_blank" className="favorites-card__title" rel="noreferrer">{name}</a>
            </div>
            <div className="favorites-card__bottom">
                <div className="favorites-card__price">
                    {available === 'Y' ? (
                        <>
                            {oldPrice && <span className="favorites-card__price-old">{oldPrice.toLocaleString()} руб.</span>}
                            {price && <span className="favorites-card__price-current">{price.toLocaleString()} руб.</span>}
                        </>
                    ) : (
                        <span className="favorites-card__available">Нет в наличии</span>
                    )}
                </div>
                <button type="button" className={`favorites-card__favorite ${isFavoriteStatus ? '' : 'favorites-card__favorite--not'}`} onClick={(e) => handleFavoriteClick(e, id, isFavoriteStatus)}>
                    {isFavoriteStatus ? <IoHeart size="16" fill="#D32A29" /> : <IoHeartOutline size="16" stroke="#2B2A29" />}
                </button>
            </div>
        </div>
    );
};

export default memo(ProductsCard);