import reviewsTypes from '../types/reviews';
import { sortItems } from '../../const';

const initialState = {
    reviews: [],
    isLoading: false,
    isLoaded: false,
    page: 1,
    totalPages: null,
    sort: {
        name: sortItems.reviews[0].name,
        title: sortItems.reviews[0].title,
        by: sortItems.reviews[0].value.by,
        order: sortItems.reviews[0].value.order
    },
    filters: {
        onlyPhoto: false
    },
    reload: false, //Параметр для перезагрузки списка отзывов (значение неважно)
    errors: null,
    createErrors: null
};

export const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case reviewsTypes.SET_REVIEWS:
            return {...state, reviews: action.payload};
        case reviewsTypes.SET_IS_LOADING:
            return {...state, isLoading: action.payload};
        case reviewsTypes.SET_IS_LOADED:
            return {...state, isLoaded: action.payload};
        case reviewsTypes.SET_TOTAL_PAGES:
            return {...state, totalPages: action.payload};
        case reviewsTypes.SET_SORT:
            return {...state, sort: {...state.sort, ...action.payload}};
        case reviewsTypes.SET_FILTERS:
            return {...state, filters: {...state.filters, ...action.payload}};
        case reviewsTypes.SET_PAGE:
            return {...state, page: action.payload};
        case reviewsTypes.TOGGLE_RELOAD:
            return {...state, reload: !state.reload};
        case reviewsTypes.SET_ERRORS:
            return {...state, errors: action.payload};
        case reviewsTypes.SET_CREATE_ERRORS:
            return {...state, createErrors: action.payload};
        default:
            return state;
    }
};