import { useHistory } from 'react-router-dom';
import { PageTitle, FormKid, Button, BlockTitle } from '../../components';
import './kid-add.scss';
import { toast } from 'react-toastify';

const KidAdd = ({ title }) => {
    const history = useHistory();

    const handleSubmit = () => {
        history.push('/kids');
    }

    return (
        <>
            <PageTitle title={title} />
            <section className="page kid-add-page">
                <div className="kid-add">
                    <div className="kid-add_header">
                        <BlockTitle
                            title="Добавление ребёнка"
                            description="С помощью этой формы, Вы можете добавить ребёнка и он отобразится в списке всех детей."
                            back
                        />
                    </div>
                    <div className="kid-add__body">
                        <FormKid onSubmit={handleSubmit}>
                            <Button type="submit">Добавить</Button>
                        </FormKid>
                    </div>
                </div>
            </section>
        </>
    );
};

export default KidAdd;