import emailTypes from "../types/email";

export const setConfirmedEmail = payload => ({
    type: emailTypes.SET_CONFIRMED_EMAIL,
    payload
});

export const setLoaded = payload => ({
    type: emailTypes.SET_LOADED,
    payload,
});

export const setError = payload => ({
    type: emailTypes.SET_ERRORS,
    payload
});