import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '../UI';
import { sendPassword } from '../../store/actions/recovery-password';

const FormSetNewPassword = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const errors = useSelector(({recoveryPassword}) => recoveryPassword.errorsStepPassword);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sendPassword({
            ...props,
            password,
            confirm_password: confirmPassword
        }));
    };

    const getError = (name) => {
        if (!errors) {
            return '';
        }

        if (typeof errors === 'object') {
            return errors[name] || '';
        } else {
            return errors.replace(/<\/?[^>]+>/g, '');
        }
    };

    return (
        <form className="form-email" onSubmit={handleSubmit}>
            <Input
                autoFocus
                type="password"
                label="Новый пароль"
                name="password"
                placeholder="Пароль"
                error={getError('password')}
                value={password}
                handleChange={(e) => setPassword(e.target.value)}
                required
            />
            <Input
                type="password"
                label="Подтвердите новый пароль"
                name="confirm_password"
                placeholder="Подтверждение пароля"
                error={getError('confirm_password')}
                value={confirmPassword}
                handleChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
            <Button type="submit" className="button__black" disabled={password === '' || confirmPassword === ''}>Сменить пароль</Button>
        </form>
    );
};

export default FormSetNewPassword;