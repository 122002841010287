import './backdrop.scss';

const Backdrop = ({children, closeHandler}) => {
    return (
        <div className="backdrop" onClick={closeHandler}>
            {children}
        </div>
    )
}

export default Backdrop;