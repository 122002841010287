import { setLoading } from '../action-creators/loading';
import {
    setRegisterData,
    setCustomerData,
    setParticipation,
    setQuestionnaireCompleted,
    setTrainerParticipation,
    setTrainerQuestionnaireCompleted,
    setErrors,
    setRegisterDataErrors,
    setCustomerDataErrors,
} from '../action-creators/user';
import {
    setExistTask,
    setNewPhone,
    setDaysLeft
} from '../action-creators/replacement-phone';
import apiClient from '../../service/axios';
import parseUserDataForStore from '../../utils/parseUserDataForStore';
import { setError } from '../action-creators/auth';
import { toast } from 'react-toastify'
import StorageCache from "../../service/storageCache";

export const getUserData = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.get('/user/get-auth');
        if (data.type === 'OK') {
            dispatch(setRegisterData(parseUserDataForStore(data.register_data)));
            dispatch(setCustomerData(parseUserDataForStore(data.customer_data)));
            dispatch(setParticipation(data.participation));
            dispatch(setQuestionnaireCompleted(data.questionnaire_completed));
            dispatch(setTrainerParticipation(data.trainer_participation));
            dispatch(setTrainerQuestionnaireCompleted(data.trainer_questionnaire_completed));
            dispatch(setExistTask(data.phone_replacement.is_exist_task))
            dispatch(setNewPhone(data.phone_replacement.new_phone))
            dispatch(setDaysLeft(data.phone_replacement.days_left))
            dispatch(setErrors(null));
            dispatch(setLoading(false));
            return Promise.resolve();
        } else {
            dispatch(setErrors(data.message));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const editRegisterData = editData => async dispatch => {
    dispatch(setLoading(true));
    try {
        editData.consent = true;
        const {data} = await apiClient.post('/user/change-user', JSON.stringify(editData));
        if (data.type === 'OK') {
            dispatch(setRegisterData(parseUserDataForStore(data.register_data)));
            dispatch(setRegisterDataErrors(null));

            toast.success('Данные успешно обновлены');
        } else {
            dispatch(setRegisterDataErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
        toast.error('Произошла ошибка. Попробуйте еще');
    }
    dispatch(setLoading(false));
}

export const editCustomerData = editData => async dispatch => {
    dispatch(setLoading(true));
    try {
        if (!editData.hasOwnProperty('consent')) {
            editData.consent = true;
        }
        const {data} = await apiClient.post('/user/change-buyer', JSON.stringify(editData));
        if (data.type === 'OK') {
            dispatch(setCustomerData(parseUserDataForStore(data.customer_data)));
            dispatch(setCustomerDataErrors(null));
            dispatch(setLoading(false));

            toast.success('Данные успешно обновлены');
            return Promise.resolve();
        } else {
            dispatch(setCustomerDataErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
        toast.error('Произошла ошибка. Попробуйте еще');
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const sendQuestionnaire = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiClient.post('/user/save-questionnaire');
        const responseJson = response.data;
        const cacheStorage = new StorageCache();
        if (responseJson.type === 'OK') {
            localStorage.setItem('anketaSuccess', 'true')
            dispatch(setError(null));
            if(cacheStorage.get('referrer') === 'order') {
                cacheStorage.unset('referrer');
                let redirectUrl = cacheStorage.get('redirect_url');
                cacheStorage.unset('redirect_url');
                window.location = redirectUrl;
                return;
            }
            window.location = '/';
        } else {
            toast.error(responseJson.errors);
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}