import { FormPhone, FormCode } from './../../components';
import { useState } from 'react';

const FormSms = ({ onSubmit }) => {
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [authType, setAuthType] = useState(null);
    const [formStep, setFormStep] = useState(1);

    const goToNextStep = (authType, phone) => {
        setAuthType(authType);
        setPhoneNumber(phone);
        setFormStep(2);
    }

    return (
        <>
            {formStep === 1 &&
                <FormPhone handleClick={goToNextStep} />
            }

            {formStep === 2 &&
                <FormCode handleClick={onSubmit} type={authType} phoneNumber={phoneNumber} />
            }
        </>
    );
};

export default FormSms;