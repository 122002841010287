import PropTypes from 'prop-types';
import { BackArrow } from '../../components';
import './block-title.scss';

const BlockTitle = ({ title, description, back, classNames = '', styles = null }) => {
    return (
        <>
            {title &&
            <h2 className={`block-title ${classNames}`} style={styles}>
                {back && <BackArrow />}
                {title}
            </h2>}
            {description && <p className="block-description">{description}</p>}
        </>
    );
};

BlockTitle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default BlockTitle;