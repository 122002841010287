import { setLoading } from '../action-creators/loading';
import {
    setAddresses,
    setAddress,
    setError,
    setLoaded,
    setTotalPages,
    setAddressesTotalCount,
    setPageElementCount
} from '../action-creators/addresses';
import { toConvertAddressesDataForRedux } from '../../utils/getFormattedAddress';
import { PAGE_ELEMENT_COUNT } from '../../const';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';


export const fetchAddresses = (option = { pageElementCount: PAGE_ELEMENT_COUNT }) => async dispatch => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/delivery/get-all-delivery', JSON.stringify(option));
        if (data.type === 'OK') {
            const result = Math.ceil(Number(data.count)/option.pageElementCount);
            const totalPages = !result ? 1 : result;
            if(data.profiles) {
                const addressesFormatted = toConvertAddressesDataForRedux(data.profiles);
                dispatch(setAddressesTotalCount(Number(data.count)));
                dispatch(setAddresses(addressesFormatted));
            }
            dispatch(setPageElementCount(option.pageElementCount));
            dispatch(setTotalPages(totalPages));
            dispatch(setLoaded(true));
            dispatch(setError(null));
        } else {
            dispatch(setError(data.errors));
        }
    } catch(e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}

export const fetchAddress = (addressId) => (dispatch, getState) => {
    const { addresses } = getState().address;
    const copyAddresses = [...addresses];
    const address = copyAddresses.find(address => address.id === addressId);
    dispatch(setAddress(address));
    dispatch(setError(null));
}

export const addNewAddress = (newAddress) => async(dispatch, getState) => {
    dispatch(setLoading(true));
    const { addresses, addressesCount, pageElementCount } = getState().address;
    const newAddressToSend = {...newAddress, ...newAddress.address};
    if(!newAddressToSend.zip)
        newAddressToSend.zip = '000000';
    delete newAddressToSend.address;
    try {
        const {data} = await apiClient.post('/delivery/create-delivery', JSON.stringify(newAddressToSend));
        if (data.type === 'OK') {
            const newAddressCount = Number(addressesCount) + 1;
            const newTotalPages = Math.ceil(newAddressCount/pageElementCount);
            let currentTotalPages = Math.ceil(addressesCount/pageElementCount);
            const createdAddress = {...newAddress, id: data.result}
            let newAddressesList = [...addresses];

            if(!currentTotalPages)
                currentTotalPages = 1;

            if(newTotalPages === currentTotalPages)
                newAddressesList = [...addresses, createdAddress];

            dispatch(setAddressesTotalCount(newAddressCount));
            dispatch(setAddresses(newAddressesList));
            dispatch(setTotalPages(newTotalPages));
            dispatch(setError(null));
            toast.success('Новый адрес доставки успешно добавлен');
        } else {
            dispatch(setError(data.message));
            toast.error('Произошла ошибка при добавлении нового адреса. Попробуйте еще');
        }
    } catch (e) {
        toast.error('Произошла ошибка при добавлении нового адреса. Попробуйте еще');
        console.error(e);
    }
    dispatch(setLoading(false));
}

export const updateAddressData = (address) => async(dispatch, getState) => {
    dispatch(setLoading(true));
    const { addresses } = getState().address;
    const copyAddresses = [...addresses];
    const idx = copyAddresses.findIndex((e) => e.id === address.id);
    copyAddresses[idx] = address;
    const updatedAddressToSend = {...address, ...address.address};
    delete updatedAddressToSend.address;
    try {
        const {data} = await apiClient.post('/delivery/update-delivery', JSON.stringify(updatedAddressToSend));
        if (data.type === 'OK') {
            dispatch(setAddresses(copyAddresses));
            dispatch(setError(null));
            toast.info('Адрес доставки успешно обновлен!');
        } else {
            dispatch(setError(data.errors));
            toast.error('Произошла ошибка при обновлении адреса доставки. Попробуйте еще');
        }
    } catch (e) {
        toast.error('Произошла ошибка при обновлении адреса доставки. Попробуйте еще');
        console.error(e);
    }
    dispatch(setLoading(false));
}

export const deleteAddress = (addressId, currentPage) => async(dispatch, getState) => {
    dispatch(setLoading(true));
    const { addressesCount, pageElementCount } = getState().address;
    try {
        const {data} = await apiClient.post('/delivery/delete-delivery', JSON.stringify({id: addressId}));
        if (data.type === 'OK') {
            const newAddressCount = addressesCount > 1 ? Number(addressesCount) - 1 : addressesCount;
            const newTotalPages = Math.ceil(newAddressCount/pageElementCount);
            const currentTotalPages = Math.ceil(addressesCount/pageElementCount);
            let newCurrentPage = currentPage;

            if(newTotalPages < currentTotalPages)
                newCurrentPage = currentPage <= 1 ? currentPage : currentPage - 1;

            const options = { pageNumber: newCurrentPage, pageElementCount}
            const { data } = await apiClient.post('/delivery/get-all-delivery', JSON.stringify(options));
            if(data.type === 'OK') {
                if(data.profiles) {
                    const addressesFormatted = toConvertAddressesDataForRedux(data.profiles);
                    dispatch(setAddresses(addressesFormatted));
                } else {
                    dispatch(setAddresses([]));
                }
                dispatch(setAddressesTotalCount(newAddressCount));
                dispatch(setTotalPages(newTotalPages));
                dispatch(setError(null));
                dispatch(setLoading(false));
                toast.success('Адрес доставки успешно удалён');
                return Promise.resolve(newCurrentPage);
            } else {
                toast.success('Адрес доставки успешно удалён');
                window.location.reload();
            }
        } else {
            dispatch(setError(data.errors));
            toast.error('Произошла ошибка при удалении. Попробуйте еще');
        }
    } catch (e) {
        console.error(e);
        toast.error('Произошла ошибка при удалении. Попробуйте еще');
    }
    dispatch(setLoading(false));
}