import purchasedProductsTypes from '../types/purchased-products';

export const setItems = payload => ({
    type: purchasedProductsTypes.SET_ITEMS,
    payload,
});

export const setPage = payload => ({
    type: purchasedProductsTypes.SET_PAGE,
    payload,
});

export const setTotalPages = payload => ({
    type: purchasedProductsTypes.SET_TOTAL_PAGES,
    payload,
});

export const setCategories = payload => ({
    type: purchasedProductsTypes.SET_CATEGORIES,
    payload,
});

export const setSort = payload => ({
    type: purchasedProductsTypes.SET_SORT,
    payload,
});

export const setFiltersCategories = payload => ({
    type: purchasedProductsTypes.SET_FILTERS_CATEGORIES,
    payload,
});

export const setFiltersAvailability = payload => ({
    type: purchasedProductsTypes.SET_FILTERS_AVAILABILITY,
    payload,
});

export const resetFilters = () => ({
    type: purchasedProductsTypes.RESET_FILTERS
});

export const setIsLoading = payload => ({
    type: purchasedProductsTypes.SET_IS_LOADING,
    payload,
});

export const setError = payload => ({
    type: purchasedProductsTypes.SET_ERROR,
    payload,
});