import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsForReview } from '../../store/actions/products-for-review';
import { Delimiter, ProductForReview, ModalCreateReview } from '../../components';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import { CSSTransition } from 'react-transition-group';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import './products-for-review.scss';

const arrowProps = {
    size: '18px',
    stroke: '#FEFEFE'
};

const ProductsForReview = ({ delimiter = 'none' }) => {
    const dispatch = useDispatch();
    const { products, isLoading, isLoaded, errors } = useSelector(state => state.productsForReview);
    const { width } = useWindowDimensions();
    const [showBlock, setShowBlock] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [productId, setProductId] = useState(0);
    const [productName, setProductName] = useState('');

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    useEffect(() => {
        if (!isLoaded) {
            dispatch(getProductsForReview());
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!showBlock && products.length > 0) {
            setShowBlock(true);
        }
    }, [products]);

    const onFeedbackClick = (id, name) => {
        setShowModal(true);
        setProductId(id);
        setProductName(name);
    };

    const onCloseModal = () => {
        setShowModal(false);
        setProductId(0);
        setProductName('');
    };

    const renderSlider = () => {
        if (isLoading) {
            return (
                <div className="products-for-review__loading">
                    {
                        Array(3)
                            .fill(0)
                            .map((_, index) => (<Skeleton className="products-for-review__loading-item" key={index} width="360px" height={190} />))
                    }
                </div>
            );
        }

        if (isLoaded && products.length > 0) {
            return (
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={width <= 575 ? 15 : 30}
                    slidesPerView={width <= 575 ? 1 : `auto`}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                >
                    {products.map((product, index) => (
                        <SwiperSlide key={`${product.id}-${index}`}>
                            <ProductForReview {...product} onFeedbackClick={(id, name) => onFeedbackClick(id, name)} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            );
        }
    };

    const renderModal = () => {
        if (showModal && productId > 0) {
            return <ModalCreateReview closeHandler={onCloseModal} id={productId} name={productName} />;
        } else {
            return '';
        }
    };

    if (products.length === 0 || errors !== null) {
        return false;
    }

    return (
        <>
            {delimiter === 'top' && <Delimiter />}
            <CSSTransition
                in={showBlock}
                timeout={300}
                classNames="products-for-review-animate"
                unmountOnExit
            >
                <div className={`products-for-review ${delimiter !== 'none' ? 'products-for-review--delimiters' : ''}`}>
                    <div className="products-for-review__wrap">
                        <div className="products-for-review__head">
                            <h2 className="products-for-review__title">Некоторые товары ожидают Вашей оценки</h2>
                            {products && products.length > 1 && (
                                <div className="products-for-review__arrows">
                                    <button type="button" className="products-for-review__arrow" ref={navigationPrevRef}>
                                        <IoArrowBackOutline {...arrowProps} />
                                    </button>
                                    <button type="button" className="products-for-review__arrow" ref={navigationNextRef}>
                                        <IoArrowForwardOutline {...arrowProps} />
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="products-for-review__slider">
                            {renderSlider()}
                        </div>
                    </div>
                </div>
            </CSSTransition>
            {delimiter === 'bottom' && <Delimiter />}
            {renderModal()}
        </>
    );
};

export default ProductsForReview;