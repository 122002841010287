import trainerTypes from '../types/trainer';

const initialState = {
    remainder: 0,
    refCode: '',
    transactions: [],
    page: 1,
    totalPages: 1,
    months: [],
    filterMonth: 0,
    isLoaded: {
        remainder: false,
        months: false,
        transactions: false
    },
    errors: {
        remainder: null,
        months: null,
        transactions: null
    },
};

export const trainerReducer = (state = initialState, action) => {
    switch(action.type) {
        case trainerTypes.SET_REMAINDER:
            return {...state, remainder: action.payload}
        case trainerTypes.SET_REF_CODE:
            return {...state, refCode: action.payload}
        case trainerTypes.SET_TRANSACTIONS:
            return {...state, transactions: action.payload}
        case trainerTypes.SET_MONTHS:
            return {...state, months: action.payload}
        case trainerTypes.SET_FILTER_MONTH:
            return {...state, filterMonth: action.payload}
        case trainerTypes.SET_IS_LOADED_REMAINDER:
            return {...state, isLoaded: { ...state.isLoaded, remainder: action.payload }}
        case trainerTypes.SET_IS_LOADED_MONTHS:
            return {...state, isLoaded: { ...state.isLoaded, months: action.payload }}
        case trainerTypes.SET_IS_LOADED_TRANSACTIONS:
            return {...state, isLoaded: { ...state.isLoaded, transactions: action.payload }}
        case trainerTypes.SET_ERROR_REMAINDER:
            return {...state, errors: { ...state.errors, remainder: action.payload }}
        case trainerTypes.SET_ERROR_MONTHS:
            return {...state, errors: { ...state.errors, months: action.payload }}
        case trainerTypes.SET_ERROR_TRANSACTIONS:
            return {...state, errors: { ...state.errors, transactions: action.payload }}
        case trainerTypes.SET_TOTAL_PAGES:
            return {...state, totalPages: action.payload}
        case trainerTypes.SET_PAGE:
            return {...state, page: action.payload}
        default:
            return state;
    }
};