import { useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IoMenu } from 'react-icons/io5';
import { usePrivateRoutes } from '../../hooks/usePrivateRoutes';
import './bottom-menu.scss';

const BottomMenu = () => {
    const menuButton = useRef();
    const history = useHistory();
    const routes = usePrivateRoutes();

    const handleClick = () => {
        if (menuButton.current && menuButton.current.classList.contains('active')) {
            history.goBack();
        }
    }

    return (
        <footer className="d-block d-lg-none bottom-menu">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="bottom-menu__wrap">
                            {routes.length && routes.map(({title, path, icon, exact = false, types = []}, index) =>
                                types && types.includes('bottom') &&
                                <NavLink to={path} className="bottom-menu__item" exact={exact} key={`${path} - ${index}`}>
                                    {icon &&
                                    <i className="bottom-menu__item-icon">
                                        {icon({
                                            size: "20px",
                                            stroke: "#92929D"
                                        })}
                                    </i>
                                    }
                                    <span className="bottom-menu__item-text">{title}</span>
                                </NavLink>
                            )}
                            <NavLink ref={menuButton} to="/menu" className="bottom-menu__item" onClick={() => handleClick()}>
                                <i className="bottom-menu__item-icon">
                                    <IoMenu size="20px" stroke="#92929D"/>
                                </i>
                                <span className="bottom-menu__item-text">Меню</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default BottomMenu;