import { useSelector } from 'react-redux';
import { CustomLink, FormEmailRecoveryPassword } from '../../components/';
import './password-recovery.scss';

const PasswordRecovery = () => {
    const tokenSent = useSelector(({recoveryPassword}) => recoveryPassword.tokenSent);

    return (
        <div className="form-auth">
            <div className="form-auth__header auth-header">
                <div className="form-auth__title auth-header__item">Восстановление пароля</div>
                <div className="already-registered auth-header__item">Введите свой e-mail</div>
            </div>

            <div className="form-auth__body">
                {tokenSent ?
                    <div className="password-recovery__text">На ваш электронный адрес отправлено письмо. Для смены пароля необходимо пройти по ссылке указанной в письме.</div>
                    : <FormEmailRecoveryPassword />
                }
            </div>

            <div className="form-auth__footer">
                <div className="form-auth__footer-links">
                    <CustomLink to='/login'>Вернуться к авторизации</CustomLink>
                </div>
            </div>
        </div>
    );
};

export default PasswordRecovery;