import StorageCache from '../service/storageCache';

const setBackUrlParam = searchParams => {
    const redirectUrl = searchParams.get('BACK_URI');
    if (redirectUrl) {
        const cache = new StorageCache();
        cache.set('redirect_url', redirectUrl, 1000 * 300);
    }
}

export default setBackUrlParam;