import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getKids, editKidData, deleteKidData } from '../../store/actions/kids';
import { useParams, Redirect } from 'react-router-dom';
import  { LineButton, PageTitle, ProfileData, Delimiter } from '../../components';
import './kid.scss';

const Kid = ({ title }) => {
    const { id: kidId } = useParams();
    const { items, loaded, errors } = useSelector(state => state.kids);
    const dispatch = useDispatch();
    const [kid, setKid] = useState('');
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (loaded) {
            const kidData = items.find(item => item.id === kidId);
            if (kidData) {
                setKid(kidData);
            } else {
                setRedirect(true);
            }
        } else {
            dispatch(getKids());
        }
    }, [loaded, items, errors]);

    const editKid = (data) => {
        dispatch(editKidData(data));
    };

    const removeKid = (id) => {
        if (window.confirm('Подтверждаете удаление?')) {
            dispatch(deleteKidData(id));
        }
    }

    if (redirect) {
        return <Redirect to="/kids" />;
    }

    return (
        <>
            <PageTitle title={title} />
            <section className="page kid-page">
                {kid && kid.fields && (
                    <>
                        <ProfileData
                            title="Мой ребёнок"
                            description="Здесь Вы можете изменить данные о ребенке или удалить его."
                            items={kid.fields}
                            onSubmit={editKid}
                            errors={errors}
                            back
                            kid
                        />
                        <Delimiter />
                        <div className="kid-page__delete-button-wrap">
                            <LineButton handleClick={() => removeKid(kid.id)}>Удалить</LineButton>
                        </div>
                    </>
                )}
            </section>
        </>
    );
};

export default Kid;