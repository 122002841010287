const ordersTypes = {
    SET_FILTERS_STATUS: 'ORDERS/SET_FILTERS_STATUS',
    SET_CURRENT_FILTERS_STATUS: 'ORDERS/SET_CURRENT_FILTERS_STATUS',
    SET_FILTERS_YEAR: 'ORDERS/SET_FILTERS_YEAR',
    SET_CURRENT_FILTERS_YEAR: 'ORDERS/SET_CURRENT_FILTERS_YEAR',
    RESET_FILTERS: 'ORDERS/REST_FILTERS',
    SET_ORDERS: 'ORDERS/SET_ORDERS',
    SET_ACTIVE_ORDERS: 'ORDERS/SET_ACTIVE_ORDERS',
    SET_DETAIL_ORDER: 'ORDERS/SET_DETAIL_ORDER',
    CANCEL_ORDER: 'ORDERS/CANCEL_ORDER',
    SET_LOADED_ORDERS: 'ORDERS/SET_LOADED_ORDERS',
    SET_LOADED_ACTIVE_ORDERS: 'ORDERS/SET_LOADED_ACTIVE_ORDERS',
    SET_ERRORS: 'ORDERS/SET_ERRORS',
};

export default ordersTypes;