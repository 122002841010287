const parseHtmlSpecialChars = (text = '') => {
    const reUnescape = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g;
    const unescapeEntities = {
        '&amp;': '&',
        '&#38;': '&',
        '&lt;': '<',
        '&#60;': '<',
        '&gt;': '>',
        '&#62;': '>',
        '&apos;': "'",
        '&#39;': "'",
        '&quot;': '"',
        '&#34;': '"',

    };

    return text.replace(reUnescape, item => unescapeEntities[item]);
}

export default parseHtmlSpecialChars;