import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from './reducers/auth';
import { loadingReducer } from './reducers/loading';
import { userReducer } from './reducers/user';
import { kidsReducer } from './reducers/kids';
import { directoriesReducer } from './reducers/directories';
import { cardReducer } from './reducers/card';
import { addressReducer } from './reducers/addresses';
import { favoritesReducer } from './reducers/favorites';
import { ordersReducer } from './reducers/orders';
import { emailReducer } from './reducers/email';
import { trainerReducer } from './reducers/trainer';
import { trainerRegisterReducer } from './reducers/trainer-register';
import { sitesReducer } from './reducers/sites';
import { productsForReviewReducer } from './reducers/products-for-review';
import { reviewsReducer } from './reducers/reviews';
import { purchasedProductsReducer } from './reducers/purchased-products';
import { notificationsReducer } from './reducers/notifications';
import { replacementPhoneReducer } from './reducers/replacement-phone';
import { replacementEmailReducer } from './reducers/replacement-email';
import { recoveryPasswordReducer } from './reducers/recovery-password';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    user: userReducer,
    kids: kidsReducer,
    directories: directoriesReducer,
    card: cardReducer,
    address: addressReducer,
    favorites: favoritesReducer,
    orders: ordersReducer,
    email: emailReducer,
    trainer: trainerReducer,
    trainerRegister: trainerRegisterReducer,
    sites: sitesReducer,
    productsForReview: productsForReviewReducer,
    reviews: reviewsReducer,
    purchasedProducts: purchasedProductsReducer,
    notifications: notificationsReducer,
    replacementPhone: replacementPhoneReducer,
    replacementEmail: replacementEmailReducer,
    recoveryPassword: recoveryPasswordReducer
});

const isDev = process.env.NODE_ENV === 'development';
export const store = createStore(rootReducer, isDev ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk));