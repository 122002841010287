export const pathNames = {
    Favorites: '/favorites',
    Purchased: '/purchased',
};

export const hideSidebarPathNames = [
    pathNames.Favorites,
    pathNames.Purchased,
];

export const sortItems = {
    favorites: [
        {
            name: 'new_first',
            title: 'Сначала новые',
            value: {
                by: 'date',
                order: 'desc'
            }
        },
        {
            name: 'old_first',
            title: 'Сначала старые',
            value: {
                by: 'date',
                order: 'asc'
            }
        },
        {
            name: 'descending_price',
            title: 'По убыванию цены',
            value: {
                by: 'price',
                order: 'desc'
            }
        },
        {
            name: 'ascending_price',
            title: 'По возрастанию цены',
            value: {
                by: 'price',
                order: 'asc'
            }
        }
    ],
    reviews: [
        {
            name: 'new_first',
            title: 'Сначала новые',
            value: {
                by: 'date',
                order: 'desc'
            }
        },
        {
            name: 'old_first',
            title: 'Сначала старые',
            value: {
                by: 'date',
                order: 'asc'
            }
        },
        {
            name: 'high_rating_first',
            title: 'Сначала с высокой оценкой',
            value: {
                by: 'rating',
                order: 'desc'
            }
        },
        {
            name: 'low_rating_first',
            title: 'Сначала с низкой оценкой',
            value: {
                by: 'rating',
                order: 'asc'
            }
        }
    ],
    purchased: [
        {
            name: 'new_first',
            title: 'Сначала новые',
            value: {
                by: 'date',
                order: 'desc'
            }
        },
        {
            name: 'old_first',
            title: 'Сначала старые',
            value: {
                by: 'date',
                order: 'asc'
            }
        },
        {
            name: 'descending_price',
            title: 'По убыванию цены',
            value: {
                by: 'price',
                order: 'desc'
            }
        },
        {
            name: 'ascending_price',
            title: 'По возрастанию цены',
            value: {
                by: 'price',
                order: 'asc'
            }
        }
    ],
};

export const availabilityFilters = [
    {
        label: 'Все',
        value: null
    },
    {
        label: 'В наличии',
        value: true
    },
    {
        label: 'Нет в наличии',
        value: false
    },
];

export const days = Array(31)
    .fill(0)
    .map((_, index) => (
        {
            label: (index + 1 < 10) ? `0${index + 1}` : `${index + 1}`,
            value: (index + 1 < 10) ? `0${index + 1}` : `${index + 1}`
        }
    ));

export const months = [
    {
        label: 'Январь',
        value: '01',
        number: 1
    },
    {
        label: 'Февраль',
        value: '02',
        number: 2
    },
    {
        label: 'Март',
        value: '03',
        number: 3
    },
    {
        label: 'Апрель',
        value: '04',
        number: 4
    },
    {
        label: 'Май',
        value: '05',
        number: 5
    },
    {
        label: 'Июнь',
        value: '06',
        number: 6
    },
    {
        label: 'Июль',
        value: '07',
        number: 7
    },
    {
        label: 'Август',
        value: '08',
        number: 8
    },
    {
        label: 'Сентябрь',
        value: '09',
        number: 9
    },
    {
        label: 'Октябрь',
        value: '10',
        number: 10
    },
    {
        label: 'Ноябрь',
        value: '11',
        number: 11
    },
    {
        label: 'Декабрь',
        value: '12',
        number: 12
    },
];

export const PAGE_ELEMENT_COUNT = 18;

export const authType = {
    SMS: 'SMS',
    CALL: 'CALL'
}