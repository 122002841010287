import classNames from 'classnames/bind';
import { LinkButton } from '../../components';
import './invite-banner.scss';

const InviteBanner = ({ title, description, link, linkText, styles = null, theme = 'default' }) => {
    return (
        <section className={classNames({
            'invite-banner': true,
            'invite-banner--dark': theme === 'dark'
        })}
            style={styles}
        >
            <h2 className="invite-banner__title">{title}</h2>
            <p className="invite-banner__text">
                {description}
            </p>
            <div className="invite-banner__button">
                <LinkButton to={link} className="button__white">{linkText}</LinkButton>
            </div>
        </section>
    );
};

export default InviteBanner;