import authTypes from '../types/auth';

export const setError = payload => ({
    type: authTypes.SET_ERROR,
    payload,
});

export const setAuth = payload => ({
    type: authTypes.SET_AUTH,
    payload,
});