import { useDispatch } from 'react-redux';
import { deleteKidData } from '../../store/actions/kids';
import { ReactSVG } from 'react-svg';
import getAgeFromBirthday from '../../utils/getAgeFromBirthday';
import BoyIcon from '../../assets/svg/boy.svg';
import GirlIcon from '../../assets/svg/girl.svg';
import { IoTrashSharp } from 'react-icons/io5';
import './kid-card-questionnaire.scss';

const KidCardQuestionnaire = ({ kid, openFormKid }) => {
    const dispatch = useDispatch();

    const deleteInfoKid = e => {
        e.stopPropagation();
        if (window.confirm('Вы уверены, что хотите удалить информацию о ребенке?')) {
            dispatch(deleteKidData(kid.id));
        }
    }

    return (
        <div className="kid-card-questionnaire" onClick={() => openFormKid(kid)}>
            <div className="kid-card-questionnaire__trash-bin" onClick={deleteInfoKid}>
                <IoTrashSharp size="16" />
            </div>
            <i className="kid-card-questionnaire__icon">
                {kid.personal_gender === 945
                    ? <ReactSVG src={BoyIcon} className="kid-card-questionnaire__icon-item" />
                    : <ReactSVG src={GirlIcon} className="kid-card-questionnaire__icon-item" />
                }
            </i>
            <div className="kid-card-questionnaire__content">
                <div className="kid-card-questionnaire__name">{kid.full_name}</div>
                <div className="kid-card-questionnaire__age">
                    {kid.over_14 ? (
                        'Старше 14 лет'
                    ) : (
                        getAgeFromBirthday(new Date(`${kid.personal_birthday_year}-${kid.personal_birthday_month}-${kid.personal_birthday_day}`))
                    )}
                </div>
            </div>
        </div>
    );
};

export default KidCardQuestionnaire;