import apiClient from '../../service/axios';
import { setLoading } from '../action-creators/loading';
import {
    setTokenSent,
    setErrorsStepEmail,
    setRedirectToStepEmail,
    setSuccess, setErrorsStepPassword
} from '../action-creators/recovery-password';

export const sendEmail = (email) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/user/send-token-password', JSON.stringify({email}));
        if (data.type === 'OK') {
            dispatch(setTokenSent(true));
            dispatch(setErrorsStepEmail(null));
        } else {
            dispatch(setErrorsStepEmail(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
};

export const checkToken = (params) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/user/check-token-password', JSON.stringify(params));
        if (data.type !== 'OK') {
            dispatch(setRedirectToStepEmail(true));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
};

export const sendPassword = (params) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/user/change-password', JSON.stringify(params));
        if (data.type === 'OK') {
            dispatch(setSuccess(true));
            dispatch(setErrorsStepPassword(null));
        } else {
            dispatch(setErrorsStepPassword(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
};