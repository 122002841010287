import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './products-loading-card.scss';

const ProductsLoadingCard = () => {
    return (
        <div className="favorites-loading-card">
            <Skeleton width="100%" height={120} />
            <Skeleton width="40%" height={20} style={{marginTop: '10px'}} />
            <Skeleton width="100%" height={25} style={{marginTop: '5px'}} />
            <Skeleton width="50%" height={25} style={{marginTop: '5px'}} />
            <Skeleton width="100%" height={25} style={{marginTop: '15px'}} />
        </div>
    );
};

export default ProductsLoadingCard;