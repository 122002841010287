import reviewsTypes from '../types/reviews';

export const setReviews = payload => ({
    type: reviewsTypes.SET_REVIEWS,
    payload,
});

export const setIsLoading = payload => ({
    type: reviewsTypes.SET_IS_LOADING,
    payload,
});

export const setIsLoaded = payload => ({
    type: reviewsTypes.SET_IS_LOADED,
    payload,
});

export const setTotalPages = payload => ({
    type: reviewsTypes.SET_TOTAL_PAGES,
    payload,
});

export const setSort = payload => ({
    type: reviewsTypes.SET_SORT,
    payload,
});

export const setFilters = payload => ({
    type: reviewsTypes.SET_FILTERS,
    payload,
});

export const setPage = payload => ({
    type: reviewsTypes.SET_PAGE,
    payload,
});

export const toggleReload = () => ({
    type: reviewsTypes.TOGGLE_RELOAD
});

export const setErrors = payload => ({
    type: reviewsTypes.SET_ERRORS,
    payload,
});

export const setCreateErrors = payload => ({
    type: reviewsTypes.SET_CREATE_ERRORS,
    payload,
});