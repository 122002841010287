export const getDay = (date) => {
    date = new Date(date);
    let day = Number(date.getDate());

    if (!day || day === 0) {
        return '00';
    }

    if (day < 10) {
        day = `0${day}`;
    }

    if (day > 31) {
        day = 31;
    }

    return `${day}`;
};

export const getMonth = (date) => {
    date = new Date(date);
    let month = Number(date.getMonth()) + 1;

    if (!month || month === 0) {
        return '00';
    }

    if (month < 10) {
        month = `0${month}`;
    }

    if (month > 12) {
        month = 12;
    }

    return `${month}`;
};

export const getYear = (date) => {
    date = new Date(date);
    let year = Number(date.getFullYear());

    if (!year || year === 0) {
        return '0000';
    }

    if (year < 10) {
        year = `000${year}`;
    } else if (year >= 10 && year < 100) {
        year = `00${year}`;
    } else if (year >= 100 && year < 1000) {
        year = `0${year}`;
    }

    return `${year}`;
};