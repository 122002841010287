import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

//Styles
import 'normalize.css';
import './assets/styles/bootstrap-grid.min.css';
import './assets/styles/style.scss';

ReactDOM.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
