import { setLoading } from '../action-creators/loading';
import {
    setItems,
    setMaxAmountKids,
    setLoaded,
    setErrors
} from '../action-creators/kids';
import apiClient from '../../service/axios';
import parseKidsDataForStore from '../../utils/parseKidsDataForStore';
import parseUserDataForStore from '../../utils/parseUserDataForStore';
import { toast } from 'react-toastify';

export const getKids = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.get('/user/childs');
        if (data.type === 'OK') {
            if (data.childs.length > 0){
                dispatch(setItems(parseKidsDataForStore(data.childs)));
            }
            dispatch(setMaxAmountKids(data.max_amount_children));
            dispatch(setLoaded(true));
            dispatch(setErrors(null));
        } else {
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}

export const createKidData = createData => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.post('/child/add', JSON.stringify(createData));
        if (data.type === 'OK') {
            const kidData = parseUserDataForStore(data.child);
            const {items} = getState().kids;
            const newItems = [...items, kidData];
            dispatch(setItems(newItems));
            dispatch(setErrors(null));
            dispatch(setLoading(false));
            toast.success('Ребёнок успешно добавлен');
            return Promise.resolve();
        } else if (data.errors) {
            toast.error(data.errors);
            dispatch(setErrors(data.errors));
        } else {
            toast.error('Произошла ошибка при создании. Попробуйте еще');
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const editKidData = editData => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        editData.consent = true;
        const {data} = await apiClient.post('/child/update', JSON.stringify(editData));
        if (data.type === 'OK') {
            const kidData = parseUserDataForStore(data.child);

            const {items} = getState().kids;
            const index = items.findIndex(item => item.id === kidData.id);
            let newItems = items.map(item => item);
            newItems[index] = kidData;

            dispatch(setItems(newItems));
            dispatch(setErrors(null));

            toast.success('Данные успешно обновлены');
            dispatch(setLoading(false));
            return Promise.resolve();
        } else {
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
    return Promise.reject();
}

export const deleteKidData = id => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.post('/child/delete', JSON.stringify({id}));
        if (data.type === 'OK') {
            const {items} = getState().kids;
            let newItems = items.filter(item => item.id !== id);
            dispatch(setItems(newItems));
            dispatch(setErrors(null));
            toast.success('Ребёнок успешно удалён');
        } else {
            dispatch(setErrors(data.errors));
            toast.error('Произошла ошибка при удалении. Попробуйте еще');
        }
    } catch (e) {
        console.error(e);
        toast.error('Произошла ошибка при удалении. Попробуйте еще');
    }
    dispatch(setLoading(false));
}