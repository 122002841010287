import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addNewAddress } from '../../store/actions/addresses';
import { setError } from '../../store/action-creators/addresses';
import { Input, DadataInput, Textarea, Button } from '../UI';
import { IoCloseOutline } from 'react-icons/io5';
import './modal-add-new-address.scss';

const ModalAddNewAddress = ({closeHandler}) => {
    const dispatch = useDispatch();
    const { errors } = useSelector(state => state.address);

    const [newAddress, setNewAddress] = useState({
        id: '',
        address: '',
        entrance: '',
        floor: '',
        apartment: '',
        comment_courier: ''
    });

    const handleChange = (event) => setNewAddress({...newAddress, [event.target.name]: event.target.value});

    const onFormClick = (event) => event.stopPropagation();

    const submitModalForm = (event) => {
        event.preventDefault();
        if(!newAddress.address) {
            dispatch(setError({ address: 'Поле обязательно для заполнения!' }));
            return;
        }
        const newAddressData = {...newAddress, id: Date.now().toString()}
        dispatch(addNewAddress(newAddressData));
        closeHandler();
    }

    return (
        <form className="modal-add-new-address" onSubmit={submitModalForm} onClick={onFormClick}>
            <div className="modal-add-new-address__inner-block">
                <div className="modal-add-new-address__close-icon" onClick={closeHandler}>
                    <IoCloseOutline size="40"/>
                </div>
                <div className="modal-add-new-address__title">Добавление нового адреса</div>
                <DadataInput
                    label="Адрес"
                    name="address"
                    required
                    placeholder="Введите название в свободной форме, адрес"
                    defaultQuery={newAddress.address.address_user}
                    handleChange={payload => handleChange({
                        target: {
                            name: payload.target.name,
                            value: {
                                address_user: payload.target.value.value,
                                dadata_fias: payload.target.value.data.fias_code,
                                dadata_kladr: payload.target.value.data.kladr_id,
                                geo_lat: payload.target.value.data.geo_lat,
                                geo_lon: payload.target.value.data.geo_lon,
                                zip: payload.target.value.data.postal_code
                            }
                        }
                    })}
                    filterToBound="houses"
                    filterFromBound="house"
                    error={(errors && errors['address']) ? errors['address'] : ''}
                    helpText="Введите полный адрес (до дома или квартиры) и выберете значение из выпадающего списка!"
                />
                <div className="modal-add-new-address__additional-address-data">
                    <div className="input-wrapp">
                        <Input
                            type="number"
                            label="Подъезд"
                            name="entrance"
                            placeholder="Подъезд"
                            value={newAddress.entrance}
                            handleChange={handleChange}
                            error={(errors && errors['entrance']) ? errors['entrance'] : ''}
                        />
                    </div>
                    <div className="input-wrapp">
                        <Input
                            type="number"
                            label="Этаж"
                            name="floor"
                            placeholder="Этаж"
                            value={newAddress.floor}
                            handleChange={handleChange}
                            error={(errors && errors['floor']) ? errors['floor'] : ''}
                        />
                    </div>
                    <div className="input-wrapp">
                        <Input
                            type="textarea"
                            label="Кв./Офис"
                            name="apartment"
                            placeholder="Квартира"
                            value={newAddress.apartment}
                            handleChange={handleChange}
                            error={(errors && errors['apartment']) ? errors['apartment'] : ''}
                        />
                    </div>
                </div>
                <Textarea
                    rows="4"
                    type="textarea"
                    label="Комментарий для курьера"
                    name="comment_courier"
                    placeholder="Комментарий"
                    value={newAddress.comment_courier}
                    handleChange={handleChange}
                    error={(errors && errors['comment_courier']) ? errors['comment_courier'] : ''}
                />

                <div className="modal-add-new-address__submit-btn">
                    <Button className="submit-btn" type="submit">Сохранить</Button>
                </div>
            </div>
        </form>
    )
}

export default ModalAddNewAddress;