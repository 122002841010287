import { useEffect, useRef, useState } from 'react';

export const useTimer = (defaultTime) => {
    const [isNotWorking, setIsNotWorking] = useState(false);
    const [time, setTime] = useState(defaultTime);
    const intervalRef = useRef();

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            setTime(time => time - 1);
        }, 1000)
    }

    const resetTimer = (time) => {
        setTime(time);
        startTimer();
    }

    if (!time) {
        setIsNotWorking(true);
        clearInterval(intervalRef.current);
        setTime(defaultTime);
    }

    useEffect(() => {
        if (!isNotWorking) {
            startTimer();
        }

        return () => clearInterval(intervalRef.current);
    }, [isNotWorking])

    return { isNotWorking, setIsNotWorking, resetTimer, time }
}