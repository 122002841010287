import { setIsLoaded, setSites, setErrors } from '../action-creators/sites';
import apiClient from '../../service/axios';

export const getSites = () => async (dispatch) => {
    try {
        const { data } = await apiClient.get('/lk_connected_sites');
        if (data.type === 'OK') {
            dispatch(setIsLoaded(true));
            dispatch(setErrors(null));
            dispatch(setSites(data.sites));
        } else {
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        console.error(e);
        dispatch(setErrors('Ошибка сервера при получени списка сайтов'));
    }
};