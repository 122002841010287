import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '../UI';
import { sendEmail } from '../../store/actions/recovery-password';

const FormEmailRecoveryPassword = () => {
    const [email, setEmail] = useState('');
    const errors = useSelector(({recoveryPassword}) => recoveryPassword.errorsStepEmail);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sendEmail(email));
    };

    const getError = (name) => {
        if (!errors) {
            return '';
        }

        if (typeof errors === 'object') {
            return errors[name] || '';
        } else {
            return errors;
        }
    };

    return (
        <form className="form-email" onSubmit={handleSubmit}>
            <Input
                autoFocus
                type="email"
                label="Ваш e-mail"
                name="email"
                placeholder="E-mail"
                error={getError('email')}
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                required
            />
            <Button type="submit" className="button__black" disabled={email === ''}>Отправить письмо</Button>
        </form>
    );
};

export default FormEmailRecoveryPassword;