//Ключи по которым определяются дополнительно отправляемые данные
const keys = [
    'address_code_fias',
    'address_code_kladr'
];

const getDataToSend = (form) => {
    const dataToSend = {};
    for (let el in form) {
        if (form.hasOwnProperty(el)){
            if (typeof form[el] === 'string' || typeof form[el] === 'number' || typeof form[el] === 'boolean') {
                dataToSend[el] = form[el];
            }
            if (typeof form[el] === 'object') {
                if (form[el] === null) {
                    dataToSend[el] = '';
                    continue;
                }

                if (form[el].length === undefined) {
                    dataToSend[el] = form[el].value;

                    for (let val in form[el]) {
                        if (form[el].hasOwnProperty(val) && keys.includes(val)) {
                            dataToSend[val] = form[el][val];
                        }
                    }
                } else {
                    dataToSend[el] = form[el].map(item => item.value);
                }
            }
        }
    }

    return dataToSend;
}

export default getDataToSend;