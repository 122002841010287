import { memo } from 'react';
import { EmptyItems } from '../../../components';
import { IoCloseCircleOutline } from 'react-icons/io5';
import './trainer-history-list.scss';
import getTrainerPeriodDate from "../../../utils/getTrainerPeriodDate";

const TrainerHistoryList = ({ items }) => {
    const renderItems = () => {
        if (items.length > 0) {
            return (
                items.map((item, index) => (
                    <div key={index} className="trainer-history-list__item">
                        <div className="trainer-history-list__item-header">
                            <div className="trainer-history-list__item-date">{getTrainerPeriodDate(item.period)}</div>
                            {/*<div className="trainer-history-list__item-sum">1 506 руб.</div>*/}
                        </div>
                        <div className="trainer-history-list__item-name">{item.name}</div>
                        <div className="trainer-history-list__item-money">
                            <span className={`trainer-history-list__${item.nachisleno > 0 ? 'came' : 'gone'}`}>{item.nachisleno > 0 ? '+' : ''}{item.nachisleno}&nbsp;руб.</span>
                        </div>
                    </div>
                ))
            );
        } else {
            return <EmptyItems
                icon={(props) => <IoCloseCircleOutline {...props} />}
                text="Нет данных"
            />;
        }
    };

    return (
        <div className="trainer-history-list">
            {renderItems()}
        </div>
    );
};

export default memo(TrainerHistoryList);