import { NumberInMenu } from '../../components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    IoBagCheckOutline,
    IoHeartOutline,
    IoBagRemoveOutline
} from 'react-icons/io5';
import './static-menu.scss';

const StaticMenu = () => {
    const activeOrders = useSelector(state => state.orders.totalActiveOrders);

    return (
        <div className="menu-groups">
            <div className="menu-group">
                <div className="menu-group__title">Заказы</div>
                <div className="menu-group__item">
                    <NavLink to="/orders" className="sidebar__menu-item">
                        <i className="sidebar__menu-item-icon">
                            <IoBagCheckOutline size="24px" stroke="#92929D" />
                        </i>
                        <span className="sidebar__menu-item-text">Мои заказы</span>
                        {activeOrders > 0 && <NumberInMenu>{activeOrders}</NumberInMenu>}
                    </NavLink>
                </div>
            </div>

            <div className="menu-group">
                <div className="menu-group__title">Товары</div>
                <div className="menu-group__item">
                    <NavLink to="/favorites" className="sidebar__menu-item">
                        <i className="sidebar__menu-item-icon">
                            <IoHeartOutline size="24px" stroke="#92929D" />
                        </i>
                        <span className="sidebar__menu-item-text">Избранные</span>
                    </NavLink>
                </div>
                <div className="menu-group__item">
                    <NavLink to="/purchased" className="sidebar__menu-item">
                        <i className="sidebar__menu-item-icon">
                            <IoBagRemoveOutline size="24px" stroke="#92929D" />
                        </i>
                        <span className="sidebar__menu-item-text">Купленные</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default StaticMenu;