import { useDispatch, useSelector } from 'react-redux';
import { setFilters, setSort } from '../../../store/action-creators/reviews';
import { SortPanel, Switcher } from '../../../components';
import { sortItems } from '../../../const';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './reviews-sort-panel.scss';

const ReviewsSortPanel = () => {
    const dispatch = useDispatch();
    const { isLoading, isLoaded, sort, filters, totalPages } = useSelector(({ reviews }) => reviews);

    const handleSortChange = (value) => dispatch(setSort(value));
    const handleOnlyPhotoFilterChange = (e) => {
        dispatch(setFilters({
            onlyPhoto: e.target.checked
        }));
    };

    const renderPanel = () => {
        if (!isLoaded && isLoading) {
            return <Skeleton width="100%" height={50} style={{marginTop: '2rem'}} />
        }

        if (totalPages !== null) {
            return (
                <div className="reviews-sort-panel">
                    <div className="reviews-sort-panel__wrap">
                        <div className="reviews-sort-panel__sort">
                            <SortPanel
                                activeSort={sort}
                                sortItems={sortItems.reviews}
                                handleSortChange={(value) => handleSortChange(value)}
                            />
                        </div>

                        <div className="reviews-sort-panel__switch">
                            <Switcher checked={filters.onlyPhoto} name="onlyPhoto" onChange={handleOnlyPhotoFilterChange} text="Только с фото" />
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>{renderPanel()}</>
    );
};

export default ReviewsSortPanel;