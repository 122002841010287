const replacementEmailTypes = {
    SET_CODE_SENT: 'REPLACEMENT_EMAIL/SET_CODE_SENT',
    SET_TYPE_DISPATCH: 'REPLACEMENT_EMAIL/SET_TYPE_DISPATCH',
    SET_SENDER_PHONE: 'REPLACEMENT_EMAIL/SET_SENDER_PHONE',
    SET_NEED_CALL: 'REPLACEMENT_EMAIL/SET_NEED_CALL',
    SET_NEW_EMAIL: 'REPLACEMENT_EMAIL/SET_NEW_EMAIL',
    SET_TIMER: 'REPLACEMENT_EMAIL/SET_TIMER',
    SET_ERRORS: 'REPLACEMENT_EMAIL/SET_ERRORS',
}

export default replacementEmailTypes;