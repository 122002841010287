import { memo } from 'react';
import { IoFunnel } from 'react-icons/io5';
import { SortPanel } from '../../../components';
import './products-sort-panel.scss';

const ProductsSortPanel = ({ items, sort, sortItems, handleSortChange, handleFilterModalClick }) => {
    const renderSortPanel = () => {
        if (items && items.length > 0) {
            return (
                <div className="favorites-sort-panel">
                    <div className="favorites-sort-panel__wrap">
                        <SortPanel activeSort={sort} sortItems={sortItems} handleSortChange={(value) => handleSortChange(value)} />

                        <button type="button" className="favorites-sort-panel__filter-button" onClick={handleFilterModalClick}>
                            <IoFunnel side="14px" />
                        </button>
                    </div>
                </div>
            );
        } else {
            return '';
        }
    };

    return (
        <>
            {renderSortPanel()}
        </>
    );
};

export default memo(ProductsSortPanel);