import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoCloseOutline } from 'react-icons/io5';
import { Button, Input } from "../UI";
import { replacePhone, replacePhoneCall, confirmReplacePhone } from "../../store/actions/replacement-phone";
import { useInput } from "../../hooks/useInput";
import { useTimer } from "../../hooks/useTimer";
import './modal-confirm-replacement-phone.scss';

const ModalConfirmReplacementPhone = ({closeHandler}) => {
    const dispatch = useDispatch();
    const { senderPhone, typeDispatch, timer, errors } = useSelector(state => state.replacementPhone);

    const { state: code, handleChange: changeCode, clearInput } = useInput('');
    const { isNotWorking: isAcceptSending, setIsNotWorking: setIsAcceptSending, time, resetTimer } = useTimer(+process.env.REACT_APP_SMS_TIMEOUT);

    useEffect(() => {
        resetTimer(timer);
    }, [timer]);

    const onFormClick = (event) => event.stopPropagation();

    const resendSmsBtnHandler = () => {
        clearInput();
        dispatch(replacePhone(senderPhone));
        setIsAcceptSending(false);
    }

    const recallBtnHandler = () => {
        clearInput();
        dispatch(replacePhoneCall(senderPhone));
        setIsAcceptSending(false);
    }

    const submitModalForm = (event) => {
        event.preventDefault();
        dispatch(confirmReplacePhone(code))
            .then(() => closeHandler())
            .catch(() => clearInput());
    }

    const renderResendSMSBlock = () => {
        if(!isAcceptSending)
            return (
                <span className="modal-replacement-phone__resend--text">
                    Выслать код повторно можно будет через: {time} сек.
                </span>
            );

        return (
            <button className="modal-replacement-phone__resend--btn" onClick={resendSmsBtnHandler}>
                Выслать код повторно
            </button>
        )
    }

    const renderResendCallBlock = () => {
        if(!isAcceptSending)
            return (
                <span className="modal-replacement-phone__resend--text">
                    Повторно звонок может быть произведен через: {time} сек.
                </span>
            );

        return (
            <button className="modal-replacement-phone__resend--btn" onClick={recallBtnHandler}>
                Позвонить повторно
            </button>
        )
    }

    const renderResendBlock = () => {
        if(typeDispatch === "SMS")
            return renderResendSMSBlock();

        return renderResendCallBlock();
    }

    const renderLabel = () => {
        if(typeDispatch === "SMS")
            return "Код из СМС";

        return "Последние 4 цифры из номера телефона";
    }

    return (
        <form className="modal-replacement-phone" onSubmit={submitModalForm} onClick={onFormClick}>
            <div className="modal-replacement-phone__inner-block">
                <div className="modal-replacement-phone__close-icon" onClick={closeHandler}>
                    <IoCloseOutline size="40"/>
                </div>
                <div className="modal-replacement-phone__title">Подтвердите номер телефона</div>
                <div className="modal-replacement-phone__sender-phone">
                    Номер получателя: <span>{senderPhone}</span>
                </div>
                <div className="modal-replacement-phone__resend">
                    {renderResendBlock()}
                </div>
                <div className="input-wrapp">
                    <Input
                        type="confirmCode"
                        label={renderLabel()}
                        name="confirmCode"
                        placeholder="Код"
                        value={code}
                        handleChange={changeCode}
                        error={(errors && errors['confirm_code']) ? errors['confirm_code'] : errors}
                        required
                    />
                </div>
                <div className="modal-replacement-phone__submit-btn">
                    <Button className="submit-btn" type="submit">Подтвердить</Button>
                </div>
            </div>
        </form>
    )
}

export default ModalConfirmReplacementPhone;