import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { checkToken } from '../../store/actions/recovery-password';
import { CustomLink, FormSetNewPassword } from '../../components/';
import getRecoveryPasswordQueryParams from '../../utils/getRecoveryPasswordQueryParams';
import { toast } from 'react-toastify';
import './set-new-password.scss';

const redirectErrorMsg = 'Ссылка из письма устарела. Попробуйте заново отправить письмо!';
const redirectSuccessMsg = 'Ваш пароль успешно изменён!';

const SetNewPassword = () => {
    const dispatch = useDispatch();
    const redirect = useSelector(({recoveryPassword}) => recoveryPassword.redirectToStepEmail);
    const success = useSelector(({recoveryPassword}) => recoveryPassword.success);
    const { search } = useLocation();
    const { email, token } = useMemo(() => getRecoveryPasswordQueryParams(search), [search]);

    useEffect(() => {
        if (!(email && token)) {
            toast.error(redirectErrorMsg);
        } else {
            dispatch(checkToken({email, token}));
        }
    }, []);

    useEffect(() => {
        if (redirect) {
            toast.error(redirectErrorMsg);
        }
    }, [redirect]);

    useEffect(() => {
        if (success) {
            toast.success(redirectSuccessMsg);
        }
    }, [success]);

    if (!(email && token) || redirect) {
        return <Redirect to='/password-recovery' />;
    }

    if (success) {
        return <Redirect to='/login' />;
    }

    return (
        <div className="form-auth">
            <div className="form-auth__header auth-header">
                <div className="form-auth__title auth-header__item">Восстановление пароля</div>
                <div className="already-registered auth-header__item">Введите новый пароль</div>
            </div>

            <div className="form-auth__body">
                <FormSetNewPassword token={token} email={email} />
            </div>

            <div className="form-auth__footer">
                <div className="form-auth__footer-links">
                    <CustomLink to='/login'>Вернуться к авторизации</CustomLink>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;