const userTypes = {
    SET_REGISTER_DATA: 'USER/SET_REGISTER_DATA',
    SET_REGISTER_DATA_ERRORS: 'USER/SET_REGISTER_DATA_ERRORS',
    SET_CUSTOMER_DATA: 'USER/SET_CUSTOMER_DATA',
    SET_CUSTOMER_DATA_ERRORS: 'USER/SET_CUSTOMER_DATA_ERRORS',
    SET_PARTICIPATION: 'USER/SET_PARTICIPATION',
    SET_QUESTIONNAIRE_COMPLETED: 'USER/SET_QUESTIONNAIRE_COMPLETED',
    SET_TRAINER_PARTICIPATION: 'USER/SET_TRAINER_PARTICIPATION',
    SET_TRAINER_QUESTIONNAIRE_COMPLETED: 'USER/SET_TRAINER_QUESTIONNAIRE_COMPLETED',
    SET_ERRORS: 'USER/SET_ERRORS',
};

export default userTypes;