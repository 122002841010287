import { memo } from 'react';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';
import './pagination.scss';

const Pagination = ({ currentPage, totalPages, onChangePage }) => {

    const changePage = newPage => {
        if (currentPage !== newPage) {
            onChangePage(newPage);
        }
    }

    return (
        <>
            {totalPages > 1 &&
            <ul className="pagination">

                {currentPage > 1 &&
                    <li className="pagination__item">
                        <a
                            className="pagination__link"
                            onClick={() => changePage(currentPage - 1)}>
                            <IoArrowBack className="pagination__icon" />
                        </a>
                    </li>
                }

                {
                    [...Array(totalPages).keys()].map(page => (
                        <li
                            key={page}
                            className={`pagination__item${currentPage === page + 1 ? " pagination__item_active" : ''}`}>
                            <a
                                className="pagination__link"
                                onClick={() => changePage(page + 1)}>
                                {page + 1}
                            </a>
                        </li>
                    ))
                }

                {currentPage < totalPages &&
                    <li className="pagination__item">
                        <a
                            className="pagination__link"
                            onClick={() => changePage(currentPage + 1)}>
                            <IoArrowForward className="pagination__icon" />
                        </a>
                    </li>
                }

            </ul>
            }
        </>
    )
}

export default memo(Pagination);