const parseKidsDataForStore = (data) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
        let obj = {};
        for (let j = 0; j < data[i].length; j++) {
            if (typeof data[i][j].value === 'string' || typeof data[i][j].value === 'number' || typeof data[i][j].value === 'boolean') {
                obj[data[i][j].name] = data[i][j].value;
            }
            if (typeof data[i][j].value === 'object') {
                if (data[i][j].value.length === undefined) {
                    obj[data[i][j].name] = data[i][j].value.value;
                } else {
                    obj[data[i][j].name] = data[i][j].value.map(v => v.label);
                }
            }
        }
        obj['fields'] = data[i];
        result.push(obj);
    }

    return result;
}

export default parseKidsDataForStore;