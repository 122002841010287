import {
    setProducts,
    setIsLoading,
    setIsLoaded,
    setErrors
} from '../action-creators/products-for-review';
import apiClient from '../../service/axios';

export const getProductsForReview = () => async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
        const { data } = await apiClient.get('/purchased-products/get-recent');

        if (data.type === 'OK') {
            dispatch(setProducts(data.products));
            dispatch(setIsLoading(false));
            dispatch(setIsLoaded(true));
        } else {
            dispatch(setErrors('Не удалось получить купленные товары'));
        }
    } catch (e) {
        console.error(e);
        dispatch(setErrors('Не удалось получить купленные товары'));
    }
};

export const removeProduct = (id) => async (dispatch, getState) => {
    try {
        const { data } = await apiClient.post('/purchased-products/change-status-product-review', JSON.stringify({
            product_id: id,
            value: false
        }));

        if (data.type === 'OK') {
            const products = getState().productsForReview.products;
            const productsFiltered = products.filter((product) => product.id !== data.product_id);
            dispatch(setProducts(productsFiltered));
        }
    } catch (e) {
        console.error(e);
    }
};