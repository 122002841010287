import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrainerHistoryTable, TrainerHistoryList, TrainerHistoryFilters, Pagination } from '../../../components';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { getMonths, getTransactions } from '../../../store/actions/trainer';
import './trainer-history.scss';
import { setIsLoadedTransactions, setPage } from "../../../store/action-creators/trainer";

const TrainerHistory = () => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const months = useSelector(({trainer}) => trainer.months);
    const transactions = useSelector(({trainer}) => trainer.transactions);
    const filterMonth = useSelector(({trainer}) => trainer.filterMonth);
    const { months: monthsError, transactions: transactionsError } = useSelector(({trainer}) => trainer.errors);
    const isLoadedMonths = useSelector(({trainer}) => trainer.isLoaded.months);
    const isLoadedTransactions = useSelector(({trainer}) => trainer.isLoaded.transactions);
    const totalPages = useSelector(({trainer}) => trainer.totalPages);
    const currentPage = useSelector(({trainer}) => trainer.page);

    useEffect(() => {
        if (!isLoadedMonths) {
            dispatch(getMonths());
        }
    }, [isLoadedMonths]);

    useEffect(() => {
        if (months.length > 0 && filterMonth > 0 && !isLoadedTransactions) {
            dispatch(getTransactions());
        }
    }, [filterMonth, months, isLoadedTransactions, currentPage]);

    const changePage = (page) => {
        dispatch(setIsLoadedTransactions(false));
        dispatch(setPage(page));
    };

    const renderTable = () => {
        if (width > 767) {
            return <TrainerHistoryTable items={transactions} />;
        } else {
            return <TrainerHistoryList items={transactions} />;
        }
    };

    return (
        <div className="trainer-history">
            <h3 className="trainer-history__title">История списания и начисления вознаграждения</h3>
            {monthsError === null && (
                <div className="trainer-history__filters">
                    <TrainerHistoryFilters months={months} active={filterMonth} />
                </div>
            )}
            {transactionsError === null && (
                <>
                    <div className="trainer-history__table">{renderTable()}</div>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={changePage} />
                </>
            )}
        </div>
    );
};

export default TrainerHistory;