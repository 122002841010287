import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoPersonOutline, IoLogOutOutline } from 'react-icons/io5';
import { logout } from '../../store/actions/auth';
import './header.scss';

const Header = () => {
    const dispatch = useDispatch();
    const { first_name } = useSelector(state => state.user.registerData);

    const signOut = () => {
        dispatch(logout());
    }

    return (
        <header className="header d-none d-sm-block">
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <Link to="/" className="header__logo">
                            <img src="/images/logo-horizontal.png" alt="logo"/>
                        </Link>
                    </div>
                    <div className="col-8">
                        <nav className="header__menu">
                            <NavLink to="/profile" className="header__menu-item" exact>
                                <i className="header__menu-item-icon">
                                    <IoPersonOutline size="18px" />
                                </i>
                                <span className="header__menu-item-text">{first_name || 'Профиль'}</span>
                            </NavLink>
                            <button type="button" className="header__menu-item" onClick={signOut}>
                                <i className="header__menu-item-icon">
                                    <IoLogOutOutline size="18px" />
                                </i>
                                <span className="header__menu-item-text">Выход</span>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;