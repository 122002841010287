import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import getAgeFromBirthday from '../../utils/getAgeFromBirthday';
import BoyIcon from '../../assets/svg/boy.svg';
import GirlIcon from '../../assets/svg/girl.svg';
import './kid-card.scss';

const KidCard = ({ item }) => {
    return (
        <Link to={`/kid/${item.id}`} className="kid-card">
            <i className="kid-card__icon">
                {item.personal_gender === 945 ? <ReactSVG src={BoyIcon} className="kid-card__icon-item" /> : <ReactSVG src={GirlIcon} className="kid-card__icon-item" />}
            </i>
            <div className="kid-card__content">
                <div className="kid-card__name">{item.full_name}</div>
                <div className="kid-card__age">
                    {item.over_14 ? (
                        'Старше 14 лет'
                    ) : (
                        getAgeFromBirthday(new Date(`${item.personal_birthday_year}-${item.personal_birthday_month}-${item.personal_birthday_day}`))
                    )}
                </div>
            </div>
        </Link>
    );
};

export default KidCard;