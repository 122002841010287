const getUrlAndTextForPushNotification = str => {
    let text = '';
    let url = '';
    const result = str.match(/http[s]?\:\/\/[\w\-\.\/]+/);
    if (result && result.length > 0) {
        url = result[0];
    }
    text = str.replace(url, '').trim();
    return { url, text };
}

export default getUrlAndTextForPushNotification;