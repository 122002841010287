import { useState } from 'react';
import { Backdrop, Button, Delimiter, ModalChangePayment, LineButton } from '../../../components';
import ReactTooltip from 'react-tooltip';
import {
    IoCardOutline,
    IoCheckmarkCircle,
    IoCloseCircle,
    IoHappyOutline,
    IoLocationOutline
} from 'react-icons/io5';
import './order-info.scss';

const OrderInfo = ({ order }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [paymentType, setPaymentType] = useState(null);

    const closeModal = () => setIsOpenModal(false);
    const openModal = paymentType => {
        setIsOpenModal(true);
        setPaymentType(paymentType);
    }

    return (
        <>
            {isOpenModal &&
                <Backdrop closeHandler={closeModal}>
                    <ModalChangePayment paymentTypes={order.availablePaySystemsList} currentPayment={paymentType} closeHandler={closeModal} />
                </Backdrop>
            }

            <div className="order-info">
                <div className="order-info__header">
                    <h2 className="order-info__title">
                        {order.canceled
                            ? 'Заказ отменен'
                            : order.status.name
                        }
                    </h2>
                    <div className="order-info__site">{order.site || 'Сайт не найден'}</div>
                </div>
                <Delimiter />
                <div className="order-info__items">
                    <div className="order-info__item">
                        <IoHappyOutline
                            className="order-info__icon"
                            size="24px"
                            stroke="#2B2A29"
                        />
                        <div className="order-info__main">
                            <div className="order-info__subtitle">Получатель: {order.buyer.name}</div>
                            {Object.keys(order.buyer.properties).map(key => (
                                <div className="order-info__data" key={key}>
                                    <span className="order-info__data-title">{key}:</span>
                                    &nbsp;&nbsp;
                                    {order.buyer.properties[key]}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="order-info__item">
                        <IoCardOutline
                            className="order-info__icon"
                            size="24px"
                            stroke="#2B2A29"
                        />
                        <div className="order-info__main">
                            <div className="order-info__subtitle">
                                {order.paid ? 'Оплачено' : 'К оплате'}
                                <span className="order-info__trait">&nbsp;&mdash;&nbsp;</span>
                                <span className="order-info__must-pay">
                                    {order.paid
                                        ? order.price.total.toLocaleString()
                                        : order.price.total - order.price.alreadyPaid.toLocaleString()
                                    }
                                    &nbsp;руб.
                                </span>
                            </div>
                            <div className="order-info__data">
                                {order.payment.map((paymentType, index) => (
                                    <div className="order-info__payment" key={paymentType.name}>
                                        <div className="order-info__payment-type">
                                            {paymentType.name}&nbsp;
                                            <div className="order-info__payment-info">
                                                <span className="order-info__trait">-&nbsp;</span>
                                                <span className="order-info__payment-sum">{paymentType.sum.toLocaleString()} руб.</span>
                                                <span className="order-info__payment-status">
                                                    <i data-tip="React-tooltip" data-for={`${index}`}>
                                                        {paymentType.isPaid ? <IoCheckmarkCircle size="24px" fill="#2787F5" /> : <IoCloseCircle size="24px" fill="#D32A29" />}
                                                    </i>
                                                    <ReactTooltip id={`${index}`} place="right" type="dark" effect="solid">{paymentType.isPaid ? 'Оплачено' : 'Не оплачено'}</ReactTooltip>
                                                </span>

                                                {order.possibilityOrderChange && !paymentType.isPaid &&
                                                    <LineButton className="order-info__payment-change custom-link__blue" handleClick={() => openModal(paymentType)}>Изменить</LineButton>
                                                }
                                            </div>
                                        </div>

                                        {!order.canceled && paymentType.link && !paymentType.isPaid &&
                                            <a className="order-info__payment-link" href={paymentType.link} target="_blank">
                                                <Button className="button__red order-info__payment-button">Оплатить</Button>
                                            </a>
                                        }

                                        <Delimiter />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="order-info__item">
                        <IoLocationOutline
                            className="order-info__icon"
                            size="24px"
                            stroke="#2B2A29"
                        />
                        <div className="order-info__main">
                            <div className="order-info__subtitle">Способ получения: {order.delivery.name}</div>
                            {Object.keys(order.delivery.properties).map(key => (
                                <div className="order-info__data" key={key}>
                                    <span className="order-info__data-title">{key}:</span>
                                    &nbsp;&nbsp;
                                    {order.delivery.properties[key]}
                                </div>
                            ))}
                            <div className="order-info__delivery-price">{order.price.delivery.toLocaleString()} руб.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderInfo;