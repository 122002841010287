const purchasedProductsTypes = {
    SET_ITEMS: 'PURCHASED_PRODUCTS/SET_ITEMS',
    SET_PAGE: 'PURCHASED_PRODUCTS/SET_PAGE',
    SET_TOTAL_PAGES: 'PURCHASED_PRODUCTS/SET_TOTAL_PAGES',
    SET_CATEGORIES: 'PURCHASED_PRODUCTS/SET_CATEGORIES',
    SET_SORT: 'PURCHASED_PRODUCTS/SET_SORT',
    SET_FILTERS_CATEGORIES: 'PURCHASED_PRODUCTS/SET_FILTERS_CATEGORIES',
    SET_FILTERS_AVAILABILITY: 'PURCHASED_PRODUCTS/SET_FILTERS_AVAILABILITY',
    RESET_FILTERS: 'PURCHASED_PRODUCTS/RESET_FILTERS',
    SET_IS_LOADING: 'PURCHASED_PRODUCTS/SET_IS_LOADING',
    SET_ERROR: 'PURCHASED_PRODUCTS/SET_ERROR',
};

export default purchasedProductsTypes;