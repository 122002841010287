import {
    setReviews,
    setIsLoading,
    setIsLoaded,
    setTotalPages,
    setErrors,
    toggleReload,
    setPage,
    setCreateErrors,
} from '../action-creators/reviews';
import { setLoading } from '../action-creators/loading';
import { setProducts } from '../action-creators/products-for-review';
import { toast } from 'react-toastify';
import apiClient from '../../service/axios';

const DEFAULT_PARAMS = {
    page: 1,
    limit: 5,
    sort: 'date',
    sort_order: 'desc',
    filter_only_photo: false
};

export const getReviews = (page = 1) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    try {
        const { sort, filters } = getState().reviews;
        const params = {
            page: page || DEFAULT_PARAMS.page,
            limit: DEFAULT_PARAMS.limit,
            sort: sort.by || DEFAULT_PARAMS.sort,
            sort_order: sort.order || DEFAULT_PARAMS.sort_order,
            filter_only_photo: (filters.onlyPhoto || DEFAULT_PARAMS.filter_only_photo) ? 'Y' : 'N'
        };
        const paramsQuery = `?page=${params.page}&limit=${params.limit}&sort=${params.sort}&sort_order=${params.sort_order}&filter_only_photo=${params.filter_only_photo}`;

        const { data } = await apiClient.get(`/review/get-reviews${paramsQuery}`);
        if (data.type === 'OK') {
            dispatch(setReviews(data.reviews));
            if (params.filter_only_photo === 'N' && +data.pageCurrent === 1 && +data.reviewsTotal === 0) {
                dispatch(setTotalPages(null));
            } else {
                dispatch(setTotalPages(data.pagesTotal));
            }
            dispatch(setPage(data.pageCurrent));
        } else {
            dispatch(setTotalPages(null));
            dispatch(setErrors('Не удалось получить отызвы'));
        }
    } catch (e) {
        console.error(e);
        dispatch(setErrors('Не удалось получить отызвы'));
    }

    dispatch(setIsLoaded(true));
    dispatch(setIsLoading(false));
};

export const addReview = (formData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        dispatch(setCreateErrors(null));

        const { data } = await apiClient({
            method: 'post',
            url: '/review/add-review',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));
        if (data.type === 'OK') {
            const productsForReview = getState().productsForReview.products;
            const productsForReviewFiltered = productsForReview.filter((item) => item.id !== formData.get('productId'));
            dispatch(setProducts(productsForReviewFiltered));
            dispatch(toggleReload());

            return Promise.resolve();
        } else {
            dispatch(setCreateErrors(data.errors));
            return Promise.reject();
        }
    } catch (e) {
        console.error(e);
        dispatch(setLoading(false));
        return Promise.reject();
    }
};

export const editReview = (formData) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        dispatch(setCreateErrors(null));

        const { data } = await apiClient({
            method: 'post',
            url: '/review/update-review',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch(setLoading(false));
        if (data.type === 'OK') {
            dispatch(toggleReload());
            return Promise.resolve();
        } else {
            dispatch(setCreateErrors(data.errors));
            return Promise.reject();
        }
    } catch (e) {
        console.error(e);
        dispatch(setLoading(false));
        return Promise.reject();
    }
};

export const removeReview = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/review/delete-review', JSON.stringify({review_id: id}));
        dispatch(setLoading(false));
        if (data.type === 'OK') {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    } catch (e) {
        console.error(e);
        dispatch(setLoading(false));
        return Promise.reject();
    }
};

export const setReviewRatings = (reviewId, action) => async (dispatch, getState) => {

    const { reviews } = getState().reviews;
    const reviewCopy = [...reviews];
    const idx = reviewCopy.findIndex((r) => r.ID === reviewId);
    const reviewById = reviewCopy[idx];

    if(action === 'like' && reviewById.USER_REACTION === 'like')
        reviewCopy[idx] = { ...reviewById, USER_REACTION: null, LIKES: reviewById.LIKES - 1 };
    else if(action === 'like' && reviewById.USER_REACTION === null)
        reviewCopy[idx] = { ...reviewById, USER_REACTION: action, LIKES: reviewById.LIKES + 1 };
    else if(action === 'like' && reviewById.USER_REACTION === 'dislike')
        reviewCopy[idx] = { ...reviewById, USER_REACTION: action, LIKES: reviewById.LIKES + 1, DISLIKES: reviewById.DISLIKES - 1 };
    else if(action === 'dislike' && reviewById.USER_REACTION === null)
        reviewCopy[idx] = { ...reviewById, USER_REACTION: action, DISLIKES: reviewById.DISLIKES + 1 };
    else if(action === 'dislike' && reviewById.USER_REACTION === 'dislike')
        reviewCopy[idx] = { ...reviewById, USER_REACTION: null, DISLIKES: reviewById.DISLIKES - 1 };
    else if(action === 'dislike' && reviewById.USER_REACTION === 'like')
        reviewCopy[idx] = { ...reviewById, USER_REACTION: action, LIKES: reviewById.LIKES - 1, DISLIKES: reviewById.DISLIKES + 1 };

    dispatch(setReviews(reviewCopy));

    try {
        const option = {review_id: reviewId, action};

        const { data } = await apiClient.post('/review/update-user-reaction', JSON.stringify(option));

        if (data.type === 'OK') {
            dispatch(setReviews(reviewCopy));
            return Promise.resolve();
        }

        dispatch(setReviews(reviews));
        toast.error('Произошла ошибка. Попробуйте еще');
        return Promise.reject();

    } catch (e) {
        dispatch(setReviews(reviews));
        toast.error('Произошла ошибка. Попробуйте еще');
        console.error(e);
        return Promise.reject();
    }
}