import {
    Login,
    Register,
    Main,
    Profile,
    Questionnaire,
    Card,
    Kids,
    Kid,
    KidAdd,
    Menu,
    Orders,
    Order,
    Addresses,
    Favorites,
    ConfirmEmail,
    TrainerRegister,
    TrainerMain,
    Reviews,
    Purchased,
    Notifications,
    PrivacyPolicy,
    AgreementOffer,
    LoyaltyProgram,
    PasswordRecovery,
    SetNewPassword,
} from '../pages';
import { ConfirmEmailStatus } from '../components';
import {
    IoGridOutline,
    IoPersonOutline,
    IoCardOutline,
    IoDocumentTextOutline,
    IoPeopleOutline,
    IoMenu,
    IoLocationOutline,
    IoChatbubblesOutline,
    IoNotificationsOutline
} from 'react-icons/io5';

/**
 * Массив публичных маршрутов
 * @type {({path: string, component: (function(*): *), exact: boolean, title: string})[]}
 */
export const publicRoutes = [
    {
        path: '/login',
        component: (props) => <Login {...props} />,
        title: 'Авторизация',
        exact: false
    },
    {
        path: '/register',
        component: (props) => <Register {...props} />,
        title: 'Регистрация',
        exact: false
    },
    {
        path: '/confirm-email',
        component: (props) => <ConfirmEmailStatus {...props} />,
        title: 'Подтверждение email',
        exact: false
    },
    {
        path: '/privacy-policy',
        component: (props) => <PrivacyPolicy {...props} />,
        title: 'Политика конфиденциальности Нужный спорт',
        exact: false
    },
    {
        path: '/password-recovery',
        component: (props) => <PasswordRecovery {...props} />,
        title: 'Восстановление пароля',
        exact: false
    },
    {
        path: '/set-new-password',
        component: (props) => <SetNewPassword {...props} />,
        title: 'Восстановление пароля',
        exact: false
    },
];

/**
 * Перечисление типов меню
 */
const menuTypes = {
    SIDEBAR: 'sidebar',
    BOTTOM: 'bottom'
};

/**
 * Массив приватных маршрутов
 * @type {({participation: {showIfNot: boolean, showIfYes: boolean}, path: string, component: (function(*): *), types: [string, string], exact: boolean, icon: (function(*): *), title: string})[]}
 */
export const privateRoutes = [
    {
        path: '/',
        component: (props) => <Main {...props} />,
        title: 'Главная',
        exact: true,
        types: [menuTypes.SIDEBAR, menuTypes.BOTTOM],
        icon: (props) => <IoGridOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/profile',
        component: (props) => <Profile {...props} />,
        title: 'Профиль',
        exact: false,
        types: [menuTypes.SIDEBAR, menuTypes.BOTTOM],
        icon: (props) => <IoPersonOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/anketa',
        component: (props) => <Questionnaire {...props} />,
        title: 'Анкета',
        exact: false,
        types: [menuTypes.SIDEBAR, menuTypes.BOTTOM],
        icon: (props) => <IoDocumentTextOutline {...props} />,
        participation: {
            showIfYes: false,
            showIfNot: true
        },
        questionnaireCompleted: true
    },
    {
        path: '/card',
        component: (props) => <Card {...props} />,
        title: 'Карта',
        exact: false,
        types: [menuTypes.SIDEBAR, menuTypes.BOTTOM],
        icon: (props) => <IoCardOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: false
        }
    },
    {
        path: '/kids',
        component: (props) => <Kids {...props} />,
        title: 'Дети',
        exact: true,
        types: [menuTypes.SIDEBAR],
        icon: (props) => <IoPeopleOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: false
        }
    },
    {
        path: '/kids/add',
        component: (props) => <KidAdd {...props} />,
        title: 'Добавление ребёнка',
        exact: true,
        types: [],
        icon: (props) => <IoPeopleOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: false
        }
    },
    {
        path: '/kid/:id',
        component: (props) => <Kid {...props} />,
        title: 'Ребёнок',
        exact: false,
        types: [],
        icon: (props) => <IoPeopleOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: false
        }
    },
    {
        path: '/my-reviews',
        component: (props) => <Reviews {...props} />,
        title: 'Мои отзывы',
        exact: true,
        types: [menuTypes.SIDEBAR],
        icon: (props) => <IoChatbubblesOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/menu',
        component: (props) => <Menu {...props} />,
        title: 'Меню',
        exact: false,
        types: [],
        icon: (props) => <IoMenu {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/orders',
        component: (props) => <Orders {...props} />,
        title: 'Мои заказы',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/order/:id',
        component: (props) => <Order {...props} />,
        title: 'Заказ',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/delivery-addresses',
        component: (props) => <Addresses {...props} />,
        title: 'Адреса доставки',
        exact: false,
        types: [menuTypes.SIDEBAR],
        icon: (props) => <IoLocationOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/favorites',
        component: (props) => <Favorites {...props} />,
        title: 'Избранные товары',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/notifications',
        component: (props) => <Notifications {...props} />,
        title: 'Настройка уведомлений',
        exact: true,
        types: [menuTypes.SIDEBAR],
        icon: (props) => <IoNotificationsOutline {...props} />,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/purchased',
        component: (props) => <Purchased {...props} />,
        title: 'Купленные товары',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/confirm-email',
        component: (props) => <ConfirmEmail {...props} />,
        title: 'Подтверждение email',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/trainer-registration',
        component: (props) => <TrainerRegister {...props} />,
        title: 'Анкета тренера',
        exact: false,
        types: [],
        icon: null,
        trainer: {
            participation: {
                showIfYes: false,
                showIfNot: true
            },
            questionnaire: {
                showIfYes: false,
                showIfNot: true
            }
        }
    },
    {
        path: '/trainer',
        component: (props) => <TrainerMain {...props} />,
        title: 'Кабинет тренера. Статистика',
        exact: true,
        types: [],
        icon: null,
        trainer: {
            participation: {
                showIfYes: true,
                showIfNot: false
            },
            questionnaire: {
                showIfYes: false,
                showIfNot: false
            }
        }
    },
    {
        path: '/trainer/rules',
        component: (props) => <Main {...props} />,
        title: 'Правила и условия',
        exact: true,
        types: [],
        icon: null,
        trainer: {
            participation: {
                showIfYes: true,
                showIfNot: false
            },
            questionnaire: {
                showIfYes: false,
                showIfNot: false
            }
        }
    },
    {
        path: '/privacy-policy',
        component: (props) => <PrivacyPolicy {...props} />,
        title: 'Политика конфиденциальности Нужный спорт',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/agreement-offer',
        component: (props) => <AgreementOffer {...props} />,
        title: 'Договор-оферта ',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
    {
        path: '/loyalty-program',
        component: (props) => <LoyaltyProgram {...props} />,
        title: 'Программа лояльности для физических лиц',
        exact: false,
        types: [],
        icon: null,
        participation: {
            showIfYes: true,
            showIfNot: true
        }
    },
];