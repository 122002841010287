
const Button = ({ className, handleClick, children, ...props}) => {

    return (
       <button className={`button ${className ? className : ''}`} onClick={handleClick} {...props}>
           {children}
       </button>
    );
};

export default Button;