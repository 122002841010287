import addressesTypes from '../types/addresses';

const initialState = {
    addresses: [],
    addressesCount: 0,
    totalPages: 0,
    pageElementCount: 0,
    address: null,
    loaded: false,
    errors: null
};

export const addressReducer = (state = initialState, action) => {
    switch (action.type) {
        case addressesTypes.SET_ADDRESSES:
            return {...state, addresses: action.payload}
        case addressesTypes.SET_TOTAL_PAGES:
            return {...state, totalPages: action.payload}
        case addressesTypes.SET_ADDRESSES_TOTAL_COUNT:
            return {...state, addressesCount: action.payload}
        case addressesTypes.SET_PAGE_ELEMENT_COUNT:
            return {...state, pageElementCount: action.payload}
        case addressesTypes.SET_ADDRESS:
            return {...state, address: action.payload}
        case addressesTypes.SET_LOADED:
            return {...state, loaded: action.payload}
        case addressesTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}