export const trainerDataValidate = (data) => {
    const errors = {};
    const messages = {
        empty: 'Обязательно для заполнения',
        sections: 'Необходимо выбрать адрес из выпадающего списка'
    };

    for (let item in data) {
        if (data.hasOwnProperty(item)) {
            switch (item) {
                case 'city':
                    if (!data[item].value) {
                        errors.city = messages.empty;
                    }
                    break;
                case 'sports':
                    if (data[item].length === 0) {
                        errors.sports = messages.empty;
                    }
                    break;
                case 'sections':
                    if (data[item].length === 0) {
                        errors.sections = messages.empty;
                    } else {
                        const sections = [...data[item].map((section, index) => {
                            return {
                                ...section,
                                id: index
                            }
                        })];

                        const filteredSections = [...sections.filter((section) => section.address.value)];
                        if (data[item].length > filteredSections.length) {
                            errors.addresses = [...sections.filter((section) => !section.address.value)];
                        }
                    }
                    break;
                case 'consent':
                    if (!data[item]) {
                        errors.consent = messages.empty;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    if (Object.keys(errors).length > 0) {
        return {
            status: false,
            errors: errors
        }
    } else {
        return {
            status: true
        }
    }
};