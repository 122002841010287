import sitesTypes from '../types/sites';

export const setSites = payload => ({
    type: sitesTypes.SET_SITES,
    payload,
});

export const setIsLoaded = payload => ({
    type: sitesTypes.SET_IS_LOADED,
    payload,
});

export const setErrors = payload => ({
    type: sitesTypes.SET_ERRORS,
    payload,
});