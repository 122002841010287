import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetFilters as resetFavoriteFilters,
    setFiltersAvailability as setFavoriteFiltersAvailability,
    setFiltersCategories as  setFavoriteFiltersCategories
} from '../../store/action-creators/favorites';
import {
    resetFilters as resetPurchasedFilters,
    setFiltersAvailability as setPurchasedFiltersAvailability,
    setFiltersCategories as  setPurchasedFiltersCategories
} from '../../store/action-creators/purchased-products';
import { ProductsFilters } from '../../components';
import { pathNames } from '../../const';
import './sidebar-additional-blocks.scss';

const SidebarAdditionalBlocks = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { totalPages: favoritesTotalPages, filters: favoritesFilters, categories: favoritesCategories, isLoading: favoritesIsLoading } = useSelector(({ favorites }) => favorites);
    const { totalPages: purchasedTotalPages, filters: purchasedFilters, categories: purchasedCategories, isLoading: purchasedIsLoading } = useSelector(({ purchasedProducts }) => purchasedProducts);

    const handleFavoriteCategoryChange = (e, id) => {
        if (e.target.checked) {
            dispatch(setFavoriteFiltersCategories([ ...favoritesFilters.categories, id ]));
        } else {
            dispatch(setFavoriteFiltersCategories(favoritesFilters.categories.filter(c => c !== id)));
        }
    };

    const handlePurchasedCategoryChange = (e, id) => {
        if (e.target.checked) {
            dispatch(setPurchasedFiltersCategories([ ...purchasedFilters.categories, id ]));
        } else {
            dispatch(setPurchasedFiltersCategories(purchasedFilters.categories.filter(c => c !== id)));
        }
    };

    const renderBlocks = () => {
        switch (pathname) {
            case pathNames.Favorites:
                return (
                    <div className="sidebar-additional-blocks__item sidebar-additional-blocks__item--favorites-filters">
                        <ProductsFilters
                            totalPages={favoritesTotalPages}
                            categories={favoritesCategories}
                            filters={favoritesFilters}
                            isLoading={favoritesIsLoading}
                            handleResetFilters={() => dispatch(resetFavoriteFilters())}
                            handleCategoryChange={(e, id) => handleFavoriteCategoryChange(e, id)}
                            handleAvailabilityFilter={(value) => dispatch(setFavoriteFiltersAvailability(value))}
                        />
                    </div>
                );
            case pathNames.Purchased:
                return (
                    <div className="sidebar-additional-blocks__item sidebar-additional-blocks__item--favorites-filters">
                        <ProductsFilters
                            totalPages={purchasedTotalPages}
                            categories={purchasedCategories}
                            filters={purchasedFilters}
                            isLoading={purchasedIsLoading}
                            handleResetFilters={() => dispatch(resetPurchasedFilters())}
                            handleCategoryChange={(e, id) => handlePurchasedCategoryChange(e, id)}
                            handleAvailabilityFilter={(value) => dispatch(setPurchasedFiltersAvailability(value))}
                        />
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <section className="sidebar-additional-blocks">
            {renderBlocks()}
        </section>
    );
};

export default SidebarAdditionalBlocks;