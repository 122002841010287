import { useDispatch, useSelector } from 'react-redux';
import { replacePhone } from '../../store/actions/replacement-phone'
import { IoCloseOutline } from 'react-icons/io5';
import { Button, Input } from "../UI";
import { useInput } from "../../hooks/useInput";
import './modal-replacement-phone.scss';

const ModalReplacementPhone = ({closeHandler, openConfirmHandler}) => {
    const dispatch = useDispatch();
    const { state: newPhone, handleChange: changeNewPhone, clearInput } = useInput('');
    const { errors } = useSelector(state => state.replacementPhone);

    const onFormClick = (event) => event.stopPropagation();

    const submitModalForm = (event) => {
        event.preventDefault();
        dispatch(replacePhone(newPhone))
            .then(() => {
                closeHandler();
                openConfirmHandler();
            })
            .catch(() => clearInput())
    }

    return (
        <form className="modal-replacement-phone" onSubmit={submitModalForm} onClick={onFormClick}>
            <div className="modal-replacement-phone__inner-block">
                <div className="modal-replacement-phone__close-icon" onClick={closeHandler}>
                    <IoCloseOutline size="40"/>
                </div>
                <div className="modal-replacement-phone__title">Введите новый номер телефона</div>
                <div className="input-wrapp">
                    <Input
                        type="tel"
                        label="Номер телефона"
                        name="phone"
                        placeholder="Телефон"
                        value={newPhone}
                        handleChange={changeNewPhone}
                        error={(errors && errors['phone']) ? errors['phone'] : errors}
                        required
                    />
                </div>
                <div className="modal-replacement-phone__submit-btn">
                    <Button className="submit-btn" type="submit">Далее</Button>
                </div>
            </div>
        </form>
    )
}

export default ModalReplacementPhone;