import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageTitle, BlockTitle, FormKid, FormPersonal, KidCardQuestionnaire } from '../../components';
import { getKids } from '../../store/actions/kids';
import { sendQuestionnaire } from '../../store/actions/user';
import { ReactSVG } from 'react-svg';
import BabyIcon from '../../assets/svg/baby.svg';
import './questionnaire.scss';

const Questionnaire = ({ title }) => {
    const { items: kids, max_amount_children, loaded } = useSelector(state => state.kids);
    const [isOpenKidForm, setIsOpenKidForm] = useState(false);
    const [currentKid, setCurrentKid] = useState(null);
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded) {
            dispatch(getKids());
        }
    }, []);

    useEffect(() => {
        if (!isOpenKidForm) {
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            });
        }
    }, [step, isOpenKidForm]);

    const goToNextStep = () => setStep(step => step + 1);

    const openEditingFormKid = kidData => {
        setCurrentKid(kidData);
        setIsOpenKidForm(true);
    }

    const openAddingFormKid = () => setIsOpenKidForm(true);

    const closeFormKid = () => {
        setCurrentKid(null);
        setIsOpenKidForm(false);
    }

    const confirmData = () => dispatch(sendQuestionnaire());

    return (
        <>
            <PageTitle title={title} />
            <section className="page questionnaire-page">
                <div className="questionnaire">
                    <div className="questionnaire__header">
                        <BlockTitle
                            title='Анкета участника программы лояльности "Нужный спорт"'
                            description="Дорогие друзья! Мы просим вас заполнить эту анкету для реализации нашей цели, а именно: помочь юным
                                        спортсменам и тренерам спортивных секций достигать высоких результатов. Помочь детям стать
                                        Чемпионами, а стране - мировым лидером, недосягаемым для других государств. Мы уверены, что нам по
                                        пути!"
                        />
                    </div>
                    <div className="questionnaire__body questionnaire-step">
                        {step === 1 &&
                            <>
                                <h2 className="questionnaire-step__title">Шаг 1. Персональная информация</h2>
                                <FormPersonal onSubmit={goToNextStep}>
                                    <div className="questionnaire-button-wrapper">
                                        <Button className="button__red questionnaire-step__button" type="submit">{`Далее (Шаг ${step + 1})`}</Button>
                                    </div>
                                </FormPersonal>
                            </>
                        }

                        {step === 2 && !isOpenKidForm &&
                            <>
                                <h2 className="questionnaire-step__title">Шаг 2. Информация о детях</h2>
                                <div className="questionnaire-step__kids">
                                    {kids.map(kidInfo => (
                                        <KidCardQuestionnaire kid={kidInfo} openFormKid={openEditingFormKid} key={kidInfo.id}/>
                                    ))}

                                    {kids.length < max_amount_children &&
                                        <div className="kid-add-card-questionnaire" onClick={openAddingFormKid}>
                                            <i className="kid-add-card-questionnaire__button-icon">
                                                <ReactSVG src={BabyIcon} />
                                            </i>
                                            <span className="kid-add-card-questionnaire__button-text">+ Добавить ребёнка</span>
                                        </div>
                                    }

                                </div>
                                <div className="questionnaire-button-kid-wrapper">
                                    <Button className="button__red questionnaire-step__button" handleClick={goToNextStep}>Далее</Button>
                                </div>
                            </>
                        }

                        {step === 2 && isOpenKidForm &&
                            <>
                               <h2 className="questionnaire-step__title">Шаг 2. Информация о ребёнке</h2>
                               <FormKid kid={currentKid} onSubmit={closeFormKid}>
                                   <div className="questionnaire-button-wrapper">
                                       <Button className="button__red questionnaire-step__button" type="submit">Сохранить</Button>
                                       <Button className="button__black questionnaire-step__button" handleClick={closeFormKid}>Отменить</Button>
                                   </div>
                               </FormKid>
                            </>
                        }

                        {step === 3 &&
                            <>
                                <h2 className="questionnaire-step__title">Анкета успешно заполнена</h2>
                                <div className="questionnaire-button-wrapper">
                                    <Button className="button__red" handleClick={confirmData}>Сохранить</Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default Questionnaire;