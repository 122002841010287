const addressesTypes = {
    SET_ADDRESSES: 'ADDRESSES/SET_ADDRESSES',
    SET_TOTAL_PAGES: 'ADDRESSES/SET_TOTAL_PAGES',
    SET_PAGE_ELEMENT_COUNT: 'ADDRESSES/SET_PAGE_ELEMENT_COUNT',
    SET_ADDRESSES_TOTAL_COUNT: 'ADDRESSES/SET_ADDRESSES_TOTAL_COUNT',
    SET_ADDRESS: 'ADDRESSES/SET_ADDRESS',
    SET_LOADED: 'ADDRESSES/SET_LOADED',
    SET_ERRORS: 'ADDRESSES/SET_ERRORS'
};

export default addressesTypes;