import { PageTitle, BlockTitle, NotificationsSection, CustomLink, Delimiter } from '../../components';
import { useSelector } from 'react-redux';
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';
import getNotificationsTitles from '../../utils/getNotificationsTitles';
import './notifications.scss';

const Notifications = ({ title }) => {
    const { settings, isLoaded } = useSelector(state => state.notifications)
    const { personal_mobil, email } = useSelector(state => state.user.registerData);
    const isEmailConfirmed = useSelector(state => state.user.registerData.fields.find(field => field.type === 'email').value.is_confirmed);

    if (!isLoaded) {
        return '';
    }

    return (
        <>
            <PageTitle title={title} />
            <section className="page notifications-page">
                <div className="notifications-page__header">
                    <BlockTitle
                        title={title}
                        description="Здесь вы можете настроить уведомления."
                    />
                    <div className="notifications-personal notifications-page__header-settings">
                        <div className="notifications-personal__item">
                            <div className="notifications-personal__subtitle">Телефон</div>
                            <div className="notifications-personal__value">{personal_mobil}</div>
                        </div>
                        <div className="notifications-personal__item">
                            <div className="notifications-personal__subtitle">Почта (E-mail)</div>
                            <div className="notifications-personal__value">
                                {email}
                                <i data-tip="React-tooltip">
                                    {isEmailConfirmed ? <IoCheckmarkCircle size="24px" fill="#2787F5" /> : <IoCloseCircle size="24px" fill="#D32A29" />}
                                </i>
                                <ReactTooltip place="right" type="dark" effect="solid">{isEmailConfirmed ? 'Подтверждена' : 'Не подтверждена'}</ReactTooltip>
                            </div>
                        </div>
                    </div>
                    <CustomLink className="custom-link__blue" to="/profile">Изменить данные</CustomLink>
                </div>

                <Delimiter />

                {isLoaded && Object.keys(settings).length > 0 &&
                    <>
                        <div className="notifications-page__body">
                            {Object.keys(settings).map(type => {
                                const { title, description } = getNotificationsTitles(type);
                                return (
                                    <NotificationsSection
                                        type={type}
                                        settings={settings[type]}
                                        title={title}
                                        description={description}
                                        key={type}
                                    />
                                )
                            })}

                            {!isEmailConfirmed &&
                                <div className="notifications-page__email-remember">Для получения уведомлений на электронную почту Вам нужно подтвердить свой Email</div>
                            }
                        </div>
                    </>
                }
            </section>
        </>
    );
};

export default Notifications;