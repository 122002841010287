import replacementPhoneTypes from '../types/replacement-phone';

export const setError = payload => ({
    type: replacementPhoneTypes.SET_ERRORS,
    payload,
});

export const setNewPhone = payload => ({
    type: replacementPhoneTypes.SET_NEW_PHONE,
    payload
});

export const setExistTask = payload => ({
   type: replacementPhoneTypes.SET_EXIST_TASK,
   payload
});

export const setDaysLeft = payload => ({
    type: replacementPhoneTypes.SET_DAYS_LEFT,
    payload
});

export const setSmsSentToChangePhone = payload => ({
    type: replacementPhoneTypes.SET_SMS_SENT_TO_CHANGE_PHONE,
    payload
});

export const setSmsSentToCancelChangePhone = payload => ({
    type: replacementPhoneTypes.SET_SMS_SENT_TO_CANCEL_CHANGE_PHONE,
    payload
});

export const setSenderPhone = payload => ({
    type: replacementPhoneTypes.SET_SENDER_PHONE,
    payload
});

export const setTypeDispatch = payload => ({
    type: replacementPhoneTypes.SET_TYPE_DISPATCH,
    payload
});

export const setTimer = payload => ({
    type: replacementPhoneTypes.SET_TIMER,
    payload
});