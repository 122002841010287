import moment from 'moment';
import 'moment/locale/ru';

const getAgeFromBirthday = (birthday) => {
    let age = Number(moment().diff(birthday, 'years',false));

    if (age === 0) {
        return 'Менее года';
    }

    if (age === 1) {
        return `${age} год`;
    }

    if (age > 1 && age < 5) {
        return `${age} года`;
    }

    if (age >= 5) {
        const lastNumber = +age.toString().slice(-1);

        if (age > 20 && lastNumber === 1){
            return `${age} год`;
        }

        if (age > 20 && lastNumber < 5){
            return `${age} года`;
        }

        return `${age} лет`;
    }
};

export default getAgeFromBirthday;