import './conditions.scss';
import {BlockTitle} from "../index";

const Conditions = ({ children }) => {
    return (
        <section className="conditions">
            <div className="conditions__title">
                <BlockTitle
                    title="Программа лояльности «Нужный спорт»"
                />
            </div>
            <p className="conditions__text">
                {children}
            </p>
        </section>
    );
};

export default Conditions;