import { useSelector } from 'react-redux';
import {
    PageTitle,
    CardOrBanner,
    ActiveOrders,
    Delimiter,
    InviteBanner,
    BlockTitle,
    ProductsForReview
} from '../../components';
import './main.scss';
import {Redirect} from "react-router-dom";
import StorageCache from "../../service/storageCache";

const Main = ({ title }) => {
    const {
        questionnaireCompleted: isQuestionnaireCompleted,
        trainerQuestionnaireCompleted: isTrainerQuestionnaireCompleted
    } = useSelector(({ user }) => user);
    const cache = new StorageCache();


    if (cache.get('referrer') === 'order') {
        if (!isQuestionnaireCompleted) {
            window.localStorage.setItem('redirectToAnketaPage', 'true');
            return <Redirect to='/anketa'/>
        }
        if (isQuestionnaireCompleted) {
            let redirectUrl = cache.get('redirect_url');
            if (redirectUrl) {
                cache.unset('redirect_url');
                window.location.href = redirectUrl;
            }
        }
    }


    return (
        <>
            <PageTitle title={title} />
            <section className="page main-page">
                {!isQuestionnaireCompleted && !isTrainerQuestionnaireCompleted && (
                    <BlockTitle
                        title="Главная"
                        styles={{
                            marginBottom: '1.8rem'
                        }}
                    />
                )}

                <CardOrBanner />

                {!isQuestionnaireCompleted && !isTrainerQuestionnaireCompleted && (
                    <InviteBanner
                        title="Доступ к кабинету тренера"
                        description="Уважаемый тренер! Мы просим Вас заполнить анкету для получения доступа к Вашему личному кабинету и реализации нашей общей цели: помочь подрастающему поколению сформироваться физически и духовно здоровыми людьми через вовлечение в занятия различными видами спорта, помочь детям стать чемпионами, а стране – мировым лидером, недосягаемым для других государств. Мы уверены, что нам по пути!"
                        link="/trainer-registration"
                        linkText="Заполнить анкету тренера"
                        theme="dark"
                        styles={{
                            marginTop: '1.8rem'
                        }}
                    />
                )}

                <ProductsForReview delimiter="top" />

                <Delimiter />
                <ActiveOrders />
            </section>
        </>
    );
};

export default Main;