import { CustomLink } from '../index';
import "./user-agreements.scss";

const UserAgreements = ({location = "footer"}) => {
    const userAgreements = [
        {id: "1649056444508", label: "Политика конфиденциальности", link: "/privacy-policy"},
        {id: "1649056460730", label: "Договор-оферта", link: "/agreement-offer"},
        {id: "1649056470537", label: "Программа лояльности для физических лиц", link: "/loyalty-program"},
    ]

    const renderUserAgreementsList = () => {
        return userAgreements.map(({id, label, link}) => (
            <li className="user-agreements__documents-item" key={id}>
                <CustomLink
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="user-agreements__documents-item-link"
                >
                    {label}
                </CustomLink>
            </li>
        ));
    }

    const renderUserAgreementsListSideBar = () => {
        return userAgreements.map(({id, label, link}) => (
            <li className="user-agreements__documents-item--side-bar" key={id}>
                <CustomLink
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="user-agreements__documents-item-link sidebar__menu-item-text"
                >
                    {label}
                </CustomLink>
            </li>
        ));
    }


    if(location === "footer") {
        return (
            <div className="user-agreements d-lg-block d-none">
                <div className="container">
                    <div className="row">
                        <ul className="user-agreements__documents-list">
                            { renderUserAgreementsList() }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section className="sidebar__menu d-lg-none d-block">
            <div className="menu-groups">
                <ul className="user-agreements__documents-list--side-bar">
                    { renderUserAgreementsListSideBar() }
                </ul>
            </div>
        </section>
    )
};

export default UserAgreements;
