import productsForReviewTypes from '../types/products-for-review';

const initialState = {
    products: [],
    isLoading: false,
    isLoaded: false,
    errors: null
};

export const productsForReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case productsForReviewTypes.SET_PRODUCTS:
            return {...state, products: action.payload};
        case productsForReviewTypes.SET_IS_LOADING:
            return {...state, isLoading: action.payload};
        case productsForReviewTypes.SET_IS_LOADED:
            return {...state, isLoaded: action.payload};
        case productsForReviewTypes.SET_ERRORS:
            return {...state, errors: action.payload};
        default:
            return state;
    }
};