import { ReactSVG } from 'react-svg';
import loader from '../../assets/svg/min-loader.svg';
import './loader.scss';

const Loader = ({ className }) => {
    return (
        <div className={`loader ${className}`}>
            <ReactSVG className="loader__icon" src={loader} />
        </div>
    );
};

export default Loader;