import { NavLink } from 'react-router-dom';
import {
    IoPodiumOutline,
    IoReaderOutline
} from 'react-icons/io5';
import './trainer-menu.scss';

const TrainerMenu = ({ access }) => {
    return (
        <div className={`menu-groups ${access ? '' : 'menu-groups--lock'}`}>
            <div className="menu-group">
                <div className="menu-group__title">Кабинет тренера</div>
                <div className="menu-group__item">
                    <NavLink to="/trainer" className="sidebar__menu-item" exact>
                        <i className="sidebar__menu-item-icon">
                            <IoPodiumOutline size="24px" stroke="#92929D" />
                        </i>
                        <span className="sidebar__menu-item-text">Статистика</span>
                    </NavLink>
                </div>
                <div className="menu-group__item">
                    <NavLink to="/trainer/rules" className="sidebar__menu-item" exact>
                        <i className="sidebar__menu-item-icon">
                            <IoReaderOutline size="24px" stroke="#92929D" />
                        </i>
                        <span className="sidebar__menu-item-text">Правила и условия</span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default TrainerMenu;