import kidsTypes from '../types/kids';

const initialState = {
    items: [],
    max_amount_children: 0,
    loaded: false,
    errors: null
};

export const kidsReducer = (state = initialState, action) => {
    switch(action.type) {
        case kidsTypes.SET_ITEMS:
            return {...state, items: action.payload}
        case kidsTypes.SET_MAX_KIDS:
            return {...state, max_amount_children: action.payload}
        case kidsTypes.SET_LOADED:
            return {...state, loaded: action.payload}
        case kidsTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}