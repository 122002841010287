import apiClient from '../../service/axios';
import { setLoading } from '../action-creators/loading';
import { setCard } from '../action-creators/card';

export const getCard = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiClient.get('/user/card');
        const responseJson = response.data;
        dispatch(setCard(responseJson));
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}