import userTypes from '../types/user';

export const setRegisterData = payload => ({
    type: userTypes.SET_REGISTER_DATA,
    payload,
});

export const setRegisterDataErrors = payload => ({
    type: userTypes.SET_REGISTER_DATA_ERRORS,
    payload,
});

export const setCustomerData = payload => ({
    type: userTypes.SET_CUSTOMER_DATA,
    payload,
});

export const setCustomerDataErrors = payload => ({
    type: userTypes.SET_CUSTOMER_DATA_ERRORS,
    payload,
});

export const setParticipation = payload => ({
    type: userTypes.SET_PARTICIPATION,
    payload,
});

export const setQuestionnaireCompleted = payload => ({
    type: userTypes.SET_QUESTIONNAIRE_COMPLETED,
    payload,
});

export const setTrainerParticipation = payload => ({
    type: userTypes.SET_TRAINER_PARTICIPATION,
    payload,
});

export const setTrainerQuestionnaireCompleted = payload => ({
    type: userTypes.SET_TRAINER_QUESTIONNAIRE_COMPLETED,
    payload,
});

export const setErrors = payload => ({
    type: userTypes.SET_ERRORS,
    payload,
});