import getNormalDate from '../../utils/getNormalDate';
import { ReplacementPhone, ReplacementEmail } from '../../components';
import isEmptyObject from '../../utils/isEmptyObject';
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

const notViewTypes = ['id', 'date_day', 'date_month'];

const ProfileDataItems = (
        {
            items,
            phoneReplacement,
            openReplacementPhoneModalHandler,
            openConfirmReplacementPhoneModalHandler,
            openConfirmCancelReplacementPhoneModalHandler,
            openReplacementEmailModalHandler,
            openConfirmReplacementEmailModalHandler,
            kid
        }
    ) => {
    let kidIsOver14;
    let date;
    if (kid) {
        kidIsOver14 = items.find(i => i.name === 'over_14').value;

        if (!kidIsOver14){
            date = `${items.find(i => i.name === 'personal_birthday_year').value}-${items.find(i => i.name === 'personal_birthday_month').value}-${items.find(i => i.name === 'personal_birthday_day').value}`;
        }
    }

    const renderDataValue = item => {
        if (item.type === 'id') return;
        if (typeof item.value === 'string' || typeof item.value === 'number' || typeof item.value === 'boolean') {
            if (!item.value) return <span>-</span>;
        } else {
            if (item.value.length === undefined) {
                if (!item.value.value) return <span>-</span>;
            } else {
                if (!item.value.length) return <span>-</span>;
            }
        }

        if (kid) {
            if (kidIsOver14) {
                switch (item.type) {
                    case 'date_year':
                        return (`${item.value} г.`);
                }
            } else {
                if (date) {
                    switch (item.type) {
                        case 'date_year':
                            return (getNormalDate(date));
                    }
                }
            }
        }

        switch (item.type) {
            case 'text':
                if(item.name === 'personal_mobil') {
                    return (
                        <>
                            {item.value}
                            {
                                !isEmptyObject(phoneReplacement) &&
                                (
                                    <ReplacementPhone
                                        phoneReplacement={phoneReplacement}
                                        openReplacementPhoneModalHandler={openReplacementPhoneModalHandler}
                                        openConfirmReplacementPhoneModalHandler={openConfirmReplacementPhoneModalHandler}
                                        openConfirmCancelReplacementPhoneModalHandler={openConfirmCancelReplacementPhoneModalHandler}
                                    />
                                )
                            }
                        </>
                    );
                }
            case 'number':
            case 'tel':
                return (item.value);
            case 'email':
                return (
                    <>
                        {item.value.value}
                        <div className="profile-data__item-value--icon">
                            <i data-tip="React-tooltip">
                                {item.value.is_confirmed ? <IoCheckmarkCircle size="24px" fill="#2787F5" /> : <IoCloseCircle size="24px" fill="#D32A29" />}
                            </i>
                            <ReactTooltip place="right" type="dark" effect="solid">{item.value.is_confirmed ? 'Подтверждена' : 'Не подтверждена'}</ReactTooltip>
                        </div>
                        <ReplacementEmail
                            openReplacementEmailModalHandler={openReplacementEmailModalHandler}
                            openConfirmReplacementEmailModalHandler={openConfirmReplacementEmailModalHandler}
                        />
                    </>
                );
            case 'date':
                return (getNormalDate(item.value));
            case 'radio':
            case 'select':
                return (item.value.label);
            case 'multi-select':
                return (item.value.map(el => el.label).join(', '));
            case 'city_address':
            case 'full_address':
                return (item.value.value);
            default:
                return;
        }
    }

    return (
        <div className="profile-data__items">
            {items && items.map((item, index) =>
                (
                    (!notViewTypes.includes(item.type) && item.title) &&
                    <div className="profile-data__item" key={`${item.name}-${index}`}>
                        <div className="profile-data__item-title">{(item.type === 'date_year' && !kidIsOver14) ? 'Дата рождения' : item.title}</div>
                        <div className="profile-data__item-value">{renderDataValue(item)}</div>
                    </div>
                )
            )}
        </div>
    );
};

export default ProfileDataItems;