import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateAddressData } from '../../store/actions/addresses';
import { Input, DadataInput, Textarea, Button } from '../UI';
import { IoCloseOutline } from 'react-icons/io5';
import './modal-edit-address.scss';

const ModalEditAddress = ({closeHandler}) => {
    const dispatch = useDispatch();
    const { address, errors } = useSelector(state => state.address);

    const [updateAddress, setUpdateAddress] = useState({...address});

    const handleChange = (event) => setUpdateAddress({...updateAddress, [event.target.name]: event.target.value});

    const onFormClick = (event) => event.stopPropagation();

    const submitModalForm = (event) => {
        event.preventDefault();
        const updatedAddressData = {...updateAddress}
        dispatch(updateAddressData(updatedAddressData));
        closeHandler();
    }

    return (
        <form className="modal-edit-address" onSubmit={submitModalForm} onClick={onFormClick}>
            <div className="modal-edit-address__inner-block">
                <div className="modal-edit-address__close-icon" onClick={closeHandler}>
                    <IoCloseOutline size="40"/>
                </div>
                <div className="modal-edit-address__title">Редактирование адреса доставки</div>
                <DadataInput
                    label="Адрес"
                    name="address"
                    required
                    placeholder="Введите название в свободной форме, адрес"
                    defaultQuery={updateAddress.address.address_user}
                    handleChange={payload => handleChange({
                        target: {
                            name: payload.target.name,
                            value: {
                                address_user: payload.target.value.value,
                                dadata_fias: payload.target.value.data.fias_code,
                                dadata_kladr: payload.target.value.data.kladr_id,
                                geo_lat: payload.target.value.data.geo_lat,
                                geo_lon: payload.target.value.data.geo_lon,
                                zip: payload.target.value.data.postal_code
                            }
                        }
                    })}
                    filterToBound="houses"
                    filterFromBound="house"
                    error={(errors && errors['address']) ? errors['address'] : ''}
                    helpText="Введите полный адрес (до дома или квартиры) и выберете значение из выпадающего списка!"
                />
                <div className="modal-edit-address__additional-address-data">
                    <div className="input-wrapp">
                        <Input
                            type="number"
                            label="Подъезд"
                            name="entrance"
                            placeholder="Подъезд"
                            value={updateAddress.entrance}
                            handleChange={handleChange}
                            error={(errors && errors['entrance']) ? errors['entrance'] : ''}
                        />
                    </div>
                    <div className="input-wrapp">
                        <Input
                            type="number"
                            label="Этаж"
                            name="floor"
                            placeholder="Этаж"
                            value={updateAddress.floor}
                            handleChange={handleChange}
                            error={(errors && errors['floor']) ? errors['floor'] : ''}
                        />
                    </div>
                    <div className="input-wrapp">
                        <Input
                            type="textarea"
                            label="Кв./Офис"
                            name="apartment"
                            placeholder="Квартира"
                            value={updateAddress.apartment}
                            handleChange={handleChange}
                            error={(errors && errors['apartment']) ? errors['apartment'] : ''}
                        />
                    </div>
                </div>
                <Textarea
                    rows="4"
                    type="textarea"
                    label="Комментарий для курьера"
                    name="comment_courier"
                    placeholder="Комментарий"
                    value={updateAddress.comment_courier}
                    handleChange={handleChange}
                    error={(errors && errors['comment_courier']) ? errors['comment_courier'] : ''}
                />
                <div className="modal-edit-address__submit-btn">
                    <Button className="submit-btn" type="submit">Сохранить</Button>
                </div>
            </div>
        </form>
    )
}

export default ModalEditAddress;