import { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { CustomSelect } from '../../../components';
import { months } from '../../../const';
import { setFilterMonth, setIsLoadedTransactions, setPage } from '../../../store/action-creators/trainer';
import './trainer-history-filters.scss';

const TrainerHistoryFilters = ({ months: storeMonths, active }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        months: [],
        active: 0
    });

    useEffect(() => {
        if (storeMonths && active) {
            const nowDate = new Date();
            const year = nowDate.getFullYear();
            const options = storeMonths.map(item => {
                return {
                    label: `${months.find(m => item.number === m.number).label} ${year}`,
                    value: item.number
                };
            });
            const activeMonth = options.find(o => o.value === active);

            setState({
               months: options,
               active: activeMonth
            });
        }
    }, [storeMonths, active]);

    const handleChangeFilter = (e) => {
        dispatch(setIsLoadedTransactions(false));
        dispatch(setPage(1));
        dispatch(setFilterMonth(e.value));
    };

    if (storeMonths.length === 0) {
        return <></>;
    }

    return (
        <div className="trainer-history-filters">
            <CustomSelect
                className="custom-select"
                classNamePrefix="custom-select"
                options={state.months}
                value={state.active}
                isSearchable={false}
                onChange={(e) => handleChangeFilter(e)}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
            />
        </div>
    );
};

export default memo(TrainerHistoryFilters);