import recoveryTypes from '../types/recovery-password';

const initialState = {
    tokenSent: false,
    errorsStepEmail: null,
    errorsStepPassword: null,
    redirectToStepEmail: false,
    success: false,
};

export const recoveryPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case recoveryTypes.SET_TOKEN_SENT:
            return {...state, tokenSent: action.payload};
        case recoveryTypes.SET_ERRORS_STEP_EMAIL:
            return {...state, errorsStepEmail: action.payload};
        case recoveryTypes.SET_ERRORS_STEP_PASSWORD:
            return {...state, errorsStepPassword: action.payload};
        case recoveryTypes.SET_REDIRECT_TO_EMAIL_STEP:
            return {...state, redirectToStepEmail: action.payload};
        case recoveryTypes.SET_SUCCESS:
            return {...state, success: action.payload};
        default:
            return state;
    }
};