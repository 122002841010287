
const parseFiltersStatusForOrders = data => {
    const sample = {id: 'value', name: 'label'};
    const arrFilters = data.map(filter => (
        Object.assign(
            {},
            ...Object.keys(filter)
                .map(key => ({[sample[key]]: filter[key]}))
        )
    ));
    return arrFilters;
};

export default parseFiltersStatusForOrders;