import { useEffect, useRef, useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import './sort-panel.scss';

const SortPanel = ({ activeSort, sortItems, handleSortChange }) => {
    const [popupIsActive, setPopupIsActive] = useState(false);
    const sortPopupRef = useRef(null);

    const handleSortClick = (value) => {
        if (value.name === activeSort.name) return false;
        setPopupIsActive(false);
        handleSortChange(value);
    };

    const handleOutsideClick = (e) => {
        if (e.path && sortPopupRef !== null && !e.path.includes(sortPopupRef.current)) {
            setPopupIsActive(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', handleOutsideClick);

        return () => document.body.removeEventListener('click', handleOutsideClick);
    }, []);

    return (
        <div className="sort-panel" ref={sortPopupRef}>
            <button type="button" className="sort-panel__button" onClick={() => setPopupIsActive(!popupIsActive)}>
                <span className="sort-panel__button-text">{activeSort.title}</span>
                <i className="sort-panel__button-icon">
                    {popupIsActive ? <IoChevronUpOutline side="14px" /> : <IoChevronDownOutline side="14px" />}
                </i>
            </button>
            <div className={`sort-panel__popup ${popupIsActive ? 'active' : ''}`}>
                {sortItems.map((item) => (
                    <button
                        key={item.name}
                        type="button"
                        className={`sort-panel__popup-item ${item.name === activeSort.name ? 'active' : ''}`}
                        onClick={() => handleSortClick({
                            name: item.name,
                            title: item.title,
                            by: item.value.by,
                            order: item.value.order
                        })}
                    >
                        {item.title}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SortPanel;