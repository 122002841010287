import apiClient from '../../service/axios';
import {
    setItems,
    setTotalPages,
    setError,
    setIsLoading,
    setCategories,
    setPage,
    setFiltersCategories
} from '../action-creators/favorites';
import  { setLoading } from '../action-creators/loading';

const ITEMS_COUNT_IN_PAGE = 20;

export const getFavorites = page => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const { sort, filters, categories } = getState().favorites;
    const dataToSend = {
        page: page,
        limit: ITEMS_COUNT_IN_PAGE,
        sort: sort.by,
        sort_order: sort.order,
        filter_available: filters.availability.value,
        filter_section: filters.categories
    };
    try {
        const { data } = await apiClient.post('/favorite-products/get-all-favorite', JSON.stringify(dataToSend));
        if (data.type !== 'ERROR') {
            dispatch(setItems(data.products));
            dispatch(setPage(page));

            if (data.count === 0 && categories.length === 0) {
                dispatch(setTotalPages(null));
            } else if (data.count === 0 && categories.length > 0) {
                dispatch(setTotalPages(0));
            } else {
                dispatch(setTotalPages(Math.ceil(Number(data.count) / ITEMS_COUNT_IN_PAGE)));
            }
        } else {
            dispatch(setError(data.errors));
        }
    } catch (e) {
        console.error(e);
        dispatch(setError('Не удалось загрузить избранные товары'));
    }
    dispatch(setIsLoading(false));
};

export const getCategories = () => async dispatch => {
    try {
        const { data } = await apiClient.post('/favorite-products/get-sections');
        if (data.type !== 'ERROR') {
            dispatch(setCategories(data.sections));
        }
    } catch (e) {
        console.error(e);
    }
};

export const removeFromFavorites = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
        const dataToSend = {
            product_id: id
        };
        const { data } = await apiClient.post('/favorite-products/remove-favorite', JSON.stringify(dataToSend));
        dispatch(setLoading(false));

        if (data.type === 'OK' && data.removed) {
            if (data.sections.length > 1) {
                const currentCategoryFilters = getState().favorites.filters.categories;
                const newCategoryFilters = data.sections.filter((section) => currentCategoryFilters.includes(section.ID));
                dispatch(setFiltersCategories(newCategoryFilters.map((c) => c.ID)));
            } else {
                dispatch(setFiltersCategories([]));
            }
            dispatch(setCategories(data.sections));

            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    } catch (e) {
        return Promise.reject();
    }
};