import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChildDirectories } from '../../store/actions/directories';
import { Input, CustomSelect, RadioGroup, DadataInput, CustomDateInput, CustomCheckboxRegisterForm } from '../../components';
import { createKidData, editKidData } from '../../store/actions/kids';
import { setErrors } from '../../store/action-creators/kids';
import getDataToSend from '../../utils/getDataToSend';
import { getDay, getMonth, getYear } from '../../utils/getNormalDateElement';
import { days, months } from '../../const';
import './formKid.scss';

const FormKid = ({ children: button, kid, onSubmit }) => {
    const directories = useSelector(state => state.directories.childDirectories);
    const errors = useSelector(state => state.kids.errors);
    const dispatch = useDispatch();
    const currentKidId = useRef(null);
    const [form, setForm] = useState({
        id: '',
        full_name: '',
        personal_gender: {},
        personal_birthday_day: '00',
        personal_birthday_month: '00',
        personal_birthday_year: '0000',
        sport_sections: [],
        trainer_name: '',
        sport_section_address: '',
        sport_level: '',
        competition_activity: '',
    });
    const [isConsent, setIsConsent] = useState(true);

    useEffect(() => {
        dispatch(setErrors(null))
        if (!directories) {
            dispatch(getChildDirectories());
        }
    }, [])

    useEffect(() => {
        if (kid && kid.id !== currentKidId.current) {
            setForm({
                id: kid.id,
                full_name: kid.full_name,
                personal_gender: kid.fields.find(item => item.name === 'personal_gender').value,
                personal_birthday_day: kid.personal_birthday_day,
                personal_birthday_month: kid.personal_birthday_month,
                personal_birthday_year: kid.personal_birthday_year,
                sport_sections: kid.fields.find(item => item.name === 'sport_sections').value,
                trainer_name: kid.trainer_name,
                sport_section_address: kid.fields.find(item => item.name === 'sport_section_address').value,
                sport_level: kid.fields.find(item => item.name === 'sport_level').value,
                competition_activity: kid.fields.find(item => item.name === 'competition_activity').value
            })
            currentKidId.current = kid.id;
        }
    }, [kid])

    useEffect(() => {
        if (directories && !kid) {
            setForm({
                ...form,
                sport_level: directories.level[0],
                competition_activity: directories.competition[0]
            });
        }
    }, [directories, kid])

    const handleChange = e => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleBirthdayChange = (e, custom) => {
        let date = {
            day: '',
            month: '',
            year: '',
        };

        if (custom) {
            const dateArr = e.target.value.split('-');
            date = {
                day: dateArr[2],
                month: dateArr[1],
                year: dateArr[0],
            };
        } else {
            date = {
                day: getDay(e.target.value),
                month: getMonth(e.target.value),
                year: getYear(e.target.value),
            };
        }

        setForm(state => {
            return {
                ...state,
                personal_birthday_day: date.day,
                personal_birthday_month: date.month,
                personal_birthday_year: date.year
            }
        });
    }

    const confirmData = e => {
        e.preventDefault();
        const data = {...form, consent: isConsent};
        const dataToSend = getDataToSend(data);
        if (kid) {
            dispatch(editKidData(dataToSend))
                .then(() => onSubmit())
                .catch(() => {});
        } else {
            dispatch(createKidData(dataToSend))
                .then(() => onSubmit())
                .catch(() => {});
        }
    }

    const renderDateInputs = () => {
        if (kid && kid.over_14) {
            return (
                <div className="form-kid__group">
                    <div className="form-kid__group-inputs">
                        <CustomSelect
                            defaultValue={days.find(day => day.value === form.personal_birthday_day)}
                            name="personal_birthday_day"
                            options={days}
                            className="custom-select"
                            classNamePrefix="custom-select"
                            onChange={value => handleChange({target: {name: 'personal_birthday_day', value}})}
                            placeholder="День"
                            noOptionsMessage={() => 'Выбраны все варианты'}
                            label="День рождения"
                            error={(errors && errors['personal_birthday_day']) ? errors['personal_birthday_day'] : ''}
                            required={false}
                        />
                        <CustomSelect
                            defaultValue={months.find(month => month.value === form.personal_birthday_month)}
                            name="personal_birthday_month"
                            options={months}
                            className="custom-select"
                            classNamePrefix="custom-select"
                            onChange={value => handleChange({target: {name: 'personal_birthday_month', value}})}
                            placeholder="Месяц"
                            noOptionsMessage={() => 'Выбраны все варианты'}
                            label="Месяц рождения"
                            error={(errors && errors['personal_birthday_month']) ? errors['personal_birthday_month'] : ''}
                            required={false}
                        />
                        <Input
                            type="number"
                            label="Год рождения"
                            name="personal_birthday_year"
                            placeholder="Год рождения"
                            value={Number(form.personal_birthday_year) > 0 && form.personal_birthday_year}
                            handleChange={handleChange}
                            required
                            error={(errors && errors['personal_birthday_year']) ? errors['personal_birthday_year'] : ''}
                        />
                    </div>
                    <div className="help-text" style={{marginTop: '0'}}>Если ваш ребёнок старше 14 лет, то мы сохраним только год его рождения!</div>
                </div>
            );
        } else {
            return (
                <CustomDateInput
                    label="Дата рождения"
                    name="personal_birthday"
                    value={`${form.personal_birthday_year}-${form.personal_birthday_month}-${form.personal_birthday_day}`}
                    handleChange={handleBirthdayChange}
                    required
                    error={(errors && (errors['personal_birthday_day'] || errors['personal_birthday_month'] || errors['personal_birthday_year'])) ? 'Некорректная дата' : ''}
                    helpText="Если ваш ребёнок старше 14 лет, то мы сохраним только год его рождения!"
                />
            );
        }
    }

    if ((kid && currentKidId.current !== kid.id) || !directories) {
        return <div>Загрузка формы...</div>
    }

    return (
        <form className="form-kid" onSubmit={confirmData}>
            <Input
                autoFocus
                type="text"
                label="ФИО"
                name="full_name"
                placeholder="Фамилия Имя Отчество"
                value={form.full_name}
                handleChange={handleChange}
                required
                error={(errors && errors['full_name']) ? errors['full_name'] : ''}
            />
            <RadioGroup
                defaultValue={form.personal_gender.value}
                items={directories.sex}
                name="personal_gender"
                label="Пол"
                onChange={handleChange}
                required
                error={(errors && errors['personal_gender']) ? errors['personal_gender'] : ''}
            />

            {renderDateInputs()}

            <CustomSelect
                defaultValue={form.sport_sections}
                name="sport_sections"
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                isMulti
                options={directories.sport}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => handleChange({target: {name: 'sport_sections', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Спортивные секции"
                required
                error={(errors && errors['sport_sections']) ? errors['sport_sections'] : ''}
            />
            <Input
                type="text"
                label="Фамилия тренера"
                name="trainer_name"
                placeholder="Фамилия тренера"
                value={form.trainer_name}
                handleChange={handleChange}
                error={(errors && errors['trainer_name']) ? errors['trainer_name'] : ''}
            />
            <DadataInput
                label="Адрес секции"
                name="sport_section_address"
                placeholder="Адрес секции"
                defaultQuery={form.sport_section_address.value}
                handleChange={payload => handleChange({
                    target: {
                        name: payload.target.name,
                        value: {
                            value: payload.target.value.value,
                            address_code_fias: payload.target.value.data.fias_code,
                            address_code_kladr: payload.target.value.data.kladr_id
                        }
                    }
                })}
                filterFromBound="street"
                error={(errors && errors['sport_section_address']) ? errors['sport_section_address'] : ''}
            />
            <CustomSelect
                defaultValue={form.sport_level || directories.level[0]}
                name="sport_level"
                options={directories.level}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => handleChange({target: {name: 'sport_level', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Уровень"
                required
                error={(errors && errors['sport_level']) ? errors['sport_level'] : ''}
            />
            <CustomSelect
                defaultValue={form.competition_activity || directories.competition[0]}
                name="competition_activity"
                options={directories.competition}
                className="custom-select"
                classNamePrefix="custom-select"
                onChange={value => handleChange({target: {name: 'competition_activity', value}})}
                placeholder="Выберите"
                noOptionsMessage={() => 'Выбраны все варианты'}
                label="Участие в соревнованиях"
                required
                error={(errors && errors['competition_activity']) ? errors['competition_activity'] : ''}
            />
            <CustomCheckboxRegisterForm
                name="consent"
                label="Согласен на обработку персональных данных"
                link="/privacy-policy"
                state={isConsent}
                setState={setIsConsent}
                onChange={() => setIsConsent(state => !state)}
                required
                error={(errors && errors['consent']) ? errors['consent'] : ''}
            />
            <div className="required-text"><span className="required-mark">*</span> - обязательные для заполнения поля</div>

            {button}

        </form>
    );
};

export default FormKid;