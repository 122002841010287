import './empty-items.scss';

const EmptyItems = ({ icon, text, hideBorder }) => {
    return (
        <div className={`empty-items ${hideBorder ? 'empty-items--hide-border' : ''}`}>
            <i className="empty-items__icon">
                {icon({size: '80px', fill: '#D32A29', stroke: '#D32A29'})}
            </i>
            <h3 className="empty-items__text">{text}</h3>
        </div>
    );
};

export default EmptyItems;