import emailTypes from '../types/email';

const initialState = {
    isEmailConfirmed: null,
    loaded: false,
    requested: false,
    errors: null
}

export const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case emailTypes.SET_CONFIRMED_EMAIL:
            return {...state, isEmailConfirmed: action.payload}
        case emailTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}