import { setLoading } from '../action-creators/loading';
import { setErrors } from '../action-creators/trainer-register';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';

export const sendTrainerRegisterData = (dataForSend) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await apiClient.post('/trainer/register', JSON.stringify(dataForSend));

        if (data.type === 'OK') {
            dispatch(setErrors(null));
            localStorage.setItem('anketaSuccess', 'true')
            window.location = '/';
        } else {
            toast.info('Данные заполнены некорректно');
            dispatch(setErrors(data.errors));
        }
    } catch (e) {
        toast.error('Произошла ошибка. Перезагрузите страницу или попробуйте позже!');
        console.error(e);
    }
    dispatch(setLoading(false));
};