import apiClient from '../../service/axios';
import { setItems, setTotalPages, setError, setIsLoading, setCategories, setPage } from '../action-creators/purchased-products';
import { toast } from 'react-toastify';

const ITEMS_COUNT_IN_PAGE = 20;

export const getPurchasedProducts = (page) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const { sort, filters } = getState().purchasedProducts;
    const dataForSend = {
        page: page,
        limit: ITEMS_COUNT_IN_PAGE,
        sort: sort.by,
        sort_order: sort.order,
        filter_available: filters.availability.value,
        filter_section: filters.categories
    };
    try {
        const { data } = await apiClient.post('/purchased-products/get-all-products', JSON.stringify(dataForSend));
        if (data.type === 'OK') {
            dispatch(setItems(data.products));
            dispatch(setPage(page));
            if (+data.totalPages > 0) {
                dispatch(setTotalPages(data.totalPages));
            } else {
                dispatch(setTotalPages(null));
            }
        } else {
            dispatch(setError(data.errors));
        }
    } catch (e) {
        console.error(e);
        dispatch(setError('Не удалось загрузить купленные товары'));
    }
    dispatch(setIsLoading(false));
};

export const getCategories = () => async (dispatch) => {
    try {
        const { data } = await apiClient.get('/purchased-products/get-sections');
        if (data.type === 'OK') {
            dispatch(setCategories(data.sections));
        }
    } catch (e) {
        console.error(e);
    }
};

export const addToFavorites = (id) => async (dispatch, getState) => {
    try {
        const { data } = await apiClient.post('/favorite-products/add-favorite', JSON.stringify({
            product_id: id
        }));

        if (data.type === 'OK' && data.added) {
            const { items } = getState().purchasedProducts;
            const newItems = items.map((item) => {
                if (item.id === id) {
                    item.isFavorite = 'Y';
                }
                return item;
            });
            dispatch(setItems(newItems));
            toast.success('Товар добавлен в список избранного');
        } else {
            toast.error('Не удалось добавить товар в список избранного. Попробуйте позже');
        }
    } catch (e) {
        console.error(e);
        toast.error('Не удалось добавить товар в список избранного. Попробуйте позже');
    }
};

export const removeFromFavorites = (id) => async (dispatch, getState) => {
    try {
        const { data } = await apiClient.post('/favorite-products/remove-favorite', JSON.stringify({
            product_id: id
        }));

        if (data.type === 'OK' && data.removed) {
            const { items } = getState().purchasedProducts;
            const newItems = items.map((item) => {
                if (item.id === id) {
                    item.isFavorite = 'N';
                }
                return item;
            });
            dispatch(setItems(newItems));
            toast.success('Товар успешно удалён из списка избранного');
        } else {
            toast.error('Не удалось удалить товар из списка избранного. Попробуйте позже');
        }
    } catch (e) {
        console.error(e);
        toast.error('Не удалось удалить товар из списка избранного. Попробуйте позже');
    }
};