import setBackUrlParam from "./setBackUrlParam";
import setReferrerParam from "./setReferrerParam";

const setQueryParameters = searchParamsString => {
    const searchParams = new URLSearchParams(searchParamsString);

    setBackUrlParam(searchParams);
    setReferrerParam(searchParams);
}

export default setQueryParameters;