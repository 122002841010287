import kidsTypes from '../types/kids';

export const setItems = payload => ({
    type: kidsTypes.SET_ITEMS,
    payload,
});

export const setMaxAmountKids = payload => ({
    type: kidsTypes.SET_MAX_KIDS,
    payload,
});

export const setLoaded = payload => ({
    type: kidsTypes.SET_LOADED,
    payload,
});

export const setErrors = payload => ({
    type: kidsTypes.SET_ERRORS,
    payload,
});