import { IoTrashSharp } from "react-icons/io5";
import { getFormattedAddress } from '../../utils/getFormattedAddress';
import './card-address.scss';

const CardAddress = ({ item, openEditModalHandler, deleteAddressHandler }) => {
    const formattedAddress = getFormattedAddress(item);

     return (
        <button className="addresses__button card" onClick={openEditModalHandler}>
            <div className="card__trash-bin" onClick={deleteAddressHandler}>
                <IoTrashSharp size="16"/>
            </div>
            <div className="addresses__button-text card-text">
                {formattedAddress}
            </div>
        </button>
    )
}

export default CardAddress;