import classNames from 'classnames/bind';
import './switcher.scss';

const Switcher = ({ checked, onChange, name, text = '', labelPosition = 'right', helpText = '', }) => {
    return (
        <>
            <label className={classNames({
                'switcher-label': true,
                'switcher-label--top': labelPosition === 'top'
            })}>
                {labelPosition === 'top' && text && <span className="switcher-label__text">{text}</span>}

                <span className="switcher">
                    <input type="checkbox" name={name} className="switcher__input" onChange={onChange} checked={checked} />
                    <span className="switcher__slider"></span>
                </span>

                {labelPosition === 'right' && text && <span className="switcher-label__text">{text}</span>}
            </label>

            {helpText && <div className="help-text">{helpText}</div>}
        </>
    );
};

export default Switcher;