import favoritesTypes from '../types/favorites';

export const setItems = payload => ({
    type: favoritesTypes.SET_ITEMS,
    payload,
});

export const setPage = payload => ({
    type: favoritesTypes.SET_PAGE,
    payload,
});

export const setTotalPages = payload => ({
    type: favoritesTypes.SET_TOTAL_PAGES,
    payload,
});

export const setCategories = payload => ({
    type: favoritesTypes.SET_CATEGORIES,
    payload,
});

export const setSort = payload => ({
    type: favoritesTypes.SET_SORT,
    payload,
});

export const setFiltersCategories = payload => ({
    type: favoritesTypes.SET_FILTERS_CATEGORIES,
    payload,
});

export const setFiltersAvailability = payload => ({
    type: favoritesTypes.SET_FILTERS_AVAILABILITY,
    payload,
});

export const resetFilters = () => ({
    type: favoritesTypes.RESET_FILTERS
});

export const setIsLoading = payload => ({
    type: favoritesTypes.SET_IS_LOADING,
    payload,
});

export const setError = payload => ({
    type: favoritesTypes.SET_ERROR,
    payload,
});