const trainerTypes = {
    SET_REMAINDER: 'TRAINER/SET_REMAINDER',
    SET_REF_CODE: 'TRAINER/SET_REF_CODE',
    SET_TRANSACTIONS: 'TRAINER/SET_TRANSACTIONS',
    SET_MONTHS: 'TRAINER/SET_MONTHS',
    SET_FILTER_MONTH: 'TRAINER/SET_FILTER_MONTH',
    SET_IS_LOADED_REMAINDER: 'TRAINER/SET_IS_LOADED_REMAINDER',
    SET_IS_LOADED_MONTHS: 'TRAINER/SET_IS_LOADED_MONTHS',
    SET_IS_LOADED_TRANSACTIONS: 'TRAINER/SET_IS_LOADED_TRANSACTIONS',
    SET_ERROR_REMAINDER: 'TRAINER/SET_ERROR_REMAINDER',
    SET_ERROR_MONTHS: 'TRAINER/SET_ERROR_MONTHS',
    SET_ERROR_TRANSACTIONS: 'TRAINER/SET_ERROR_TRANSACTIONS',
    SET_TOTAL_PAGES: 'TRAINER/SET_TOTAL_PAGES',
    SET_PAGE: 'TRAINER/SET_PAGE',
};

export default trainerTypes;