import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import getUrlAndTextForPushNotification from '../utils/getUrlAndTextForPushNotification';

const firebaseKeys = {
    vapidKey: 'BM4SFeloDsexeOfz5rBewbjjFOdBumaxjdbTGuuzu3g6RKUCMY3Giq6wcT2AdGLnzfb-GhzrrvREBYcK7hvAT80',
    senderKey: '978395429796'
};

initializeApp({
    apiKey: 'AIzaSyDYGZDZi3u90duD3C1lL9y2MTE9fWQpblQ',
    appId: '1:978395429796:web:67997154270d130d5b5f31',
    projectId: 'nuznyisport-18393',
    messagingSenderId: firebaseKeys.senderKey
});

const messaging = getMessaging();

export const getBrowserToken = () => {
    return new Promise((resolve, reject) =>
        Notification.requestPermission()
            .then(permission => {
                if (permission === 'granted') {
                    getToken(messaging, {vapidKey: firebaseKeys.vapidKey})
                        .then(token => resolve(token))
                        .catch(e => reject(e));
                } else {
                    reject('Настройка уведомлений выключена в браузере');
                }
            })
    );
};

export const onMessageListener = () => {
    onMessage(messaging, payload => {
        const { text, url } = getUrlAndTextForPushNotification(payload.data.title);
        if ('Notification' in window) {
            const notification = new Notification(text);
            if (url) {
                notification.onclick = function(event) {
                    event.preventDefault();
                    window.open(url, '_blank');
                }
            }
        } else {
            navigator.serviceWorker.register('firebase-messaging-sw.js');
            navigator.serviceWorker.ready
                .then(registration => {
                    registration.showNotification(text, {tag: 'once'})
                })
                .catch(error => console.error('ServiceWorker registration failed', error));
        }
    });
}
