import { useMemo, useRef } from 'react';

export const useValidateInput = inputType => {
    const validateInputFunctionRef = useRef();

    const validateText = e => {
        if (e.key.match(/[^\sA-zА-я]|[\\_]/g)) {
            e.preventDefault();
        }
    }

    const validateTextarea = e => {
        if (e.key.match(/[^\s_A-zА-я0-9,.]/g)) {
            e.preventDefault();
        }
    }

    const validateNumber = e => {
        if ((['+','-','e'].includes(e.key)) || (e.key.match(/\d/) && e.target.value.length > 3)) {
            e.preventDefault();
        }
    }

    const validateConfirmCode = e => {
        if ((e.key.match(/\d/) && e.target.value.length > 4)) {
            e.preventDefault();
        }
    }

    const validatePhone = e => {
        if (e.key.match(/\D/) && e.key !== 'Backspace' && e.key !== 'Enter') {
            e.preventDefault();
        }
    }

    const validatePassword = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    }

    useMemo(() => {
        switch (inputType) {
            case 'text':
                validateInputFunctionRef.current = validateText;
                break;
            case 'textarea':
                validateInputFunctionRef.current = validateTextarea;
                break;
            case 'confirmCode':
                validateInputFunctionRef.current = validateConfirmCode;
                break;
            case 'number':
                validateInputFunctionRef.current = validateNumber;
                break;
            case 'tel':
                validateInputFunctionRef.current = validatePhone;
                break;
            case 'password':
                validateInputFunctionRef.current = validatePassword;
                break;
            default:
                validateInputFunctionRef.current = () => {};
                break;
        }
    }, [inputType]);

    return {
        validate: validateInputFunctionRef.current
    };
};