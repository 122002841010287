import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePrivateRoutes } from '../../hooks/usePrivateRoutes';
import { StaticMenu, Delimiter, CustomLink, SidebarAdditionalBlocks, TrainerMenu, SitesMenu, UserAgreements } from '../../components';
import { hideSidebarPathNames } from '../../const';
import './sidebar.scss';

const Sidebar = () => {
    const { first_name, second_name, email } = useSelector(state => state.user.registerData);
    const {
        trainerParticipation: isTrainerParticipation,
        trainerQuestionnaireCompleted: isTrainerQuestionnaireCompleted
    } = useSelector(({ user }) => user);

    const routes = usePrivateRoutes();
    const { pathname } = useLocation();

    return (
        <aside className="sidebar">
            <section className="sidebar__profile">
                <div className="sidebar__profile-avatar">{first_name && first_name[0]}{second_name && second_name[0]}</div>
                <div className="sidebar__profile-name">{first_name || 'Имя'} {second_name || 'Фамилия'}</div>
                <div className="sidebar__profile-email">{email || 'E-mail'}</div>
                <Delimiter />
                <div className="sidebar__profile-link profile-link">
                    <CustomLink to="/profile" className="profile-link__item">Изменить профиль</CustomLink>
                </div>
            </section>

            {!hideSidebarPathNames.includes(pathname) && (
                <>
                    {isTrainerQuestionnaireCompleted && (
                        <section className="sidebar__menu">
                            <TrainerMenu
                                access={isTrainerParticipation}
                            />
                        </section>
                    )}

                    <section className="sidebar__menu">
                        {routes.length && routes.map(({title, path, icon, exact = false, types = []}, index) =>
                            types && types.includes('sidebar') &&
                            <NavLink to={path} className="sidebar__menu-item" exact={exact} key={`${path} - ${index}`}>
                                {icon &&
                                    <i className="sidebar__menu-item-icon">
                                        {icon({
                                            size: "24px",
                                            stroke: "#92929D"
                                        })}
                                    </i>
                                }
                                <span className="sidebar__menu-item-text">{title}</span>
                            </NavLink>
                        )}
                        <StaticMenu />
                    </section>

                    <SitesMenu />

                    <UserAgreements location="sidebar" />
                </>
            )}

            <SidebarAdditionalBlocks />
        </aside>
    );
};

export default memo(Sidebar);