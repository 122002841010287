import { setLoading } from '../action-creators/loading';
import { setSettings, setLoaded } from '../action-creators/notificaions';
import apiClient from '../../service/axios';
import { toast } from 'react-toastify';

export const getNotificationsSettings = () => async dispatch => {
    dispatch(setLoading(true));
    try {
        const {data} = await apiClient.get('/notifications/get-settings');
        if (data.type !== 'ERROR') {
            dispatch(setSettings(data.settings));
            dispatch(setLoaded(true));
        } else {
            toast.error('Не удалось получить настройки уведомлений');
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
}

export const changeNotificationsSettings = (type, id) => async (dispatch, getState) => {
    const { settings: oldSettings } = getState().notifications;
    const newSettings = {
        ...oldSettings,
        [type]: oldSettings[type].map(item => (
            item.id === id ? {...item, value: !item.value} : item
        ))
    };
    dispatch(setSettings(newSettings));
    const dataToSend = {ids: newSettings[type].filter(setting => setting.value === true).map(setting => setting.id)};
    try {
        const {data} = await apiClient.post(`/notifications/set-${type.replace('_', '-')}`, JSON.stringify(dataToSend));
        if (data.type !== 'OK') {
            toast.error('Не удалось изменить настройку уведомления');
            dispatch(setSettings({type, id}));
        }
    } catch (e) {
        console.error(e);
        toast.error('Не удалось изменить настройку уведомления');
        dispatch(setSettings(oldSettings));
    }
}

export const sendBrowserToken = token => async () => {
    const oldToken = localStorage.getItem('notificationToken');
    if (oldToken === token) return;

    const dataToSend = { oldToken, newToken: token };
    try {
        const {data} = await apiClient.post('/notifications/add-token', JSON.stringify(dataToSend));
        if (data.type === 'OK') {
            localStorage.setItem('notificationToken', token);
        } else {
            toast.error('Ошибка сохранения настроек для Push-уведомлений! Попробуйте настроить позже');
        }
    } catch (e) {
        console.error(e);
    }
}