import { PageTitle, Delimiter, Conditions, CardOrBanner } from '../../components';
import './card.scss';

const Card = ({ title }) => {
    //TODO::9257 - Зполнить реальными данными
    return (
        <>
            <PageTitle title={title} />
            <section className="page card-page">
                <CardOrBanner />
                <Delimiter />
                <Conditions>
                    ✓ Участниками бонусной программы являются физические лица, достигшие возраста 18 лет.
                    <br/><br/>
                    Сотрудники ООО «А.В.Т. СПОРТ» не могут принимать участие в Программе.
                    <br/><br/>
                    Программа рассчитана на привлечение новых покупателей в магазины «А.В.Т. СПОРТ» и увеличение продаж, а также поощрение постоянных покупателей предоставлением дополнительных скидок и кэшбэка.
                    <br/><br/>
                    ✓ С целью увеличения продаж постоянным покупателя выдается пластиковая карта Программы лояльности «Нужный спорт» с нанесением штрихкода и содержанием ID-кода.
                    <br/><br/>
                    Количество карт Программы лояльности «Нужный спорт» ограничено тиражом. Для получения карты Программы лояльности «Нужный спорт» необходимо обратиться к администраторам магазинов «А.В.Т. СПОРТ».
                    <br/><br/>
                    ✓ Держатель карты при совершении покупки получает скидку в магазинах «А.В.Т. СПОРТ» в размере до 20 % на товары для спортивных секций. Для совершения покупки достаточно назвать ID-номер карты.
                    <br/><br/>
                    Владельцу карты Программы лояльности «Нужный спорт» сразу после покупки начисляются бонусы (1 бонус равен 1 рублю) и выплачивается кэшбэк в размере от 4 % до 7 % от стоимости товаров, приобретенных по карте. Размер бонусов зависит от категории товара (обувь, одежда для гимнастики - 7%, аксессуары, чехлы - 5%, спортивные предметы - 4%).
                    <br/><br/>
                    В случае возврата товара по основаниям, не связанным с его качеством, кэшбэк пересчитывается.
                    <br/><br/>
                    ✓ Для удобства исчисления суммы кэшбэка владелец карты Программы лояльности «Нужный спорт» может скачать программное приложение для телефона Jamp Работа в App Store и Google Play и отслеживать накопленные бонусы в режиме он-лайн.
                    <br/><br/>
                    Факт регистрации в программном приложении для телефона означает подтверждение участия владельца карты Программы лояльности «Нужный спорт» в данной программе и принятие её условий (акцепт оферты).
                    <br/><br/>
                    ✓ Настоящая бонусная программа действует до 31.12.2021г. (может быть продлена) и не может быть досрочно прекращена до истечения этой даты (данная оферта не предусматривает досрочного отзыва оферты).
                    <br/><br/>
                    Бонусы, накопленные на карте Программы лояльности должны быть получены владельцем карты до 31.12.2021г., после указанной даты бонусы обнуляются.
                    <br/><br/>
                    Бонусы, накопленные за декабрь, не сгорают, а переносятся к выплате на январь 2022 года.
                    <br/><br/>
                    ✓ ООО «А.В.Т. СПОРТ» вправе вносить изменения и дополнения в условия настоящей Программы. В случае изменения настоящей Программы ООО «А.В.Т. СПОРТ» уведомляет об этом не менее чем за 30 календарных дней до вступления новой редакции настоящей Программы в силу путем размещения новой редакции настоящей Программы на данном сайте.
                    <br/><br/>
                    Участник программы обязуется самостоятельно отслеживать внесение изменений и дополнений в настоящую Программу. Совершение Участником программы действий по использованию карты Программы лояльности «Нужный спорт» и/или настоящей Программы после вступления в силу новой редакции настоящей Программы является подтверждением согласия Участника программы с новой редакцией настоящей Программы.
                    <br/><br/><br/>
                    Присоединение к программе осуществляется на условиях публичной оферты.
                    <br/><br/>
                    При присоединении участника программы к условиям публичной оферты срок для акцепта составляет не менее 30 дней.
                </Conditions>
            </section>
        </>
    );
};

export default Card;