import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import './dadata-input.scss';
import classNames from 'classnames/bind';

const DadataInput = ({ label, name, handleChange, value, required, error, helpText, defaultQuery, filterFromBound = 'city', filterToBound = 'houses', ...props }) => {

    return (
        <div className={classNames({
                'input-dadata-wrapper': true,
                'error': !!error
            })}
             title={defaultQuery}
        >
            <label className="custom-label">
                {label}
                {label && required && <span className="required-mark">*</span>}
            </label>
            <AddressSuggestions
                filterFromBound={filterFromBound}
                filterToBound={filterToBound}
                inputProps={{name, ...props}}
                defaultQuery={defaultQuery}
                value={value}
                required={required}
                token={process.env.REACT_APP_DADATA_API_KEY}
                onChange={value => handleChange({target: {name, value}})}
                autocomplete="off"
            />
            {error && <div className="error-text">{error}</div>}
            {helpText && <div className="help-text">{helpText}</div>}
        </div>
    );
};

export default DadataInput;