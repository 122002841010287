import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../../hooks/useInput';
import { sendEmailAndPassword } from '../../store/actions/auth';
import { Button, Input } from '../UI';
import { setError } from '../../store/action-creators/auth';

const FormEmail = ({ onSubmit }) => {
    const { state: form, handleChange, clearInput } = useInput({email: '', password: ''});
    const error = useSelector(state => state.auth.error);
    const dispatch = useDispatch();

    const confirmData = e => {
        e.preventDefault()
        dispatch(sendEmailAndPassword(form))
            .then(() => onSubmit())
            .catch(() => {clearInput('password')});
    }

    useEffect(() => {
        dispatch(setError(null));
    }, [])

    return (
        <form className="form-email" onSubmit={confirmData}>
            <Input
                autoFocus
                type="email"
                label="Ваш e-mail"
                name="email"
                placeholder="E-mail"
                error={error}
                value={form.email}
                handleChange={handleChange}
                required
            />
            <Input
                type="password"
                label="Ваш пароль"
                name="password"
                placeholder="Пароль"
                error={error}
                value={form.password}
                handleChange={handleChange}
                required
            />
            <Button type="submit" className="button__black" disabled={form.email === '' || form.password === ''}>Войти</Button>
        </form>
    );
};

export default FormEmail;