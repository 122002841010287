import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormSms, FormEmail, LineButton, CustomLink } from '../../components/';
import { login, checkRedirect } from '../../store/actions/auth';
import { useDispatch } from 'react-redux';
import './login.scss';

const Login = () => {
    const [isSmsForm, setIsSmsForm] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const signIn = () => {
        dispatch(checkRedirect())
            .then(() => {})
            .catch(() => dispatch(login()));
    }

    const checkUser = (data, phoneNumber) => {
        if (data.registerAllow) {
            history.push({
                pathname: '/register',
                state: {
                    phoneNumber
                }
            });
        } else {
            signIn();
        }
    }

    return (
        <div className="form-auth">
            <div className="form-auth__header auth-header">
                <div className="form-auth__title auth-header__item">
                    {isSmsForm
                        ? 'Авторизация/Регистрация'
                        : 'Авторизация'
                    }
                </div>
                <div className="auth-header__item">
                    {isSmsForm
                        ? <LineButton handleClick={() => setIsSmsForm(false)}>Войти с помощью e-mail и пароля</LineButton>
                        : <LineButton handleClick={() => setIsSmsForm(true)}>Войти/Зарегистрироваться с помощью СМС</LineButton>
                    }
                </div>
            </div>

            <div className="form-auth__body">
                {isSmsForm
                    ? <FormSms onSubmit={checkUser} />
                    : <FormEmail onSubmit={signIn} />
                }
            </div>

            <div className="form-auth__footer">
                <div className="form-auth__footer-links">
                    <CustomLink to='/password-recovery'>Забыли пароль?</CustomLink>
                </div>
            </div>
        </div>
    );
};

export default Login;