import { PageTitle, BlockTitle, ProductsForReview, ReviewsList } from '../../components';
import './reviews.scss';

const Reviews = ({ title }) => {
    return (
        <>
            <PageTitle title={title} />
            <section className="page reviews-page">
                <div className="reviews-page__header">
                    <BlockTitle
                        title="Мои отзывы"
                        description="На этой странице находятся Ваши отзывы и список последних купленных товаров."
                    />
                </div>
                <div className="reviews-page__body">
                    <ProductsForReview />
                    <ReviewsList />
                </div>
            </section>
        </>
    );
};

export default Reviews;