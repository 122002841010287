import { useMemo, useRef, useState } from 'react';

export const useInput = initialState => {
    const [state, setState] = useState(initialState);
    const handleFunction = useRef();

    const handleChangeObject = e => {
        setState({...state, [e.target.name]: e.target.value});
    }

    const handleChangeValue = e => {
        setState(e.target.value);
    }

    const clearInput = (key = '') => {
        if (typeof state === 'object' && state.hasOwnProperty(key)) {
            setState({...state, [key]: ''})
        } else {
            setState('')
        }
    }

    useMemo(() => {
        if (typeof initialState === 'object') {
            handleFunction.current = handleChangeObject
        } else {
            handleFunction.current = handleChangeValue
        }
    }, [initialState]);

    return {
        state,
        handleChange: handleFunction.current,
        clearInput
    };
};