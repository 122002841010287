import { Checkbox } from 'pretty-checkbox-react';
import classNames from 'classnames/bind';
import './custom-checkbox.scss'

const CustomCheckbox = ({ label, state, required, error, ...props }) => {
    return (
        <div className="custom-checkbox-wrapper">
            <Checkbox className={classNames({
                'custom-checkbox': true,
                'checked': state
            })}
                icon={<img className="custom-checkbox__icon" src="/images/checkmark.svg"/>}
                state={state}
                required={required}
                {...props}
            >
                {label}
                {required && <span className="required-mark">*</span>}
            </Checkbox>
            {error && <div className="error-text">{error}</div>}
        </div>
    );
};

export default CustomCheckbox;