import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../../hooks/useInput';
import { useTimer } from '../../hooks/useTimer';
import { Button, Input, LineButton } from '../UI';
import { sendSmsCode, confirmCode, sendPhoneCall } from '../../store/actions/auth';
import { authType } from '../../const';
import './formCode.scss';

const FormCode = ({ type, phoneNumber, handleClick }) => {
    const { state: code, handleChange: changeCode, clearInput } = useInput('');
    const { isNotWorking: isAcceptSending, setIsNotWorking: setIsAcceptSending, time } = useTimer(+process.env.REACT_APP_SMS_TIMEOUT);
    const error = useSelector(state => state.auth.error);
    const dispatch = useDispatch();

    const confirmData = e => {
        e.preventDefault();
        dispatch(confirmCode(phoneNumber, code))
            .then((data) => handleClick(data, phoneNumber))
            .catch(() => clearInput());
    }

    const resendSms = () => {
        clearInput();
        dispatch(sendSmsCode(phoneNumber));
        setIsAcceptSending(false);
    }

    const recall = () => {
        clearInput();
        dispatch(sendPhoneCall(phoneNumber));
        setIsAcceptSending(false);
    }

    return (
        <>
            <div className="phone-info">
                <div className="phone-info__recipients">Номер получателя: <span className="phone-info__number">{phoneNumber}</span></div>

                {type === authType.SMS &&
                    <>
                        {isAcceptSending
                            ? <LineButton handleClick={resendSms}>Выслать код повторно</LineButton>
                            : <span className="phone-info__timer">Выслать код повторно можно будет через: {time}с</span>
                        }
                    </>
                }

                {type === authType.CALL &&
                    <>
                        {isAcceptSending
                            ? <LineButton handleClick={recall}>Позвонить повторно</LineButton>
                            : <span className="phone-info__timer">Позвонить повторно можно будет через: {time}с</span>
                        }
                    </>
                }

            </div>
            <form className="form-code" onSubmit={confirmData}>
                <Input
                    autoFocus
                    type="number"
                    label={type === authType.SMS ? "Код из СМС" : "Последние 4 цифры номера входящего звонка"}
                    name="code"
                    placeholder={type === authType.SMS ? "Код" : "Последние 4 цифры"}
                    error={error}
                    value={code}
                    handleChange={changeCode}
                    max={9999}
                    min={0}
                    required
                />
                <Button type="submit" className="button__black" disabled={code.length < 4}>Продолжить</Button>
            </form>
        </>
    );
};

export default FormCode;