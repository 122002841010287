import { memo } from 'react';
import { ProductsCard, ProductsLoadingCard } from '../../../components';
import './products-list.scss';

const ProductsList = ({ items, isLoading, keys, emptyComponent, handleFavoriteChange }) => {
    const renderCards = () => {
        if (isLoading) {
            return (
                <div className="favorites-list__wrap">
                    {
                        Array(4)
                            .fill(0)
                            .map((_, index) => <ProductsLoadingCard key={index} />)
                    }
                </div>
            );
        }

        if (items && items.length > 0) {
            return (
                <div className="favorites-list__wrap">
                    {items.map((item) => (
                        <ProductsCard
                            key={item[keys.id]}
                            id={item[keys.id]}
                            name={item[keys.name]}
                            picture={item[keys.picture]}
                            link={item[keys.link]}
                            price={item[keys.price]}
                            oldPrice={item[keys.oldPrice]}
                            site={item[keys.site]}
                            available={item[keys.available] || 'Y'}
                            isFavorite={item[keys.isFavorite] || 'Y'}
                            handleFavoriteChange={handleFavoriteChange}
                        />
                    ))}
                </div>
            );
        } else {
            return emptyComponent;
        }
    };

    return (
        <section className="favorites-list">
            {renderCards()}
        </section>
    );
};

export default memo(ProductsList);